import React from 'react';
import ReactDOM from 'react-dom'
import _ from 'lodash';
import { connect } from 'react-redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { bindActionCreators } from 'redux';
import {Table,Button,Panel,Input,Select,Modal} from 'react-bootstrap';
import DateTimePicker from '../../components/DateTimePicker';
import moment from 'moment';


class LabEdit extends React.Component {
	constructor(props) {
		super(props);
		if(this.props.currentLab) {
			var date = this.props.currentLab.lab_date
			var id = this.props.currentLab.id
			var lab_detail = this.props.currentLab.lab_detail
		}
		this.state = {
			lab_date: date || moment().format("YYYY-MM-DD"),
			id: id || Date.now(),
			lab_detail: lab_detail || [{id: _.uniqueId('newLabDetail'), lab_name: "",lab_value: "",lab_unit_id: 13}],
			gridHeight: 1,
			dateAdded: false,
			errorList: [],
			showModal: false
		}
		this.toggleCancelConfirmation = this.toggleCancelConfirmation.bind(this);
	}

	handleDateChange(newDate) {
		return this.setState({lab_date: newDate});
	}

	handleDateFocus(e) {
		e.target.value = "";
	}

	handleDateBlur(e) {
		if(this.state.lab_date == "Invalid date") {
			this.setState({dateError: true})
		} else {
			this.setState({dateError: false})
		};
		if(e.target.value == "") {
			e.target.value = moment(this.state.lab_date, 'YYYY-MM-DD').format('MM/DD/YYYY');
		};
	}

	handleInputChange(labDetail, key, i, e) {
		if(this.state.errorList.indexOf(labDetail.id) !== -1) {
			var errorList = this.state.errorList;
			var index = errorList.indexOf(labDetail.id);
			errorList.splice(index, 1)
			this.setState({errorList: errorList})
		}
		var labDetailClone = _.cloneDeep(labDetail);
		labDetailClone[key] = e.target.value;
		var labCollection = _.cloneDeep(this.state.lab_detail);
		labCollection.splice(i, 1, labDetailClone);
		this.setState({lab_detail: labCollection})
	}

	toggleCancelConfirmation(e) {
		e.preventDefault();
        this.props.showSecondaryTimer();
		this.setState({ showModal: !this.state.showModal });
	}

	createRow() {
		var labCollection = this.state.lab_detail.slice();
		labCollection.push({id: _.uniqueId('newLabDetail'), lab_name: "",lab_value: "",lab_unit_id: 13});
		this.setState({lab_detail: labCollection});
	}

	deleteRow(index) {
		var labCollection = _.cloneDeep(this.state.lab_detail);
		labCollection.splice(index, 1);
		this.setState({lab_detail: labCollection})
	}

	submitEdit(e) {
		e.preventDefault();
		var emptyError;
		var errorList = this.state.errorList
		_.forEach(this.state.lab_detail, function(lab) {
			if((lab.lab_name.length === 0 || !lab.lab_name.trim()) || (lab.lab_value.length === 0)) {
				emptyError = true
				errorList.indexOf(lab.id) === -1 ? errorList.push(lab.id) : null
				this.setState({errorList: errorList})
			}
		}.bind(this))
		if(emptyError) {
	        this.props.commonActions.showToast({
	            status: 'error',
	            title: 'Empty Fields',
	            message: 'All fields need a name and value'
	        });
	        var interval = setInterval(()=> {
	            clearInterval(interval);
	            this.props.commonActions.hideToast();
	        }, 2);
		}
		this.state.dateError || emptyError ? null : this.props.submitEdit(this.state)
	}

	render() {
		var dateInputProps = {
			onFocus: e => this.handleDateFocus(e),
			onBlur: e => this.handleDateBlur(e),
			placeholder: 'MM/DD/YYYY',
			maxLength: 10
		};
		return (
			<form onSubmit={this.submitEdit.bind(this)}>
				<Panel className="col-md-offset-3 col-md-6">
					<DateTimePicker
						dateTime={this.state.lab_date}
						format="YYYY-MM-DD"
						viewMode="years"
						inputFormat="MM/DD/YYYY"
						onChange={this.handleDateChange.bind(this)}
						inputProps={dateInputProps}
					/>
					<Table className="alignleft addLabContainer" striped bordered>
						<thead>
							<tr>
								<th>Delete</th>
								<th>Name</th>
								<th>Value</th>
								<th>Unit</th>
							</tr>
						</thead>
						<tbody>
							{_.map(this.state.lab_detail, (labDetail, i)=> {
								var missingValue = this.state.errorList.indexOf(labDetail.id) !== -1 ? true : false;
								return (
									<tr key={labDetail['id']} className={missingValue ? "labRowError" : null}>
										<td><i className="fa fa-times" aria-hidden="true" onClick={this.deleteRow.bind(this, i)}></i></td>
										<td><Input id={'labNameInput' + i} type="text" value={labDetail['lab_name']} onChange={this.handleInputChange.bind(this, labDetail, 'lab_name', i)} /></td>
										<td><Input id={'labValueInput' + i} type="text" step="any" value={labDetail['lab_value']} onChange={this.handleInputChange.bind(this, labDetail, 'lab_value', i)} /></td>
										<td>
											<select className="form-control" id={'labUnitInput' + i} value={labDetail['lab_unit_id']} onChange={this.handleInputChange.bind(this, labDetail, 'lab_unit_id', i)}>
												{_.map(this.props.labUnits, (unit)=> <option key={unit['id']} value={unit['id']}>{unit['lab_unit']}</option>)}
											</select>
										</td>
									</tr>
								)
							})}
						</tbody>
					</Table>

					<Button bsStyle="default" className="aligncenter labAddRow" onClick={()=>this.createRow()}>
						<i className="fa fa-plus labIconPadding" aria-hidden="true"></i>Add Another Row
					</Button>
					<Button bsStyle="danger" className="aligncenter labButtonMargin" onClick={this.toggleCancelConfirmation}>Cancel</Button>
					<Button bsStyle="success" type="submit" className="aligncenter">Save</Button>
				</Panel>
				<Modal show={this.state.showModal} onHide={this.toggleCancelConfirmation}>
		          <Modal.Header closeButton>
		            <Modal.Title>Confirm cancellation</Modal.Title>
		          </Modal.Header>
		          <Modal.Body>
		            <p>Clicking "Confirm" will cause you to lose any unsaved changes. If you would like to continue editing labs, please click "Cancel."</p>
		          </Modal.Body>
		          <Modal.Footer>
		          	<Button bsStyle="default" onClick={this.toggleCancelConfirmation}>Cancel</Button>
		          	<Button bsStyle="danger" onClick={this.props.cancelEdit.bind(this)}>Confirm</Button>
		          </Modal.Footer>
		        </Modal>
			</form>
		)
	}
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect (mapStateToProps, mapDispatchToProps)(LabEdit);