import React from 'react';
import PatientQueue from './PatientQueue';

const OptOutQueue = () =>
    <PatientQueue
        showQueueCount
        showFilters
        patientDetailColumn
        providerDetailColumn
        actionsColumn
        page
        lastOutCallColumn
        lastHistoryColumn
        queue="Optout"
        endpoint="Optout"
        label="OptOut"
        showSort
        sortOptions={[
            {
                'name': 'Last Called Ascending',
                'key': 'lastOutCallTs&dir=asc',
            },
            {
                'name': 'Last Called Descending',
                'key': 'lastOutCallTs&dir=desc',
            }
        ]}
    />;

export default OptOutQueue;
