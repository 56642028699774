import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmationModal = ({ show, onConfirm, onHide }) =>
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Confirm Email Enrollment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                The patient has reviewed electronically the chronic care management program and the services that will be provided to the patient including electronic communication of medical information with other treating practitioners and providers. The patient has been explained how to unenroll from the chronic care management program. The patient was instructed that they can only have one chronic care management provider. The patient is aware that there may be cost-sharing involved with the CCM service. The patient was given the opportunity to ask questions and have them answered prior to enrollment. The patient has agreed to participate in the chronic care management program and has consented electronically in writing.
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button bsStyle="default" onClick={onHide}>
                Cancel
            </Button>
            <Button bsStyle="success" onClick={onConfirm}>
                Enroll
            </Button>
        </Modal.Footer>
    </Modal>;

export default ConfirmationModal;
