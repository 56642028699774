import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as appActionCreator from '../../actions/app';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import {Panel,Input,Button,Grid, Row, Col} from 'react-bootstrap';
import ResponsiveFixedDataTable from '../../components/ResponsiveFixedDataTable';
import PageHeader from '../../components/PageHeader';
import {Cell, Column} from "fixed-data-table";
import PaginationBar from '../../components/PaginationBar'
import RecordFixNoteModal from './RecordFixNoteModal';

class FixFaxRecordsScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            showModal:false
        }
    }

    componentDidMount(){
        this.props.adminActions.getInboundRecordsToFix();
    }
    handleColumnResize = (columnWidth, columnKey) => {
        this.setState({
            columnWidths: {
                ...this.state.columnWidths,
                [columnKey]: columnWidth,
            },
        });
    }

    previewFile = (queueRecord) => {
        this.props.adminActions.openRecordFile(queueRecord);
    }
    onPopupHide = () => {
        this.setState({showModal:false})
    }

    onPopupShow = (record) => {
        this.setState({selectedRecord:record , showModal:true})
    }
    render() {
        let {inboundRecordsToFixQueue} = this.props;
        const queueRecord = inboundRecordsToFixQueue ? inboundRecordsToFixQueue.items : [];
        const meta = inboundRecordsToFixQueue ? inboundRecordsToFixQueue : {};
        return <div>
            <PageHeader>
                Fix Fax Records
                {meta.total_items >= 0 &&
                ` - ${meta.total_items}`}
            </PageHeader>
            <div className="consent-form-left-panel" style={{width:'100%'}}>
                <Panel
                    header={<div className="clearfix">
                        <div className="pull-left">
                            Fix Fax Records
                        </div>
                    </div> }>
                    <div style={{ height: '600px', position: 'relative' }}>
                    <ResponsiveFixedDataTable height={600}
                                              width={600}
                                              rowHeight={60}
                                              headerHeight={50}
                                              isColumnResizing={false}
                                              rowsCount={queueRecord.length ? queueRecord.length : 0}
                                              onColumnResizeEndCallback={this.handleColumnResize}
                                              {...this.props}>
                        <Column
                            columnKey="faxNumber"
                            fixed={false}
                            isResizable={true}
                            width={350}
                            header={<Cell>Fax Number</Cell>}
                            cell={({ rowIndex, data, columnKey, ...props }) =>
                                <Cell {...props}>
                                    {queueRecord[rowIndex].caller_id}
                                </Cell>
                            }
                        />
                        <Column
                            columnKey="dateTime"
                            fixed={false}
                            isResizable={true}
                            width={350}
                            header={<Cell>Date</Cell>}
                            cell={({ rowIndex, data, columnKey, ...props }) =>
                                <Cell {...props}>
                                    {queueRecord[rowIndex].create_ts}
                                </Cell>
                            }
                        />
                        <Column
                            columnKey="viewRecord"
                            fixed={false}
                            isResizable={true}
                            width={350}
                            header={<Cell></Cell>}
                            cell={({ rowIndex, data, columnKey, ...props }) =>
                                <Cell {...props}>
                                    <div style={{
                                        cursor: 'pointer',
                                        color: 'rgb(79,129,182)',
                                        textAlign:'center'
                                    }}
                                         onClick={()=>this.previewFile(queueRecord[rowIndex])}>{'View Record'}</div>
                                </Cell>
                            }
                        />
                        <Column
                            columnKey="dateTime"
                            fixed={false}
                            isResizable={true}
                            width={350}
                            header={<Cell></Cell>}
                            cell={({ rowIndex, data, columnKey, ...props }) =>
                                <div style={{textAlign:'center',height:60,lineHeight:4}}>
                                    <Button
                                        bsStyle="primary"
                                        style={{ marginLeft: 15 }}
                                        onClick={() => this.onPopupShow(queueRecord[rowIndex])}>
                                        Complete
                                    </Button>
                                </div>
                            }
                        />

                    </ResponsiveFixedDataTable>
                    <PaginationBar
                        visibleBtns={5}
                        pageCount={meta.total_pages}
                        currentPage={ meta.page + 1}
                        onPageSelected={(pageNum) =>
                            this.props.adminActions.getInboundRecordsToFix(pageNum - 1)
                        }
                    />
                    </div>
                </Panel>
                <RecordFixNoteModal show={this.state.showModal}
                                    onPopupHide={this.onPopupHide}
                                    selectedRecord={this.state.selectedRecord}/>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    'inboundMedicalForms': state.consentForms.inboundMedicalForms,
    'medicalFormDocument': state.consentForms.medicalFormDocument,
    'medicalFormDocumentUrl': state.consentForms.medicalFormDocumentUrl,
    'selectedDoc':state.consentForms.selectedDoc,
    'userId': state.auth.id,
    inboundRecordsToFixQueue: state.faxMatching.inboundRecordsToFixQueue,
    isFetching: state.app.isFetching
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    appActions:bindActionCreators(appActionCreator,dispatch),
    commonActions:bindActionCreators(commonActionCreator,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FixFaxRecordsScreen);
