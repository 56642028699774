import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentQueueAppointment = () =>
	<PatientQueue
		showQueueCount
		patientDetailColumn
		providerDetailColumn
		timeBilledColumn
		todoItemColumn
		statusColumn
		showFilters
		showProgramTypeFilter
        showScheduleCallFilter
		chartColumn
		lastWorkedByColumn
		page
		queue="urgentAppointment"
		endpoint="Urgent?typesToInclude=2"
		label="Urgent - Appointment"
	/>;

export default UrgentQueueAppointment;
