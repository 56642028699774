/**
 * Created by synerzip on 21/03/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Input,Modal,Button,FormControl,FormGroup,ControlLabel} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
var LinkedStateMixin = require('react-addons-linked-state-mixin');

class AddCareGoalIntervention extends React.Component{
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentWillReceiveProps(nextProps){
        if(this.props.statusId != nextProps.statusId){
            this.setState({
                statusId:nextProps.statusId
            })
        }
    }
    
    onCompletionStatusChanged(status){
        this.setState({statusId:status.id});
    }
    onInterventionTypeChanged(interventionType){
        this.setState({interventionTypeId:interventionType.id});
    }
    onSubmit(e) {
        e.preventDefault();
        let isError = false;
        const {selectedPatient,adminActions,goalId} = this.props;
        

        if(!this.state.statusId){
            this.setState({
                statusIdError: true
            });
            isError = true;
        }else{
            this.setState({
                statusIdError: false
            })
        }

        if(!this.state.evaluation){
            this.setState({
                evaluationError: true
            });
            isError = true;
        }else{
            this.setState({
                evaluation: false
            })
        }

        if(!isError){
            this.props.showSecondaryTimer();
            adminActions.addGoalIntervention(selectedPatient.id,
                goalId,
                this.state.problem,
                this.state.statusId,
                this.state.action,
                this.state.plan,
                this.state.evaluation);
            this.setState(
                {
                    problem:'',
                    action:'',
                    plan:'',
                    evaluation:'',
                    statusId:null,
                    statusIdError:false,
                }
            );
            this.props.onPopupHide();            
        }
    }

    onCancel() {
        this.setState(
            {
                problem:'',
                statusId:null,
                action:'',
                plan:'',
                evaluation:'',
                statusIdError:false,
            }
        );
        this.props.onCloseEdit();
        this.props.onPopupHide();
    }

    render(){
        const {goalCompletionStatuses,stepTypes,selectedPatient,
            icd10code,
            goalTitle} = this.props;
        return (
            <Modal show={this.props.show} className="add-goal-modal">
                <Modal.Header closeButton onHide={()=>{
                                this.props.onPopupHide();
                            }}>
                    <Modal.Title>
                        <div style={
                            {
                            textAlign: 'center'
                            }
                        }>
                            <div>Add Care Goal Intervention</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{overflow:'auto'}}>
                        <form onSubmit={this.onSubmit.bind(this)} novalidate>
                            <div style={{marginBottom:15,display:'flex',
                                flexDirection:'column',padding:15,
                                backgroundColor:'rgb(210,230,243)',borderRadius:5}}>
                                <div style={{display:'flex',fontWeight:600}}>
                                    <span>Care Goal:</span> 
                                    <span style={{paddingLeft:10}}>{goalTitle}</span>
                                </div>
                                <div style={{display:'flex',fontWeight:600}}>
                                    <span>Diagnosis:</span> 
                                    <span style={{paddingLeft:10}}>{icd10code}</span>
                                </div>
                            </div>
                            
                                
                            <Input type="text" label="Problem" 
                                placeholder="Enter Patient Problem" required
                                valueLink={this.linkState('problem')} maxLength="100"/>
                           
                            <FormGroup className={this.state.evaluationError}>                        
                                <ControlLabel>Evaluation</ControlLabel>
                                <div>
                                    <FormControl componentClass="textarea" label="Evaluation" placeholder="Enter Evaluation"
                                        valueLink={this.linkState('evaluation')} maxLength="4096" style={{resize:'none',height:'100px'}}/>
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        {`${this.state.evaluation ? this.state.evaluation.length : 0}/4096`}
                                    </div>   
                                </div>     
                                {this.state.evaluationError && <span className="text-danger"><small>Please enter evaluation</small></span>}
                            </FormGroup>   
                            <div style={{display:'flex',
                                        justifyContent:'space-between',
                                        flexDirection:'column',
                                        padding:15,
                                        paddingLeft:0,
                                        paddingRight:0}}>
                                <ControlLabel>Care Plan Completion Status</ControlLabel>
                                <div>
                                    <div style={{display:'flex'}}>
                                        {
                                                _.map(goalCompletionStatuses,(status) => {
                                                    return <div key={status.id} style={{display:'flex',alignItems:'center',flex:1}}>
                                                        <input type="radio" name="completionStatusRadio"
                                                               style={{height:'15px !important',marginBottom:3}}
                                                               checked={this.state.statusId == status.id}
                                                               value={status.id}
                                                               onChange={this.onCompletionStatusChanged.bind(this,status)}/>
                                                        <div style={{marginLeft:5}}>
                                                            {status.name}
                                                        </div>
                                                    </div>
                                                })
                                            }
                                    </div>
                                    {this.state.statusIdError && <span className="text-danger"><small>Please select completion status</small></span>}
                                    
                                </div>
                            </div>  
                            
                            <div style={{textAlign:'center',paddingTop:10,borderTop:'1px solid lightgray'}}>
                                <Button bsStyle="danger" onClick={this.onCancel.bind(this)} style={{float:'left'}}>
                                    Cancel
                                </Button>
                                <Button bsStyle="success" type="submit" style={{float:'right'}}>
                                    Save
                                </Button>
                            </div>
                        </form>
                    </div>


                </Modal.Body>

            </Modal>
        );
    }
}

reactMixin(AddCareGoalIntervention.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    'user': state.auth.user,
    'selectedPatient':state.app.selectedPatient,
    'goalReviewFrequency':state.admin.goalReviewFrequency,
    'stepTypes':state.levelTwoDashboard.stepTypes,
    'goalTypes':state.levelTwoDashboard.goalTypes,
    'goalSources':state.levelTwoDashboard.goalSources,
    'goalCompletionStatuses':state.levelTwoDashboard.goalCompletionStatuses,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCareGoalIntervention);