import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import {Input,Modal,Button,FormGroup,FormControl,Table} from 'react-bootstrap';
import * as appActionCreator from "../../actions/app";

class RecordFixNoteModal extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            note: null
        }
    }

    handleNotesChange = (event) => {
        this.setState({note: event.target.value});
    }

    onCancel() {
        this.setState({
            note:null
        });
        this.props.onPopupHide();
    }

    onSubmit(){
        const { selectedRecord } = this.props;
        const {note } = this.state;
        this.props.adminActions.completeToFixRecord(selectedRecord.id,note);
        this.onCancel();
    }

    render() {
        let noteLength = this.state.note ? this.state.note.length : 0;
        return (
            <Modal bsSize="large" show={this.props.show} dialogClassName="close-chart-popup">
                <Modal.Header closeButton onHide={this.onCancel.bind(this)}>
                    <Modal.Title>
                        <div className="text-align-center">
                            <div>Record Fix Notes</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{overflow: 'auto', height: 'auto'}}>
                        <FormGroup>
                            <div style={{fontWeight:'bold',padding:15}}>Manual Record Fix Notes</div>
                            <div>
                                <textarea className="form-control" rows="10" id="notes"
                                        value={this.state.note}
                                        placeholder="What steps where taken to manually correct this record?"
                                        onChange={this.handleNotesChange}></textarea>
                                <div style={{display:'flex',justifyContent:'flex-end'}}>
                                    {`${noteLength}/1024`}
                                </div> 
                            </div>          
                        </FormGroup>
                        <div style={{borderTop: '1px solid gray', height: 50, textAlign: 'center', paddingTop: 15}}>
                            <Button bsStyle="danger" onClick={this.onCancel.bind(this)} style={{float: 'left'}}>
                                Cancel
                            </Button>
                            <Button bsStyle="success" type="submit" style={{float: 'right'}}
                                    disabled={!this.state.note}
                                    onClick={this.onSubmit.bind(this)} >
                                Complete
                            </Button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    'inboundMedicalForms': state.consentForms.inboundMedicalForms,
    'medicalFormDocument': state.consentForms.medicalFormDocument,
    'medicalFormDocumentUrl': state.consentForms.medicalFormDocumentUrl,
    'selectedDoc':state.consentForms.selectedDoc,
    'userId': state.auth.id,
    inboundRecordsToFixQueue: state.faxMatching.inboundRecordsToFixQueue,
    isFetching: state.app.isFetching
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    appActions:bindActionCreators(appActionCreator,dispatch),
    commonActions:bindActionCreators(commonActionCreator,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RecordFixNoteModal);