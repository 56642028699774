/**
 * Created by synerzip on 03/03/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Panel,Input,Button,Col,Accordion} from 'react-bootstrap';
import {Table, Column, Cell} from 'fixed-data-table';
import _ from 'lodash';
import moment from 'moment';
import PatientEducationItem from './PatientEducationItem';
import AddPatientEducation from './AddPatientEducation';
import GoalEditDeleteModal from './GoalEditDeleteModal';

class PatientEducation extends React.Component{

    constructor(props){
        super(props);
        this.state={
            openId:null,
            goalId: null,
            actionType: null,
            showGoalEditDeleteModal: false,
            goalTitle: '',
        }
    }

    componentDidMount(){
        // this.props.adminActions.getGoals(this.props.selectedPatient.id);
        this.props.adminActions.getPatientEducations(this.props.selectedPatient.id);
    }

    onPopupHide(){
        this.props.showSecondaryTimer();
        this.setState({show:false});
    }

    handleGoalAction = ( goalId, actionType ) => {
        let goalTitle = '';
        this.props.patienEducations && this.props.patienEducations.length && this.props.patienEducations.forEach( ( goal ) => {
            if ( goalId === goal.id ) {
                goalTitle = goal.goal;
            }
        });
        this.setState({ goalId, actionType, goalTitle }, this.toggleGoalEditDeleteModal );
    };

    toggleGoalEditDeleteModal = () => {
        this.setState({ showGoalEditDeleteModal: !this.state.showGoalEditDeleteModal });
    };

    handleGoalModalAction = (e) => {
    	e.preventDefault();
        let { goalId, actionType, goalTitle } = this.state;
        let { selectedPatient } = this.props;

        if ( selectedPatient.id ) {
            if ( actionType === 'edit' ) {
                this.props.adminActions.updateGoal( selectedPatient.id, goalId, goalTitle );
                return;
            }

            if ( actionType === 'delete' ) {
                this.props.adminActions.deleteGoal( selectedPatient.id, goalId );
                return;
            }
        } else {
            console.log( 'No patient id available' );
        }
    };

    goalTitleUpdate = ( e ) => {
        this.setState({ goalTitle: e.target.value });
    };

    header( data ) {
        let rotate = this.state.openId == data.id ? ' rotate' : '';

        let style = {
            marginTop: '-10px',
            marginBottom: '-10px',
            marginLeft: '-15px',
            marginRight: '-15px',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '15px',
            paddingRight: '15px',
            overflow: 'auto',
            width: '100%',
        }
        return (
            <div>
                <div style={style}>
                    <i className={'fa fa-angle-down circleBorder ' + rotate} aria-hidden="true"></i>{data.title}
                </div>
            </div>
        )
    }

    toggleOpen( id ) {
        this.props.showSecondaryTimer();
        if(this.state.openId != id) {
            this.setState({openId:id})
        } else {
            this.setState({openId:null})
        }
    }

    handleAddGoal() {
        this.props.showSecondaryTimer();
        this.setState({ show:true });
    }

    componentWillReceiveProps( nextProps ) {
        this.setState({ showGoalEditDeleteModal: nextProps.showEditDeleteModal });
    }

    handleCancelDetail(){
        this.setState({openId:null})
    }

    render(){
        const header =
            <div style={{height:35}}>
                <div style={{fontSize:18,float:'left'}}>Patient Education</div>
                <div style={{float:'right',marginRight:10}}>
                    <Button bsStyle="primary" id="addGoalButton" onClick={ this.handleAddGoal.bind( this )}>Add New</Button></div>
            </div>;

        let educations = [];

        const {patienEducations} = this.props;
        educations =  _.map(patienEducations,(data)=>{
            return (
                <Panel header={this.header(data)} bsStyle={data.complete ? 'success' : 'info'} 
                   key={data.id} eventKey={data.id} style={{marginBottom:'20px'}}>
                    <PatientEducationItem data={data} key={data.id} handleCancelDetail={this.handleCancelDetail.bind(this)}/>
                   
                </Panel>
            )
        })

        return (
            <div>
                <Panel header={header}>
                    <Accordion>
                        {educations}
                    </Accordion>
                </Panel>

                <AddPatientEducation show={this.state.show}
                                    onCloseEdit={this.onPopupHide.bind(this)}
                                    onPopupHide={this.onPopupHide.bind(this)}
                                    showSecondaryTimer={ this.props.showSecondaryTimer.bind( this ) }/>

                <GoalEditDeleteModal show={ this.state.showGoalEditDeleteModal }
                                     onHide={ this.toggleGoalEditDeleteModal }
                                     modalAction={ this.handleGoalModalAction }
                                     actionType={ this.state.actionType }
                                     goalTitleUpdate={ this.goalTitleUpdate }
                                     goalTitle={ this.state.goalTitle } />
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    'patienEducations': state.levelTwoDashboard.patienEducations,
    'carePlanAndGoals':state.levelTwoDashboard.carePlanAndGoals,
    'userId': state.auth.id,
    'selectedPatient': state.app.selectedPatient,
    'showGoalEditDeleteModal': state.levelTwoDashboard.showGoalEditDeleteModal || false,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientEducation);
