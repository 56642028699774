
import React from 'react';
const { Cell } = require('fixed-data-table-2');

class CollapseCell extends React.Component {
    constructor(props){
        super(props);
    }
    render() {
        const {data, rowIndex, columnKey, collapsedRows, callback, ...props} = this.props;
        return (
            <Cell {...props}>
                <span onClick={() => callback(rowIndex)} style={{cursor:'pointer'}}>
                    {collapsedRows.has(rowIndex) ? <i className="fa fa-circle"
                                                      style={{color:'rgb(89,125,150)',float:'left',paddingBottom:5}}></i> : '\u25BA'}
                </span>
            </Cell>
        );
    }
};

export default CollapseCell;