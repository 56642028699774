import React, { Component } from 'react';
import moment from 'moment';
import { pushState } from 'redux-router';
import { connect } from 'react-redux';
import { hideToast, showToast } from "../../actions/common";
import {redirectToSearch,getPatientDetail,
    loadPatientDetail,isChartOpen,checkOpenAssociatedChart} from '../../actions/admin';
import ChartPreviewModal from "./ChartPreview"    

class ChartRouteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal:false,
            patientData:null,
            organizationNote:null,
            lastNote:null
            
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.activeUser && this.props.activeUser != nextProps.activeUser){
            const { router,activeUser } = nextProps;
            if (router.location.pathname.indexOf("patient-chart") !== -1) {
                const id = router.location.query.id;
                if(id && id.trim()){
                     //check if chart is open?
                     this.props.isChartOpen(id)
                     .then(result => {
                            this.props.getPatientDetail(id)
                            .then(patientData => {
                                localStorage.setItem(`showPreview${id}`,'true')
                                this.props.redirectToSearch(`id=${id}`);
                                
                        });
                     })
                     .catch(error => {
                        const isCne = activeUser && activeUser.center && activeUser.center.ce_flag;
                        const isRPM = activeUser && activeUser.center && activeUser.center.rpm_flag;
                        const isGAPS = activeUser && activeUser.center && activeUser.center.gaps_flag;
                        let errorMessage = '';
                        if(isCne){
                            errorMessage = 'Patient is not in consent and enrollment specific status'
                        }else if(isRPM){
                            errorMessage = 'Patient is not in RPM specific status'
                        }else if(isGAPS){
                            errorMessage = 'Patient is not in GAPS specific status'
                        }else{
                            errorMessage = 'Patient is not in Clinical/Support specific status'
                        }
                        this.props.showToast({
                            status: 'error',
                            title: 'Error',
                            message: errorMessage,
                        });
                        const interval = setInterval(() => {
                            clearInterval(interval);
                            this.props.hideToast();
                        }, 2);
                     })
                }
            }
        }
    }

    render(){
        return <div/>

    }
}

export default connect(
    (state, ownProps) => 
    ({
        router: state.router,
        activeUser: state.admin.activeUser,
        selectedPatient: state.app.selectedPatient
     }),
     {
        redirectToSearch,
        getPatientDetail,
        hideToast,
        showToast,
        loadPatientDetail,
        isChartOpen,
        checkOpenAssociatedChart
    }
    )(ChartRouteComponent)