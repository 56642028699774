/**
 * Created by synerzip on 08/02/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as notificationsActionCreator from '../../actions/notifications';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {Input,Modal,Button} from 'react-bootstrap';
import _ from 'lodash';
import ConfirmChartEnterModal from './ConfirmChartEnterModal';

class SelectPatientConfirmPopup extends React.Component {

    constructor() {
        super()
        this.state = {
            showConfirmChartEnterModal: false,
        }
    }
    onCancel(){
        this.props.onPopupHide();
    }

    onSubmit(){
        const {data} = this.props;
        this.props.adminActions.loadPatientDetail(data);
        if(this.props.fromNotification) {
            window.localStorage.setItem('ccm_queue', 'notification');
            window.localStorage.setItem('ccm_queue_user', this.props.activeUser.username);
        }else if(this.props.fromCaseMessages) {
            window.localStorage.setItem('ccm_queue', 'caseMessages');
            window.localStorage.setItem('ccm_queue_user', this.props.activeUser.username);
        }
    }

    checkCssEnterUnverified(){
        this.props.onPopupHide();
        const {data, activeUser} = this.props;
        if ( activeUser.center.ce_flag === false && data.status === 104 ) {
            this.setState({
                showConfirmChartEnterModal: true,
            });
        } else {
            this.onSubmit();
        }
    }

    render() {
        const {data} = this.props;
        let name = "";
        let provider = "";
        let organization = "";
        let specialty = "Primary Care";
        let specialty_id = 1;
        if(!_.isEmpty(data)){
            name = data.first_name+' '+data.last_name;
            provider = data.provider.provider;
            organization = data.provider ? data.provider.organization_secondary_name ?
                data.provider.organization_secondary_name :
                data.provider.organization : "";
            specialty = data.provider.specialty_description;
            specialty_id = data.provider.specialty_id;
        }
        let patient = <div className="value-box">{name}</div>;
        provider = <div>
            <div style={{clear:'both'}}>
                <div className="value-box">{provider}</div>
            </div>

            <div style={{clear:'both'}}>
                <div className="value-box">
                    <div>{organization}</div>
                </div>
            </div>
        </div>;
        return (
            <div>
                <Modal show={this.props.show}>
                    <Modal.Header closeButton onHide={()=>{
                                    this.props.onPopupHide();
                                }}>
                        <Modal.Title>
                            <div style={
                                {
                                textAlign: 'center'
                                }
                            }>
                                <div>Please verify that the selected patient is</div>
                                <div>correct before proceeding.</div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{height:100}}>
                            <div className="header-box">
                                <div className="header-label">
                                    Patient
                                </div>
                                <div className="header-label">
                                    Provider
                                </div>
                            </div>
                            <div className="ma-value-box">
                                <div className="value-label">
                                    {patient}
                                </div>
                                <div className="value-label">
                                    {provider}
                                </div>
                            </div>
                        </div>
                        <div style={{height:100, borderBottom: "1px solid gray", fontWeight: 500}}>
                            <div className="header-box">
                                <div className="header-label"></div>
                                <div className="header-label">
                                    Specialty
                                </div>
                            </div>
                            <div className="ma-value-box">
                                <div className="value-label"></div>
                                {specialty_id === 1 ?
                                    <div className="value-label">
                                        {specialty}
                                    </div> :
                                    <div className="value-label" style={{color: "red"}}>
                                        {specialty}
                                    </div>
                                }
                            </div>
                        </div>

                        <div style={{height:50,textAlign:'center',paddingTop:15}}>
                            <Button bsStyle="danger" onClick={this.onCancel.bind(this)} style={{float:'left'}}>
                                Cancel
                            </Button>
                            <Button bsStyle="success" onClick={this.checkCssEnterUnverified.bind(this)} style={{float:'right'}}>
                                Open Chart
                            </Button>
                        </div>
                    </Modal.Body>

                </Modal>
                <ConfirmChartEnterModal
                    show={this.state.showConfirmChartEnterModal}
                    onConfirm={this.onSubmit.bind(this)}
                    onHide={()=>{this.setState({showConfirmChartEnterModal:false})}}
                />
            </div>
        );

    }
}

const mapStateToProps = (state) => ({
    'activeUser': state.admin.activeUser,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    notificationsAction:bindActionCreators(notificationsActionCreator,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectPatientConfirmPopup);
