import React, { Component } from 'react';
import moment from 'moment';
import DateTimeField from 'react-bootstrap-datetimepicker-custom-icons';

class SearchHeader extends Component {
    handleSearch = (e) => {
        e.preventDefault();
        const date = moment(this.dateInput.value).isValid()
                     ? moment(this.dateInput.value).format('MM/DD/YYYY')
                     : '';
        const searchTerm = this.lastName.value.trim();
        let phone = this.phone.value ? this.phone.value.trim().replace(/\D/g, '') : null;
        if(phone && phone.length > 10){
            phone = phone.substring(phone.length-10);
        }
        const email = this.email.value ? this.email.value.trim() : null;
        const ccmId = this.ccmid.value.trim();
        if (searchTerm || date || phone || ccmId || email) {
            this.props.onSearch(this.lastName.value.trim(), date, phone,ccmId,email);
        }
    };

    render() {
        return (
            <form
                onSubmit={this.handleSearch}
                style={{ display: 'flex', justifyContent: 'center', margin: '15px 0'  }}
            >
                <input id="searchName"
                    type="text"
                    className="form-control"
                    style={{ height: '30px !important', width: '17%', marginRight: '30px' }}
                    ref={(ref) => { this.lastName = ref; }}
                    placeholder="Patient First and/or Last Name"
                />
                <input id="searchEmail"
                    type="text"
                    className="form-control"
                    style={{ height: '30px !important', width: '17%', marginRight: '30px' }}
                    ref={(ref) => { this.email = ref; }}
                    placeholder="Email"
                    defaultValue={this.props.searchEmail}
                />
                <div style={{ marginRight: '30px', width: '20%', position: 'relative' }}>
                    <DateTimeField id="searchDOB"

                        mode="date"
                        viewMode="years"
                        maxDate={moment()}
                        inputFormat="MM/DD/YYYY"
                        defaultText=''
                        inputProps={{
                            maxLength: 10,
                            style: { height: '30px !important' },
                            ref: (ref) => { this.dateInput = ref },
                            placeholder: 'Date of Birth (MM/DD/YYYY)',
                        }}
                        iconStyle={{
                        	class: 'fa',
	                        time: 'fa-clock-o',
	                        calendar: 'fa-calendar',
	                        up: 'fa-chevron-up',
	                        down: 'fa-chevron-down',
	                        right: 'fa-chevron-right',
	                        left: 'fa-chevron-left',
                        }}
                    />
                </div>
	            <input id="searchPhone"
		            type="text"
		            className="form-control"
		            style={{ height: '30px !important', width: '17%', marginRight: '30px' }}
		            ref={(ref) => { this.phone = ref; }}
		            placeholder="Phone Number (123-456-7890)"
                    defaultValue={this.props.searchPhone}
	            />
                <input id="searchCcmId"
                    type="text"
                    className="form-control"
                    style={{ height: '30px !important', width: '17%', marginRight: '30px' }}
                    ref={(ref) => { this.ccmid = ref; }}
                    placeholder="CCM ID"
                    defaultValue={this.props.searchId}
                />
                <button id="searchButton" type="submit" className="btn btn-success btn-sm">
                    Search
                </button>
            </form>
        );
    }
}

export default SearchHeader;
