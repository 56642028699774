/**
 * Created by synerzip on 09/01/16.
 */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as appActionCreator from '../../actions/app';
import * as commonActionCreator from '../../actions/common';
import * as notificationsActionCreator from '../../actions/notifications';
import CircularProgress from '../../components/CircularProgress';
import AnimatedLinearProgress from '../../components/AnimatedLinearProgress';
var ReactCSSTransitionGroup = require('react-addons-css-transition-group');
import {Input,Button} from 'react-bootstrap';
import NotificationInboxItem from './NotificationInboxItem';
import PaginationBar from '../../components/PaginationBar';
import _ from 'lodash';
import moment from 'moment';
import MessageReplyPopup from '../../components/MessageReplyPopup';
import SelectPatientConfirmPopup from '../patientCare/SelectPatientConfirmPopup';
import DateTimePicker from 'react-bootstrap-datetimepicker-custom-icons';
import PageHeader from '../../components/PageHeader';
import {hideToast} from "../../actions/common";

class Notification extends React.Component {
    constructor(props) {
        super(props);
        this.pageSize = 10;
        this.selectedItems = {};
        this.state = {
            currentPage: 1,
            pageCount: (props.notificationList) ? props.notificationList.total_pages:0,
            searchText: null,
            show:false,
            userInput: '',
            DOB: '',
            selectedQueryFilters:{},
            priority:false
        }
    }

    onPopupHide() {
        this.setState({selectedPatient: null, show: false});
    }

    componentDidMount() {
        if(this.props.adminUser) {
            this.props.notificationsAction.getNotificationsUnreadCount();
        }
        this.props.notificationsAction.getNotificationThreads(this.state.currentPage - 1,this.pageSize);
    }

    componentWillReceiveProps(nextProps){
        var selectedPatientDataChange = _.isEqual(nextProps.selectedPatientData,this.props.selectedPatientData);
        if(nextProps.selectedPatientData && !selectedPatientDataChange){
            this.setState({
                show:true,
                selectedPatient: nextProps.selectedPatientData,
                notifications: nextProps.notifications
            })
        }
    }

    onItemSelected() {

    }

    onReset() {
        this.props.notificationsAction.getNotificationThreads(0,this.pageSize);
        this.setState({
            selectedQueryFilters:{},
            priority:false
        });
    }

    getInboxItem() {

        var notificationList = this.props.notificationList || {};
        var totalMessages = 0;
        var inboxObjects = _.map(_.keys(notificationList.items), (messageKey)=> {
            return notificationList.items[messageKey];
        });
        //inboxObjects = _.sortByOrder(inboxObjects, ['date_created'], ['desc']);
        totalMessages = notificationList.total_items;

        var startIndex = 0;

        var inboxItems = [];
        for (var index = startIndex, j = 0; index < inboxObjects.length && j < this.pageSize; index++, j++) {
            var message = inboxObjects[index];
            var selected = false;
            inboxItems.push(<NotificationInboxItem key={index} id={index} msg={message} patientData={this.props.selectedPatientData} openPatientPopup={this.onOpenPatientPopup.bind(this)}/>);
        }
        return ({
            inboxItems: inboxItems,
            pageCount: notificationList.total_pages
        })
    }


    onPageSelected(currentPage) {
        this.setState({currentPage: currentPage});
        const {selectedQueryFilter,priority,userInput,DOB} = this.state;
        if ( userInput || this.isValidDate( DOB ) || _.keys(selectedQueryFilter).length > 0
            || priority) {
            this.searchByUserInput( null, null, null, currentPage );
            return;
        }
        this.props.notificationsAction.getNotificationThreads((currentPage - 1),this.pageSize);
    }

    onOpenPatientPopup(){
        this.setState({
            show:true,
            selectedPatient:this.props.selectedPatientData
        })
    }

    updateUserInput() {
        this.setState({
            userInput: this.refs.userInput.value
        });
    }

    updateDOB( newDate ) {
        this.setState({
            DOB: newDate || ''
        });
    }

    searchByUserInput( e, rId, E, currentPage = 0 ) {
        e && e.preventDefault();
        const {selectedQueryFilters,priority,userInput,DOB} = this.state;
        if ( userInput || this.isValidDate( DOB ) || _.keys(selectedQueryFilters).length > 0
            || priority) {
            this.props.notificationsAction.searchByUserInput( userInput ? userInput : null ,
                this.isValidDate( DOB ) ? DOB : null,
                _.keys(selectedQueryFilters).length > 0 ?_.keys(selectedQueryFilters) : null,
                priority ? priority : null , currentPage );
            return;
        }

        this.props.commonActions.showToast({
            valid: false,
            showError: true,
            title: 'Search Values Missing',
            status: 'error',
            message: 'Please Provide Patient\'s Name or Date of Birth'
        });

        const interval = setInterval(()=> {
            clearInterval(interval);
            this.props.commonActions.hideToast();
        }, 2);
    }

    isValidDate(dateString) {
        // First check for the pattern
        if(!/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
            return false;
        }

        // Parse the date parts to integers
        var parts = dateString.split("-");
        var day = parseInt(parts[1], 10);
        var month = parseInt(parts[0], 10);
        var year = parseInt(parts[2], 10);

        // Check the ranges of month and year
        if(year < 1000 || year > 3000 || month == 0 || month > 12)
            return false;

        var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

        // Adjust for leap years
        if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
            monthLength[1] = 29;

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
    }
    setFilter(e,tag){
        const {selectedQueryFilters} = this.state;
        if(e.target.checked){
            selectedQueryFilters[tag.label] = true
        }else{
            delete selectedQueryFilters[tag.label];
        }
        this.setState(selectedQueryFilters)
    }

    setPriority(e){
        this.setState({priority:e.target.checked});
    }

    render() {
        var inboxItemObject = this.getInboxItem();
        var inboxItems = inboxItemObject.inboxItems;
        var pageCount = inboxItemObject.pageCount;

        let inputStyles1 = {
            'width': '400px',
            'borderRadius': '5px',
            'border': '1px solid lightgray',
            'marginRight': '25px',
            'display': 'inline-block',
            'verticalAlign': 'middle',
            'paddingLeft':'10px'
        };

        let inputStyles2 = {
            'width': '300px',
            'marginRight': '25px',
            'display': 'inline-block',
            'verticalAlign': 'middle'
        };

        const tags = [{id:1,label:'Nurse'},{id:2,label:'Refill'},{id:3,label:'Appointment'}];

        return (
            <ReactCSSTransitionGroup transitionName="example" transitionAppear={true} transitionAppearTimeout={1000}
                                     transitionEnterTimeout={500}
                                     transitionLeaveTimeout={1000}>

                <div>
                    <PageHeader>
                        Notification
                    </PageHeader>
                    <div className="content-summary-wrapper-delivery">
                        <div className="content-summary-chart-delivery">
                            <div>
                                <div className="inbox-wrapper">
                                    <div className="notification-search" style={{ 'textAlign': 'center' }}>
                                        <form onSubmit={ this.searchByUserInput.bind( this ) }>
                                               <h3 style={{ 'borderBottom': '1px solid black', 'margin': '25px auto', 'paddingBottom': '15px', 'width': '100%' }}>Search Notifications</h3>
                                               <div style={{display:'flex',justifyContent:'space-between'}}>
                                                <input  ref="userInput"
                                                        name="userInput"
                                                        id="notificationSearchField"
                                                        type="text"
                                                        onChange={ this.updateUserInput.bind( this ) }
                                                        placeholder="Search by patient name (first or last)"
                                                        style={ inputStyles1 } />
                                                <div style={ inputStyles2 }>
                                                    <DateTimePicker format="MM-DD-YYYY"
                                                                    inputFormat="MM/DD/YYYY"
                                                                    dateTime={ moment().format("MM-DD-YYYY") }
                                                                    maxDate={ moment() }
                                                                    defaultText=""
                                                                    ref="DOB"
                                                                    onChange={ this.updateDOB.bind( this ) }
                                                                    iconStyle={{
                                                                        class: 'fa',
                                                                        time: 'fa-clock-o',
                                                                        calendar: 'fa-calendar',
                                                                        up: 'fa-chevron-up',
                                                                        down: 'fa-chevron-down',
                                                                        right: 'fa-chevron-right',
                                                                        left: 'fa-chevron-left',
                                                                    }}
                                                                    inputProps={{ 'placeholder': 'DOB (MM/DD/YYYY)' }} />

                                                </div>
                                                <div style={{display:'flex'}}>
                                                    {
                                                        _.map(tags,(tag)=>{
                                                            return <div key={tag.id} style={{display:'flex',alignItems:'center'}}>

                                                                <input style={{margin:0,marginLeft:15,marginRight:5,transform: 'scale(1.8)'}} type="checkbox"
                                                                       checked={this.state.selectedQueryFilters[tag.label] ? true : false}
                                                                       value={this.state.selectedQueryFilters[tag.label]}
                                                                       id={tag.label+"_filter"}
                                                                       onChange={(e) => this.setFilter(e,tag)}/>
                                                                <span style={{fontSize:16,fontWeight:400,paddingLeft:5}}>{tag.label}</span>
                                                            </div>
                                                        })
                                                    }
                                                    <div  style={{display:'flex',alignItems:'center'}}>

                                                        <input style={{margin:0,marginLeft:15,marginRight:5,transform: 'scale(1.8)'}} type="checkbox"
                                                               checked={this.state.priority ? true : false}
                                                               value={this.state.priority}
                                                               onChange={(e) => this.setPriority(e)}/>
                                                        <span style={{fontSize:16,fontWeight:400,paddingLeft:5}}>Priority</span>
                                                    </div>
                                                </div>
                                               <div style={{display:'flex'}}>
                                                    <Button bsStyle="success" id="notificationSearchButton" onClick={ this.searchByUserInput.bind( this ) } style={{ 'height': '50px', 'marginRight': '15px' }}>Search</Button>
                                                    <Button id="notificationResetButton"  onClick={ this.onReset.bind(this)} style={{ 'height': '50px' }}>Reset</Button>
                                               </div>
                                           </div>
                                        </form>
                                    </div>
                                    <div className="email-client">
                                        <div id="page-content">
                                            {inboxItems}
                                            {
                                                pageCount > 1
                                                    ?
                                                    <div id="pagination-wrapper" className="email-client-footer">
                                                        <PaginationBar pageCount={pageCount}
                                                                       onPageSelected={this.onPageSelected.bind(this)}
                                                                       currentPage={this.state.currentPage}/>
                                                    </div>
                                                    :
                                                    <div />
                                            }
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SelectPatientConfirmPopup show={this.state.show} onPopupHide={this.onPopupHide.bind(this)}
                                           data={this.state.selectedPatient}
                                           fromNotification/>
            </ReactCSSTransitionGroup>
        );
    }
}

const mapStateToProps = (state) => ({
    'userId': state.auth.id,
    'adminUser':state.app.adminUser,
    'notificationList':state.notifications.notificationList,
    'selectedPatientData':state.notifications.selectedPatientData,
    'notifications': state.notifications
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    appActions:bindActionCreators(appActionCreator,dispatch),
    commonActions:bindActionCreators(commonActionCreator,dispatch),
    notificationsAction:bindActionCreators(notificationsActionCreator,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
