import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentQueueLead = () =>
	<PatientQueue
		showQueueCount
		patientNameColumn
		providerDetailColumn
		timeBilledColumn
		todoItemColumn
		showFilters
		showProgramTypeFilter
        showScheduleCallFilter
		statusColumn
		chartColumn
		lastWorkedByColumn
		page
		queue="urgentLead"
		endpoint="Urgent?typesToInclude=3"
		label="Urgent - Lead"
	/>;

export default UrgentQueueLead;
