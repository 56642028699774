import React from 'react';
var ReactToastr = require("react-toastr");
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import * as authActionCreator from "../actions/authactions";
var {ToastContainer} = ReactToastr; // This is a React Element.
var ToastMessageFactory = React.createFactory(ReactToastr.ToastMessage.animation);

class CoreLayout extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading:true
        }
    }

    async componentDidMount(){
        const vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        let samlToken = vars.auth_token;
        if(samlToken){
            samlToken = samlToken.substring(0,samlToken.indexOf("#"));
            await this.props.actions.getMeUser(samlToken);
            let URL = window.location.href;
            URL = URL.substring(0,URL.indexOf("?"));
            window.location.href = URL;
            this.setState({loading:false});
        }else if(vars.sso_failure){
            this.props.actions.showSAMLError("Error while signing with GSuite, please try again or contact administrator");
            this.setState({loading:false});
        }else{
            this.setState({loading:false});
        }

    }
    componentWillReceiveProps(nextProps,nextState){
        if(nextProps.toast &&  nextProps.toast.show == true) {
            if (nextProps.toast.toastData) {
                if(nextProps.toast.toastData.status == 'error') {

                    this.refs.container.error(
                        nextProps.toast.toastData.message, nextProps.toast.toastData.title, {
                            timeOut: 3000,
                            extendedTimeOut: 1000,
                            "showDuration": "300",
                            "hideDuration": "1000",
                            "showEasing": "swing",
                            "hideEasing": "linear",
                            "showMethod": "fadeIn",
                            "hideMethod": "fadeOut"
                        });
                }else if(nextProps.toast.toastData.status == 'success') {
                    this.refs.container.success(
                        nextProps.toast.toastData.message, nextProps.toast.toastData.title, {
                            timeOut: 3000,
                            extendedTimeOut: 1000,
                            "showDuration": "300",
                            "hideDuration": "1000",
                            "showEasing": "swing",
                            "hideEasing": "linear",
                            "showMethod": "fadeIn",
                            "hideMethod": "fadeOut"
                        });
                }
            }
        }
    }
    render() {
        const {loading} = this.state;

        return (
            <div>
                <ToastContainer ref="container"
                                toastMessageFactory={ToastMessageFactory}
                                className="toast-top-right"
                                iconClassName="error"/>
                {loading ? <div/> : this.props.children}
            </div>

        );
    }
}


const mapStateToProps = (state) => ({
    toast: state.toast
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(authActionCreator, dispatch)
});


export default connect(mapStateToProps,mapDispatchToProps)(CoreLayout);
