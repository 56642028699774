import React from 'react';
import {Panel,Input,Button,OverlayTrigger,Popover} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import {actionPlans,actionPlans2} from './actionPlans'

class EngagementComp extends React.Component {

    getStatusStyle(data){
        const engagement_type = data.engagement_type;
        let backgroundColor = '';
        let border = '';
        let label = '-';
        const gaps_care_plan_status_id = data.gaps_care_plan_status_id;
        let filteredData = _.filter(actionPlans,(plan) => {
            return plan.code == engagement_type;
        });
        if(filteredData.length == 0){
            filteredData = _.filter(actionPlans2,(plan) => {
                return plan.code == engagement_type;
            });
        }

        if(filteredData.length > 0){
            label =  filteredData[0].label.split('-')[0];
        }
        if(gaps_care_plan_status_id == 2){
            backgroundColor = 'rgb(220,228,239)'
            border = '1px solid rgb(115,148,189)'
        }else if(gaps_care_plan_status_id == 3){
            backgroundColor = 'rgb(218,228,214)'
            border = '1px solid rgb(159,193,143)'
        }else if(gaps_care_plan_status_id == 4){
            backgroundColor = 'rgb(237,218,218)'
            border = '1px solid rgb(211,128,121)'
        }
        return {
            label,
            backgroundColor,
            border
        };
    }
    render(){
        const {onPlanSelected} = this.props;
        const data = this.props.engagement;
        const styleData = this.getStatusStyle(data);
        let backgroundColor = styleData.backgroundColor;
        let border = styleData.border;
        let statusText = styleData.label;

        return (
         <div key={data.id} style={{
            display:'flex',
            flexDirection:'column',
            backgroundColor:backgroundColor,
            border:border,
            fontWeight:400,
            margin:15,
            padding:15}}>
            <div  style={{
            display:'flex',
            justifyContent:'space-between',
             }} onClick={()=>onPlanSelected(data)}>
                    <div style={{display:'flex',flex:1,flexDirection:'column'}}>
                        {statusText}
                    </div>
                    <div style={{display:'flex',flex:1}}>{data.gaps_care_plan_status}</div>
                    <div style={{display:'flex',flex:1}}>Created: {moment(data.created_ts).format('MM-DD-YYYY hh:mma')}</div>

                </div>   
            <div>
                {data.note}
            </div>
        </div>
        )
    }
}
export default EngagementComp;
