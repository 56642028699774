/**
 * Created by synerzip on 23/01/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Button} from 'react-bootstrap';
import _ from 'lodash';
import UnassignedPatientCell from './UnassignedPatientCell';
import OrganizationCell from './OrganizationCell';
import {Table, Column, Cell} from 'fixed-data-table';
import moment from 'moment';
import AssignMAModal from './AssignMAModal';

const TextCell = ({rowIndex, data, columnKey, ...props}) => (
    <Cell {...props}>
        {data[rowIndex].first_name + ' ' + data[rowIndex].last_name}
    </Cell>
);
const AssignMACell = ({rowIndex, data, columnKey, ...props}) => (
    <Cell {...props}>
        <Button bsStyle="primary" onClick={this.onAssign.bind(this,data[rowIndex])}>Assign</Button>
    </Cell>
);


const AccountCreatedCell = ({rowIndex, data, columnKey, ...props}) => (
    <Cell {...props}>
        {data[rowIndex].create_ts}
    </Cell>
);

const ProviderCell = ({rowIndex, data, columnKey, ...props}) => (
    <Cell {...props}>
        {data[rowIndex].provider.provider}
    </Cell>
);

const PatientCell = ({rowIndex, data, columnKey, ...props}) => (

    <Cell {...props}>
        <UnassignedPatientCell data={data[rowIndex]}/>
    </Cell>
);

const OrgCell = ({rowIndex, data, columnKey, ...props}) => (
    <Cell {...props}>
        <OrganizationCell data={data[rowIndex].provider}/>
    </Cell>
);


class UnAssignedPatients extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columnWidths: {
                unassignedPatient: 330,
                organization: 200,
                provider: 180,
                accountCreated: 150,
                assignMA: 100
            }
        };

        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    }

    onAssign(data) {
        //alert(data.first_name);
        this.setState({show:true,selectedPatient:data});
    }
    onPopupHide(){
        this.setState({show:false});
    }
    componentDidMount() {
        this.props.adminActions.getUnAssignedPatients();
    }

    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({columnWidths}) => ({
            columnWidths: {
                ...columnWidths,
                [columnKey]: newColumnWidth,
            }
        }));
    }

    _renderButton(rowData) {
        var {unassignedPatients} = this.props;
        return
        <Cell {...props}>
            <Button bsStyle="primary"
                    onClick={this.onAssign.bind(this,unassignedPatients[rowData.rowIndex])}>Assign</Button>
        </Cell>;

    }

    render() {
        var {columnWidths} = this.state;
        var {unassignedPatients} = this.props;

        return (
            <div>
                <Table
                    rowHeight={180}
                    headerHeight={50}
                    rowsCount={_.keys(unassignedPatients).length}
                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    isColumnResizing={false}
                    width={1000}
                    height={500}
                    {...this.props}>
                    <Column
                        columnKey="unassignedPatient"
                        header={<Cell>Unassigned Patient</Cell>}
                        cell={<PatientCell data={unassignedPatients} />}
                        fixed={true}
                        width={columnWidths.unassignedPatient}
                        isResizable={true}
                        />
                    <Column
                        columnKey="organization"
                        header={<Cell>Organization</Cell>}
                        cell={<OrgCell data={unassignedPatients} />}
                        width={columnWidths.organization}
                        isResizable={true}
                        minWidth={70}
                        maxWidth={170}
                        />
                    <Column
                        columnKey="provider"
                        header={<Cell>Provider</Cell>}
                        cell={<ProviderCell data={unassignedPatients} />}
                        width={columnWidths.provider}
                        isResizable={true}
                        />
                    <Column
                        columnKey="accountCreated"
                        header={<Cell>Account Created</Cell>}
                        cell={<AccountCreatedCell data={unassignedPatients} />}
                        width={columnWidths.accountCreated}
                        isResizable={true}
                        />
                    <Column
                        columnKey="assignMA"
                        header={<Cell>Assign MA</Cell>}
                        cell={ ({rowIndex, data, columnKey, ...props}) => (
                        <Cell {...props}>
                            <Button bsStyle="primary" onClick={this.onAssign.bind(this,unassignedPatients[rowIndex])}>Assign</Button>
                        </Cell>
                    )}
                        width={columnWidths.assignMA}
                        isResizable={false}
                        />


                </Table>
                <AssignMAModal show={this.state.show} onPopupHide={this.onPopupHide.bind(this)}
                               selectedPatient={this.state.selectedPatient}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    'user': state.auth.user,
    'unassignedPatients': state.admin.unassignedPatients
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UnAssignedPatients);