/**
 * Created by synerzip on 23/01/16.
 */
/**
 * Created by synerzip on 22/01/16.
 */
import {createReducer} from '../utils';
import CONSTANTS from '../constants';

const {REQUEST_SENT,REQUEST_FAIL,LOGIN_USER_SUCCESS,LEFT_SELECTION_CHANGED,UNASSIGNED_PATIENTS_RECEIVED,
    MA_ASSIGNED_SUCCESSFULLY,MA_DETAIL_RECEIVED,INBOUND_CONSENT_FORM_RECEIVED,CONSENT_DOC_RECEIVED,
    INBOUND_MEDICAL_RECORD_RECEIVED,MEDICAL_DOC_RECEIVED,
    LEVEL_TWO_DASHBOARD_DATA_RECEIVED,INBOUND_MATCHED_MEDICAL_RECORD_RECEIVED,
    PATIENT_DETAIL_RECEIVED,PATIENT_DETAILS_UPDATED,RESET_PATIENT_UPDATE_STATUS,LOGOUT_USER,TODO_ADDED_SUCCESSFULLY,
    TODOS_MARK_COMPLETED,TODO_MARK_UNCOMPLETED,CASE_HISTORY_RECEIVED,ASSESSMENTS_RECEIVED,
    ASSESSMENT_ADDED,ASSESSMENT_DELETED,ASSESSMENT_UPDATED,NOTES_RECEIVED,PATIENT_VERIFIED,
    PATIENT_SEARCH_RESULT_RECEIVED,MEDICAL_RECORD_MATCHED_SUCCESSFULLY,CARE_PLAN_AND_GOALS_RECEIVED,
    CHART_OPEN_BY_REQUEST, CHART_OPEN_BY_REQUEST_SUCCESS, CHART_OPEN_BY_REQUEST_FAIL,
    CHART_CLOSED_SUCCESSFULLY, TODO_UPDATED_SUCCESSFULLY,PATIENT_CCM_PROVIDERS_RECEIVED,
    INITIATE_UNENROLL_PATIENT_REQUEST, INITIATE_UNENROLL_PATIENT_SUCCESS, INITIATE_UNENROLL_PATIENT_FAIL,
    GET_OPEN_CHARTS_REQUEST, GET_OPEN_CHARTS_SUCCESS, GET_OPEN_CHARTS_FAIL,
    ADMIN_CLOSE_CHART_REQUEST, ADMIN_CLOSE_CHART_SUCCESS, ADMIN_CLOSE_CHART_FAIL,SSO_TOKEN_RECEIVED,
    NON_ACCOUNT_PATIENT_DETAIL_RECEIVED,ACCOUNT_CREATED_SUCCESSFULLY,
    GET_CCDA_AVAILABILITY_REQUEST, GET_CCDA_AVAILABILITY_SUCCESS, GET_CCDA_AVAILABILITY_FAIL,
    GET_CCDA_PDF_REQUEST, GET_CCDA_PDF_SUCCESS, GET_CCDA_PDF_FAIL,
    GET_PHS_AVAILABILITY_REQUEST, GET_PHS_AVAILABILITY_SUCCESS, GET_PHS_AVAILABILITY_FAIL,
    GET_PHS_PDF_REQUEST, GET_PHS_PDF_SUCCESS, GET_PHS_PDF_FAIL,
    GOAL_ADDED_SUCCESSFULLY,LAB_UNITS_RECEIVED,LABS_RECEIVED,CALL_LOG_SELECTIONS_RECEIVED,GOAL_COMPLETED_SUCCESSFULLY,
    GOAL_UPDATED_SUCCESSFULLY,STEP_UPDATED_SUCCESSFULLY,STEP_ADDED_SUCCESSFULLY,SET_SELECTED_LABEL,SET_QUEUE_SELECTION,
    ASSESSMENT_QUESTIONS_RECEIVED, ASSESSMENT_ANSWERS_RECEIVED,
    MAKE_CALL_SENT, MAKE_CALL_SUCCESS, MAKE_CALL_FAIL, RESET_PASSWORD_COMPLETE, REQUEST_COMPLETE,
    RECORDS_RECEIVED, GET_MED_SUMMARY_PDF_REQUEST, GET_MED_SUMMARY_PDF_SUCCESS,
    GET_MED_SUMMARY_PDF_FAILURE, SELECTED_OPTION_REMOVED, FETCH_QUEUE_SUCCESS,
    FETCH_QUEUE_REQUEST, FETCH_QUEUE_FAILURE, NAV_LINK_CHANGED, MAILING_ADDRESS_UPDATED,
    SEARCH_PATIENTS_SUCCESS, SEARCH_PATIENTS_FAILURE, URGENT_TODO_TYPES_RECEIVED, SEND_TO_SUPPORT_SUCCESS, SEND_TO_SUPPORT_FAILED,
	UPDATE_PATIENT_SATISFACTION_PREFERENCES,TODO_SOCIAL_DETERMINANTS_RECEIVED,ON_PATIENT_PHARMACY_OPTIONS_RECEIVED,
    ON_PATIENT_CHARTSPAN_PROVIDERS_RECEIVED,CALL_ASSISTANCE_TYPES,PATIENT_CARE_PLAN_RECEIVED,ON_PATIENT_MEDICATION_OPTIONS_RECEIVED,
    PATIENT_EDUCATIONS_RECEIVED,PATIENT_ENGAGEMENT_HISTIRY_RECEIVED,GOAL_STEP_ADDED_SUCCESSFULLY,
    GOAL_STEP_DELETED_SUCCESSFULLY,GOAL_STEP_UPDATED_SUCCESSFULLY,
    PATIENT_LAST_VISITED_DATE_RECEIVED, PATIENT_CASE_MESSAGES_UNREAD_CHECK_SUCCESS,
    PATIENT_CASE_DETAIL_MESSAGES_SUCCESS} = CONSTANTS;

import {PATIENT_STATUS_ACTIVE, PATIENT_STATUS_UNENROLL_PENDING, PATIENT_STATUS_SUPPORT} from '../constants/other';

const initialState = {
    isFetching: false,
    selectedPatient: {},
    patientCarePlans:[],
    adminUser:false,
    maUser:false,
    chartOpened:false,
    openCharts: null,
    activeNavLink: '',

};

function loading(state) {
    return Object.assign({}, state, {
        'isFetching': true
    });
}

function loaded(state) {
    return Object.assign({}, state, {
        'isFetching': false
    });
}

export default createReducer(initialState, {
    [LOGOUT_USER]:state => {
        return Object.assign({}, state, {
            isFetching: false,
            adminUser:false,
            maUser:false,
            openCharts: null
        });
    },
    [REQUEST_SENT]: state => {
        return Object.assign({}, state, {
            'isFetching': true
        });
    },
    [MAKE_CALL_SENT]: state => {
        return Object.assign({}, state, {
            'isFetching': true
        });
    },
    [MAKE_CALL_SUCCESS]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [MAKE_CALL_FAIL]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [CASE_HISTORY_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [GOAL_ADDED_SUCCESSFULLY]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [ON_PATIENT_PHARMACY_OPTIONS_RECEIVED]: (state,payload) => {
        return Object.assign({}, state, {
            'selectedPatientPharmacyOptions': payload
        });
    },
    [ON_PATIENT_MEDICATION_OPTIONS_RECEIVED]: (state,payload) => {
        return Object.assign({}, state, {
            'selectedPatientMedicationOptions': payload
        });
    },
    [ON_PATIENT_CHARTSPAN_PROVIDERS_RECEIVED]: (state,payload) => {
        return Object.assign({}, state, {
            'selectedPatientChartspanProviders': payload
        });
    },
    [GOAL_UPDATED_SUCCESSFULLY]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [GOAL_COMPLETED_SUCCESSFULLY]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [NON_ACCOUNT_PATIENT_DETAIL_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [STEP_ADDED_SUCCESSFULLY]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [STEP_UPDATED_SUCCESSFULLY]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [ACCOUNT_CREATED_SUCCESSFULLY]: (state,payload) => {
        const existingProvider = state.selectedPatient ? state.selectedPatient.provider : null;
        return Object.assign({}, state, {
            'isFetching': false,
            'selectedPatient':{...payload,provider:existingProvider}
        });
    },
    [PATIENT_CASE_MESSAGES_UNREAD_CHECK_SUCCESS]: (state, payload) => {
        const selectedPatientClone = {...state.selectedPatient, ...payload};
        return Object.assign({}, state, {
            'isFetching': false,
            'selectedPatient':selectedPatientClone
        });

    },
    [PATIENT_ENGAGEMENT_HISTIRY_RECEIVED]: (state,payload) => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [MEDICAL_RECORD_MATCHED_SUCCESSFULLY]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [PATIENT_SEARCH_RESULT_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [SSO_TOKEN_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [CARE_PLAN_AND_GOALS_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [PATIENT_EDUCATIONS_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [NOTES_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [LAB_UNITS_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [LABS_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [ASSESSMENT_QUESTIONS_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [ASSESSMENT_ANSWERS_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [PATIENT_VERIFIED]: (state, payload) => {
        var _state = _.cloneDeep(state);
        _state.isFetching = false;

        if(_state.selectedPatient){
            _state.selectedPatient.status = PATIENT_STATUS_ACTIVE;
        }

        return _state;
    },
	[MAILING_ADDRESS_UPDATED]: (state, payload) => {
		return Object.assign({}, state, {
			'selectedPatient':payload,
			'isFetching': false,
		});
	},
    [ASSESSMENT_UPDATED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [REQUEST_FAIL]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [ASSESSMENT_DELETED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [ASSESSMENT_ADDED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [TODOS_MARK_COMPLETED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [TODO_MARK_UNCOMPLETED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [TODO_ADDED_SUCCESSFULLY]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [TODO_UPDATED_SUCCESSFULLY]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [INBOUND_MEDICAL_RECORD_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [INBOUND_MATCHED_MEDICAL_RECORD_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [MEDICAL_DOC_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [LEVEL_TWO_DASHBOARD_DATA_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false,
            'chartOpened':true
        });
    },
    [CHART_CLOSED_SUCCESSFULLY]: state => {
        return Object.assign({}, state, {
            chartOpened: false,
            isFetching: false,
        });
    },
    [ASSESSMENTS_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [CONSENT_DOC_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [INBOUND_CONSENT_FORM_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [PATIENT_DETAIL_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            'selectedPatient':payload,
            'selectedPatientLastVisited':null,
            'patientCarePlans':[]
        });
    },
    [PATIENT_LAST_VISITED_DATE_RECEIVED]: (state,payload) => {
        return Object.assign({}, state, {
            'selectedPatientLastVisited':payload,
        });
    },
    [PATIENT_CARE_PLAN_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            'patientCarePlans':payload
        });
    },
    [CALL_LOG_SELECTIONS_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            'isFetching': false,
            'callLogSelections':payload,
        });
    },
    [CALL_ASSISTANCE_TYPES]: (state, payload) => {
        return Object.assign({}, state, {
            'isFetching': false,
            'callAssistanceTypes':payload,
        });
    },
    [RESET_PATIENT_UPDATE_STATUS]:(state,payload)=>{
        return Object.assign({}, state, {
            'isPatientDetailsUpdated':false
        });
    },
    [PATIENT_DETAILS_UPDATED]:(state,payload)=>{
        return Object.assign({}, state, {
            'isFetching':false,
            'selectedPatient':payload,
            'isPatientDetailsUpdated':true
        });
    },
    [UNASSIGNED_PATIENTS_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [MA_DETAIL_RECEIVED]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [MA_ASSIGNED_SUCCESSFULLY]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [GOAL_STEP_ADDED_SUCCESSFULLY]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [GOAL_STEP_DELETED_SUCCESSFULLY]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [GOAL_STEP_UPDATED_SUCCESSFULLY]: state => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [LOGIN_USER_SUCCESS]: (state, payload) => {
        var adminUser = _.findIndex(payload.token.roles, (role)=> {
                return role == "ROLE_SUPERVISOR";
            }) != -1;

        var pathname = payload.currentRoute;

        return Object.assign({}, state, {
            'adminUser': adminUser,
            'maUser': !adminUser,
            'openCharts': null
        });

    },
    [CHART_OPEN_BY_REQUEST]: loading,
    [INITIATE_UNENROLL_PATIENT_REQUEST]: loading,
    [GET_OPEN_CHARTS_REQUEST]: loading,
    [ADMIN_CLOSE_CHART_REQUEST]: loading,
    [GET_CCDA_AVAILABILITY_REQUEST]: loading,
    [GET_CCDA_PDF_REQUEST]: loading,
    [GET_MED_SUMMARY_PDF_REQUEST]: loading,
    [GET_PHS_AVAILABILITY_REQUEST]: loading,
    [GET_PHS_PDF_REQUEST]: loading,

    [CHART_OPEN_BY_REQUEST_FAIL]: loaded,
    [INITIATE_UNENROLL_PATIENT_FAIL]: loaded,
    [GET_OPEN_CHARTS_FAIL]: loaded,
    [ADMIN_CLOSE_CHART_FAIL]: loaded,
    [GET_CCDA_AVAILABILITY_FAIL]: loaded,
    [GET_CCDA_PDF_FAIL]: loaded,
    [GET_MED_SUMMARY_PDF_FAILURE]: loaded,
    [GET_PHS_AVAILABILITY_FAIL]: loaded,
    [GET_PHS_PDF_FAIL]: loaded,
    [ SEND_TO_SUPPORT_FAILED ]: loaded,

    [CHART_OPEN_BY_REQUEST_SUCCESS]: loaded,
    [GET_CCDA_AVAILABILITY_SUCCESS]: loaded,
    [GET_CCDA_PDF_SUCCESS]: loaded,
    [GET_MED_SUMMARY_PDF_SUCCESS]: loaded,
    [GET_PHS_AVAILABILITY_SUCCESS]: loaded,
    [GET_PHS_PDF_SUCCESS]: loaded,
    [INITIATE_UNENROLL_PATIENT_SUCCESS]: (state, payload) => {
        let _state = _.cloneDeep(state),
            selectedPatient = _state.selectedPatient;

        _state.isFetching = false;

        if (selectedPatient && selectedPatient.id === payload.patient.id) {
            selectedPatient.status = PATIENT_STATUS_UNENROLL_PENDING;
            selectedPatient.status_description = payload.patient.status_description;
        }

        return _state;
    },

    [GET_OPEN_CHARTS_SUCCESS]: (state, payload) => {
        return Object.assign({}, state, {
            openCharts: payload,
            isFetching: false
        });
    },

    [ADMIN_CLOSE_CHART_SUCCESS]: (state, payload) => {
        let _state = _.cloneDeep(state);

        _state.isFetching = false;

        if (_state.openCharts && _state.openCharts.length) {
            _state.openCharts = _.filter(_state.openCharts, openChart => {
                return openChart.ma_id !== payload.ma_id;
            });
        }

        return _state;
    },
    [RESET_PASSWORD_COMPLETE]: ( state = {}, payload ) => {
        return Object.assign({}, state, {
            showConfirmReset: false,
            isFetching: false
        });
    },
    [REQUEST_COMPLETE]: ( state = {}, payload) => {
        return Object.assign({}, state, {
            isFetching: false
        });
    },
    [PATIENT_CCM_PROVIDERS_RECEIVED]: ( state = {}, payload) => {
        return Object.assign({}, state, {
            patientCcmProviders: payload
        });
    },
    [RECORDS_RECEIVED]: ( state = {}, payload) => {
        return Object.assign({}, state, {
            recordsHeaders: payload
        });
    },
    [URGENT_TODO_TYPES_RECEIVED]: ( state = {}, payload ) => {
        return Object.assign({}, state, {
            urgentTypes: payload
        });
    },
    [TODO_SOCIAL_DETERMINANTS_RECEIVED]: ( state = {}, payload ) => {
        return Object.assign({}, state, {
            socialDeterminants: payload
        });
    },
    [FETCH_QUEUE_REQUEST]: (state, payload) => {
        return Object.assign({}, state, {
            isFetching: true,
        });
    },
    [FETCH_QUEUE_SUCCESS]: (state, payload) => {
        return Object.assign({}, state, {
            isFetching: false,
        });
    },
    [FETCH_QUEUE_FAILURE]: (state, payload) => {
        return Object.assign({}, state, {
            isFetching: false,
        });
    },
    [NAV_LINK_CHANGED]: (state, payload) => {
        return Object.assign({}, state, {
            activeNavLink: payload,
        });
    },
    [SEARCH_PATIENTS_SUCCESS]: (state, payload) => {
        return Object.assign({}, state, {
            isFetching: false,
        });
    },
    [SEARCH_PATIENTS_FAILURE]: (state, payload) => {
        return Object.assign({}, state, {
            isFetching: false,
        });
    },

    [ SEND_TO_SUPPORT_SUCCESS ]: ( state = {}, payload ) => {
        let updatedPatient = Object.assign( {}, state.selectedPatient );
        if ( payload.status.toLowerCase() === 'success' ) {
            updatedPatient.status = PATIENT_STATUS_SUPPORT;
        }

        return Object.assign( {}, state, {
            fetching: false,
            selectedPatient: updatedPatient,
        });
    },
	[UPDATE_PATIENT_SATISFACTION_PREFERENCES]: ( state, payload ) => {
		let updatedPatient = Object.assign( {}, state.selectedPatient );
		updatedPatient.show_nps = payload;
		return Object.assign({}, state, {
        	selectedPatient: updatedPatient
        })
	},
    [PATIENT_CASE_DETAIL_MESSAGES_SUCCESS]:( state, payload ) => {
		return Object.assign({}, state, {
        	isFetching: false,
        })
	},
});
