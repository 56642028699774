/**
 * Created by synerzip on 08/12/15.
 */
import { checkHttpStatus, parseJSON } from '../../utils';
import CONSTANTS from '../../constants';
import config from '../../config';
import {updateAccessToken} from '../authactions';
import {pushState} from 'redux-router';

const {SHOW_TOAST,HIDE_TOAST} = CONSTANTS;

export function showToast(toastData) {
    return {
        type: SHOW_TOAST,
        payload: toastData
    }
}

export function hideToast() {

    return {
        type: HIDE_TOAST
    }
}

function redirectLoginScreen(error){
    if(error.response && error.response.status == 401){
        localStorage.clear();
        config.store.dispatch(pushState(null, '/login'));
        config.store.dispatch(showToast({
            status: 'error',
            title: "Session Timeout",
            message: "Session has been timed out, please login again."
        }));
        var interval = setInterval(()=> {
            clearInterval(interval);
            config.store.dispatch(hideToast());
        }, 2);
    }else {
        if(error.response && error.response.status == 400) {
            throw error;
        }
        if(error.response && error.response.status != 304){
            localStorage.clear();
        }
        config.store.dispatch(pushState(null, '/login'));
         config.store.dispatch(showToast({
             status: 'error',
             title: "Session Timeout",
             message: "Session has been timed out, please login again."
         }));
         var interval = setInterval(()=> {
             clearInterval(interval);
             config.store.dispatch(hideToast());
         }, 2);
         throw {error,code:'session_timeout'};
    }
}

export function get(nodeURL, headers = undefined, getResponseBlob = false) {
    //get token
    let loggedinUser = localStorage.getItem('ccmLoggedinUser');
    if (loggedinUser !== null) {
        var loggedinUserObj = JSON.parse(loggedinUser);
        return fetch(config.BASE_URL + nodeURL, {
            method: 'get',
            headers: Object.assign({}, {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': loggedinUserObj.token
            }, headers)

        }).then(checkHttpStatus)
            .then((response) => {
                var token = response.headers.get('X-AUTH-TOKEN');
                updateAccessToken(token);

                if (getResponseBlob) {
                    response = response.blob();
                } else {
                    response = parseJSON(response);
                }

                return response;
            })
            .then(result => {
                return result;
            })
            .catch(error => {
                redirectLoginScreen(error)
            })
    } else {
        config.store.dispatch(pushState(null, '/login'));
    }
}

export function deleteRequest(nodeURL) {
    //get token
    let loggedinUser = localStorage.getItem('ccmLoggedinUser');
    if (loggedinUser !== null) {
        var loggedinUserObj = JSON.parse(loggedinUser);
        return fetch(config.BASE_URL + nodeURL, {
            method: 'delete',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': loggedinUserObj.token
            }

        }).then(checkHttpStatus)
            .then((response) => {
                var token = response.headers.get('X-AUTH-TOKEN');
                updateAccessToken(token);
                return parseJSON(response);
            })
            .then(result => {
                return result;
            })
            .catch(error => {
                redirectLoginScreen(error)
            })
    }else {
        config.store.dispatch(pushState(null, '/login'));
    }
}

export function post(nodeURL,data, headers = undefined) {
    //get token
    let loggedinUser = localStorage.getItem('ccmLoggedinUser');
    if (loggedinUser !== null) {
        var loggedinUserObj = JSON.parse(loggedinUser);
        let fetchDetails;
        if(data) {
            fetchDetails = {
                method: 'post',
                headers: Object.assign({}, {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-AUTH-TOKEN': loggedinUserObj.token
                }, headers),
                body:JSON.stringify(data),
                keepalive:true
            };
        } else {
            fetchDetails = {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-AUTH-TOKEN': loggedinUserObj.token
                }
            };
        }

        return fetch(config.BASE_URL + nodeURL, fetchDetails).then(checkHttpStatus)
            .then((response) => {
                var token = response.headers.get('X-AUTH-TOKEN');
                updateAccessToken(token);
                return parseJSON(response);
            })
            .then(result => {
                return result;
            })
            .catch(error => {
                redirectLoginScreen(error)
            })
    } else {
        config.store.dispatch(pushState(null, '/login'));
    }
}

export function put(nodeURL,data) {
    //get token
    let loggedinUser = localStorage.getItem('ccmLoggedinUser');
    if (loggedinUser !== null) {
        var loggedinUserObj = JSON.parse(loggedinUser);
        return fetch(config.BASE_URL + nodeURL, {
            method: 'put',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': loggedinUserObj.token
            },
            body:JSON.stringify(data)

        }).then(checkHttpStatus)
            .then((response) => {
                var token = response.headers.get('X-AUTH-TOKEN');
                updateAccessToken(token);
                return parseJSON(response);
            })
            .then(result => {
                return result;
            })
            .catch(error => {
                redirectLoginScreen(error)
            })
    } else {
        config.store.dispatch(pushState(null, '/login'));
    }
}