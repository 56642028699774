import React from 'react';
import * as adminActionCreator from '../../actions/admin';
import * as appActionCreator from '../../actions/app';
import * as notificationsActionCreator from '../../actions/notifications';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, FormGroup, FormControl, InputGroup, Col, ControlLabel, Button, Grid, Row } from 'react-bootstrap';
import { reduxForm, getValues } from 'redux-form';
import _ from 'lodash';
import moment from 'moment';
import DateTimePicker from 'react-bootstrap-datetimepicker-custom-icons';

export const fields = [ 'clinic', 'specialty', 'fax', 'dateRangeBegin', 'dateRangeEnd' ];

class RequestRecordExtendedForm extends React.Component {

	constructor() {
		super();
		this.state = {
			dateRangeBegin: moment().format("MM-DD-YYYY"),
			dateRangeEnd: moment().format("MM-DD-YYYY")
		}
	}

	render() {
		const { fields: { clinic, specialty, fax, dateRangeBegin, dateRangeEnd }, handleSubmit } = this.props;
		const inputStyle = { 'width': '100%', 'paddingLeft': '5px', 'borderRadius': '4px', 'border': '1px solid rgb(204, 204, 204)', 'height': '50px' };
		const inputStyleError = { 'width': '100%', 'paddingLeft': '5px', 'border': '1px solid rgba(169, 67, 66, 0.8)' };
		const labelStyle = { 'lineHeight': '50px' };
		const labelStyle2 = { 'margin': 'auto', 'display': 'block', 'marginBottom': '20px', 'textAlign': 'center' };
		const formStyle = { 'paddingBottom': '35px' };
		return (
			<div>
				<Col sm={12}>
					<Col smOffset={1} sm={2}>
						<label style={ labelStyle }>Clinic Name</label>
					</Col>
					<Col sm={6}>
						<input  type="text"
								disabled="true"
								id="thing1"
								style={ inputStyle }
								{ ...clinic } />
					</Col>
				</Col>
				<Col sm={12}>
					<Col smOffset={1} sm={2}>
						<label style={ labelStyle }>Specialty</label>
					</Col>
					<Col sm={6}>
						<input  type="text" 
								disabled="true"
								id="thing2"
								style={ inputStyle }
								{ ...specialty } />
					</Col>
				</Col>
				<Col sm={12}>
					<Col smOffset={1} sm={2}>
						<label style={ labelStyle }>Fax Number</label>
					</Col>
					<Col sm={6}>
						<input  type="text" 
								disabled="true"
								id="thing3"
								style={ inputStyle }
								{ ...fax }  />
					</Col>
				</Col>
				<div className="col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4"> 
					<label style={ labelStyle2 }>Begin Date Range</label>
					<DateTimePicker format="MM-DD-YYYY"
									inputFormat="MM/DD/YYYY"
									dateTime={this.state.dateRangeBegin}
									defaultText=""
									inputProps={{'placeholder': 'MM/DD/YYYY'}}
									className="dateError"
									iconStyle={{
										class: 'fa',
										time: 'fa-clock-o',
										calendar: 'fa-calendar',
										up: 'fa-chevron-up',
										down: 'fa-chevron-down',
										right: 'fa-chevron-right',
										left: 'fa-chevron-left',
									}}
									{ ...dateRangeBegin } />
					<Col sm={12}>
						<span 	className="text-danger"
								style={ labelStyle2 }>
							<small>{ this.props.levelTwoDashboard.recordErrors && this.props.levelTwoDashboard.recordErrors.dateRangeBegin }</small>
						</span>
					</Col>
					<label style={ labelStyle2 }>End Date Range</label>
					<DateTimePicker format="MM-DD-YYYY"
									inputFormat="MM/DD/YYYY"
									dateTime={this.state.dateRangeEnd}
									defaultText=""
									inputProps={{'placeholder': 'MM/DD/YYYY'}}
									{ ...dateRangeEnd } />
					<Col sm={12}>
						<span 	className="text-danger"
								style={ labelStyle2 }>
							<small>{ this.props.levelTwoDashboard.recordErrors && this.props.levelTwoDashboard.recordErrors.dateRangeEnd }</small>
						</span>
					</Col>
				</div>
				<div className="col-md-4 col-md-offset-4 col-lg-2 col-lg-offset-5" style={{"marginTop": "15px"}}> 
					<Button type="submit"
							bsStyle="success"
							block>
						Preview Request
					</Button>
				</div>
			</div>
		)
	}
}

RequestRecordExtendedForm = reduxForm({
	form: 'extendedRequestForm',
	fields: fields
})(RequestRecordExtendedForm);

const mapStateToProps = (state) => ({
	'levelTwoDashboard': state.levelTwoDashboard
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    appActions:bindActionCreators(appActionCreator,dispatch),
    notificationsAction:bindActionCreators(notificationsActionCreator,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestRecordExtendedForm);