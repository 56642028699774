import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from "moment";
import * as notificationsActionCreator from '../../actions/notifications';
import { Panel, Button, Popover, OverlayTrigger } from "react-bootstrap";
import { Table, Column, Cell } from "fixed-data-table";
import ResponsiveFixedDataTable from "../../components/ResponsiveFixedDataTable";
import SelectPatientConfirmPopup from '../patientCare/SelectPatientConfirmPopup';
import PageHeader from "../../components/PageHeader";
import categories from "./categories.json"

class CaseMessagesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnWidths: {
        organization: 170,
        patientName: 170,
        case: 170,
        subject: 470,
        chart: 150,
        createUpdate: 180,
        category: 180
        
      },
      colSortDirs: {},
      show:false,
      selectedPatient:null
    };
  }

  componentDidMount() {
    this.props.notificationsAction.getCaseMessages();
  }

  onPopupHide() {
    this.setState({selectedPatient: null, show: false});
}

  handleColumnResize = (columnWidth, columnKey) => {
    console.log("first",columnWidth,"second",columnKey)
    this.setState({
      columnWidths: {
        ...this.state.columnWidths,
        [columnKey]: columnWidth,
      },
    });
  };

  openChart = async(caseMessageData) => {
    console.log("caseMessageData:",caseMessageData)
    const ccmOrgId = caseMessageData.ccm_org_id;
    const patientEhrID = caseMessageData.patient_ehr_id;
    try{
     const patientDetail =  await this.props.notificationsAction.getEHRPatientData(ccmOrgId,patientEhrID);
     this.setState({selectedPatient:patientDetail,show:true});
    }catch(error){
      //Dont do amnything, as message is handled on action
    }
  }

  render() {
    const { columnWidths, colSortDirs } = this.state;
    console.log("columnWidths",columnWidths)
    const { caseMessages } = this.props;
    const recordLength =  caseMessages ? caseMessages.length : 0;
    return (
      <div>
        <div
          style={{
            display: "flex",
            marginBottom: "1rem",
            justifyContent: "flex-end",
          }}
        >
          <PageHeader></PageHeader>
          <div style={{ flex: 1, alignItems: "center" }}>
            <Panel
              header={
                <div className="clearfix">
                  <span style={{ fontSize: 20, fontWeight: 500 }}>
                    Case Messages
                  </span>
                </div>
              }
              style={{ width: "100%" }}
            >
              <div style={{ height: "750px", position: "relative" }}>
                {
                  caseMessages !== null && caseMessages.length === 0 ?
                  <div>There are no unread case messages.</div>
                  :
                  <ResponsiveFixedDataTable
                  height={750}
                  width={1000}
                  rowHeight={50}
                  headerHeight={50}
                  isColumnResizing={false}
                  rowsCount={recordLength}
                  onColumnResizeEndCallback={this.handleColumnResize}
                  {...this.props}
                >
                  <Column
                    columnKey="organization"
                    fixed={true}
                    isResizable={true}
                    width={columnWidths.organization}
                    header={<Cell>Organization</Cell>}
                    cell={({ rowIndex, data, columnKey, ...props }) => {
                      return <Cell {...props}>{
                        caseMessages[rowIndex].org_name
                      }</Cell>;
                    }}
                  />
                  <Column
                    columnKey="patientName"
                    fixed={true}
                    isResizable={true}
                    width={columnWidths.patientName}
                    header={<Cell>Patient Name</Cell>}
                    cell={({ rowIndex, data, columnKey, ...props }) => {
                      return <Cell {...props}>{
                        `${caseMessages[rowIndex].first_name} ${caseMessages[rowIndex].last_name}`
                        }</Cell>;
                    }}
                  />
                  <Column
                    columnKey="case"
                    fixed={false}
                    isResizable={true}
                    width={columnWidths.case}
                    header={<Cell>Case</Cell>}
                    cell={({ rowIndex, data, columnKey, ...props }) => {
                      return <Cell {...props}>{
                        caseMessages[rowIndex].case_id
                      }</Cell>;
                    }}
                  />
                  <Column
                    columnKey="subject"
                    flexGrow={1}
                    fixed={false}
                    isResizable={true}
                    width={columnWidths.subject}
                    header={<Cell>Subject</Cell>}
                    cell={({ rowIndex, data, columnKey, ...props }) => {
                      return <Cell {...props}>{
                        caseMessages[rowIndex].subject
                      }</Cell>;
                    }}
                  />
                  <Column
                    columnKey="type"
                    fixed={false}
                    isResizable={true}
                    width={columnWidths.category}
                    header={<Cell>Type</Cell>}
                    cell={({ rowIndex, data, columnKey, ...props }) => {
                      return <Cell {...props}>{
                        caseMessages[rowIndex].category ? categories[caseMessages[rowIndex].category] : '--'
                        }</Cell>;
                    }}
                  />
                  <Column
                    columnKey="createUpdate"
                    fixed={false}
                    
                    isResizable={true}
                    width={columnWidths.createUpdate}
                    header={<Cell>Create/Update</Cell>}
                    cell={({ rowIndex, data, columnKey, ...props }) => {
                      return <Cell {...props}>{
                        moment(caseMessages[rowIndex].last_message_time).format('MMM DD, YYYY hh:MM a')
                        }</Cell>;
                    }}
                  />
                  
                  <Column
                    columnKey="chart"
                    fixed={false}
                   
                    isResizable={false}
                    width={columnWidths.chart}
                    header={<Cell>Chart</Cell>}
                    cell={({ rowIndex, data, columnKey, ...props }) => {
                      return  <Cell {...props}>
                          <div style={{ textAlign: "center" }}>
                            <button
                              type="button"
                              className="btn btn-primary"
                              style={{ padding: "2px 12px" }}
                              onClick={() => this.openChart(caseMessages[rowIndex])}
                            >
                              Open Chart
                            </button>
                          </div>
                        </Cell>
                    }}
                  />
                  
                </ResponsiveFixedDataTable>
                }
                
              </div>
            </Panel>
          </div>
        </div>
        <SelectPatientConfirmPopup show={this.state.show} onPopupHide={this.onPopupHide.bind(this)}
                                           data={this.state.selectedPatient}
                                           fromCaseMessages/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  'caseMessages':state.notifications.caseMessages,
  'selectedPatientData':state.notifications.selectedPatientData,
});

const mapDispatchToProps = (dispatch) => ({
  notificationsAction:bindActionCreators(notificationsActionCreator,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseMessagesList);
