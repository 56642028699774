import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentQueueCopay = () =>
	<PatientQueue
		showQueueCount
		patientNameColumn
		providerDetailColumn
		timeBilledColumn
		todoItemColumn
		showFilters
		showProgramTypeFilter
        showScheduleCallFilter
		statusColumn
		chartColumn
		lastWorkedByColumn
		lastOutCallColumn
		callAttemptsCol
		showSort
		sortOptions={[
			{
				'name': 'Last Called Ascending',
				'key': 'lastOutCallTs&dir=asc',
			},
			{
				'name': 'Last Called Descending',
				'key': 'lastOutCallTs&dir=desc',
			}
		]}
		page
		queue="urgentCopay"
		endpoint="Urgent?typesToInclude=15"
		label="Urgent - Copay/Insurance"
	/>;

export default UrgentQueueCopay;
