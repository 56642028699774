/**
 * Created by synerzip on 08/02/16.
 */
import React from "react";
import { bindActionCreators } from "redux";
import * as adminActionCreator from "../../actions/admin";
import * as commonActionCreator from "../../actions/common";
import { connect } from "react-redux";
import reactMixin from "react-mixin";
import { Input, Modal, Button, FieldGroup } from "react-bootstrap";
import * as notificationsActionCreator from "../../actions/notifications";
import _ from "lodash";
const unidecode = require('unidecode');
import moment from "moment";
var DateTimeField = require("react-bootstrap-datetimepicker-custom-icons");
var LinkedStateMixin = require("react-addons-linked-state-mixin");
import categories from "./categories.json"

const Message = (props) => {
  const { message, onMessageSelect } = props;

  if (message.origin === "CHARTSPAN") {
    return (
      <div
        className="col-md-10  pvs phxs provider-notification-item offset-md-2"
        style={{ alignSelf: "flex-end", paddingLeft:0, paddingRight:0 }}
      >
        <div>
          <div>
            <div className="notification-body pvs phs">
              {(message.message)}
              <div className="notification-body-tag-box">
                {" "}
                <span>&nbsp;</span> <div className="tags-box"> </div>
              </div>
            </div>
            <div className="notification-time">
              {message.created_by || message.origin}{" "}
            </div>
            <div className="notification-time">
              {moment
                .utc(message.updated_at)
                .local()
                .format("MMM D, YYYY h:mm a")}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="col-md-10  pvs phxs ccm-notification-item offset-md-2"
        style={{ alignSelf: "flex-start", paddingLeft:0, paddingRight:0 }}
      >
        <div>
          <div>
            <div className="notification-body pvs phs">
              {(message.message)}
              <div className="notification-body-tag-box">
                {" "}
                <span>&nbsp;</span> <div className="tags-box"> </div>
              </div>
            </div>
            <div className="notification-time">
            {message.created_by || message.origin}{" "}
            </div>
            <div className="notification-time">
              {moment
                .utc(message.updated_at)
                .local()
                .format("MMM D, YYYY h:mm a")}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

class CaseMessageDetail extends React.Component {
  constructor(props) {
    super(props);
    const { activeUser } = props;
    this.state = {
      editMessage: "",
      inProgress: false
    };
    this.scrollDataBox = null;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedMessage && this.props.selectedMessage !== nextProps.selectedMessage) {
      this.props.notificationsAction
        .getPatientCaseMessagesById(nextProps.selectedMessage.id)
        .then((data) => {
          
          //check all unread message
          const messageIds = [];
          _.each(data, (message) => {
            if (message.origin === "EHR" && !message.read) {
              messageIds.push(message.id);
            }
          });
          if (messageIds.length > 0 || nextProps.selectedMessage.unread_messages) {
            const {rinconOrgId,patientRinconId} = nextProps.selectedPatient;
            this.props.notificationsAction.markReadCaseMessages(
              nextProps.selectedMessage.id,
              messageIds,
              rinconOrgId,
              patientRinconId
            );
          }

          setTimeout(() => {
            const element = document.getElementById('scrollContainerId');
            if(element){
              element.scrollTop = element.scrollHeight;
            }
            
          },100);
         
        });
    }
  }

  
  openProfile(e) {
    e.preventDefault();
    this.props.onOptionSelect("chartspan");
  }

  showErrorMessage(title, message) {
    this.props.commonActions.showToast({
      status: "error",
      title: title,
      message: message,
    });
    var interval = setInterval(() => {
      clearInterval(interval);
      this.props.commonActions.hideToast();
    }, 2);
  }
  validateAndSubmitData() {
    if (!this.state.editMessage) {
      this.showErrorMessage("Invalid Message", "Message is mandatory");
      return false;
    }
    return true;
  }
  async onSubmit(e) {
    this.setState({inProgress:true});
    e.preventDefault();
    if (this.validateAndSubmitData()) {
      const { id } = this.props.selectedMessage;
      const { rinconOrgId, provider, ehr_pid } = this.props.selectedPatient;
      try {
        await this.props.notificationsAction.createCaseMessage(
          id,
          rinconOrgId,
          unidecode(this.state.editMessage)
        );
        this.setState({ editMessage: "" });
       
      } catch (error) {
        //Do nothing
      }
    }
    this.setState({inProgress:false});
  }
  componentDidUpdate(prevProps){
    if(prevProps.messages !== this.props.messages){
      setTimeout(() => {
        const element = document.getElementById('scrollContainerId');
        if(element){
          element.scrollTop = element.scrollHeight;
        }
        
      },500);
    }
    
  }

  onPriorityChanged = (value, e) => {
    this.setState({ priority: value });
  };

  onCancel() {
    this.props.showSecondaryTimer();
    this.setState({
      editMessage: "",
      inProgress:false
    });
    this.props.onPopupHide();
    this.props.notificationsAction.resetCaseMessages();
  }

  render() {
    let { selectedMessage } = this.props;
    const {first_name, last_name, date_of_birth, age, provider} = this.props.selectedPatient;
    if (!selectedMessage) {
      selectedMessage = {};
    }

    let messageLength = this.state.editMessage
      ? this.state.editMessage.length
      : 0;
    return (
      <Modal show={this.props.show} dialogClassName="message-modal">
        <Modal.Header
          closeButton
          onHide={() => {
            this.props.onPopupHide();
          }}
        >
          <Modal.Title>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <div>Case Detail</div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="clearfix">
              <div style={{display:'flex',  background: "#d9edf7", borderRadius: 4, border: "1px solid #bce8f1", padding:15,
                  flexDirection:'column'
              }}>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                      <div style={{display:'flex', flex:1}}>
                        <span style={{fontWeight:'bold',paddingRight:4}}>Name:</span><span>{first_name + ' ' + last_name}</span>
                      </div>
                      <div style={{display:'flex', justifyContent:'flex-end', flex:1}}>
                        <span style={{fontWeight:'bold',paddingRight:4}}>Provider:</span><span>{provider ? provider.provider : ""}</span>
                      </div>
                </div>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                      <div style={{display:'flex', flex:1}}>
                        <span style={{fontWeight:'bold',paddingRight:4}}>DOB:</span><span>
                          {moment(date_of_birth).format('MM/DD/YYYY') + ', Age:' + age}
                        </span>
                      </div>
                      <div style={{display:'flex', justifyContent:'flex-end', flex:1}}>
                        <span style={{fontWeight:'bold',paddingRight:4}}>Organization:</span><span>
                        {provider ? provider.organization_secondary_name ?
                                                        provider.organization_secondary_name :
                                                        provider.organization : ""}
                        </span>
                      </div>
                </div>
                <div>
                  <hr style={{height:1, background:'#555555', margin:'7px 0'}}/> 
                </div>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                      <div style={{display:'flex', flex:1}}>
                        <span style={{fontWeight:'bold',paddingRight:4}}>Case ID:</span><span>
                          {selectedMessage.case_id}
                        </span>
                      </div>
                      <div style={{display:'flex', justifyContent:'flex-end', flex:1, alignItems:'center'}}>
                        <span style={{fontWeight:'bold',paddingRight:4}}>Case Status:</span><span>{selectedMessage.status}</span>
                        {
                        selectedMessage.priority === 'HIGH' && 
                          <div style={{background:'red', 
                          marginLeft: 5,
                          borderRadius:15, 
                          color:'#FFFFFF', 
                          fontWeight:600,
                          fontSize:11, 
                          padding:5}}>
                            High Priority
                          </div>
                        }
                      </div>
                </div>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                      <div style={{display:'flex', flex:1}}>
                        <span style={{fontWeight:'bold',paddingRight:4}}>Subject:</span><span>
                          {(selectedMessage.subject)}
                        </span>
                      </div>
                      <div style={{display:'flex', justifyContent:'flex-end', flex:1}}>
                        <span style={{fontWeight:'bold',paddingRight:4}}>Case Type:</span><span>{categories[selectedMessage.category]}</span>
                      </div>
                </div>
                <div style={{display:'flex', flexDirection:'column'}}>
                  <span style={{ fontWeight: 'bold' }}>Description</span> 
                  <div>
                    <span>{(selectedMessage.description)}</span>
                  </div>
                </div>
              </div>
              {
                  this.props.messages !== undefined &&
                      this.props.messages.length === 0 && 
                      this.props.selectedMessage &&
                      this.props.selectedMessage.origin !== "CHARTSPAN" &&
                      <div style={{padding:15,paddingBottom:0}}>
                        <div style={{padding:15,  background:'#fff3cd',borderRadius: 4,
                          border: "1px solid #ffeeba", color:'#856404', fontWeight:600}}>
                                New case received from provider {moment(this.props.selectedMessage.last_message_time).format("MMM D, YYYY h:mm a")}.
                        </div>
                      </div>
              }
            <form
              onSubmit={this.onSubmit.bind(this)}
              noValidate
              ref={(el) => (this.todoForm = el)}
            >
              <div className="col-sm-12 no-padding">
                <div
                  style={{
                    overflow: "auto",
                    marginTop: 15,
                    display:
                      this.props.messages === undefined ||
                      this.props.messages.length === 0
                        ? "none"
                        : "block",
                  }}
                >
                  <div className="notification-message-container notification-items-collection" 
                    id="scrollContainerId">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {this.props.messages !== undefined &&
                      this.props.messages.length === 0 ? (
                        <div style={{ marginTop: 15 }}>No Messages Found</div>
                      ) : (
                        _.map(this.props.messages, (message) => {
                          return <Message key={message.id} message={message} />;
                        })
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 no-padding">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 15,
                  }}
                >
                  <textarea
                    id="notificationTextAre"
                    maxLength="1024"
                    className="col-md-12 notification-reply-input"
                    placeholder="Type your reply here"
                    valueLink={this.linkState("editMessage")}
                  />
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {`${messageLength}/1024`}
                  </div>
                </div>
              </div>
              <div style={{ textAlign: "center", paddingTop: 10 }}>
                <Button
                  bsStyle="danger"
                  onClick={this.onCancel.bind(this)}
                  style={{ float: "left" }}
                >
                  Cancel
                </Button>
                <div>
                  <Button
                    bsStyle="success"
                    type="submit"
                    style={{ float: "right" }}
                    disabled={!this.state.editMessage || this.state.inProgress}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
reactMixin(CaseMessageDetail.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
  activeUser: state.admin.activeUser,
  messages: state.notifications.messagesByCase,
  user: state.auth.user,
  selectedPatient: state.app.selectedPatient,
  urgentTypes: state.app.urgentTypes,
  patient: state.app.selectedPatient,
  selectedPatientPharmacyOptions: state.app.selectedPatientPharmacyOptions,
  patientCcmProviders: state.app.patientCcmProviders,
  selectedPatientChartspanProviders:
    state.app.selectedPatientChartspanProviders,
  selectedPatientMedicationOptions: state.app.selectedPatientMedicationOptions,
});

const mapDispatchToProps = (dispatch) => ({
  adminActions: bindActionCreators(adminActionCreator, dispatch),
  commonActions: bindActionCreators(commonActionCreator, dispatch),
  notificationsAction: bindActionCreators(notificationsActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseMessageDetail);
