import React from 'react';
import * as adminActionCreator from '../../actions/admin';
import * as appActionCreator from '../../actions/app';
import * as notificationsActionCreator from '../../actions/notifications';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, FormGroup, FormControl, InputGroup, Col, ControlLabel, Button, Grid, Row } from 'react-bootstrap';
import { reduxForm, getValues } from 'redux-form';
import _ from 'lodash';
import moment from 'moment';
import RequestRecordExtendedForm from './RequestRecordExtendedForm';

class MergePatientForm extends React.Component {

	constructor() {
		super();
	}

	render() {
		const { fields: { currentAccount, mergeEmail }, mergePatient } = this.props;
		const labelStyle = { 'lineHeight': '50px', 'width': '25%', 'marginRight': '2%' };
		const labelStyle2 = { 'margin': 'auto', 'display': 'block', 'marginBottom': '20px', 'textAlign': 'center' };
		const inputStyle = { 'width': '100%', 'paddingLeft': '5px', 'borderRadius': '4px', 'border': '1px solid rgb(204, 204, 204)', 'height': '50px' };
		const inputStyleError = { 'width': '100%', 'paddingLeft': '5px', 'border': '1px solid rgba(169, 67, 66, 0.8)' };
		const formStyle = { 'paddingBottom': '35px', 'width': '100%', 'marginTop': '35px' };
		return (
			<Grid style={ formStyle }>
				<Row>
					<form onSubmit={ mergePatient }>
						<div>
							<label style={ labelStyle }>Current Account</label>
							<div style={{ 'lineHeight': '50px', 'display': 'inline-block', 'width': '73%' }}>
								<input  type="email"
									disabled="true"
									id="thing1"
									placeholder={ this.props.selectedPatient.email }
									style={ inputStyle }
									{ ...currentAccount } />
							</div>
						</div>
						
						<div>
							<label style={ labelStyle }>New Email Address</label>
							<div style={{ 'lineHeight': '50px', 'display': 'inline-block', 'width': '73%' }}>
								<input  type="email"
									id="thing1"
									placeholder="example@example.com"
									style={ inputStyle }
									{ ...mergeEmail } />
							</div>
							<span 	className="text-danger"
									style={ labelStyle2 }>
								<small>{ this.props.emailError }</small>
							</span>
						</div>
					</form>
				</Row>
			</Grid>
		)
	}

}

MergePatientForm = reduxForm({
	form: 'mergePatientForm',
	fields: [ 'currentAccount', 'mergeEmail' ]
})(MergePatientForm);

const mapStateToProps = (state) => ({
	'levelTwoDashboard': state.levelTwoDashboard,
	'formData': state.form,
	'admin': state.admin,
	'app': state.app
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    appActions:bindActionCreators(appActionCreator,dispatch),
    notificationsAction:bindActionCreators(notificationsActionCreator,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MergePatientForm);