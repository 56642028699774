import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentQueueNurse = () =>
	<PatientQueue
		showQueueCount
		patientNameColumn
		providerDetailColumn
		timeBilledColumn
		todoItemColumn
		showFilters
		showProgramTypeFilter
        showScheduleCallFilter
		statusColumn
		chartColumn
		lastWorkedByColumn
		page
		queue="urgentNurse"
		endpoint="Urgent?typesToInclude=1"
		label="Urgent - Nurse"
	/>;

export default UrgentQueueNurse;