import React from 'react';
import reactMixin from 'react-mixin';
import LinkedStateMixin from 'react-addons-linked-state-mixin';
import { Modal, Button, FormControl, FormGroup, ControlLabel } from 'react-bootstrap';
import { StateData } from '../../constants/other'

class UpdatePatientMailingAddress extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			address1:this.props.patient.address1,
			address2:this.props.patient.address2,
			city:this.props.patient.city,
			state:this.props.patient.state || '',
			zip_code:this.props.patient.zip_code
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			address1:nextProps.patient.address1,
			address2:nextProps.patient.address2,
			city:nextProps.patient.city,
			state:nextProps.patient.state || '',
			zip_code:nextProps.patient.zip_code
		});
	}

	onConfirm() {
		const { address1, address2, city, state, zip_code } = this.state;
		this.props.onConfirm(address1, address2, city, state, zip_code);
	}

	render() {
		const { show, onHide } = this.props;
		return (
			<Modal show={show} onHide={onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Update Mailing Address</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormGroup>
						<ControlLabel>Address (Line 1)</ControlLabel>
						<FormControl
							type="text"
							valueLink={this.linkState('address1')}
							placeholder="Address (Line 1)"
							style={{marginBottom: '10px'}}
						/>
						<ControlLabel>Address (Line 2)</ControlLabel>
						<FormControl
							type="text"
							valueLink={this.linkState('address2')}
							placeholder="Address (Line 2)"
							style={{marginBottom: '10px'}}
						/>
						<ControlLabel>City</ControlLabel>
						<FormControl
							type="text"
							valueLink={this.linkState('city')}
							placeholder="City"
							style={{marginBottom: '10px'}}
						/>
						<ControlLabel>State</ControlLabel>
						<FormControl
							componentClass="select"
							valueLink={this.linkState('state')}
							placeholder="State"
							style={{marginBottom: '10px'}}
						>
							<option value='' disabled>-------------------</option>
							{ StateData.map( ( state ) => {
								return (
									<option value={ state.id } key={ state.id }>{ state.name }</option>
								)
							}) }
						</FormControl>
						<ControlLabel>Zip Code</ControlLabel>
						<FormControl
							type="text"
							valueLink={this.linkState('zip_code')}
							placeholder="Zip Code"
							style={{marginBottom: '10px'}}
						/>
					</FormGroup>
				</Modal.Body>
				<Modal.Footer>
					<Button bsStyle="default" onClick={onHide}>
						Cancel
					</Button>
					<Button bsStyle="success" onClick={this.onConfirm.bind(this)}>
						Update
					</Button>
				</Modal.Footer>
			</Modal>
		)
	}

}

reactMixin(UpdatePatientMailingAddress.prototype, LinkedStateMixin);

export default UpdatePatientMailingAddress;
