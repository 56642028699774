import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getActiveUser,
    getCompanies,
    fetchOrganizations,
    fetchCallLogCodes,
    redirectIfChartOpen,
    checkWhiteboard,
    closeChart
} from '../actions/admin';
import {
    getNotificationsUnreadCount,
    checkCaseMessagesUnread
} from '../actions/notifications';
import {
    setActiveNavLink,
} from '../actions/app';
import MaskComp from './MaskComp';
import Header from './Header';
import Whiteboard from './Whiteboard';
import ChartSummary from './ChartSummary'
import config from '../config';
import _ from 'lodash';
import {isAdmin} from "../utils";

class Home extends Component {
    componentDidMount() {
       
        this.props.getActiveUser(this.props.userId);
        this.props.getCompanies();
        this.props.fetchOrganizations();
        this.props.fetchCallLogCodes();
        this.props.redirectIfChartOpen(this.props.userId);
        this.props.setActiveNavLink(this.props.location.pathname.split('/').pop());
        window.addEventListener('beforeunload', async(ev) => {
            ev.preventDefault();
            if(this.props.chartOpened){
                return ev.returnValue = 'Are you sure you want to close?';
            }else{
                return undefined;
            }
            
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname && !nextProps.params.patientId) {
            window.scroll(0, 0);
            nextProps.redirectIfChartOpen(nextProps.userId);
            nextProps.setActiveNavLink(nextProps.location.pathname.split('/').pop());
        }
        if(nextProps.activeUser && nextProps.activeUser !== this.props.activeUser){
            const { activeUser, adminUser } = nextProps;
            const isGAPSUser = activeUser && activeUser.center && activeUser.center.gaps_flag;
            const isCne = activeUser && activeUser.center && activeUser.center.ce_flag;
            const isRPMUser = activeUser && activeUser.center && activeUser.center.rpm_flag;
            if(adminUser &&  !isGAPSUser  && !isCne && !isRPMUser) {
                this.props.getNotificationsUnreadCount();
                this.props.checkCaseMessagesUnread();
            }
        }
    }

    componentWillUnmount(){
        window.removeEventListener('beforeunload',null);
    }

    render() {

        return (
            <div style={{ paddingTop: 75 }}>
                <Header />
                <div style={{ padding: '0 30px' }}>
                    {this.props.children}
                </div>
                <MaskComp show={this.props.isFetching || this.props.isNotificationFetching} />
                <ChartSummary />
                <Whiteboard />
            </div>
        );
    }
}

export default connect(
    (state) => ({
        userId: state.auth.id,
        adminUser: state.app.adminUser,
        isFetching: state.app.isFetching,
        isNotificationFetching: state.notifications.isFetching,
        chartOpened:state.app.chartOpened,
        selectedPatient:state.app.selectedPatient,
        activeUser: state.admin.activeUser
    }),
    {
        getActiveUser,
        getCompanies,
        fetchOrganizations,
        fetchCallLogCodes,
        redirectIfChartOpen,
        getNotificationsUnreadCount,
        checkCaseMessagesUnread,
        setActiveNavLink,
        checkWhiteboard,
        closeChart
    }
)(Home);
