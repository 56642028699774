/**
 * Created by synerzip on 11/12/15.
 */


let React = require('react');
let ReactDom = require('react-dom');
let Bootstrap = require('react-bootstrap');
let _ = require('lodash');
var LinkedStateMixin = require('react-addons-linked-state-mixin');
var errMsg = {
    required: 'This field cannot be empty.',
    email: 'Invalid email',
    phone:'Invalid Phone Number',
    fax:'Invalid Fax Number',
    confirmPassword: "Passwords don't match",
    alphabets:"Please enter letters, apostrophes, or hyphens only"
};

class FormInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            errorMessage: '',
            val: (props.type === 'phone') ?this.formatPhoneNumber(this.props.initialValue):this.props.initialValue
        };
        const defaultVal = {
            isRequired: false,
            numbersOnly: false,
            type: 'text',
            addonBefore: null,
            showErrorMessage:true
        };
        this.properties = _.assign({}, defaultVal, props);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        if(this.properties.maxLength ) {
            this.properties.maxLength = parseInt(this.properties.maxLength);
        } else {
            this.properties.maxLength = -1;
        }
    }
    componentWillReceiveProps(nextProps,nextState){
        if(nextProps.initialValue != this.props.initialValue){
            this.setState({
                error: false,
                errorMessage: '',
                val: (nextProps.type === 'phone') ?this.formatPhoneNumber(nextProps.initialValue):nextProps.initialValue
            });
        }
    }

    formatPhoneNumber(phoneVal){
        var numbers = phoneVal.replace(/\D/g, ''),
            char = {0:'(',3:') ',6:'-'};
        var formattedValue = '';
        for (var i = 0; i < numbers.length; i++) {
            formattedValue += (char[i]||'') + numbers[i];
        }
        return formattedValue;
    }
    _getInput() {
        return ReactDom.findDOMNode(this.refs.input);
    }
    _getForm() {
        return ReactDom.findDOMNode(this.refs.form);
    }
    _checkRequired(value) {
        return ( value.trim() !== '' );
    }
    _checkEmail(value){
        /* Email type format true=john@sam.com False=as.com as@com */
        var pattern=/^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+/;
        return (pattern.test(value));
    }

    _checkPhone(value){
        /* Email type format true=john@sam.com False=as.com as@com */
        var pattern = /^\(?([0-9]{3})\)?[- ]?([0-9]{3})[-]?([0-9]{4})$/;
        return (pattern.test(value));
    }

    _checkAlphabets(value){
        var pattern = /^[a-zA-Z-']*$/;
        return (pattern.test(value));
    }


    _isNumber(charCode) {
        return !(charCode > 31 && (charCode < 48 || charCode > 57));
    }
    _addEvents() {
        let ip = this._getInput();
        ip.addEventListener('blur', this.handleBlur);
        ip.addEventListener('focus', this.handleFocus);
        if( this.props.numbersOnly ) {
            ip.addEventListener('keypress', this.validateForNumber.bind(this));
        }
    }
    _removeEvents() {
        let ip = this._getInput();
        ip.removeEventListener('blur', this.handleBlur);
        ip.removeEventListener('focus', this.handleFocus);
        if( this.props.numbersOnly ) {
            ip.addEventListener('keypress', this.validateForNumber.bind(this));
        }
    }
    _formHasError(type) {
        this._getForm().classList.add('has-error');
        this.setState({
            error: true,
            errorMessage: errMsg[type]
        });
    }
    focus(){
        this._getInput().focus();
    }
    componentDidMount() {
        /* Adding Dynamic Event's */
        this._addEvents();
        let input = this._getInput();

        /* Adding Dynamic Attributes */
        if( this.props.placeholder ) {
            input.setAttribute("placeholder",this.props.placeholder);
        }
        if(this.properties.maxLength !== -1) {
            input.setAttribute("maxLength", this.properties.maxLength);
        }
        if(this.props.type) {
            input.setAttribute("type",this.props.type);
        }

        if(this.props.tabIndex){
            input.setAttribute("tabIndex",this.props.tabIndex);
        }

        if(this.props.addBefore) {
            /*let inputContainer = React.findDOMNode(this.refs.inputContainer);
             let icon = (<span className="input-group-addon" >{this.props.addBefore}</span>);
             inputContainer.insertBefore(icon, this._getInput());*/
        }

        if(this.props.addAfter) {
            /*let inputContainer = React.findDOMNode(this.refs.inputContainer);
             let icon = (<span className="input-group-addon" >{this.props.addAfter}</span>);
             inputContainer.insertAfter(icon, this._getInput());*/
        }

    }
    validateForNumber(evt) {
        evt.preventDefault();
        let preVal = this.state.val;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if( this._isNumber(charCode) ) {
            let value = this._getInput().value;
            let currLength = value.length + 1;
            let maxLength = this.properties.maxLength;
            if( maxLength != -1 && currLength <= maxLength ) {
                this.setState({
                    val: this._getInput().value + String.fromCharCode(charCode)
                });
            }
        } else {
            this.setState({
                val: preVal
            });
        }
    }
    componentWillUnmount() {
        this._removeEvents();
    }

    getValue(){
        return (this.props.type == 'phone' && this.state.val) ? this.state.val.replace(/[^0-9]/g, ''):this.state.val;
    }
    validate() {
        let value = this._getInput().value;
        if( this.props.isRequired ) {
            if(!this._checkRequired(value)) {
                this._formHasError('required');
                return false;
            }
        }

        if(_.isEmpty(value)) {
            return true;
        }

        if( this.props.type == 'email' ) {
            if(!this._checkEmail(value)) {
                this._formHasError('email');
                return false;
            }
        }

        if( this.props.type == 'phone' ) {
            if(!this._checkPhone(value)) {
                this._formHasError('phone');
                return false;
            }
        }

        if( this.props.type == 'fax' ) {
            if(!this._checkPhone(value)) {
                this._formHasError('fax');
                return false;
            }
        }

        if( this.props.type == 'alphabets' ) {
            if(!this._checkAlphabets(value)) {
                this._formHasError('alphabets');
                return false;
            }
        }

        return true;
    }
    resetComponent() {
        if( this.state.error ) {
            this._getForm().classList.remove('has-error');
            this.setState({
                error: false,
                errorMessage: ''
            });
        }
    }
    showError(type) {
        this.setState({
            error: true
        });
        this._formHasError(type);
    }
    handleFocus() {
        this.resetComponent();
    }
    handleBlur(e) {
        if(this.props.handleBlurEvent){
            this.props.handleBlurEvent();
        }

        if(String(e.target.attributes.type.value).indexOf('phone') !== -1){
            var numbers = e.target.value.replace(/\D/g, ''),
                char = {0:'(',3:') ',6:'-'};
            e.target.value = '';
            for (var i = 0; i < numbers.length; i++) {
                e.target.value += (char[i]||'') + numbers[i];
            }
            this.setState({
                val:e.target.value
            })
        }
        this.validate();
    }
    render() {
        return (
            <div ref='form' className='form-group' style={{width:"100%"}}>
                <div ref='inputContainer' className='input-group'>
                    <input className={this.props.className ? ('form-control ' + this.props.className) : 'form-control'}
                           ref='input'
                           valueLink={this.linkState('val')}
                           readOnly={this.props.readOnly ? this.props.readOnly:false}
                    />
                </div>
                {
                    (this.properties.showErrorMessage)?
                        <span ref='errorMessage' className='text-danger'>
                            <small>{this.state.errorMessage}</small>
                        </span>
                        :
                        null
                }

            </div>
        );
    }
}
FormInput.propTypes = {
    isRequired: React.PropTypes.bool,
    type: React.PropTypes.oneOf(['text', 'email', 'password', 'number','phone','fax', 'alphabets']),
    numbersOnly: React.PropTypes.bool
};

_.assign(FormInput.prototype, LinkedStateMixin);

export default FormInput;