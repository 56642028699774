import React from 'react';
import PatientQueue from './PatientQueue'


const UrgentQueueTriage = () =>
	<PatientQueue
    showQueueCount
    showFilters
    patientDetailColumn
    statusColumn
    providerDetailColumn
    chartColumn
    lastWorkedByColumn
    page
    lastOutCallColumn
    lastHistoryColumn
    queue="urgentTriage"
    endpoint="Urgent?typesToInclude=24"
    label="Urgent - Triage"
    showSort
        sortOptions={[
            {
                'name': 'Last Called Ascending',
                'key': 'lastOutCallTs&dir=asc',
            },
            {
                'name': 'Last Called Descending',
                'key': 'lastOutCallTs&dir=desc',
            }
        ]}
	/>
 

export default UrgentQueueTriage;
