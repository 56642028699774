import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentQueueBadPhone = () =>
    <PatientQueue
        showQueueCount
        patientDetailColumn
        providerDetailColumn
        timeBilledColumn
        todoItemColumn
        statusColumn
        showFilters
        showProgramTypeFilter
        showScheduleCallFilter
        chartColumn
        lastWorkedByColumn
        lastOutCallColumn
        callAttemptsCol
        showSort
        sortOptions={[
            {
                'name': 'Last Called Ascending',
                'key': 'lastOutCallTs&dir=asc',
            },
            {
                'name': 'Last Called Descending',
                'key': 'lastOutCallTs&dir=desc',
            }
        ]}
        page
        queue="urgentBadPhone"
        endpoint="Urgent?typesToInclude=6"
        label="Urgent - Bad Phone"
    />;

export default UrgentQueueBadPhone;
