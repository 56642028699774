/**
 * Created by synerzip on 08/02/16.
 */
import React from "react";
import { bindActionCreators } from "redux";
import * as adminActionCreator from "../../actions/admin";
import * as commonActionCreator from "../../actions/common";
import { connect } from "react-redux";
import reactMixin from "react-mixin";
import { Input, Modal, Button, FieldGroup } from "react-bootstrap";
import * as notificationsActionCreator from "../../actions/notifications";
import _ from "lodash";
import moment from "moment";
const unidecode = require('unidecode');


var DateTimeField = require("react-bootstrap-datetimepicker-custom-icons");
var LinkedStateMixin = require("react-addons-linked-state-mixin");

class AddNewCasePopup extends React.Component {
  constructor(props) {
    super(props);
    const { activeUser } = props;
    this.state = {
      subject: "",
      case: "",
      priority: false,
      submitInProgress: false,
      type: ""
    };
  }


  openProfile(e) {
    e.preventDefault();
    this.props.onOptionSelect("chartspan");
  }

  showErrorMessage(title, message) {
    this.props.commonActions.showToast({
      status: "error",
      title: title,
      message: message,
    });
    var interval = setInterval(() => {
      clearInterval(interval);
      this.props.commonActions.hideToast();
    }, 2);
  }
  validateAndSubmitData() {
    if(!this.state.type){
      this.showErrorMessage("Invalid Type", "Please select case type");
      return false;
    }
    if (!this.state.subject) {
      this.showErrorMessage("Invalid Data", "Subject is mandatory");
      return false;
    }
    if(!this.state.description){
      this.showErrorMessage("Invalid Data", "Description is mandatory");
      return false;
    }
    return true;
  }
  async onSubmit(e) {
    this.setState({caseError: null, submitInProgress:true});
    e.preventDefault();
    if (this.validateAndSubmitData()) {
      const { ehr_pid, rinconOrgId, patientRinconId } =
        this.props.selectedPatient;
      const ehr_provider_id =   this.props.selectedPatient.provider.ehr_provider_id;
      const { location_ehr_location_id } = this.props.selectedPatient.provider;
      const { subject, priority , description, type} = this.state;
      const priorityValue = priority ? 'HIGH' : 'NORMAL'
      //ehr_provider_id
      try{
        await this.props.notificationsAction.createPatientCase(
            rinconOrgId,
            ehr_pid,
            ehr_provider_id,
            location_ehr_location_id,
            unidecode(subject),
            priorityValue,
            unidecode(description),
            type,
            patientRinconId
          );
        this.onCancel();  
      }catch(error){
        this.setState({caseError: error});
      }
    }
    this.setState({submitInProgress:false});
  }

  onPriorityChanged = (e) => {
    this.setState({ priority: e.target.checked });
  };

  onCancel() {
    this.props.showSecondaryTimer();
    this.setState({
      subject: "",
      case: "",
      priority: false,
      description: "",
      type: "",
      submitInProgress:false,
      caseError:""
    });
    this.props.onPopupHide();
  }

  render() {
    let descriptionLength = this.state.description
      ? this.state.description.length
      : 0;
    descriptionLength = descriptionLength ? descriptionLength : 0;
    const {first_name, last_name, date_of_birth, age, provider} = this.props.selectedPatient;
    return (
      <Modal show={this.props.show}>
        <Modal.Header
          closeButton
          onHide={() => {
            this.props.onPopupHide();
          }}
        >
          <Modal.Title>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <div>Add New Case</div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="clearfix">
            <form
              onSubmit={this.onSubmit.bind(this)}
              noValidate
              ref={(el) => (this.todoForm = el)}
            >
              <div className="col-sm-12 no-padding">
                <div style={{display:'flex',  background: "#d9edf7", borderRadius: 4, border: "1px solid #bce8f1", padding:15}}>
                  <div style={{display:'flex',flexDirection:'column'}}>
                    <div>
                      <div>
                        <span style={{fontWeight:'bold',paddingRight:4}}>Name:</span><span>{first_name + ' ' + last_name}</span>
                      </div>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>DOB:</span> {moment(date_of_birth).format('MM/DD/YYYY') + ', Age:' + age}
                      </div>
                    </div>
                  </div>
                  <div style={{display:'flex',flexDirection:'column', justifyContent:'flex-end', flex:1, alignItems:'flex-end'}}>
                    <div>
                      <div style={{textAlign:'right'}}>
                        <span style={{fontWeight:'bold',paddingRight:4}}>Provider:</span><span>{provider ? provider.provider : ""}</span>
                      </div>
                      <div style={{textAlign:'right'}}>
                        <span style={{ fontWeight: 'bold' }}>Organization:</span> {(provider && provider.organization)  ? provider.organization : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{color:'red', fontSize:15, textAlign:'center', paddingTop:15}}>
                  {this.state.caseError ? this.state.caseError.message : ""}
                </div>
                <div style={{ display: "flex", flexDirection:'column' }}>
                  <label>Type</label>
                  <div style={{ display: "flex", alignItems: "center", marginBottom:15 }}>
                    <select style={{padding: '6px 12px',flex:1, marginRight: 15}} className="form-control"
                              onChange={(e) => {this.setState({type:e.target.value})}} value={this.state.type}>
                          <option value='' selected disabled>Select Type</option>
                          <option value='PATIENTCASE'>Patient Case</option>
                          <option value='ADMINISTRATIVE'>Administrative</option>
                          <option value='BILLING'>Billing</option>
                          <option value='CLINICALQUESTION'>Clinical Question</option>
                          <option value='ELECTRONICMEDICALRECORDS'>Electronic Medical Records</option>
                          <option value='MEDICALRECORDS'>Medical Records</option>
                          <option value='MEDICATION'>Medication</option>
                          <option value='NEWRX'>New Prescription</option>
                          <option value='PATIENTNOSHOW'>Patient No Show</option>
                          <option value='REFERRAL'>Referral</option>
                          <option value='REFILL'>Refill</option>
                          <option value='OTHER' selected>Other</option>
                    </select>      
                    <Input
                        type="checkbox"
                        id="priority"
                        label="High Priority"
                        onClick={this.onPriorityChanged.bind(this)}
                        style={{ display: "inline-block", marginBottom:0}}
                        checked={this.state.priority}
                      />
                    
                  </div>
                </div>
              </div>
              <div className="col-sm-12 no-padding">
                <Input
                  placeholder="Subject"
                  type="text"
                  id="subject"
                  label="Subject"
                  style={{ width: "100%", display: "inline-block" }}
                  valueLink={this.linkState("subject")}
                />
              </div>
              <div>
                  <Input rows="10" type="textarea" label="Description" placeholder="Type the case description here" required
                      valueLink={this.linkState('description')} maxLength="4000"/>
                  <div style={{display:'flex',justifyContent:'flex-end'}}>
                      {`${descriptionLength}/4000`}
                  </div>    
              </div>
              {/* <div>
                  <Input rows="8" type="textarea" label="Message" placeholder="Type your message here" required
                      valueLink={this.linkState('message')} maxLength="1024"/>
                  <div style={{display:'flex',justifyContent:'flex-end'}}>
                      {`${descriptionLength}/1024`}
                  </div>    
              </div> */}
              <div style={{ textAlign: "center", paddingTop: 10 }}>
                <Button
                  bsStyle="danger"
                  onClick={this.onCancel.bind(this)}
                  style={{ float: "left" }}
                >
                  Cancel
                </Button>
                <div>
                  <Button
                    bsStyle="success"
                    disabled={!this.state.subject || !this.state.type || !this.state.description || this.state.submitInProgress}
                    type="submit"
                    style={{ float: "right" }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
reactMixin(AddNewCasePopup.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
  activeUser: state.admin.activeUser,
  user: state.auth.user,
  selectedPatient: state.app.selectedPatient,
  urgentTypes: state.app.urgentTypes,
  patient: state.app.selectedPatient,
  selectedPatientPharmacyOptions: state.app.selectedPatientPharmacyOptions,
  patientCcmProviders: state.app.patientCcmProviders,
  selectedPatientChartspanProviders:
    state.app.selectedPatientChartspanProviders,
  selectedPatientMedicationOptions: state.app.selectedPatientMedicationOptions,
});

const mapDispatchToProps = (dispatch) => ({
  adminActions: bindActionCreators(adminActionCreator, dispatch),
  commonActions: bindActionCreators(commonActionCreator, dispatch),
  notificationsAction: bindActionCreators(notificationsActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCasePopup);
