import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import { Panel, Button } from 'react-bootstrap';
import moment from 'moment';

class CCDA extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasCheckedCCDA: false,
            hasCheckedMedSummary: false,
        };
    }

    componentDidMount() {
        if (!this.props.selectedPatient) {
            this.showErrorMessage(
                'Unknown Patient',
                'Patient Details aren\'t Available. Please Refresh the Page or Try Again Later.',
            );
            this.setState({
                hasCheckedCCDA: true,
                hasCheckedMedSummary: true,
            });
        } else {
            this.checkMedSummaryAvailability();
            this.checkCCDAAvailability();
        }
    }

    checkMedSummaryAvailability = () =>
        this.props.adminActions.getMedSummaryAvailability(this.props.selectedPatient.id)
            .then(() => {
                this.setState({ hasCheckedMedSummary: true });
            });

    checkCCDAAvailability = () =>
        this.props.adminActions.getCCDAAvailability(this.props.selectedPatient.id)
            .then(() => {
                this.setState({ hasCheckedCCDA: true });
            });

    showErrorMessage = (title, message) => {
        this.props.commonActions.showToast({
            status: 'error',
            title: title,
            message: message
        });
        var interval = setInterval(()=> {
            clearInterval(interval);
            this.props.commonActions.hideToast();
        }, 2);
    }

    showCCDA = () => {
        const selectedPatient = this.props.selectedPatient;

        if (!selectedPatient) {
            this.showErrorMessage(
                'Unknown Patient',
                'Patient Details aren\'t Available. Please Refresh the Page or Try Again Later.',
            );
        } else {
            this.props.adminActions.getCCDAPdf(selectedPatient.id)
                .then(() => {
                    window.open(this.props.ccdaPdfUrl, '_blank').focus();
                })
                .catch(() => false);
        }
    }

    showMedicalSummary = () => {
        const selectedPatient = this.props.selectedPatient;

        if (!selectedPatient) {
            this.showErrorMessage(
                'Unknown Patient',
                'Patient Details aren\'t Available. Please Refresh the Page or Try Again Later.',
            );
        } else {
            this.props.adminActions.getMedSummaryPdf(selectedPatient.id)
                .then(() => {
                    window.open(this.props.medSummaryPdfUrl, '_blank').focus();
                })
                .catch(() => false);
        }
    }

    render() {
        const { hasCheckedCCDA, hasCheckedMedSummary } = this.state;
        const { ccdaAvailable, medSummaryAvailable,selectedPatient } = this.props;

        return (
            <Panel>
                <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                    {!hasCheckedCCDA &&
                        <div><i>Checking for CCDA availability...</i></div>
                    }
                    {hasCheckedCCDA && !ccdaAvailable &&
                        <div>CCDA is unavailable for the patient.</div>
                    }
                    {hasCheckedCCDA && ccdaAvailable &&
                        <div>
                            <Button bsStyle="success" onClick={this.showCCDA}>
                                Open CCDA PDF
                            </Button>
                            <span style={{paddingLeft:25}}>
                                {selectedPatient.ccda_ts ? moment.utc(selectedPatient.ccda_ts).local().format('MM/DD/YYYY HH:mm')
                                : ' - '}
                            </span>
                        </div>
                    }
                </div>
                <div style={{ textAlign: 'center' }}>
                    {!hasCheckedMedSummary &&
                        <div><i>Checking for medical summary availability...</i></div>
                    }
                    {hasCheckedMedSummary && !medSummaryAvailable &&
                        <div>Medical summary is unavailable for the patient.</div>
                    }
                    {hasCheckedMedSummary && medSummaryAvailable &&
                        <div>
                            <Button bsStyle="success" onClick={this.showMedicalSummary}>
                                Open Medical Summary PDF
                            </Button>
                            <span style={{paddingLeft:25}}>
                                {selectedPatient.medsummary_ts ? moment.utc(selectedPatient.medsummary_ts).local().format('MM/DD/YYYY HH:mm')
                                : ' - '}
                            </span>
                        </div>
                    }
                </div>
            </Panel>
        );
    }
}

const mapStateToProps = (state) => ({
    ccdaAvailable: state.levelTwoDashboard.ccdaAvailable,
    ccdaPdfUrl: state.levelTwoDashboard.ccdaPdfUrl,
    medSummaryAvailable: state.levelTwoDashboard.medSummaryAvailable,
    medSummaryPdfUrl: state.levelTwoDashboard.medSummaryPdfUrl,
    selectedPatient: state.app.selectedPatient,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CCDA);
