/**
 * Created by synerzip on 08/02/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Input, Modal, Button, FormGroup, FormControl, Table, Row, Col, Grid} from 'react-bootstrap';
import { STATUSES } from '../../constants/other';
import _ from 'lodash';
import LinkedStateMixin from 'react-addons-linked-state-mixin';
import SpellCheckInput from '../../components/SpellCheckInput';

class ReconsentRequiredModal extends React.Component {

    constructor(props){
        super(props);
    }

    onCancel() {
        this.props.onPopupHide();
    }

    reConsent() {
        const {selectedPatient,adminActions} = this.props;
        adminActions.changeProviderRequestConsent(selectedPatient.id);
        this.onCancel();
    }

    sendSupport() {
        const {selectedPatient,adminActions} = this.props;
        adminActions.changeProviderRequestDissent(selectedPatient.id);
        this.onCancel();
    }


    render() {
        const {selectedPatient} = this.props;
        return (
            <Modal bsSize="large" show={this.props.show}>
                <Modal.Header closeButton onHide={this.onCancel.bind(this)}>
                    <Modal.Title>
                        <div className="text-align-center">
                            <div>Re-Consent Patient</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{overflow:'auto',height:'auto'}}>
                        <div style={{padding:15}}>
                            <label style={{color:'red'}}>Re-consent Required</label>
                            <label>
                                {`${selectedPatient.first_name} ${selectedPatient.last_name}` } chronic care program has been
                                transferred to {selectedPatient.provider ? selectedPatient.provider.provider : ""} at {selectedPatient.provider ? selectedPatient.provider.organization_secondary_name ?
                                                        selectedPatient.provider.organization_secondary_name :
                                                        selectedPatient.provider.organization : ""}. In order to
                                continue participation in the Chronic Care Program, re-consent is required.
                            </label>
                            <div style={{display:'flex',flex:1,margin:'15px 0',
                            justifyContent:'space-evenly',
                            alignItems:'center'}}>
                                <div style={{display:'flex',
                                flexDirection:'column',
                                justifyContent:'space-between',
                                padding:15,border:'1px solid rgb(232,232,232)',
                                borderRadius:4,
                                height: 300,
                                width: 250}}>
                                    <label style={{textAlign:'center'}}>Re-consent {`${this.props.selectedPatient.first_name} ${this.props.selectedPatient.last_name}` } into the program under {selectedPatient.provider ? selectedPatient.provider.provider : ""}</label>
                                    <Button onClick={this.reConsent.bind(this)} className="btn-primary" bsStyle="success">Re-Consent</Button>
                                </div>
                                <div style={{display:'flex',
                                flexDirection:'column',
                                justifyContent:'space-between',
                                padding:15,border:'1px solid rgb(232,232,232)',
                                borderRadius:4,
                                height: 300,
                                width: 250}}>
                                    <label style={{textAlign:'center'}}>Send {`${this.props.selectedPatient.first_name} ${this.props.selectedPatient.last_name}` } to CCM Support</label>
                                    <Button onClick={this.sendSupport.bind(this)} className="btn-primary" bsStyle="danger">Send to Support</Button>
                                </div>
                            </div>
                        </div>
                        <div style={{borderTop: '1px solid gray', height: 50, textAlign: 'center', paddingTop: 15}}>
                            <Button id="patientContactCancel" onClick={this.onCancel.bind(this)} style={{float: 'left'}}>
                                Cancel
                            </Button>
                        </div>
                    </div>


                </Modal.Body>

            </Modal>
        );
    }
}
reactMixin(ReconsentRequiredModal.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    selectedPatient: state.app.selectedPatient,
    selectedQueue:state.app.selectedQueue,
    callLogSelections:state.app.callLogSelections,
    callAssistanceTypes:state.app.callAssistanceTypes,
    activeUser: state.admin.activeUser,
    workSummary: state.admin.workSummary,
	associatedCharts: state.levelTwoDashboard.associatedCharts,
    todoLists: state.levelTwoDashboard.todoLists
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReconsentRequiredModal);
