import {combineReducers} from 'redux';
import {routerStateReducer} from 'redux-router';
import { reducer as formReducer } from 'redux-form';
import CONSTANTS from '../constants';

import auth from './auth';
import toast from './toast';
import admin from './admin';
import app from './app';
import consentForms from './consentForm';
import levelTwoDashboard from './levelTwoDashboard';
import notifications from './notifications';
import faxMatching from './faxMatching';
import queue from './queue';

const reducers = combineReducers({
    auth,
    app,
    admin,
    toast,
    consentForms,
    levelTwoDashboard,
    notifications,
    faxMatching,
    router: routerStateReducer,
    form: formReducer,
    queue,
});

export default function (state, action) {
  if (action.type === CONSTANTS.LOGOUT_USER) {
    return reducers(undefined, action);
  }
  return reducers(state, action);
}
