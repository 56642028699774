import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentQueueAppointment = () =>
	<PatientQueue
		showQueueCount
		patientDetailColumn
		providerDetailColumn
		timeBilledColumn
		todoItemColumn
		showFilters
		showProgramTypeFilter
        showScheduleCallFilter
		statusColumn
		chartColumn
		lastWorkedByColumn
		page
		queue="urgentPreEnrollment"
		endpoint="Urgent?typesToInclude=16"
		label="Urgent - Pre-Enrollment"
	/>;

export default UrgentQueueAppointment;
