import React from 'react';

const ProviderDetailCell = ({  data}) => {
        const provider = data.provider;
        return <div>
                <div>
                        {provider.provider}
                </div>
                <div>
                        {provider ? provider.organization_secondary_name ?
                            provider.organization_secondary_name :
                            provider.organization : ""}
                </div>
                <div>
                        {provider.phone}
                </div>
                {data.program_type == 'pathways_stroke_care' ? <span style={{color:'red'}}>Pathways - Stroke</span> :
                    provider.specialty_id === 1 ?
                        <div style={{"fontWeight": "500"}}>
                                {provider.specialty_description}
                        </div> :
                        <div style={{"fontWeight": "500", "color": "red"}}>
                                {provider.specialty_description}
                        </div>
                }
        </div>
};

export default ProviderDetailCell;
