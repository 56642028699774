import React from 'react';
import PatientQueue from './PatientQueue';

const MyTeamPatients = () =>
	<PatientQueue
		patientNameWithStatusColumn
		providerDetailColumn
		myPatientBillingColumn
		lastWorkedHistoryColumn
		showScheduleCallFilter
		showFilters
		myPatientStatusColumn
		chartColumn
		lastWorkedByColumn
		page
		queue="myTeamPatients"
		endpoint="MyTeamQueue"
		label="My Team"
		lastContactColumn
		showUrgentFilter
		rowHeight={150}
	/>;

export default MyTeamPatients;
