import React from 'react';
import PatientQueue from './PatientQueue';

const MyPatients = () =>
	<PatientQueue
		patientNameWithStatusColumn
		providerDetailColumn
		myPatientBillingColumn
		lastWorkedHistoryColumn
		showScheduleCallFilter
		showFilters
		showUrgentFilter
		myPatientStatusColumn
		chartColumn
		lastWorkedByColumn
		page
		queue="myPatients"
		endpoint="MyQueue"
		label="My Patients"
		lastContactColumn
		rowHeight={150}
	/>;

export default MyPatients;
