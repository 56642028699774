import React from 'react';
import ReactDOM from 'react-dom'
import _ from 'lodash';
import {Table} from 'react-bootstrap'

class ViewLabResultTable extends React.Component {

	getUnit(id) {
		var unit = _.find(this.props.labUnits, function(o) {return o.id == id});
		return unit.lab_unit;
	}

	render() {
		return (
			<Table className="alignleft" striped bordered condensed hover>
				<thead>
					<tr>
						<th>Name</th>
						<th>Value</th>
						<th>Unit</th>
					</tr>
				</thead>
				<tbody>
					{_.map(this.props.lab_detail, (labDetail)=>
						<tr key={labDetail.id}>
							<td>{labDetail['lab_name']}</td>
							<td>{labDetail['lab_value']}</td>
							<td>{this.getUnit(labDetail['lab_unit_id'])}</td>
						</tr>
					)}
				</tbody>
			</Table>
		)
	}


}

export default ViewLabResultTable;