import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as appActionCreator from '../../actions/app';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import {Panel,Input,Button,Grid, Row, Col} from 'react-bootstrap';
import PageHeader from '../../components/PageHeader';
import moment from "moment";
import _ from "lodash";
const searchIcon = require('../../images/search_icon.png');
const userIcon =require( '../../images/user.png');
const  matchRecord = require('../../images/matchRecord.png');

class FaxApprovalScreen extends React.Component {
    constructor(props){
        super(props);
        this.onNextRecord = this.onNextRecord.bind(this);
        this.onArchiveRecord = this.onArchiveRecord.bind(this);
        this.previewFile = this.previewFile.bind(this);
        this.onReload = this.onReload.bind(this);
    }

    componentDidMount() {
        this.props.adminActions.getNextFaxRecordForApproval();
    }
    onReload() {
        this.props.adminActions.getNextFaxRecordForApproval();
    }

    onNextRecord() {
        const {approvalQueueRecord} = this.props;
        this.props.adminActions.getNextFaxRecordForApproval(approvalQueueRecord.record);
    }

    previewFile(){
        const {approvalQueueRecord} = this.props;
        this.props.adminActions.openRecordFile(approvalQueueRecord.record.inbound_record);
    }
    onArchiveRecord() {
        // const {approvalQueueRecord} = this.props;
        // this.setState({
        //     showDeleteConfirmation: true,
        //     recordIdToBeDeleted: approvalQueueRecord ? approvalQueueRecord.id : 0
        // });

    }

    rejectMatchRecord = () => {
        const {approvalQueueRecord} = this.props;
        this.props.adminActions.rejectMatchRecord(approvalQueueRecord.record.patient.id,
            approvalQueueRecord.record.id,approvalQueueRecord.record);
    }

    approveMatchRecord = () => {
        const {approvalQueueRecord} = this.props;
        this.props.adminActions.approveMatchRecord(approvalQueueRecord.record.patient.id,
            approvalQueueRecord.record.id,approvalQueueRecord.record);
    }

    render() {
        const {approvalQueueRecord, isFetching} = this.props;
        const recordAvailable  = approvalQueueRecord && approvalQueueRecord.record;
        let record = null;
        let totalRecord =0;
        let request  = null;
        if(recordAvailable){
            record = approvalQueueRecord.record;
            totalRecord = approvalQueueRecord.total_records;
            request = record.record_requests && _.find(record.record_requests, (request) => {
                return request.match_id == record.id
            });
        }
        return <div>
            <PageHeader>
                Fax Approval - {totalRecord}
            </PageHeader>
            <div className="consent-form-left-panel" style={{width:'100%'}}>
                <Grid style={{width:'100%'}}>
                    <Row>
                        <Col xs={3}>
                            <Panel className="record-box">
                                <div>
                                    <div className="header-title" style={{fontSize:22}}>Faxed Record</div>
                                    <div className="fax-record-body">
                                        {
                                            recordAvailable ?
                                                <div onClick={this.previewFile} style={{height:'100%', textAlign:'center',fontSize:18}}>
                                                    <div style={{textAlign:'left'}}>
                                                        <span style={{color:'rgb(146,146,146)'}}>Patient: {record.patient.first_name +' '+record.patient.last_name}</span>
                                                    </div>
                                                    <div style={{textAlign:'left'}}>
                                                        <span style={{color:'rgb(146,146,146)'}}>Provider: {request ? request.name  : ' - '}</span>
                                                    </div>
                                                    <div style={{textAlign:'left',marginTop:15}}>
                                                        {record && record.inbound_record.caller_id ? record.inbound_record.caller_id.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") : '-'}
                                                    </div>
                                                    <div style={{textAlign:'left'}}>
                                                        {record && record.inbound_record.create_ts ? moment.utc(record.inbound_record.create_ts).local().format('MM-DD-YYYY hh:mm A') : '-'}
                                                    </div>
                                                    <div className="stencil-box" style={{width:'60%'}}/>
                                                    <div className="stencil-box" style={{width:'85%'}}/>
                                                    <div className="stencil-box" style={{width:'85%'}}/>
                                                    <div className="stencil-box" style={{width:'85%'}}/>
                                                </div>
                                                : <div style={{textAlign:'center'}}>
                                                    <Button bsStyle="primary"  style={{width:180,whiteSpace:'pre-wrap'}} onClick={this.onReload}>
                                                        No Record Available in Queue, Click to reload
                                                    </Button>
                                                </div>
                                        }

                                    </div>
                                    <div className="fax-record-bottom-panel">
                                        {
                                            recordAvailable && <div className="fax-record-view-link" onClick={this.previewFile}>
                                                Click to Preview
                                            </div>
                                        }
                                    </div>
                                    <div className="bottom-button-bar" style={{paddingTop:15,textAlign:'center'}}>
                                        {
                                            recordAvailable && <div>
                                                <div className="bottom-button" onClick={this.onNextRecord} style={{width:90}}>
                                                    <i className="fa fa-arrow-right fa-2x" style={{color:'rgb(89,125,150)'}}></i>
                                                    <div>
                                                        Next Record
                                                    </div>
                                                </div>
                                                {/*<div className="bottom-button" onClick={this.onFixRecord} style={{width:75}}>*/}
                                                    {/*/!*<i className="fa fa-cogs fa-2x" style={{color:'rgb(89,125,150)'}}></i>*!/*/}
                                                    {/*/!*<div>*!/*/}
                                                        {/*/!*Fix Record*!/*/}
                                                    {/*/!*</div>*!/*/}
                                                {/*</div>*/}
                                                {/*/!*<div className="bottom-button" onClick={this.onArchiveRecord} style={{width:75}}>*!/*/}
                                                    {/*/!*<i className="fa fa-archive fa-2x" style={{color:'rgb(194,72,73)'}}></i>*!/*/}
                                                    {/*/!*<div>*!/*/}
                                                        {/*/!*Archive*!/*/}
                                                    {/*/!*</div>*!/*/}
                                                {/*/!*</div>*!/*/}
                                            </div>
                                        }


                                    </div>
                                </div>
                            </Panel>
                        </Col>
                        <Col xs={9}>
                            <Panel style={{height:505}}>
                                <div>
                                    <div className="header-title"></div>
                                    <div style={{float:'left',width:'30%'}}>
                                        <h2 style={{color:'rgb(88,124,150)'}}>
                                            Patient Information
                                        </h2>
                                    </div>
                                </div>
                                <div className="header-table" style={{clear:'both',fontSize:18}}>
                                    <div style={{float:'left',textAlign:'left',width:'20%'}}>
                                        <div style={{paddingBottom:10,fontWeight:'600',color:'rgb(167,167,167)'}}>Name</div>
                                        <div style={{paddingBottom:10}}>
                                            {record ?  record.patient.first_name +' '+record.patient.last_name : ' - '}
                                        </div>
                                    </div>
                                    <div style={{float:'left',textAlign:'left',width:'20%'}}>
                                        <div style={{paddingBottom:10,fontWeight:'600',color:'rgb(167,167,167)'}}>DOB</div>
                                        <div style={{paddingBottom:10}}>
                                            {record ?  record.patient.date_of_birth : ' - '}
                                        </div>
                                    </div>
                                    <div style={{float:'left',textAlign:'left',width:'20%'}}>
                                        <div style={{paddingBottom:10,fontWeight:'600',color:'rgb(167,167,167)'}}>Phone</div>
                                        <div style={{paddingBottom:10}}>
                                            {record && record.inbound_record.caller_id ? record.inbound_record.caller_id.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") : '-'}
                                        </div>
                                    </div>
                                    <div style={{float:'left',textAlign:'left',width:'20%'}}>
                                        <div style={{paddingBottom:10,fontWeight:'600',color:'rgb(167,167,167)'}}>Provider</div>
                                        <div style={{paddingBottom:10}}>
                                            {request ? request.name  : ' - '}
                                        </div>
                                    </div>
                                    <div style={{float:'left',textAlign:'left',width:'20%'}}>
                                        <div style={{paddingBottom:10,fontWeight:'600',color:'rgb(167,167,167)'}}>Status</div>
                                        <div style={{paddingBottom:10}}>
                                            {record ?  record.patient.status_description : ' - '}
                                        </div>
                                    </div>
                                </div>
                                <div className="header-table" style={{clear:'both',fontSize:18,marginTop:55}}>
                                    <div style={{paddingBottom:10,fontWeight:'600',color:'rgb(167,167,167)'}}>Request Start - Request End</div>
                                    <div>
                                        {request  ?
                                            moment.utc(request.request_start).local().format('MM/DD/YYYY') +' -  '+ moment.utc(request.request_end).local().format('MM/DD/YYYY')
                                            : ' - '
                                        }
                                    </div>
                                </div>
                                <div style={{position:'absolute',bottom:75,right:75}}>
                                    <div className="bottom-button" style={{marginRight:50}}>
                                        {/*<Button bsStyle="default" style={{width:150,marginRight:10}} onClick={this.onStep3Submit.bind(this)}>No Record</Button>*/}
                                        <Button bsStyle="danger" style={{width:150,fontSize:18}}
                                                onClick={this.rejectMatchRecord}>Reject</Button>
                                    </div>
                                    <div className="bottom-button" style={{marginRight:0}}>
                                        {/*<Button bsStyle="default" style={{width:150,marginRight:10}} onClick={this.onStep3Submit.bind(this)}>No Record</Button>*/}
                                        <Button bsStyle="success" style={{width:150,fontSize:18}}
                                                onClick={this.approveMatchRecord}>Match</Button>
                                    </div>

                                </div>
                            </Panel>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    'inboundMedicalForms': state.consentForms.inboundMedicalForms,
    'medicalFormDocument': state.consentForms.medicalFormDocument,
    'medicalFormDocumentUrl': state.consentForms.medicalFormDocumentUrl,
    'selectedDoc':state.consentForms.selectedDoc,
    'userId': state.auth.id,
    approvalQueueRecord: state.faxMatching.approvalQueueRecord,
    isFetching: state.app.isFetching
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    appActions:bindActionCreators(appActionCreator,dispatch),
    commonActions:bindActionCreators(commonActionCreator,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FaxApprovalScreen);
