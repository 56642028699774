import React, { useState } from 'react';
import _ from 'lodash';
import { Typeahead } from 'react-bootstrap-typeahead';


const MultiSelectFilter = ({ options, value, onChange, label,disabled=false,width="auto" }) => {

    // const [selectedValue, setSelectedValue] = useState();
    // const multiOptions = _.map(options,(option) => {
    //     return {name: }
    // })

    const onSelect = (selectedList) => {
        onChange(selectedList);
    }

    const onRemove = (e) => {
        console.log(e);
    }

    return <div style={{ marginLeft: '1rem', minWidth:200 }}>
        <label>{label}</label>
        <Typeahead
            placeholder={"Type to Search"}
            options={options} // Options to display in the dropdown
            selected={value}
            labelKey={option => option.name}
            onChange={onSelect} // Function will trigger on select event
            multiple={true} // Property name to display in the dropdown options
            />
        
    </div>
}

export default MultiSelectFilter;
