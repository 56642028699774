import React from 'react';
import { Modal, Button } from 'react-bootstrap';

class PatientNotesPopup extends React.Component {

	constructor(props) {
		super(props);
    }

    render() {
        return (
        <Modal show={this.props.notes !== null} onHide={this.props.onHide}>
          <Modal.Header closeButton>
            <Modal.Title>Patient Notes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                {this.props.notes ? 
                <div dangerouslySetInnerHTML={{__html: this.props.notes.replace("\n","<br/>") }}/>: 'No Notes Available'}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button bsStyle="default" onClick={this.props.onHide}>
                Cancel
              </Button>
          </Modal.Footer>
        </Modal>)    
    }


}

export default PatientNotesPopup;