import React from 'react';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import { Panel, Table } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';

class RequestRecordTable extends React.Component {

	constructor() {
		super();
		this.hasRequestRecords = this.hasRequestRecords.bind(this);
	}
	hasRequestRecords() {
		let tableContent;
		let keyIncrement = 0;
		if (!this.props.requestHistory || (this.props.requestHistory && !this.props.requestHistory.length)) {
			tableContent =  <tbody>
							    <tr>
								    <td>Clinic Name</td>
								    <td>Doctor Name</td>
								    <td>MM/DD/YYYY</td>
								    <td>MM/DD/YYYY - MM/DD/YYYY</td>
								    <td></td>
							    </tr>
						    </tbody>
		} else {
			tableContent =  <tbody>
								{this.props.requestHistory.map( (record) => {
										return (
											<tr key={ keyIncrement++ }>
										        <td>{ record.clinic }</td>
										        <td>{ record.name }</td>
										        <td>{ moment(record.create_ts).local().format('MM/DD/YYYY') }</td>
										        <td>{ moment(record.request_start).local().format('MM/DD/YYYY') } - { moment(record.request_end).local().format('MM/DD/YYYY') }</td>
										        <td>{ record.status || 'Open' }</td>
									    	</tr>
										)
									})}
						    </tbody>
		}
		return tableContent;
	}
	render() {
		return(
			<Table bordered condensed>
				<thead>
					<tr>
						<th>Clinic Name</th>
						<th>Doctor Name</th>
						<th>Date Requested</th>
						<th>Request Date Range</th>
						<th>Status</th>
					</tr>
				</thead>
				{this.hasRequestRecords()}
			</Table>
		)
	}

}

export default RequestRecordTable;
