import React from 'react';
import {Modal, Button, FormControl, FormGroup, ControlLabel, Input} from 'react-bootstrap';
import _ from 'lodash';
import {bindActionCreators} from "redux";
import * as authActionCreator from "../actions/authactions";
import * as appActionCreator from "../actions/app";
import * as adminActionCreator from "../actions/admin";
import {connect} from "react-redux";

class ChangeProfilePopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedProfile:null,
            confirmBillableProfileChange:null,
            billableProfileChange:false
        }
    }

    onProfileChange = (e) => {
        const id = parseInt(e.target.value);
        this.setState({selectedProfile:id,confirmBillableProfileChange:null});
        const {user} = this.props;
        const selectedProfile = _.filter(user.user_profiles, (profile) => {
            return id === profile.id;
        })[0];

        const currentProfile = _.filter(user.user_profiles, (profile) => {
            return user.id === profile.id;
        })[0];

        if(selectedProfile.billable !== currentProfile.billable){
            this.setState({billableProfileChange:true});
        }else{
            this.setState({billableProfileChange:false,
                confirmBillableProfileChange:true});
        }

    }

    onConfirmBillableChange = (e) => {
        if(e.target.checked){
            this.setState({confirmBillableProfileChange:true});
        }else{
            this.setState({confirmBillableProfileChange:false});
        }
    }

    onConfirm = () => {
        const {user} = this.props;
        const selectedProfile = _.filter(user.user_profiles, (profile) => {
            return this.state.selectedProfile === profile.id;
        })[0];
        this.props.actions.changeProfile(this.props.user.token,selectedProfile);
    }

    onHide = () => {
        this.setState({selectedProfile:null,
            confirmBillableProfileChange:null,
            billableProfileChange:false});
        this.props.onHide();
    }

    render() {
        const {show, user} = this.props;
        const {selectedProfile,billableProfileChange,confirmBillableProfileChange} = this.state;
        const filteredProfiles = _.filter(user.user_profiles, (profile) => {
            return user.id !== profile.id;
        });
        const selectedUser = _.filter(user.user_profiles, (profile) => {
            return user.id === profile.id;
        })[0];
        return (
            <Modal show={show} onHide={this.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Profiles</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{fontStyle: 'italic'}}>Please choose which profile you would like to use</div>
                    <div style={{margin: '15px 0 0 0', fontWeight: 'bold'}}>Current Profile:</div>
                    <div>{`${selectedUser.username} [${selectedUser.center_name}] ${selectedUser.billable ? 'Billable' : 'Non Billable'}`}</div>

                    <select style={{padding: '6px 12px',marginTop:10}} className="form-control"
                            onChange={this.onProfileChange} value={selectedProfile}>
                        <option value='' selected disabled>Select an option</option>
                        {
                            _.map(filteredProfiles, profile => {

                                return <option value={profile.id} key={profile.id}>
                                    {`${profile.username} [${profile.center_name}] ${profile.billable ? 'Billable' : 'Non Billable'}`}
                                </option>

                            })
                        }
                    </select>
                    {
                        billableProfileChange && <Input type="checkbox" label="You are switching between billable and non billable profile, please confirm."
                                                        onChange={this.onConfirmBillableChange}
                                                        style={{ display: 'inline-block' }} checked={this.state.urgent} />
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="danger" onClick={this.onHide}>
                        Cancel
                    </Button>
                    <Button bsStyle="success" onClick={this.onConfirm} disabled={!selectedProfile || (billableProfileChange &&
                        !confirmBillableProfileChange)}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    activeNavLink: state.app.activeNavLink,
    chartOpened: state.app.chartOpened,
    user: state.auth,
    notificationUnreadCount: state.notifications.notificationUnreadCount,
    activeUser: state.admin.activeUser,
    companies: state.admin.companies,
    utilizationBillable: state.admin.utilizationBillable,
    utilizationOverage: state.admin.utilizationOverage,
    utilizationPercentBillable: state.admin.utilizationPercentBillable,
    consents: state.admin.consents,
    declines: state.admin.declines,
    configParams: state.admin.configParams

});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(authActionCreator, dispatch),
    appActions: bindActionCreators(appActionCreator, dispatch),
    adminActions: bindActionCreators(adminActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeProfilePopup);
