import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import {Panel,Button} from 'react-bootstrap';
import _ from 'lodash';

class PHS extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hasChecked: false,
            hasError: false
        };
    }

    componentDidMount() {
        let selectedPatient = this.props.selectedPatient;

        if (!selectedPatient) {
            this.showErrorMessage('Unknown Patient', 'Patient Details aren\'t Available. Please Refresh the Page or Try Again Later.');
            this.setState({hasChecked: true, hasError: true});
        } else {
            this.props.adminActions.getPHSAvailability(selectedPatient.id, hasError => this.setState({hasChecked: true, hasError: hasError}));
        }
    }

    showErrorMessage(title, message){
        this.props.commonActions.showToast({
            status: 'error',
            title: title,
            message: message
        });
        var interval = setInterval(()=> {
            clearInterval(interval);
            this.props.commonActions.hideToast();
        }, 2);
    }

    showPHS() {
        let selectedPatient = this.props.selectedPatient;

        if (!selectedPatient) {
            this.showErrorMessage('Unknown Patient', 'Patient Details aren\'t Available. Please Refresh the Page or Try Again Later.');
        } else {
            this.props.adminActions.getPHSPdf(selectedPatient.id, hasError => {
                if (!hasError) {
                  const newWindow = window.open(this.props.phsPdfUrl, '_blank');
                  if(newWindow) {
                    // Show popup block error, if window.open is null/undefined
                    newWindow.focus();
                  }else{
                    this.showErrorMessage('Error','Please disable popup blocker to download PHS ');
                  }
                }
            });
        }
    }


    render() {
        let {hasChecked, hasError} = this.state,
            {phsAvailable} = this.props,
            body;

        if (!hasChecked) {
            body = <div className="text-muted text-align-center"><i>Checking for PHS Availability...</i></div>;
        } else {
            if (hasError) {
                body = <div>Error occurred while fetching PHS Availability. Please try again later.</div>
            } else if (phsAvailable) {
                body = <div className="text-align-center">
                    <Button bsStyle="success"
                            id="openPhsButton"
                            onClick={this.showPHS.bind(this)}>
                        Open PHS PDF
                    </Button>
                </div>;
            } else {
                body = "PHS is Unavailable for the Patient";
            }
        }

        return (
            <Panel>
                {body}
            </Panel>
        )
    }
}

const mapStateToProps = (state) => ({
    'phsAvailable': state.levelTwoDashboard.phsAvailable,
    'phsPdfUrl': state.levelTwoDashboard.phsPdfUrl,
    'selectedPatient': state.app.selectedPatient
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PHS);