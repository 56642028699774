/**
 * Created by synerzip on 25/01/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Button} from 'react-bootstrap';
import _ from 'lodash';
import {Table, Column, Cell} from 'fixed-data-table';
import moment from 'moment';
import ResponsiveFixedDataTable from '../../components/ResponsiveFixedDataTable';
const TextCell = ({rowIndex, data, columnKey, ...props}) => (
    <Cell {...props}>
        {data[rowIndex].first_name + ' ' + data[rowIndex].last_name}
    </Cell>
);

const PatientCell = ({rowIndex, data, columnKey, ...props}) => (

    <Cell {...props}>
        {data[rowIndex].first_name + ' ' + data[rowIndex].last_name}
    </Cell>
);

const OrganizationCell = ({rowIndex, data, columnKey, ...props}) => (

    <Cell {...props}>
        {data[rowIndex].provider.organization}
    </Cell>
);

const ProviderCell = ({rowIndex, data, columnKey, ...props}) => (

    <Cell {...props}>
        {data[rowIndex].provider.provider}
    </Cell>
);

const LastBillDateCell = ({rowIndex, data, columnKey, ...props}) => (

    <Cell {...props}>
        {data[rowIndex].last_bill_date}
    </Cell>
);


class MedicalAssistants extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columnWidths: {
                patientName: 250,
                organization: 200,
                provider: 180,
                lastBilled: 150,
                unAssignMA: 100
            }
        };

        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    }

    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({columnWidths}) => ({
            columnWidths: {
                ...columnWidths,
                [columnKey]: newColumnWidth,
            }
        }));
    }

    onUnAssign(data){

    }
    componentDidMount() {
        //this.props.adminActions.getUnAssignedPatients();
    }

    onMASelected(e) {
        this.setState({selectedMA: e.target.value});
        this.props.adminActions.getMADetail(e.target.value);
    }

    render() {
        var {columnWidths,selectedMA} = this.state;
        var {medicalAssistants,selectedMADetail} = this.props;
        var selectedMAName = "No Medical Assistant Selected";
        var selectedMAActivePatient = "";
        if (selectedMA) {
            selectedMAName = medicalAssistants[selectedMA].name;
            selectedMAActivePatient = "Total Patients: "+medicalAssistants[selectedMA].active_patient_count;
        }

        var optionList = _.map(_.keys(medicalAssistants), (maKey)=> {

            return <option className="replyPatients" key={maKey} value={maKey}>
                {medicalAssistants[maKey].name}
            </option>
        });
        var to = <select id="modal-message-recipient"
                         className="ma-select" ref="meaningfulUse" onChange={this.onMASelected.bind(this)}>
            <option value="" disabled selected>Select Medical Assistant</option>
            {optionList}
        </select>;

        var rowCounts = 0;
        var rows = [];
        if (selectedMADetail) {
            rowCounts = selectedMADetail.patients.length;
            rows = selectedMADetail.patients;
        }
        return (
            <div>
                <div className="modal-header">
                    <h4 classNamw="modal-title">
                        <div style={{textAlign:'center'}}>
                            {to}
                        </div>
                    </h4>
                </div>
                <div className="modal-body">
                    <div className="medicalAssistantsBox">
                        <div className="medical-assistant-header">
                            <div style={{float:'left'}}>
                                {selectedMAName}
                            </div>
                            <div style={{float:'right'}}>
                                 {selectedMAActivePatient}
                            </div>
                        </div>
                        <div style={{width:'100%',height:'500px'}}>
                            <ResponsiveFixedDataTable
                                rowHeight={50}
                                headerHeight={50}
                                rowsCount={rowCounts}
                                onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                                isColumnResizing={false}
                                width={950}
                                height={500}
                                style={{margin: '0 auto',marginBottom: '10px'}}
                                {...this.props}>
                                <Column
                                    columnKey="patient"
                                    header={<Cell>Patient Name</Cell>}
                                    cell={<PatientCell data={rows} />}
                                    fixed={true}
                                    width={columnWidths.patientName}
                                    isResizable={true}
                                    />
                                <Column
                                    columnKey="organization"
                                    header={<Cell>Organization</Cell>}
                                    cell={<OrganizationCell data={rows} />}
                                    fixed={true}
                                    width={columnWidths.organization}
                                    isResizable={true}
                                    />

                                <Column
                                    columnKey="organization"
                                    header={<Cell>Provider</Cell>}
                                    cell={<ProviderCell data={rows} />}
                                    fixed={true}
                                    width={columnWidths.provider}
                                    isResizable={true}
                                    />

                                <Column
                                    columnKey="lastBilled"
                                    header={<Cell>Last Month Billed</Cell>}
                                    cell={<LastBillDateCell data={rows} />}
                                    fixed={true}
                                    width={columnWidths.lastBilled}
                                    isResizable={true}
                                    />
                                <Column
                                    columnKey="assignMA"
                                    header={<Cell>UnAssign</Cell>}
                                    cell={ ({rowIndex, data, columnKey, ...props}) => (
                                        <Cell {...props}>
                                            <Button bsStyle="primary" onClick={this.onUnAssign.bind(this,rows[rowIndex])}>UnAssign</Button>
                                        </Cell>
                                    )}
                                    width={columnWidths.unAssignMA}
                                    isResizable={false}
                                    />
                            </ResponsiveFixedDataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    'user': state.auth.user,
    'medicalAssistants': state.admin.medicalAssistants,
    'selectedMADetail': state.admin.selectedMADetail
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MedicalAssistants);