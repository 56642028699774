/**
 * Created by synerzip on 08/02/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import {Input,Modal,Button,FieldGroup} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
var DateTimeField = require('react-bootstrap-datetimepicker-custom-icons');
var LinkedStateMixin = require('react-addons-linked-state-mixin');

class AddToDoPopup extends React.Component {

    constructor(props){
        super(props);
        const {activeUser} = props;
        this.state={
            dueDate:'',
            urgent:activeUser && activeUser.center && activeUser.center.rpm_flag,
            urgentType: null,
	        urgentTemplate: null,
	        description: '',
	        dynamicValues: [],
            mode:'date',
            inputFormat:'MM/DD/YYYY',
            minDueDate:moment()
        }
    }

    componentWillMount(){
        const {selectedPatient} = this.props;
        this.props.adminActions.getUrgentTodoTypes();
        this.props.adminActions.getPharmacyOptions(selectedPatient.id);
        this.props.adminActions.getMedicationOptions(selectedPatient.id);
        this.props.adminActions.getChartspanProviders(selectedPatient.id);
    }


    openProfile(e){
        e.preventDefault();
        this.props.onOptionSelect('chartspan')
    }


    showErrorMessage(title,message){
        this.props.commonActions.showToast({
            status: 'error',
            title: title,
            message: message
        });
        var interval = setInterval(()=> {
            clearInterval(interval);
            this.props.commonActions.hideToast();
        }, 2);
    }
    validateAndSubmitData(addAnother){
        if((( this.state.description.trim() === '' || this.state.description.trim() === undefined)) && this.state.dynamicValues.length === 0){
            this.showErrorMessage('Invalid Decription','Please provide a valid description for To Do item');
            return;
        } else if(this.state.description.length > 2048){
            this.showErrorMessage('Invalid Description','Description should not be more than 2048 character');
            return;
        } else if(this.state.urgent && !(this.state.urgentType > 0)) {
            this.showErrorMessage('Invalid Urgent Type','Select an urgent todo type from the dropdown');
            return;
        }else if(this.state.urgent && (this.state.urgentType === '11' || this.state.urgentType === '10')
            && !this.state.dueDate){
            this.showErrorMessage('Invalid TODO Due Date','Select the due date');
            return;
        }

        if ( this.state.dynamicValues.length > 0 ) {
            let description = '';
            let pass = true;
            const { urgentTemplate, dynamicValues } = this.state;
            _.each(urgentTemplate, function(questionTemplate) {
                let answer = _.find(dynamicValues, function( value ) {
                    return value.label === questionTemplate.label
                });
                if ( answer ) {
                    description = `${description}${description.length !== 0 ? '\n' : ''}${answer['label']}: ${answer['value']}`;
                }
                if ( questionTemplate.required && !answer) {
                    pass = false;
                }
            });
            if ( pass === false ) {
                this.showErrorMessage('Missing Fields','Please fill out all required fields');
                return;
            }
            if(description.length > 2048){
                this.showErrorMessage('Invalid Description','Description should not be more than 2048 character');
                return;
            }
            this.props.adminActions.addTODO(this.props.selectedPatient.id,{
                description:description,
                urgent:this.state.urgent ? 1 : 0,
                urgent_todo_type_id: this.state.urgentType,
                due_date:this.state.dueDate ? this.state.dueDate : moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            });
        } else if(!this.props.editMode && (this.state.description.trim() !== '' || this.state.description.trim() !== undefined)){
            this.props.adminActions.addTODO(this.props.selectedPatient.id,{
                description:this.state.description,
                urgent:this.state.urgent ? 1 : 0,
                urgent_todo_type_id: this.state.urgentType,
                due_date:this.state.dueDate ? this.state.dueDate : moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            });
        } else if((this.state.description.trim() !== '' || this.state.description.trim() !== undefined)){
            this.props.adminActions.updateTODO(this.props.selectedPatient.id,this.props.todoDetails.id,{
                ccmuser_id: this.props.todoDetails.ccmuser_id,
                completed: this.props.todoDetails.completed,
                completed_ts: this.props.todoDetails.completed_ts,
                create_ts: this.props.todoDetails.create_ts,
                description: this.state.description,
                due_date: this.state.dueDate,
                id: this.props.todoDetails.id,
                patient_id: this.props.todoDetails.patient_id,
                update_ts: this.props.todoDetails.update_ts,
                urgent: this.state.urgent ? 1 : 0,
                urgent_todo_type_id: this.state.urgentType,
            });
        }
        if(addAnother){
            this.setState(
                {
                    description:'',
                    dynamicValues:[],
                    urgentTemplate:this.state.urgentTemplate
                }
            );
            this.todoForm.reset();
        }else{
            this.setState(
                {
                    dueDate:'',
                    description:'',
                    urgent:false,
                    urgentType:null,
                    urgentTemplate:null,
                    dynamicValues:[],
                    dueDateInput:null,
                    dueDateText:null
                }
            );
            this.props.onCloseEdit();
            this.props.onPopupHide();
        }
    }
    onSubmit(e) {
        e.preventDefault();
        this.validateAndSubmitData();

    }

    onAddAnother(){
        this.validateAndSubmitData(true);
    }

    componentWillReceiveProps(nextProps){
        const {activeUser} = nextProps;
        if(activeUser.center.rpm_flag){
            this.setState({urgent:true});
        }
        if(nextProps.editMode && ((this.props.todoDetails) ? this.props.todoDetails.id !== nextProps.todoDetails.id : true)){
            var selectedDateArr = nextProps.todoDetails.due_date.split('-');
            var yr = parseInt(selectedDateArr[0]), mnth = parseInt(selectedDateArr[1] - 1), day = parseInt(selectedDateArr[2]);
            var changedDt = moment([yr,mnth,day]);
            this.setState(
                {
                    dueDate:changedDt.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    description:nextProps.todoDetails.description,
                    urgent:nextProps.todoDetails.urgent,
                    urgentType:nextProps.todoDetails.urgent_todo_type_id,
                    dueDateInput:changedDt,
                    dueDateText:changedDt.format("MM/DD/YYYY")
                }
            )
        }
    }

	updateComponent(template, e) {
    	const inputValue = template.type === 'datetime' ? moment(new Date(parseInt(e+''))).format("MM/DD/YYYY") : e.target.value;
    	const fieldObject = {id: template.id, label: template.label, value: inputValue, required: template.required};
    	let objectInState = this.state.dynamicValues.filter(object => ( object.id === fieldObject.id ));
    	if ( template.type !== 'checkbox' ) {
		    if ( objectInState.length === 0 ) {
			    this.setState({
				    dynamicValues: this.state.dynamicValues.concat(fieldObject)
			    })
		    } else if ( inputValue === '' ) {
			    this.setState({
				    dynamicValues: this.state.dynamicValues.filter((value) => value.id !== fieldObject.id)
			    })
		    } else {
			    this.setState({
				    dynamicValues: this.state.dynamicValues.map((value) => {
					    if ( value.id !== fieldObject.id ) return value;
					    return fieldObject;
				    })
			    })
		    }
	    } else {
		    if ( objectInState.length === 0 ) {
			    this.setState({
				    dynamicValues: this.state.dynamicValues.concat(fieldObject)
			    })
		    } else {
			    this.setState({
				    dynamicValues: this.state.dynamicValues.map((value) => {
					    if ( value.id !== fieldObject.id ) return value;
					    return fieldObject;
				    })
			    })
		    }
	    }

        if(template.id == 1){
            const selectedMedicationId = e.target.value;
            const {selectedPatientMedicationOptions} = this.props;
            const {urgentTemplate} = this.state;
            //get value object 
            const selectedMedicationObjct = _.filter(selectedPatientMedicationOptions,(option) => {
                return option.medication_name == selectedMedicationId;
            })[0];
            if(selectedMedicationObjct){
                //get template for 2 and 3
                const dependentTemplates =  _.filter(urgentTemplate,(template) => {
                    return template.id == 2 || template.id == 3;
                });
                const filedObjects = [fieldObject];
                _.each(dependentTemplates,(template) => {
                    if(template.id == 2){
                        const fieldObject = {id: template.id, 
                            label: template.label, value: `${selectedMedicationObjct.medication_dosage} ${selectedMedicationObjct.medication_measurement}`, 
                            required: template.required};
                        filedObjects.push(fieldObject);
                    }else if(template.id == 3){
                        const fieldObject = {id: template.id, 
                            label: template.label, 
                            value: `${selectedMedicationObjct.medication_frequency}`, 
                            required: template.required};
                        filedObjects.push(fieldObject);
                    }
                });
                const filterSavedValues = _.filter(this.state.dynamicValues,(value) => {
                    return value.id != 2 && value.id != 3;
                })
                this.setState({
				    dynamicValues: filterSavedValues.concat(filedObjects)
			    })
            }
            
        }
	}

	determineUrgentComponent(template) {
    	if ( template.type === 'text' || template.type === 'number' ) return this.generateTextField(template);
	    else if ( template.type === 'select' ) return this.generateSelectField(template);
	    else if ( template.type === 'radio' || template.type === 'checkbox' ) return this.generateRadioOrCheckboxField(template);
	    else if ( template.type === 'datetime' ) return this.generateDatetimeField(template);
	    else if ( template.type === 'textarea' ) return this.generateTextareaField(template);
        else if ( template.type === 'readonly' ) return this.generateTextField(template,true);
	}

	generateTextField(template,readonly = false) {
        const {dynamicValues} = this.state;
    	const label = template.label + (template.required ? ' (required)': '');
        if(readonly){
            const valueArray = _.filter(dynamicValues,(valueObject) => {
                    return valueObject.id == template.id;
            })

            return (
                <Input type={template.type} required={true} label={label} placeholder={template.label}
                        disabled={readonly}
                        value={valueArray && valueArray.length > 0 ? valueArray[0].value : ''}
                       onChange={this.updateComponent.bind(this, template)} maxLength="512"/>
            )
        }
    	return (
		    <Input type={template.type} required={true} label={label} placeholder={template.label}
                    disabled={readonly}
		           onChange={this.updateComponent.bind(this, template)} maxLength="512"/>
	    )
	}

	 generateSelectField(template) {
        const {selectedPatientPharmacyOptions,selectedPatientMedicationOptions,patient} = this.props;
        const {urgentType} = this.state;
		const label = template.label + (template.required ? ' (required)': '');

         if(urgentType == '8' && template.id == '9'){
             // const result  = await adminActions.getPharmacyOptions(selectedPatient.id);
             // console.log("Options Result:",selectedPatientPharmacyOptions);
             const options =  _.map(selectedPatientPharmacyOptions,(pharmacy)=>{
                 return {
                     label:`${pharmacy.name} - ${pharmacy.address +' '+pharmacy.city+' '+pharmacy.state+' '+pharmacy.zip}`,
                     id:pharmacy.id
                 }
             });
             template.options = options;

         }else if(urgentType == '8' && template.id == '10'){
             const optionsArray = [];
             _.each(this.props.selectedPatientChartspanProviders,(provider)=>{
                 _.each(provider.contacts,(contact)=>{
                     optionsArray.push({
                         label:`${contact.provider_name}`,
                         id:contact.contact_ref
                     });
                 })
             })
             // let options =  _.map(this.props.patientCcmProviders, (provider) => {
             //     return {
             //         label:`${provider.provider}`,
             //         id:provider.id
             //     }
             // })
             const options = [{
                 label:patient.provider.provider,
                  id:patient.provider.id}].concat(optionsArray);
             template.options = options;
         }
         if(urgentType == '8' && template.id == '1'){
            const options =  _.map(selectedPatientMedicationOptions,(medication)=>{
                return {
                    label:`${medication.medication_name}`,
                    id:medication.id
                }
            });
            template.options = options;

        }


         return (
			<div key={template.label + template.id} style={{marginBottom:15}}>
				<label className="control-label">{label}</label>
				<select style={{ padding: '6px 12px' }} className="form-control" 
                onChange={this.updateComponent.bind(this, template)}>
					<option value="-1" selected disabled>Select an option</option>
					{
						_.map( template.options, ( option ) => {
							return <option value={option.label} key={option.label + option.id}>{option.label}</option>
						})
					}
				</select>
			</div>
		)
	}

	generateRadioOrCheckboxField(template) {
		const label = template.label + (template.required ? ' (required)': '');
		return (
		    <div key={template.label + template.id}>
			    <label className="control-label">{label}</label>
			    {
				    _.map( template.options, ( option ) => {
					    return (
					    	<div className={template.type} key={template.label + option.id} style={{marginTop: '0px !important'}}>
							    <label>
								    <input style={template.type === 'radio' ? {height: '16px !important'} : null} name={template.label} onChange={this.updateComponent.bind(this, template)} type={template.type} value={option.label} />
								    {option.label}
							    </label>
						    </div>
					    )
				    })
			    }
		    </div>
	    )
	}

	generateDatetimeField(template) {
		const label = template.label + (template.required ? ' (required)': '');
		return (
    		<div key={template.label + template.id} className="form-group">
			    <label>{label}</label>
			    <DateTimeField
				    onChange={this.updateComponent.bind(this, template)}
				    inputFormat={this.state.inputFormat}
				    mode={this.state.mode}
				    defaultText=''
				    iconStyle={{
					    class: 'fa',
					    time: 'fa-clock-o',
					    calendar: 'fa-calendar',
					    up: 'fa-chevron-up',
					    down: 'fa-chevron-down',
					    right: 'fa-chevron-right',
					    left: 'fa-chevron-left',
				    }}
				    inputProps={{placeholder: 'MM/DD/YYYY', maxLength: 10}}/>
		    </div>
	    )
	}

	generateTextareaField(template) {
		const label = template.label + (template.required ? ' (required)': '');
		return (
			<div key={template.label + template.id} className="form-group">
				<label className="control-label">{label}</label>
				<textarea onChange={this.updateComponent.bind(this, template)} className="form-control" placeholder={template.placeholder ? template.placeholder : template.label} />
			</div>
		)
	}

    onUrgentChange(e){
        this.setState({
            urgent:e.target.checked,
            urgentType: null,
	        urgentTemplate: null,
	        dynamicValues: []
        });
    }

    onUrgentTypeChange(e) {
    	const urgent = _.find( this.props.urgentTypes, ( urgent ) => urgent.id === Number( e.target.value ) )
        this.setState({
            urgentType: e.target.value,
	        urgentTemplate: urgent ? JSON.parse(urgent.template) : null,
	        dynamicValues: [],
        })
    }

    onCancel() {
        this.props.showSecondaryTimer();
        this.setState(
            {
                dueDate:'',
                description:'',
                urgent:false,
                urgentType: null,
	            urgentTemplate: null,
                dueDateInput:null,
                dueDateText:null,
	            dynamicValues: [],
            }
        );
        this.props.onCloseEdit();
        this.props.onPopupHide();
    }

    onDateChange(e){
        this.state.dueDate = moment(new Date(parseInt(e+''))).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    }

    render() {
    	const { urgentTemplate,urgent,urgentType } = this.state;
    	const {activeUser} = this.props;
        const isRPMUser = activeUser && activeUser.center && activeUser.center.rpm_flag;
    	const actionText = this.props.editMode ? 'Edit' : 'Add';
    	let urgentDisabled = false;
    	if(isRPMUser || (this.props.editMode && this.state.urgent)){
            urgentDisabled = true;
        }
        let descriptionLength = this.state.description ? this.state.description.length : 0;
        descriptionLength = descriptionLength ? descriptionLength : 0;
    	const urgentKey = this.props.activeUser && this.props.activeUser.center.ce_flag ? 'ce_seqnbr' : 'clinical_seqnbr';
        return (
            <Modal show={this.props.show}>
                <Modal.Header closeButton onHide={()=>{
                                this.props.onPopupHide();
                            }}>
                    <Modal.Title>
                        <div style={
                            {
                            textAlign: 'center'
                            }
                        }>
                            <div>{ actionText } To Do</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="clearfix">
                        {/*<form onSubmit={this.onSubmit.bind(this)} noValidate*/}
                        {/*      ref={(el) => this.todoForm = el}>*/}


                            <div className="col-sm-12 no-padding">
                                <div className="col-sm-6 no-padding">
                                    <Input type="checkbox" id="urgentTODOCheck" label="Urgent" onChange={this.onUrgentChange.bind(this)}
                                           style={{ display: 'inline-block' }} checked={this.state.urgent}
                                            disabled={urgentDisabled}/>
                                    { this.state.urgent ?
                                        <select style={{ marginLeft: '15px' }}
                                                id={"urgentTypeSelect"}
                                                onChange={this.onUrgentTypeChange.bind(this)} value={this.state.urgentType}>
                                            <option value="-1">Select a todo type</option>
                                            {
	                                            _.map( this.props.urgentTypes, ( urgentType ) => {
	                                            	if (( urgentType[urgentKey] > 0 ) || ( this.props.todoDetails && this.props.todoDetails.urgent_todo_type_id === urgentType.id )) {
			                                            return <option value={urgentType.id} key={urgentType.id} id={urgentType.name}>
                                                            {urgentType.name}
			                                            </option>
		                                            }
	                                            })
                                            }
                                        </select>
                                        :
                                        null
                                    }
                                </div>
                                <div className="col-sm-6 add-todo-date no-padding">
                                    {
                                        (this.props.editMode)?
                                            <DateTimeField
                                                onChange={this.onDateChange.bind(this)}
                                                id={'todoDateSelection'}
                                                inputFormat={this.state.inputFormat}
                                                mode={this.state.mode}
                                                showToday={false}
                                                dateTime={this.state.dueDateInput}
                                                minDate={this.state.minDueDate}
                                                defaultText={this.state.dueDateText}
                                                iconStyle={{
	                                                class: 'fa',
	                                                time: 'fa-clock-o',
	                                                calendar: 'fa-calendar',
	                                                up: 'fa-chevron-up',
	                                                down: 'fa-chevron-down',
	                                                right: 'fa-chevron-right',
	                                                left: 'fa-chevron-left',
                                                }}
                                                inputProps={{placeholder: 'MM/DD/YYYY', maxLength: 10}}/>
                                            :
                                            this.state.urgent &&
                                            (this.state.urgentType === '11' || this.state.urgentType === '10')?
                                                <DateTimeField
                                                    onChange={this.onDateChange.bind(this)}
                                                    id={'todoDateSelection'}
                                                    inputFormat={this.state.inputFormat}
                                                    mode={this.state.mode}
                                                    showToday={false}
                                                    defaultText={''}
                                                    minDate={this.state.minDueDate}
                                                    iconStyle={{
                                                        class: 'fa',
                                                        time: 'fa-clock-o',
                                                        calendar: 'fa-calendar',
                                                        up: 'fa-chevron-up',
                                                        down: 'fa-chevron-down',
                                                        right: 'fa-chevron-right',
                                                        left: 'fa-chevron-left',
                                                    }}
                                                    inputProps={{placeholder: 'MM/DD/YYYY', maxLength: 10}}/>
                                                :
                                            <DateTimeField
                                                key={this.state.urgent}
                                                onChange={this.onDateChange.bind(this)}
                                                inputFormat={this.state.inputFormat}
                                                dateTime={this.state.dueDate ? this.state.dueDate : moment()}
                                                mode={this.state.mode}
                                                minDate={this.state.minDueDate}
                                                iconStyle={{
	                                                class: 'fa',
	                                                time: 'fa-clock-o',
	                                                calendar: 'fa-calendar',
	                                                up: 'fa-chevron-up',
	                                                down: 'fa-chevron-down',
	                                                right: 'fa-chevron-right',
	                                                left: 'fa-chevron-left',
                                                }}
                                                inputProps={{placeholder: 'MM/DD/YYYY', maxLength: 10,
                                                id:'todoDateSelection'}}/>
                                    }
                                </div>
                            </div>
                        {
                            urgent && urgentType == '8' &&
                            <div className="col-sm-12 no-padding">
                                <div style={{padding:25,backgroundColor:'#D8D8D8'}}>
                                    Note: If you wish to add new medication, pharmacy or provider information, please visit
                                    <a href={'#'} onClick={this.openProfile.bind(this)}> patient profile</a> page.
                                </div>
                            </div>
                        }
                        <form onSubmit={this.onSubmit.bind(this)} noValidate
                              ref={(el) => this.todoForm = el}>
	                        {
	                        	urgentTemplate ?
			                        <div style={{marginBottom: '15px'}}>
				                        {_.map( urgentTemplate, ( template ) => {
					                        return this.determineUrgentComponent(template)
				                        })}
			                        </div>
			                        :
                                    <div>
			                            <Input rows="8" type="textarea" label="Description" placeholder="Description" required
			                                valueLink={this.linkState('description')} maxLength="1024"/>
                                        <div style={{display:'flex',justifyContent:'flex-end'}}>
                                            {`${descriptionLength}/1024`}
                                        </div>    
                                    </div>
	                        }
                            <div style={{textAlign:'center',paddingTop:10}}>
                                <Button bsStyle="danger" onClick={this.onCancel.bind(this)} style={{float:'left'}}>
                                    Cancel
                                </Button>
                                {
                                    (this.props.editMode)?
                                        <Button bsStyle="success" type="submit" style={{float:'right'}}>
                                            Update
                                        </Button>
                                        :
                                        <div>
                                            <Button bsStyle="success" type="submit" style={{float:'right'}}>
                                                Save
                                            </Button>
                                            {
                                                this.state.urgentType === '8'
                                                ? <Button  style={{float:'right',marginRight:10}}
                                                    onClick={this.onAddAnother.bind(this)}>
                                                    Add Another Refill
                                                </Button> : null
                                            }
                                        </div>

                                }
                            </div>
                        </form>
                    </div>


                </Modal.Body>

            </Modal>
        );

    }
}
reactMixin(AddToDoPopup.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
	'activeUser': state.admin.activeUser,
	'user': state.auth.user,
    'selectedPatient':state.app.selectedPatient,
    'urgentTypes': state.app.urgentTypes,
    'patient': state.app.selectedPatient,
    'selectedPatientPharmacyOptions':state.app.selectedPatientPharmacyOptions,
    'patientCcmProviders': state.app.patientCcmProviders,
    'selectedPatientChartspanProviders':state.app.selectedPatientChartspanProviders,
    'selectedPatientMedicationOptions':state.app.selectedPatientMedicationOptions
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddToDoPopup);
