import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentQueuePoa = () =>
	<PatientQueue
		showQueueCount
		patientNameColumn
		providerDetailColumn
		timeBilledColumn
		todoItemColumn
		statusColumn
		showFilters
		showProgramTypeFilter
        showScheduleCallFilter
		chartColumn
		lastWorkedByColumn
		lastOutCallColumn
		callAttemptsCol
		showSort
		sortOptions={[
			{
				'name': 'Last Called Ascending',
				'key': 'lastOutCallTs&dir=asc',
			},
			{
				'name': 'Last Called Descending',
				'key': 'lastOutCallTs&dir=desc',
			}
		]}
		page
		queue="urgentPoa"
		endpoint="Urgent?typesToInclude=9"
		label="Urgent - Power of Attorney"
	/>;

export default UrgentQueuePoa;
