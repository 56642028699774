import { checkHttpStatus, parseJSON } from '../../utils';
import CONSTANTS from '../../constants';
import { pushState } from 'redux-router';
import config from '../../config';
import {showToast,hideToast,post} from '../common';
import fetch from 'isomorphic-fetch';
import {get} from '../app';

const {LOGIN_USER_REQUEST, LOGIN_USER_FAILURE, LOGIN_USER_SUCCESS, LOGOUT_USER, FETCH_PROTECTED_DATA_REQUEST, RECEIVE_PROTECTED_DATA,
    USER_REQUEST_COMPLETE,USER_REQUEST} = CONSTANTS;

function displayMessage(dispatch, message) {
    dispatch(showToast(message));

    var interval = setInterval(()=> {
        clearInterval(interval);
        dispatch(hideToast());
    }, 2);
}

export const changeProfile = (token, profile,redirect="/")  => dispatch => {

    dispatch(loginUserRequest());
    return fetch(config.BASE_URL + "/v1/auth/changeProfile", {
        method: 'post',
        headers: {
            'X-AUTH-TOKEN': `${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({"email": profile.username})
    })
        .then(checkHttpStatus)
        .then((response) => {
            token = response.headers.get('X-AUTH-TOKEN');
            return parseJSON(response);
        })
        .then(result => {
            try {
                //if (result.result === '1' && result.message === 'SUCCESS') {
                //window.location.hash = '/home';
                var accountHolder = {
                    "id": result.id,
                    "username": result.username,
                    "name": result.name,
                    "changePassword": result.changePassword,
                    "roles": result.roles,
                    "token":token,
                    "user_profiles":result.user_profiles
                };
                dispatch(loginUserSuccess(accountHolder));
                dispatch(pushState(null, redirect));


            } catch (e) {
                dispatch(loginUserFailure({
                    response: {
                        status: 403,
                        statusText: 'Invalid token'
                    }
                }));
            }
        })
        .catch(error => {
            displayMessage(dispatch, {
                status: 'error',
                title: 'Invalid Credentials',
                message: error.message
            });
            dispatch(loginUserFailure(error));
        })
}

export const getMeUser = (token,redirect="/") => dispatch => {
    return new Promise((resolve,reject)=>{
        // console.log(config.BASE_URL + "/v1/ccm/me");
        return fetch(config.BASE_URL + "/v1/auth/me", {
            headers: {
                'X-AUTH-TOKEN': `${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(checkHttpStatus)
            .then((response) => {
                token = response.headers.get('X-AUTH-TOKEN');
                return parseJSON(response);
            })
            .then(result => {
                const accountHolder = {
                    "id": result.id,
                    "username": result.username,
                    "name": result.name,
                    "changePassword": result.changePassword,
                    "roles": result.roles,
                    "token":token,
                    "user_profiles":result.user_profiles
                };
                dispatch(loginUserSuccess(accountHolder));
                dispatch(pushState(null, redirect));
                resolve();
            })
            .catch(error => {
                if (error.response.status === 401) {
                    dispatch(loginUserFailure(error));
                    dispatch(pushState(null, '/login'));
                }
                reject();
            })
    });
}

export function showSAMLError (errorMessage) {
    return (dispatch) => {
        displayMessage(dispatch, {
            status: 'error',
            title: 'Error',
            message: errorMessage
        });
    }

}

export function loginUserSuccess(token,currentRoute) {
    localStorage.setItem('ccmLoggedinUser', JSON.stringify(token));
    return {
        type: LOGIN_USER_SUCCESS,
        payload: {token:token,currentRoute:currentRoute}
    }
}

export function updateAccessToken(token) {
    let loggedinUser = localStorage.getItem('ccmLoggedinUser');
    if (loggedinUser !== null && token) {
        var loggedinUserObj = JSON.parse(loggedinUser);
        var loggedinUserObjNew = {
            "id": loggedinUserObj.id,
            "username": loggedinUserObj.username,
            "name": loggedinUserObj.name,
            "changePassword": loggedinUserObj.changePassword,
            "roles": loggedinUserObj.roles,
            "token":token,
            "user_profiles":loggedinUserObj.user_profiles
        };
        localStorage.setItem('ccmLoggedinUser', JSON.stringify(loggedinUserObjNew));
    }
}

export function loginUserFailure(error) {
    localStorage.removeItem('ccmLoggedinUser');
    return {
        type: LOGIN_USER_FAILURE,
        payload: {
            status: error.response.status,
            statusText: error.response.statusText
        }
    }
}

export function loginUserRequest() {
    return {
        type: LOGIN_USER_REQUEST
    }
}

export function logout() {
    post(`/v1/logout`, {});
	localStorage.removeItem('ccmLoggedinUser');
	localStorage.removeItem('ccm_queue');
	localStorage.removeItem('ccm_queue_user');
    return {
        type: LOGOUT_USER
    }
}

export function logoutAndRedirect() {
    return (dispatch, state) => {
        dispatch(logout());
        dispatch(pushState(null, '/login'));
    }
}

export function loginUser(email, password, redirect = "/") {
    let token;
    return function (dispatch) {
        dispatch(loginUserRequest());
        return fetch(config.BASE_URL + "/v1/auth", {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({username: email, password: password})
        })
            .then(checkHttpStatus)
            .then((response) => {
                console.log(response);
                token = response.headers.get('X-AUTH-TOKEN');
                return parseJSON(response);
            })
            .then(result => {
                try {
                    //if (result.result === '1' && result.message === 'SUCCESS') {
                    //window.location.hash = '/home';
                    var accountHolder = {
                        "id": result.id,
                        "username": result.username,
                        "name": result.name,
                        "changePassword": result.changePassword,
                        "roles": result.roles,
                        "token":token,
                        "user_profiles":result.user_profiles
                    };
                    dispatch(loginUserSuccess(accountHolder));
                    dispatch(pushState(null, redirect));


                } catch (e) {
                    dispatch(loginUserFailure({
                        response: {
                            status: 403,
                            statusText: 'Invalid token'
                        }
                    }));
                }
            })
            .catch(error => {
                if(error.response){
                    error.response.json().
                        then(errorData=>{
                            if(errorData.message){
                                displayMessage(dispatch, {
                                    status: 'error',
                                    title: errorData.error,
                                    message: errorData.message
                                });
                                dispatch(loginUserFailure(error));
                            }else{
                                displayMessage(dispatch, {
                                    status: 'error',
                                    title: 'Invalid Credentials',
                                    message: error.message
                                });
                                dispatch(loginUserFailure(error));
                            }

                    })
                        .catch(error=>{
                            displayMessage(dispatch, {
                                status: 'error',
                                title: 'Invalid Credentials',
                                message: error.message
                            });
                            dispatch(loginUserFailure(error));
                        })
                }else{
                    displayMessage(dispatch, {
                        status: 'error',
                        title: 'Invalid Credentials',
                        message: error.message
                    });
                    dispatch(loginUserFailure(error));
                }
            })
    }
}

export function receiveProtectedData(data) {
    return {
        type: RECEIVE_PROTECTED_DATA,
        payload: {
            data: data
        }
    }
}

export function fetchProtectedDataRequest() {
    return {
        type: FETCH_PROTECTED_DATA_REQUEST
    }
}

export function fetchProtectedData(token) {

    return (dispatch, state) => {
        dispatch(fetchProtectedDataRequest());
        return fetch('http://localhost:3000/getData/', {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(checkHttpStatus)
            .then(parseJSON)
            .then(response => {
                dispatch(receiveProtectedData(response.data));
            })
            .catch(error => {
                if (error.response.status === 401) {
                    dispatch(loginUserFailure(error));
                    dispatch(pushState(null, '/login'));
                }
            })
    }
}


