import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import {Modal,Button} from 'react-bootstrap';
import LinkedStateMixin from 'react-addons-linked-state-mixin';

class UpdateContactPreferenceModal extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            contactPreference: props.patient.contact_preferences,
            preferred_language:props.patient.preferred_language,
        }
    }

    onCancel() {
        this.setState({reason: null});
        this.props.onPopupHide();
    }

    onContactPreferenceChanged(e){
        this.setState({contactPreference:e.target.value});
    }

    onPreferredLanguageChanged(e) {
        this.setState({preferred_language:e.target.value});
    }

    onSubmit(){
        const {contactPreference,preferred_language} = this.state;
        this.props.adminActions.updateContactPreference(this.props.patient,
            contactPreference,preferred_language,(response)=>{
            this.props.onPopupHide();
        });

    }

    render(){
        const {patient} = this.props;
        const ehrLanguage = patient.ehr_language ? patient.ehr_language : 'Not Specified';
            return (
                <Modal show={this.props.show}>
                    <Modal.Header closeButton onHide={this.onCancel.bind(this)}>
                        <Modal.Title>
                            <div className="text-align-center">
                                <div>Contact Preferences</div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{borderBottom:'1px solid gray'}}>
                            <p>Update patient contact preferences</p>
                        </div>
                        <div style={{ marginTop: '10px'}}>
                            <form onSubmit={this.onSubmit.bind(this)}>
                                <div>
                                    <textarea style={{padding:10,width:'100%'}}
                                    id="contactPreferenceTextArea"
                                          value={this.state.contactPreference}
                                          onChange={this.onContactPreferenceChanged.bind(this)}
                                          maxLength={250}/>
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        {`${this.state.contactPreference ? this.state.contactPreference.length : 0}/250`}
                                    </div>       
                                </div>
                                <div style={{display:'flex'}}>
                                    <div style={{display:'flex',flex:1,flexDirection:'column'}}>
                                        <p style={{fontWeight:500,fontSize:16}}>EHR Language</p>
                                        <div>{ehrLanguage}</div>
                                    </div>
                                    <div style={{display:'flex',flex:1,flexDirection:'column'}}>
                                        <p style={{fontWeight:500,fontSize:16}}>Preferred Contact Language</p>
                                        <div style={{display:'flex',flexDirection:'column'}}>
                                            <div style={{display:'flex'}}>
                                                <input type="radio" name="preferred_language"
                                                       style={{height:'15px !important'}}
                                                       value={'English'}
                                                       checked={this.state.preferred_language == 'English'}
                                                       onChange={this.onPreferredLanguageChanged.bind(this)}/>
                                                <div style={{marginLeft:15}}>
                                                    English
                                                </div>
                                            </div>
                                            <div style={{display:'flex'}}>
                                                <input type="radio" name="preferred_language"
                                                       style={{height:'15px !important'}}
                                                       value={'Spanish'}
                                                       checked={this.state.preferred_language == 'Spanish'}
                                                       onChange={this.onPreferredLanguageChanged.bind(this)}/>
                                                <div style={{marginLeft:15}}>
                                                    Spanish
                                                </div>
                                            </div>
                                            <div style={{display:'flex'}}>
                                                <input type="radio" name="preferred_language"
                                                       style={{height:'15px !important'}}
                                                       value={'Other'}
                                                       checked={this.state.preferred_language == 'Other'}
                                                       onChange={this.onPreferredLanguageChanged.bind(this)}/>
                                                <div style={{marginLeft:15}}>
                                                    Other
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>
                        <div style={{borderTop:'1px solid gray',height: 50,textAlign: 'center',paddingTop: 15, marginTop: '15px'}}>
                            <Button onClick={this.onCancel.bind(this)} style={{float:'left'}}>
                                Cancel
                            </Button>
                            <Button bsStyle="success" type="submit"
                                    style={{float:'right'}}
                                    onClick={this.onSubmit.bind(this)} id="saveContactPreference">
                                Save
                            </Button>

                        </div>
                    </Modal.Body>
                </Modal>
        )
    }
}


reactMixin(UpdateContactPreferenceModal.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    'user': state.auth.user,
    'patientServicesReasons': state.admin.patientServicesReasons,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateContactPreferenceModal);
