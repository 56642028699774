/**
 * Created by synerzip on 22/03/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Panel,Input,Button} from 'react-bootstrap';
import {Table, Column, Cell} from 'fixed-data-table';
import _ from 'lodash';
import moment from 'moment';


class CarePlanStep extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            edit:false,
            step:props.step.step
        }
    }

    onEdit(){
        this.setState({edit:true});
    }


    onCancel(){
        var {step} = this.props;
        this.setState({edit:false,step:step.step});
    }

    onSave(){
        var {step,goalId,adminActions,selectedPatient} = this.props;
        adminActions.updateStep(selectedPatient.id,goalId,step.id,this.state.step);
        this.setState({edit:false});
    }

    onKeyDown(e){
        if(e.key == 'Enter'){
            console.log('enter press here! ')
            console.log(this.state.step);
            this.onSave();
        }
    }
    onComplete(){
        var {step,goalId,adminActions,selectedPatient} = this.props;
        adminActions.markStepComplete(selectedPatient.id,goalId,step.id,step.step);
        //this.setState({edit:false});
    }

    onGoalChanged(e){
        this.setState({step:e.target.value})
    }

    render() {
        var {step} = this.props;

        var stepComp = null;
        var editButtons = null;
        var completeButton = <span/>;

        if(this.state.edit){
            stepComp =  <div className="plan-step">
                <Input type="text" style={{height:'30px !important'}} placeholder="Enter.."
                       defaultValue={step.step}  value={this.state.step}
                       onChange={this.onGoalChanged.bind(this)}
                       onKeyDown={this.onKeyDown.bind(this)}/>
               </div>;
            editButtons = [];
            editButtons.push(<div className="step-edit" key={"save"} onClick={this.onSave.bind(this)}>
                <span style={{color:'grey'}}><i className="fa fa-check"></i></span>
            </div>);
            editButtons.push(<div className="step-edit" key={"cancel"} onClick={this.onCancel.bind(this)}>
                <span style={{color:'grey'}}><i className="fa fa-close"></i></span>
            </div>);
        }else{
            var stepCompletedComp = null;
            if(step.complete){
                stepCompletedComp = <span style={{color:'green',marginRight:5}}><i className="fa fa-check"></i></span>;
            }else{
                stepCompletedComp = <span style={{color:'green',marginRight:5}}></span>;
                completeButton = <div className="step-action">
                    <Button bsStyle="success" onClick={this.onComplete.bind(this)}>Completed</Button>
                </div>;
            }
            stepComp = <div className="plan-step">
                {stepCompletedComp}
                <span>{this.state.step}</span>

            </div>;
            editButtons = <div className="step-edit" onClick={this.onEdit.bind(this)}>
                <span style={{color:'grey'}}><i className="fa fa-pencil"></i></span>
            </div>;
        }

        return (
            <div className="careplan-step">
                {stepComp}

                {completeButton}
                {editButtons}
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    'carePlanAndGoals': state.levelTwoDashboard.carePlanAndGoals,
    'userId': state.auth.id,
    'selectedPatient': state.app.selectedPatient
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CarePlanStep);