import React from 'react';

const PatientWithStatusCell = ({ data: { first_name, last_name, status,id } }) =>
    <div>
        <div style={{fontWeight:600}}>{`${first_name} ${last_name}`}</div>
        <div>CCM ID: {id}{" "}</div>
        <div style={{marginTop:10,width:50}}>
            {
                status == 1 && <div className="new-patient-status" style={{border:'2px solid rgb(109,173,130)'}}>
                                New
                            </div>
            }
            
        </div>
    </div>;

export default PatientWithStatusCell;
