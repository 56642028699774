/**
 * Created by synerzip on 08/02/16.
 */
import {createReducer} from '../utils';
import CONSTANTS from '../constants';
import {pushState} from 'redux-router';
import _ from 'lodash';

const {
    LEVEL_TWO_DASHBOARD_DATA_RECEIVED, PATIENT_SSO_TOKEN_RECEIVED,
    LOGOUT_USER, TODO_ADDED_SUCCESSFULLY, TODOS_MARK_COMPLETED, TODO_MARK_UNCOMPLETED, CASE_HISTORY_RECEIVED,
    OPENED_PATIENT_ID, ASSESSMENTS_RECEIVED, ASSESSMENT_ADDED, ASSESSMENT_DELETED, ASSESSMENT_UPDATED, NOTES_RECEIVED,
    PATIENT_VERIFIED, CARE_PLAN_AND_GOALS_RECEIVED, TODO_UPDATED_SUCCESSFULLY, SSO_TOKEN_RECEIVED,
    GOAL_ADDED_SUCCESSFULLY, GOAL_UPDATED_SUCCESSFULLY, GOAL_DELETED_SUCCESSFULLY,
    STEP_UPDATED_SUCCESSFULLY,
    NON_ACCOUNT_PATIENT_DETAIL_RECEIVED,
    GET_CCDA_AVAILABILITY_SUCCESS, GET_CCDA_AVAILABILITY_FAIL,
    GET_CCDA_PDF_SUCCESS,
    GET_PHS_AVAILABILITY_SUCCESS,
    GET_PHS_PDF_SUCCESS, STEP_ADDED_SUCCESSFULLY, LAB_UNITS_RECEIVED, LABS_RECEIVED,
    ASSESSMENT_QUESTIONS_RECEIVED, ASSESSMENT_ANSWERS_RECEIVED, UPDATE_ASSESSMENT_ANSWERS, UPDATE_ASSESSMENT_UPDATES,
    REQUEST_RECORD_INVALID, REQUEST_RECORD_VALID, SUBMIT_REQUEST_VALID, MERGED_ACCOUNTS, GOAL_COMPLETED_SUCCESSFULLY,
    RECEIVED_LAST_PASSWORD_RESET, GET_RECORD_SUCCESS, GET_MED_SUMMARY_AVAILABILITY_FAILURE,
    GET_MED_SUMMARY_AVAILABILITY_SUCCESS, GET_MED_SUMMARY_PDF_SUCCESS, RECEIVED_ORGANIZATION_NOTE,
    CHART_CLOSED_SUCCESSFULLY, RECEIVED_ASSOCIATED_CHARTS, OPT_OUT_REASONS_RECEIVED, CARE_PLAN_QUESTION_RECEIVED,
    PATIENT_EDUCATIONS_RECEIVED,EDUCATION_ADDED_SUCCESSFULLY,PATIENT_ENGAGEMENT_HISTIRY_RECEIVED,
    ON_GOAL_TYPES_RECEIVED,
    ON_GOAL_SOURCES_RECEIVED,
    ON_GOAL_COMPLETION_STATUS_RECEIVED,
    ON_GOAL_STEP_TYPES_RECEIVED,
    GOAL_STEP_ADDED_SUCCESSFULLY,
    GOAL_STEP_UPDATED_SUCCESSFULLY,
    GOAL_STEP_DELETED_SUCCESSFULLY,
    ON_OPEN_CHART_TAB
}  = CONSTANTS;


const initialState = {
    todoLists: null,
    gapsPlanList: [1,2,3],
    completedToDoList: null,
    patientSSOToken: null,
    caseHistory: null,
    assessments: null,
    carePlanAndGoals:null,
    linkAccountAvailable:false,
    ccdaAvailable: null,
    ccdaPdfUrl: null,
    phsAvailable: null,
    medSummaryAvailable: false,
    medSummaryPdfUrl: '',
    phsPdfUrl: null,
    assessmentUpdates: [],
	associatedCharts: [],
    patienEducations:[],
    goalStepTypes:[]
};

export default createReducer(initialState, {
    [NON_ACCOUNT_PATIENT_DETAIL_RECEIVED]:(state, payload) => {
        return Object.assign({}, state, {
            linkAccountAvailable: payload.linkAccountAvailable,
            linkAccountData:payload.linkAccountData
        });
    },
    [LOGOUT_USER]: (state, payload) => {
        return Object.assign({}, state, {
            todoLists: null,
            gapsPlanList:null,
            completedToDoList: null,
            patientSSOToken: null,
            ccdaAvailable: null,
            ccdaPdfUrl: null,
            phsAvailable: null,
            phsPdfUrl: null
        });
    },
    [ASSESSMENTS_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            assessments: payload
        });
    },
    [NOTES_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            generalNotes: payload
        });
    },
    [ON_GOAL_STEP_TYPES_RECEIVED]:(state, payload) => {
        return Object.assign({}, state, {
            stepTypes: payload
        });
    },
    [ON_GOAL_TYPES_RECEIVED]:(state, payload) => {
        return Object.assign({}, state, {
            goalTypes: payload
        });
    },
    [ON_GOAL_SOURCES_RECEIVED]:(state, payload) => {
        return Object.assign({}, state, {
            goalSources: payload
        });
    },
    [ON_GOAL_COMPLETION_STATUS_RECEIVED]:(state, payload) => {
        return Object.assign({}, state, {
            goalCompletionStatuses: payload
        });
    },
    [LAB_UNITS_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            labUnits: payload
        });
    },
    [LABS_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            labDetails: payload
        });
    },
    [PATIENT_EDUCATIONS_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            patienEducations: payload
        });
    },
    [EDUCATION_ADDED_SUCCESSFULLY]: (state, payload) => {
        var _state = _.cloneDeep(state);
        if(!_state.patienEducations){
            _state.patienEducations = [];
        }
        _state.showGoalEditDeleteModal = false;
        _state.patienEducations.unshift(payload);
        return _state;
    },
    [CARE_PLAN_AND_GOALS_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            carePlanAndGoals: payload
        });
    },
    [GOAL_STEP_ADDED_SUCCESSFULLY]: (state, payload) => {
        const _state = _.cloneDeep(state);
        if(!_state.carePlanAndGoals){
            _state.carePlanAndGoals = [];
        }
        const goalId = payload.goalId;
        const goalObject = _.filter( _state.carePlanAndGoals,(goal) => {
            return goal.id == goalId;
        })[0];
        goalObject.completion_status_id = payload.data.completion_status_id;
        const goal_steps = goalObject.goal_steps;
        goal_steps.push(payload.data);
        return _state;
    },
    [GOAL_STEP_DELETED_SUCCESSFULLY]: (state, payload) => {
        const _state = _.cloneDeep(state);
        if(!_state.carePlanAndGoals){
            _state.carePlanAndGoals = [];
        }
        const goalId = payload.goalId;
        const goalObject = _.filter( _state.carePlanAndGoals,(goal) => {
            return goal.id == goalId;
        })[0];
        const goal_steps = goalObject.goal_steps;
        const newGoalSteps = [];
        _.each(goal_steps,(step) => {
            if(step.id != payload.stepId){
                newGoalSteps.push(step);
            }
        })
        goalObject.goal_steps = newGoalSteps;

        return _state;
    },
    [GOAL_STEP_UPDATED_SUCCESSFULLY]: (state, payload) => {
        const _state = _.cloneDeep(state);
        if(!_state.carePlanAndGoals){
            _state.carePlanAndGoals = [];
        }
        const goalId = payload.goalId;
        const goalObject = _.filter( _state.carePlanAndGoals,(goal) => {
            return goal.id == goalId;
        })[0];
        goalObject.completion_status_id = payload.data.completion_status_id;
        const goal_steps = goalObject.goal_steps;
        const newGoalSteps = [];
        _.each(goal_steps,(step) => {
            if(step.id == payload.data.id){
                newGoalSteps.push(payload.data);
            }else{
                newGoalSteps.push(step)
            }
        })
        goalObject.goal_steps = newGoalSteps;

        return _state;
    },
    [GOAL_ADDED_SUCCESSFULLY]: (state, payload) => {
        var _state = _.cloneDeep(state);
        if(!_state.carePlanAndGoals){
            _state.carePlanAndGoals = [];
        }
        _state.showGoalEditDeleteModal = false;
        _state.carePlanAndGoals.unshift(payload);
        return _state;
    },
    [GOAL_UPDATED_SUCCESSFULLY]:(state, payload) => {
        var _state = _.cloneDeep(state);
        var newGoals = [];
        _.each(_state.carePlanAndGoals,(goal)=>{
            if(goal.id == payload.id){
                payload.goal_steps = goal.goal_steps;
                newGoals.push(payload);
            }else{
                newGoals.push(goal);
            }
        });
        _state.carePlanAndGoals = newGoals;
        return _state;
    },
    [ GOAL_DELETED_SUCCESSFULLY ]: ( state = {}, payload ) => {
        return Object.assign( {}, state, {
            showGoalEditDeleteModal: false,
        });
    },
    [GOAL_COMPLETED_SUCCESSFULLY]:(state, payload) => {
        var _state = _.cloneDeep(state);
        var newGoals = [];
        _.each(_state.carePlanAndGoals,(goal)=>{
            if(goal.id == payload.id){
                payload.goal_steps = goal.goal_steps;
                newGoals.push(payload);
            }else{
                newGoals.push(goal);
            }
        });
        _state.carePlanAndGoals = newGoals;
        return _state;
    },
    [STEP_UPDATED_SUCCESSFULLY]:(state, payload) => {
        var _state = _.cloneDeep(state);

        _.each(_state.carePlanAndGoals,(goal)=>{
            if(goal.id == payload.goalId){
                var newSteps = [];
                _.each(goal.goal_steps,(step)=>{
                    if(step.id == payload.step.id){
                        newSteps.push(payload.step);
                    }else{
                        newSteps.push(step);
                    }
                });
                goal.goal_steps = newSteps;
            }
        });

        return _state;
    },
    [STEP_ADDED_SUCCESSFULLY]:(state, payload) => {
        var _state = _.cloneDeep(state);

        _.each(_state.carePlanAndGoals,(goal)=>{
            if(goal.id == payload.goalId){
                goal.goal_steps.push(payload.step);
            }
        });

        return _state;
    },
    [ASSESSMENT_QUESTIONS_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            'assessmentQuestions': payload
        });
    },
    [CARE_PLAN_QUESTION_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            'assessmentQuestions': payload
        });
    },
    [ASSESSMENT_ANSWERS_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            'assessmentAnswers': payload
        });
    },
    [UPDATE_ASSESSMENT_ANSWERS]: (state, payload) => {
        return Object.assign({}, state, {
            'assessmentAnswers': payload
        });
    },
    [UPDATE_ASSESSMENT_UPDATES]: (state, payload) => {
        return Object.assign({}, state, {
            'assessmentUpdates': payload
        });
    },
    [ASSESSMENT_ADDED]: (state, payload) => {
        var _state = _.cloneDeep(state);
        var assessmentObj = _state.assessments[payload.type];
        if (!assessmentObj) {
            assessmentObj = [];
            _state.assessments[payload.type] = assessmentObj;
        }
        assessmentObj.push(payload);
        return _state;
    },
    [ASSESSMENT_UPDATED]: (state, payload) => {
        var _state = _.cloneDeep(state);
        var assessmentObj = _state.assessments[payload.type];
        var newArray = [];
        if (assessmentObj) {
            _.each(assessmentObj, (data)=> {
                if (data.id == payload.id) {
                    newArray.push(payload);
                } else {
                    newArray.push(data);
                }
            });
            _state.assessments[payload.type] = newArray;
        }

        return _state;
    },
    [ASSESSMENT_DELETED]: (state, payload) => {
        var _state = _.cloneDeep(state);
        var assessmentObj = _state.assessments[payload.assessmentType];
        var newArray = [];
        if (assessmentObj) {
            _.each(assessmentObj, (data)=> {
                if (data.id != payload.assessmentId) {
                    newArray.push(data);
                }
            });
            _state.assessments[payload.assessmentType] = newArray;
        }

        return _state;
    },
    [CASE_HISTORY_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            caseHistory: payload
        });
    },
    [TODO_ADDED_SUCCESSFULLY]: (state, payload) => {
        var _state = _.cloneDeep(state);
        if (!_state.todoLists) {
            _state.todoLists = {};
        }
        _state.todoLists = payload;
        return _state;
    },
    [TODO_UPDATED_SUCCESSFULLY]: (state, payload) => {
        var _state = _.cloneDeep(state);
        if (!_state.todoLists) {
            _state.todoLists = {};
        }
        _state.todoLists = payload;
        return _state;
    },
    [SSO_TOKEN_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            'patientSSOToken': payload.ssoToken
        });
    },
    [LEVEL_TWO_DASHBOARD_DATA_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            'patientSSOToken': payload.ssoToken,
            'todoLists': payload.todoLists,
            'completedToDoList': payload.completedTODOList
        });
    },
    [TODOS_MARK_COMPLETED]: (state, payload) => {
        var _state = _.cloneDeep(state);
        var todoList = [];
        var completedTodoList = _state.completedToDoList;
        if (!completedTodoList) {
            completedTodoList = {};
        }
        _.each(_.values(payload), (todo)=> {
            delete _state.todoLists[todo.id];
            completedTodoList[todo.id] = todo;
        });
        _state.completedToDoList = completedTodoList;
        return _state;
    },
    [TODO_MARK_UNCOMPLETED]: (state, payload) => {
        var _state = _.cloneDeep(state);
        var todoList = _state.todoLists;
        if (!todoList) {
            todoList = {};
        }

        delete _state.completedToDoList[payload.id];
        todoList[payload.id] = payload;

        _state.todoLists = todoList;
        return _state;
    },
    [GET_CCDA_AVAILABILITY_SUCCESS]: (state, payload) => {
        return Object.assign({}, state, {
            'ccdaAvailable': payload.available
        });
    },
    [GET_CCDA_AVAILABILITY_FAIL]: (state, payload) => {
        return Object.assign({}, state, {
            ccdaAvailable: false,
        });
    },
    [GET_CCDA_PDF_SUCCESS]: (state, payload) => {
        return Object.assign({}, state, {
            'ccdaPdfUrl': payload
        });
    },
    [GET_MED_SUMMARY_AVAILABILITY_SUCCESS]: (state, payload) => {
        return Object.assign({}, state, {
            medSummaryAvailable: payload.available,
        });
    },
	[RECEIVED_ORGANIZATION_NOTE]: (state, payload) => {
		return Object.assign({}, state, {
			organizationNote: payload.note,
            call_preference_description:payload.call_preference_description
		});
	},
    [PATIENT_ENGAGEMENT_HISTIRY_RECEIVED]: (state,payload) => {
        return Object.assign({}, state, {
            'engagementHistory':payload
        });
    },
	[CHART_CLOSED_SUCCESSFULLY]: (state, payload) => {
		return Object.assign({}, state, {
			organizationNote: undefined,
            engagementHistory:[]
		});
	},
    [GET_MED_SUMMARY_AVAILABILITY_FAILURE]: (state, payload) => {
        return Object.assign({}, state, {
            medSummaryAvailable: false,
        });
    },
    [GET_MED_SUMMARY_PDF_SUCCESS]: (state, payload) => {
        return Object.assign({}, state, {
            medSummaryPdfUrl: payload,
        });
    },
    [GET_PHS_AVAILABILITY_SUCCESS]: (state, payload) => {
        return Object.assign({}, state, {
            'phsAvailable': payload.available
        });
    },
    [GET_PHS_PDF_SUCCESS]: (state, payload) => {
        return Object.assign({}, state, {
            'phsPdfUrl': payload
        });
    },
    [REQUEST_RECORD_INVALID]: (state, payload) => {
        return Object.assign({}, state, {
            recordErrors: payload
        });
    },
    [REQUEST_RECORD_VALID]: (state, payload) => {
        return Object.assign({}, state, {
            requestRecord: payload
        });
    },
    [ RECEIVED_LAST_PASSWORD_RESET ]: ( state = {}, payload ) => {
        return Object.assign( {}, state, {
            lastPasswordReset: payload
        });
    },
    [GET_RECORD_SUCCESS]: (state, payload) => {
        return Object.assign({}, state, {
            'recordPdfUrl': payload
        });
    },
	[RECEIVED_ASSOCIATED_CHARTS]: ( state={}, payload ) => {
		return Object.assign( {}, state, {
			associatedCharts: payload,
		});
	},
    [OPT_OUT_REASONS_RECEIVED]: ( state = {}, payload ) => {
        return Object.assign({}, state, {
            optedOutReasons: payload
        });
    },
    [ ON_OPEN_CHART_TAB ]: ( state = {}, payload ) => {
        return Object.assign( {}, state, {
            chartTabTobeOpen: payload,
        });
    },
});
