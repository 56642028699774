import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentQueueCarePlan = () =>
	<PatientQueue
		showQueueCount
		patientNameColumn
		providerDetailColumn
		timeBilledColumn
		lastWorkedHistoryColumn
		showFilters
		showProgramTypeFilter
        showScheduleCallFilter
		statusColumn
		chartColumn
		lastWorkedByColumn
		page
		queue="urgentCarePlan"
		endpoint="Urgent?typesToInclude=13"
		label="Urgent - Provider Communications"
	/>;

export default UrgentQueueCarePlan;
