
export const actionPlans = [
    {
        label:'Member scheduled - scheduled on own prior to engagement',
        code:'MemSche'
    },
    {
        label:'Member compelete - screening was already completed',
        code:'MemCom'
    },
    {
        label:'Records requested - records requested',
        code:'RecReq'
    },
    {
        label:'Records received - records received',
        code:'RecRec'
    },
    {
        label:'Office notified - office notified of patient wanting to schedule screening',
        code:'OffNot'
    },
    {
        label:'Appointment Kept - vendor confirmed appointment kept',
        code:'AppKep'
    },
    {
        label:'Appointment reminder - vendor reminded patient of scheduled appointment',
        code:'AppRem'
    },
    {
        label:'Appointment scheduled - GAPS Scheduled',
        code:'AppSchGAPS',
        attributes:[
            {
                name:'Date',
                type:'Date'
            },
            {
                name:'Time',
                type:'Text'
            },
            {
                name:'Location',
                type:'Text'
            }
        ]
    }
];

export const actionPlans2 = [
    {
        label:'Failed to reach - Did not reach member',
        code:'FailRea',
    },
    {
        label:'Bad number - Bad number, disconnected',
        code:'BadNo',
    },
    {
        label:'Deceased - Member deceased',
        code:'Deceased',
    },
    {
        label:'Member opted out - Member asked not to call again',
        code:'OptedOut',
    },
    {
        label:'Reached no interest - Patient reached nut no interest',
        code:'NoInterest',
    },
    {
        label:'Engaged but failed to scheduled - Patient indicated wanted to schedule but did not schedule',
        code:'FailSche',
    }
]
