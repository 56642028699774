import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import { connect } from 'react-redux';
import { Modal, Button, Input } from 'react-bootstrap';
import _ from 'lodash';

class PatientStatusUpdateModal extends React.Component{

	constructor(props){
		super(props);
		this.state={
			newStatus: 'Active',
			reason: null,
			notes: '',
			showNotes: false,
		}
	}

	onNewStatusChange( e ) {
		this.setState({newStatus:e.target.value})
	}

	onUnenrollReasonChange( e ) {
		let selectedReason = _.find( this.props.unenrollReasons, ( reason ) => reason.id === Number( e.target.value ) );
		if ( selectedReason.id === 6 || selectedReason.id === 23 ) {
			this.setState({ reason: selectedReason.id, showNotes: true });
			return;
		}
		this.setState({ reason: selectedReason.id, notes: selectedReason.reason, showNotes: false });
	}

	updateNotes( e ) {
		this.setState({ notes: e.target.value });
	}

	render() {
		const { show, onHide, patient, unenrollReasons, onConfirm } = this.props;
		const inputStyle = { 'width': '100%', 'paddingLeft': '5px', 'borderRadius': '4px', 'border': '1px solid rgb(204, 204, 204)', 'height': '30px' };

		return (
			<Modal show={show} onHide={onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Patient Status</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div style={{borderBottom:'1px solid gray',height:100}}>
						<div className="header-box">
							<div className="header-label">
								Patient
							</div>
							<div className="header-label">
								Provider
							</div>
						</div>
						<div className="ma-value-box clearfix">
							<div className="value-label">
								<div className="value-box">
									{`${patient.first_name} ${patient.last_name}`}
								</div>
							</div>
							<div className="value-label">
								<div>
									<div style={{clear:'both'}}>
										<div className="value-box">{patient.provider ? patient.provider.provider : ''}</div>
									</div>

									<div style={{clear:'both'}}>
										<div className="value-box">
											<div>{patient.provider ? patient.provider.organization : ''}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						<h5>Assign Patient Status</h5>
						<select style={ inputStyle }
						        ref="newStatus"
						        onChange={this.onNewStatusChange.bind(this)}
						        defaultValue={this.state.newStatus}
						>
							<option value="Active">Active</option>
							<option value="Pending Unenroll">Pending Unenroll</option>
						</select>
					</div>
					{ this.state.newStatus === 'Pending Unenroll' ?
						<div>
							<div>
								<h5>Select an Unenroll Reason</h5>
								<select style={inputStyle} ref="unenrollReason"
								        onChange={this.onUnenrollReasonChange.bind(this)}
										defaultValue={this.state.reason}>
									{
										_.map(unenrollReasons, (unenrollReason) => {
											return <option  value={unenrollReason.id}
											                key={unenrollReason.id}>
															{unenrollReason.reason}
													</option>
										})
									}
								</select>
								{this.state.providerError && <span className="text-danger"><small>{this.state.providerError}</small></span>}
							</div>
							{ this.state.showNotes ?
								<div>
									<h5>Internal Notes</h5>
									<div>
										<textarea className="form-control" rows="10" value={ this.state.notes } onChange={ this.updateNotes.bind( this ) } />
										<div style={{display:'flex',justifyContent:'flex-end'}}>
											{`${this.state.notes ? this.state.notes.length : 0}/4096`}
										</div>  
									</div>
								</div>
								:
								null
							}
						</div>
						:
						null
					}

				</Modal.Body>
				<Modal.Footer>
					<Button bsStyle="danger" onClick={onHide}>
						Cancel
					</Button>
					<Button bsStyle="success" onClick={onConfirm.bind(null, this.state.newStatus, this.state.reason, this.state.notes)}>
						Change Patient Status
					</Button>
				</Modal.Footer>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	'unenrollReasons': state.admin.unenrollReasons,
});

const mapDispatchToProps = (dispatch) => ({
	adminActions: bindActionCreators(adminActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientStatusUpdateModal);
