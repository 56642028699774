import React from 'react';
import { connect } from 'react-redux';
import {Input,Modal,Button,FormControl,FormGroup,ControlLabel} from 'react-bootstrap';

class GoalEditDeleteModal extends React.Component{

    constructor( props ) {
        super( props );
    }

    determineActionType = () => {
        const actionContainerStyles = {
            'borderTop': '1px solid gray',
            'marginTop': '35px',
            'overflow': 'auto',
            'paddingTop': '15px',
        };

        const bodyStyles = {
            'textAlign': 'center',
        };

        let { actionType,goalReviewFrequency } = this.props;

        if ( actionType === 'edit' ) {
            return (
                <Modal bsSize="large" show={ this.props.show } dialogClassName="close-chart-popup">
                    <Modal.Header closeButton onHide={ this.props.onHide }>
                        <Modal.Title>
                            Edit Goal
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={ this.props.modalAction }>
                            <h5>Enter new title:</h5>
                            <Input id="goalTitle" type="text" maxLength="255" defaultValue={ this.props.goalTitle } placeholder="Enter Goal" 
                             onChange={ this.props.goalTitleUpdate }/>
                            <FormGroup>                        
                                <ControlLabel>Review Frequency</ControlLabel>
                                <select style={{padding: '6px 12px',marginTop:10}} 
                                    className="form-control"
                                    onChange={this.props.handleFrequenceChange} defaultValue={this.props.frequency}>
                                    <option value='' selected disabled>Select One</option>
                                    {
                                        _.map(goalReviewFrequency,(frequency) => {
                                            return <option value={frequency.id}>{frequency.frequency}</option>
                                        })
                                    }
                                </select>
                            </FormGroup>
                           
                           {
                            this.props.frequency == '5' && <div><textarea className="form-control" rows="5" id="notes"
                            defaultValue={this.props.otherNote}
                            placeholder="Enter Notes"
                            onChange={this.props.handleNotesChange}></textarea>
                            <div style={{display:'flex',justifyContent:'flex-end'}}>
                                {`${this.state.otherNote ? this.state.otherNote.length : 0}/256`}
                            </div>
                            </div>
                           }
                            <div style={ actionContainerStyles }>
                                <Button bsStyle="default" className="pull-left" onClick={ this.props.onHide }>Cancel</Button>
                                <Button bsStyle="primary" className="pull-right" onClick={ this.props.modalAction }>Save</Button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            );
        }
        if ( actionType === 'delete' ) {
            return (
                <Modal bsSize="large" show={ this.props.show } dialogClassName="close-chart-popup">
                    <Modal.Header closeButton onHide={ this.props.onHide }>
                        <Modal.Title>
                            Delete Goal
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={ bodyStyles }>
                        <h4>
                            Clicking "Confirm" will delete the selected Goal.
                        </h4>
                        <div style={ actionContainerStyles }>
                            <Button bsStyle="default" className="pull-left" onClick={ this.props.onHide }>Cancel</Button>
                            <Button bsStyle="danger" className="pull-right" onClick={ this.props.modalAction }>Confirm</Button>
                        </div>
                    </Modal.Body>
                </Modal>
            )
        }
        return <div></div>;
    };

    render() {
        return this.determineActionType();
    }
}

const mapStateToProps = (state) => ({
    'goalReviewFrequency':state.admin.goalReviewFrequency
});

export default connect(mapStateToProps)( GoalEditDeleteModal );