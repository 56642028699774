import React from 'react';
import moment from "moment";

const StatusCell = ({ data: { urgent_flag, status_description, status, suspended,provider_transition,
    current_est_time,provider_transition_effective_date} }) => {
    let color = '#067809';
    if (status === 1) color = '#337ab7';
    if (urgent_flag === 1) color = '#f52b04';
    const transitionEffectiveDate = moment(provider_transition_effective_date);
    const currentESTTime = moment(current_est_time);
    return (
        <div style={{ textAlign: 'center', fontSize: 18, color }}>
            {urgent_flag === 1 && suspended &&
                <span>
                    {status_description}, Suspended (Urgent)
                </span>
            }

            {urgent_flag === 1 && provider_transition &&
                <span>
                    {`${status_description}, ${currentESTTime.isAfter(transitionEffectiveDate) ? 'Provider Transition (Urgent)' : ''}`}
                </span>
            }


            {urgent_flag === 1 && !suspended &&
		        <span>
			        {status_description}, (Urgent)
		        </span>
	        }
            {urgent_flag !== 1 && suspended &&
                <span>
                    {status_description}, Suspended
                </span>
            }
            {
            urgent_flag !== 1 && provider_transition &&
                <span>
                    {`${status_description}, ${currentESTTime.isAfter(transitionEffectiveDate) ? 'Provider Transition' : ''}`}
                </span>
            }
            {urgent_flag !== 1 && !suspended && !provider_transition &&
                <span>
                    {status_description}
                </span>
            }
        </div>
    );
};

export default StatusCell;
