import React from 'react';
import { Router,Route, IndexRoute, IndexRedirect } from 'react-router';
import { App } from '../containers';
import { requireAuthentication } from '../components/AuthenticatedComponent';
import {
    Home,
    LoginView,
    Notification,
    MedicalAssistants,
    LevelTwoDashboard,
    OpenCharts,
    ReportsContainer,
    UnverifiedQueue,
	RPMUnverifiedQueue,
	OptOutQueue,
    NewQueue,
    ActiveQueue,
    UrgentQueueCcm,
	UrgentQueueLead,
    UrgentQueueNurse,
    UrgentQueueCne,
	UrgentQueueBadPhone,
	UrgentQueuePoa,
	UrgentQueueRequestedInfo,
	UrgentQueueCallBack,
    RecordsQueue,
    SearchPatient,
    UnverifiedUnder65,
    UrgentQueueRefill,
	UrgentQueueRoundTwo,
	UrgentQueueCarePlan,
	PatientServicesQueue,
	PatientServicesOpenChartsQueue,
	UrgentQueueRetSpec,
	UrgentQueueProviderChange,
	UrgentQueueCopay,
	UrgentQueueAppointment,
	UrgentQueuePreEnrollment,
	UrgentDoNotCall,
	UrgentRemoveFromQueue,
	UrgentQueueNoAnswer,
	UrgentAccount,
	UrgentQueueMips,
	LoadingView,
	ITMedicalSummary,
	ITReconcile,
	FaxMatchingScreen,
	FixFaxRecordsScreen,
	FaxApprovalScreen,
	StrokeCareQueue,
	GAPSColorectalQueue,
	GAPSBreastCancerQueue,
	GAPSDiabeticEyeQueue,
	PCCPatientAssignment,
	ChartRouteComponent,
	UrgentQueueTriage,
	MyPatients,
	MyTeamPatients,
	CaseMessagesList,
	AllPatientsQueue
} from '../views';

export default (
    <Route path='/' component={App}>
        <IndexRoute component={requireAuthentication(Home)} />
        <Route path="login" component={LoginView }/>
        <Route path="home" component={requireAuthentication(Home)}>
            <Route path="medicalAssistant" component={MedicalAssistants} />
            <Route path="caseMessages" component={CaseMessagesList} />
			<Route path="notification" component={Notification} />
            <Route path="medicalRecords" component={FaxMatchingScreen} />
			<Route path="fixFaxRecords" component={FixFaxRecordsScreen} />
            <Route path="reports" component={ReportsContainer} />
	        <Route path="rerouting" component={LoadingView}/>
            <Route path="records" component={RecordsQueue} />
            <Route path="urgentITMedicalRecord" component={ITMedicalSummary} />
			<Route path="urgentITReconcile" component={ITReconcile} />
            <Route path="urgentCcm" component={UrgentQueueCcm} />
            <Route path="urgentNurse" component={UrgentQueueNurse} />
	        <Route path="urgentCne" component={UrgentQueueCne} />
	        <Route path="urgentSupport" component={UrgentQueueRetSpec} />
	        <Route path="urgentBadPhone" component={UrgentQueueBadPhone} />
	        <Route path="urgentPoa" component={UrgentQueuePoa} />
	        <Route path="urgentRequestedInfo" component={UrgentQueueRequestedInfo} />
	        <Route path="urgentCallBack" component={UrgentQueueCallBack} />
	        <Route path="urgentLead" component={UrgentQueueLead} />
	        <Route path="urgentRoundTwo" component={UrgentQueueRoundTwo} />
	        <Route path="urgentCopay" component={UrgentQueueCopay} />
	        <Route path="urgentDoNotCall" component={UrgentDoNotCall} />
	        <Route path="urgentRemoveFromQueue" component={UrgentRemoveFromQueue} />
	        <Route path="urgentProviderChange" component={UrgentQueueProviderChange} />
	        <Route path="urgentRefill" component={UrgentQueueRefill} />
	        <Route path="urgentCarePlan" component={UrgentQueueCarePlan} />
	        <Route path="urgentAppointment" component={UrgentQueueAppointment} />
	        <Route path="urgentPreEnrollment" component={UrgentQueuePreEnrollment} />
	        <Route path="urgentMips" component={UrgentQueueMips} />
	        <Route path="patientServices" component={PatientServicesQueue} />
	        <Route path="patientServicesOpenCharts" component={PatientServicesOpenChartsQueue} />
	        <Route path="urgentNoAnswer" component={UrgentQueueNoAnswer} />
			<Route path="urgentAccount" component={UrgentAccount}/>
            <Route path="approval" component={FaxApprovalScreen} />
            {/* <Route path="new" component={NewQueue} /> */}
            <Route path="unverified" component={UnverifiedQueue} />
			<Route path="rpmenrollment" component={RPMUnverifiedQueue} />
			<Route path="optOut" component={OptOutQueue} />
            <Route path="UnverifiedUnder65" component={UnverifiedUnder65} />
            {/* <Route path="active" component={ActiveQueue} /> */}
			{/* <Route path="strokecare" component={StrokeCareQueue} /> */}
            <Route path="openCharts" component={OpenCharts} />
            <Route path="search" component={SearchPatient} />
            <Route path="patient/:patientId" component={LevelTwoDashboard} />
			<Route path="patient-chart" component={ChartRouteComponent} />
            <Route path="search/:searchPhone" component={SearchPatient}/>
			<Route path="gAPSColorectal" component={GAPSColorectalQueue} />
			<Route path="gAPSBreastCancer" component={GAPSBreastCancerQueue} />
			<Route path="gAPSDiabeticEye" component={GAPSDiabeticEyeQueue} />
			<Route path="pccPatientAssignment" component={PCCPatientAssignment}/>
			<Route path="urgentTriage" component={UrgentQueueTriage}/>
			<Route path="mypatients" component={MyPatients} />
			<Route path="myTeamPatients" component={MyTeamPatients} />
			<Route path="allPatients" component={AllPatientsQueue} />
			
        </Route>
    </Route>
);
