import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentQueueMips = () =>
	<PatientQueue
		showQueueCount
		patientNameColumn
		providerDetailColumn
		timeBilledColumn
		todoItemColumn
		showFilters
		showProgramTypeFilter
        showScheduleCallFilter
		statusColumn
		chartColumn
		lastWorkedByColumn
		page
		queue="urgentMips"
		endpoint="Urgent?typesToInclude=23"
		label="Urgent - MIPS"
		callAttemptsCol
	/>;

export default UrgentQueueMips;
