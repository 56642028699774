/**
 * Created by synerzip on 27/02/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Input,Modal,Button} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
var LinkedStateMixin = require('react-addons-linked-state-mixin');

class ConfirmDeleteGoal extends React.Component{

    onSubmit(e){
        e.preventDefault();
        this.props.onDeleteConfirm(this.props.dataToBeDeleted);
        this.props.onPopupHide();
    }
    onCancel() {
        this.props.onPopupHide();
    }
    render(){
        return (
            <Modal show={this.props.show}>
                <Modal.Header closeButton onHide={()=>{
                                this.props.onPopupHide();
                            }}>
                    <Modal.Title>
                        <div style={
                            {
                            textAlign: 'center'
                            }
                        }>
                            <div>Delete Goal</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{overflow:'auto',height:'auto'}}>
                        <form onSubmit={this.onSubmit.bind(this)}>
                           <label style={{display: 'flex',
                                            padding: 15,
                                            justifyContent: 'center'}}>
                                                Clicking "Confirm" will delete the selected Goal.
                                                </label>
                            <div style={{borderTop:'1px solid gray',textAlign:'center',paddingTop:30}}>
                                <Button bsStyle="danger" onClick={this.onCancel.bind(this)} style={{float:'left'}}>
                                    Cancel
                                </Button>
                                <Button bsStyle="success" type="submit"  style={{float:'right'}}>
                                    Confirm
                                </Button>

                            </div>
                        </form>
                    </div>


                </Modal.Body>

            </Modal>
        );
    }
}

export default ConfirmDeleteGoal;