import React from 'react';
import reactMixin from 'react-mixin';
import LinkedStateMixin from 'react-addons-linked-state-mixin';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import {
    Modal, Button, FormControl, FormGroup, ControlLabel,
    Grid, Row, Col
} from 'react-bootstrap';
import {StateData} from '../../constants/other'
import _ from 'lodash';
import {connect} from "react-redux";
import ScheduleNextCallModal from "./ScheduleNextCallModal"

class ScheduleNextCallPopup extends React.Component {

    constructor(props) {
        super(props);
    }

    onSave(){
        const {show, onHide, onScheduleDateSelected,onScheduleSlotSelected,
            patient, confirmText, isCne,onSave,
            scheduleNextCallDate,scheduleNextCallSlot} = this.props;
            console.log("scheduleNextCallSlot:",scheduleNextCallSlot)
            console.log("scheduleNextCallDate:",scheduleNextCallDate);
            this.props.adminActions.saveScheduleNextCall(patient.id,scheduleNextCallSlot.id);
            this.props.onHide();
    }

    render() {
        const {show, onHide, onScheduleDateSelected,onScheduleSlotSelected,
            patient, confirmText, isCne,onSave,
            scheduleNextCallDate,scheduleNextCallSlot} = this.props;
        if (!show) {
            return <div/>
        }
        return (
            <Modal show={show} onHide={onHide} bsSize={"large"}>
                <Modal.Header closeButton>
                    <Modal.Title>Patient Scheduling</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ScheduleNextCallModal
                        onSave={this.onSave.bind(this)}
                        onScheduleDateSelected={onScheduleDateSelected}
                        onScheduleSlotSelected={onScheduleSlotSelected}
                        scheduleNextCallSlot={scheduleNextCallSlot}
                        isCne={isCne}
                        scheduleNextCallDate={scheduleNextCallDate}/>
                </Modal.Body>
                <Modal.Footer>
		            <Button bsStyle="default" onClick={onHide}>
		                Cancel
		            </Button>
		            <Button bsStyle="success" diabled={!scheduleNextCallSlot} onClick={this.onSave.bind(this)}>
		                Save
		            </Button>
		        </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    'patient': state.app.selectedPatient,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleNextCallPopup);