/**
 * Created by synerzip on 22/03/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Panel,Input,Button} from 'react-bootstrap';
import {Table, Column, Cell} from 'fixed-data-table';
import _ from 'lodash';
import moment from 'moment';
import CarePlanStep from './CarePlanStep';
import AddStepPopup from './AddStepPopup';


class CarePlanSteps extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    onAddStep(){
        this.setState({show:true});
    }

    onPopupHide(){
        this.setState({show:false});
    }

    render() {
        var {data} = this.props;
        var steps = data.goal_steps;

        var stepComps = _.map(steps, (step)=> {
            return (
                <CarePlanStep key={step.id} step={step} goalId={data.id}/>
            );
        });

        return (
            <div className="careplan-steps">
                {stepComps}
                <div className="add-step-box" onClick={this.onAddStep.bind(this)}>
                    + Add New Step
                </div>
                <AddStepPopup show={this.state.show} onPopupHide={this.onPopupHide.bind(this)}
                              data={data}/>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    'carePlanAndGoals': state.levelTwoDashboard.carePlanAndGoals,
    'userId': state.auth.id,
    'selectedPatient': state.app.selectedPatient
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CarePlanSteps);