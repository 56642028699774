import React from 'react';
import PatientQueue from './PatientQueue';

const GAPSDiabeticEyeQueue = () =>
    <PatientQueue
        showQueueCount
        patientNameColumn
        providerDetailColumn
        timeBilledColumn
        todoItemColumn
        showFilters
        statusColumn
        chartColumn
        lastWorkedByColumn
        page
        queue="gAPSDiabeticEye"
        endpoint="GAPSDiabeticEye"
        label="GAPSDiabeticEye"
    />;

export default GAPSDiabeticEyeQueue;
