import React from 'react';
import PatientQueue from './PatientQueue';

const RPMUnverifiedQueue = () =>
    <PatientQueue
		showQueueCount
		showFilters
        patientDetailColumn
        providerDetailColumn
        actionsColumn
        page
        lastOutCallColumn
        lastHistoryColumn
		callAttemptsCol
		showSort
        queue="rpmenrollment"
        endpoint="RPMEnrollment"
        label="Enrollment"
	    sortOptions={[
		    {
		    	'name': 'Last Called Ascending',
			    'key': 'lastOutCallTs&dir=asc',
		    },
		    {
			    'name': 'Last Called Descending',
			    'key': 'lastOutCallTs&dir=desc',
		    }
	    ]}
    />;

export default RPMUnverifiedQueue;
