/**
 * Created by synerzip on 03/03/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Panel,Input,Button} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';

class GeneralNotes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            notes: "",
        };
    }

    showErrorMessage(title,message){
        this.props.commonActions.showToast({
            status: 'error',
            title: title,
            message: message
        });
        var interval = setInterval(()=> {
            clearInterval(interval);
            this.props.commonActions.hideToast();
        }, 2);
    }

    componentDidMount() {
        this.props.adminActions.fetchGeneralNotes(this.props.selectedPatient.id);
    }

    onEdit() {
        if(this.state.edit){
            if((this.state.notes == null || this.state.notes.trim() === '' || this.state.notes.trim() === undefined)){
                this.showErrorMessage('Invalid Data','\'Notes\' section cannot be empty');
                return;
            } else {
                this.props.adminActions.submitGeneralNote(this.props.selectedPatient.id,this.state.notes,(this.props.generalNotes.id)?this.props.generalNotes.id:null);
            }
        }
        let notes = this.state.notes || '';
        if (notes) {
            notes += '\n';
        }
        notes += `(${moment().format('MM/DD/YYYY h:mm a')} - ${this.props.username}): `;
        this.setState({edit: !this.state.edit, notes});
    }

    onCancel() {
        this.props.adminActions.fetchGeneralNotes(this.props.selectedPatient.id);
        this.setState({edit: !this.state.edit});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.generalNotes != this.props.generalNotes){
            this.setState({notes: nextProps.generalNotes.notes});
        }
    }

    onNoteChange(e){
        this.setState({notes: e.target.value});
    }

    render() {
        var buttonLabel = "Add";
        var centerComp = <div className="preserve-line-breaks-and-spaces display-inline-block">
            {this.state.notes || <i className="text-muted">No notes added yet</i>}
        </div>;

        if (this.state.notes && this.state.notes.trim()) {
            buttonLabel = "Edit";
        }

        if (this.state.edit) {
            let notes = this.state.notes;
            buttonLabel = "Submit";
            centerComp = <div><Input type="textarea"
                                label=""
                                placeholder="Enter internal notes"
                                value={this.state.notes}
                                onChange={this.onNoteChange.bind(this)}
                                rows="6"
                                maxLength="65535" />
                                <div style={{display:'flex',justifyContent:'flex-end'}}>
                                    {`${this.state.notes ? this.state.notes.length : 0}/65535`}
                                </div>
                                </div>;
        }

        return (
            <div>
                <Panel>
                    {centerComp}
                    <div className="display-inline-block pull-right">
                        <Button bsStyle="primary" id="modifyNoteButton" onClick={this.onEdit.bind(this)}>{buttonLabel}</Button>
                        {this.state.edit && 
                            <Button bsStyle="danger" style={{marginLeft: '10px'}} onClick={this.onCancel.bind(this)}>Cancel</Button>
                        }
                    </div>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    'generalNotes': state.levelTwoDashboard.generalNotes,
    'userId': state.auth.id,
    'selectedPatient': state.app.selectedPatient,
    'username': state.auth.name,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralNotes);