/**
 * Created by synerzip on 21/03/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Input,Modal,Button,FormControl,FormGroup,ControlLabel} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
let LinkedStateMixin = require('react-addons-linked-state-mixin');

class AddPatientEducation extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            patientEducation:null,
            title:null,
            stepError:false
        }
    }
    onSubmit(e) {
        e.preventDefault();

        let {selectedPatient,adminActions} = this.props;
        if(this.state.title.trim() == '') {
            this.setState({
                stepError: true
            })
        } else {
            this.props.showSecondaryTimer();
            adminActions.addPatientEducation(selectedPatient.id,this.state.title,this.state.patientEducation);
            this.setState(
                {
                    patientEducation:null,
                    title:null,
                    stepError:false
                }
            );
            this.props.onPopupHide();            
        }
    }

    onCancel() {
        this.setState(
            {
                goal:null,
                step:null,
                step_title:null
            }
        );
        this.props.onCloseEdit();
        this.props.onPopupHide();
    }

    render(){
        return (
            <Modal show={this.props.show} className="add-goal-modal">
                <Modal.Header closeButton onHide={()=>{
                                this.props.onPopupHide();
                            }}>
                    <Modal.Title>
                        <div style={
                            {
                            textAlign: 'center'
                            }
                        }>
                            <div>Add Patient Education</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{overflow:'auto',height:400}}>
                        <form onSubmit={this.onSubmit.bind(this)} novalidate>

                            <Input type="text" label="Title" placeholder="Enter Title" required
                                   valueLink={this.linkState('title')} maxLength="255"/>

                           
                            <FormGroup className={this.state.stepError && 'has-error'}>                        
                                <ControlLabel>Patient Education</ControlLabel>
                                <div>
                                    <FormControl componentClass="textarea" label="Patient Education" placeholder="Patient Education" required
                                        valueLink={this.linkState('patientEducation')} maxLength="4096" style={{resize:'none',height:'200px'}}/>
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        {`${this.state.patientEducation ? this.state.patientEducation.length : 0}/4096`}
                                    </div>         
                                </div>
                                {this.state.stepError && <span className="text-danger"><small>Please enter a patient education</small></span>}
                            </FormGroup>
                            <div style={{textAlign:'center',paddingTop:10}}>
                                <Button bsStyle="danger" onClick={this.onCancel.bind(this)} style={{float:'left'}}>
                                    Cancel
                                </Button>
                                {
                                    (this.props.editMode)?
                                        <Button bsStyle="success" type="submit" style={{float:'right'}}>
                                            Update
                                        </Button>
                                        :
                                        <Button bsStyle="success" type="submit" style={{float:'right'}}>
                                            Save
                                        </Button>
                                }
                            </div>
                        </form>
                    </div>


                </Modal.Body>

            </Modal>
        );
    }
}

reactMixin(AddPatientEducation.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    'user': state.auth.user,
    'selectedPatient':state.app.selectedPatient
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPatientEducation);