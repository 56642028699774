import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { getChartOpenBy } from '../../actions/admin';
import { connect } from 'react-redux';
import { isMA } from '../../utils';

class ChartPreviewModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartOpenedBy: {},
        }
    }
    getChartOpenBy = () =>
        this.props.getChartOpenBy(this.props.patient.id)
            .then((chartOpenedBy) => {
                this.setState({ chartOpenedBy });
            })
            .catch(() => false);
    render() {
        const { show, organizationNote, onHide, patient, openChart,activeUser,userRole } = this.props;
        const isCne = activeUser && activeUser.center && activeUser.center.ce_flag;
        const { chartOpenedBy } = this.state;
        const isMAUser = isMA(userRole);
        const chartIsOpen = patient ? patient.active_work_item_id : '';
        return <Modal show={show} bsSize="lg">
            <Modal.Header onHide={onHide}>
                <Modal.Title>Chart Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    patient && <div style={{ display: 'flex', flex: 1 }}>
                        <div style={{ flex: 1 }}>
                            <div style={{ fontWeight: 'bold' }}>{patient.first_name + ' ' + patient.last_name}</div>
                            <div>POA: {patient.poa_name ? patient.poa_name : '-'}</div>
                            <div className="value-box" style={{ width: '100%' }}><span>ZIP Code:</span> {patient.zip_code || 'N/A'}</div>
                            <div className="value-box" style={{ width: '100%' }}><span>State:</span> {patient.state || 'N/A'}</div>
                            
                        </div>
                        <div style={{ flex: 1, flexDirection: 'column' }}>
                            <div>DOB: {moment(patient.date_of_birth).format('MM/DD/YYYY')}</div>
                            <div>Age: {patient.age}</div>
                            <div>{patient.phone}</div>
                            <div>CCM ID: {patient.id}</div>
                        </div>
                        <div style={{ flex: 1, flexDirection: 'column' }}>
                            <div style={{ fontWeight: 'bold' }}>{patient.provider.provider}</div>
                            <div>{patient.provider.pronunciation}</div>
                            <div>{patient.provider.organization}</div>
                            <div>{patient.provider.specialty_description}</div>
                        </div>
                        <div style={{ flex: 1, flexDirection: 'column', display: 'flex', textAlign: 'right' }}>
                            <div style={{ width: '100%' }}><span style={{ fontWeight: 'bold' }}>Attempt: </span>{patient.call_count}</div>
                            <div style={{ width: '100%' }}>{patient.last_worked_by}</div>
                            <div style={{ width: '100%' }}>{moment(patient.last_worked_on_ts).format("HH:mm a MM/DD/YYYY")}</div>
                        </div>

                    </div>
                }
                <hr />
                <div>
                    <h5>Last Note</h5>
                    <p className="notes-content">{!patient ? 'Loading...' : (!!patient.last_worked_note ? patient.last_worked_note : 'No last note available')}</p>
                </div>
                <hr />
                <div>
                    <h5>Organization Notes</h5>
                    <p className="notes-content">{organizationNote === undefined ? 'Loading...' : (!!organizationNote ? organizationNote : 'No organization notes available')}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {
                       isCne && patient && patient.provider && patient.provider.copay_link ? 
                        <Button onClick={() => window.open(patient.provider.copay_link,"_blank")} 
                             style={{border:'none',color:'rgb(99,128,161)',boxShadow:'none'}}>
                            Copay Table <i className="fa fa-external-link" aria-hidden="true"></i>
                        </Button>
                        :
                        <div></div>
                    }
                    
                    <div>
                        <Button id="patientNotesConfirm" bsStyle="danger" onClick={onHide}>
                            Cancel
                        </Button>
                        {chartIsOpen && !_.isEmpty(chartOpenedBy) &&
                            <span style={{ marginLeft: 15 }}>{chartOpenedBy.name}</span>
                        }
                        {chartIsOpen && _.isEmpty(chartOpenedBy) && <Button
                            bsSize="small"
                            type="button"
                            onClick={this.getChartOpenBy}
                        >
                            Opened By?
                        </Button>
                        }
                        {
                            !chartIsOpen && <Button id="patientNotesConfirm" bsStyle="success" onClick={openChart}
                                disabled={organizationNote === undefined}>
                                Open Chart
                            </Button>
                        }
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
    }

}


export default connect(
    (state, ownProps) => ({
        'userRole': state.auth.roles,
    }),
    {
        getChartOpenBy
    }
)(ChartPreviewModal);