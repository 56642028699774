import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import { connect } from 'react-redux';
import {Button,MenuItem} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import $ from 'jquery';

class NewRecordsDropdown extends React.Component{

    constructor(props){
        super(props);
        this.state={
        }
    }

    getRecord( recordId ) {
        this.props.adminActions.getRecord( recordId, hasError => {
            if (!hasError) {
                window.open(this.props.recordPdfUrl, '_blank').focus();
            }
        });
    }

    completeRecord( recordId ) {
        this.props.adminActions.completeRecord( recordId, this.props.selectedPatient.id );
    }

    render(){
        return (
            <div style={containerStyle}>
                <div style={{padding:'5px'}} className="text-center">
                    <h4 style={recordHeadingStyle}>Select Record to Open</h4>
                    <div className="newRecordsContainer text-left" style={{marginTop: '10px'}}>
                        {
                            _.map(this.props.recordsHeaders && this.props.recordsHeaders.content, (record) => {
                                let date = record.update_ts ? record.update_ts : record.create_ts;
                                return (
                                    <div className="clearfix" style={recordContainer} key={record.id}>
                                        <div className="col-sm-10">
                                            <h4 style={recordHeadingStyle}><span style={{cursor: 'pointer'}} data-toggle="dropdown" onClick={this.getRecord.bind(this, record.id)}>Fax - {record.provider_name || 'New Record'}</span></h4>
                                            <span>{moment.utc(date).local().format('MM/DD/YYYY')} </span>
                                            <span> {moment.utc(date).local().format('h:mm')}</span>
                                        </div>
                                        <div className="clearfix col-sm-2">
                                            <Button bsStyle="primary" className="pull-right" onClick={this.completeRecord.bind(this, record.id)}>Completed</Button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div>
        )
    }
}
const containerStyle = {
    width: '400px',
}
const recordHeadingStyle = {
    fontWeight: 300,
    marginTop: 0,
    marginBottom: 0,
    color: '#337ab7',
}
const recordContainer = {
    padding: '5px',
    borderBottom: '1px solid grey',
    marginTop: '10px',
}
const mapStateToProps = (state) => ({
    'recordPdfUrl': state.levelTwoDashboard.recordPdfUrl,
    'selectedPatient': state.app.selectedPatient,
    'recordsHeaders': state.app.recordsHeaders,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(NewRecordsDropdown);
