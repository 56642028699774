/**
 * Created by synerzip on 29/01/16.
 */
import {createReducer} from '../utils';
import CONSTANTS from '../constants';
import {PATIENT_STATUS_UNENROLL_PENDING} from '../constants/other';
import {pushState} from 'redux-router';
import _ from 'lodash';

const {
    INBOUND_MEDICAL_RECORD_RECEIVED,MEDICAL_DOC_RECEIVED,LOGOUT_USER,
    PATIENT_SEARCH_RESULT_RECEIVED,CONSENT_FORM_RECORD_SELECTED,MEDICAL_RECORD_MATCHED_SUCCESSFULLY,
    CLEAR_SEARCHED_PATIENT_LIST,INBOUND_MATCHED_MEDICAL_RECORD_RECEIVED,
    INITIATE_UNENROLL_PATIENT_SUCCESS, CLEAR_MEDICAL_FORM_DOC,
    DELETE_INBOUND_RECORD_SUCCESS} = CONSTANTS;
    
const initialState = {
    inboundMedicalForms: null,
    medicalFormDocument: null,
    medicalFormDocumentUrl: '',
    patientList:null,
    selectedDoc:null
};

export default createReducer(initialState, {

    [LOGOUT_USER]: (state, payload) => {
        return Object.assign({}, state, {
            inboundMedicalForms: null,
            medicalFormDocument: null,
            medicalFormDocumentUrl: '',
            patientList:null,
            selectedDoc:null
        });
    },
    [CONSENT_FORM_RECORD_SELECTED]: (state, payload) => {
        return Object.assign({}, state, {
            selectedDoc:payload
        });
    },
    [PATIENT_SEARCH_RESULT_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            patientList:payload
        });
    },

    [CLEAR_SEARCHED_PATIENT_LIST]: (state) => {
        return Object.assign({}, state, {
            patientList: null
        });
    },
    [CLEAR_MEDICAL_FORM_DOC]: (state) => {
        return Object.assign({}, state, {
            medicalFormDocument: null,
        });
    },
    [MEDICAL_RECORD_MATCHED_SUCCESSFULLY]: (state, payload) => {
        var _state = _.cloneDeep(state);

        var newInboundRecords = [];
        _.each(_state.inboundMedicalForms,(record)=>{
            if(record.id != payload){
                newInboundRecords.push(record);
            }
        });

        _state.inboundMedicalForms = newInboundRecords;
        _state.selectedDoc = null;
        _state.medicalFormDocument = null;

        return _state;
    },
    [INBOUND_MEDICAL_RECORD_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            'inboundMedicalForms': payload
        });
    },
    [INBOUND_MATCHED_MEDICAL_RECORD_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            'inboundMatchedMedicalForms': payload
        });
    },
    [MEDICAL_DOC_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            'medicalFormDocument': payload.doc,
            'medicalFormDocumentUrl': payload.url,
        });
    },
    [INITIATE_UNENROLL_PATIENT_SUCCESS]: (state, payload) => {
        var _state = _.cloneDeep(state);

        _.each(_state.patientList, patient => {
            if (patient.id === payload.patient.id) {
                patient.status = PATIENT_STATUS_UNENROLL_PENDING;
                patient.status_description = payload.patient.status_description;
            }
        });

        return _state;
    },
    [DELETE_INBOUND_RECORD_SUCCESS]: (state, payload) => {
        const index = state.inboundMedicalForms.map((f) => f.id).indexOf(payload.id);
        return {
            ...state,
            inboundMedicalForms: [
                ...state.inboundMedicalForms.slice(0, index),
                ...state.inboundMedicalForms.slice(index + 1),
            ],
            selectedDoc: null,
            medicalFormDocument: null,
            medicalFormDocumentUrl: '',
        };
    },
});
