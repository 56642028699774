/**
 * Created by synerzip on 08/12/15.
 */
import {createReducer} from '../utils';
import CONSTANTS from '../constants';

const {SHOW_TOAST,HIDE_TOAST} = CONSTANTS;

const initialState = {
    show:false,
    toastData:null
};

export default createReducer(initialState, {
    [SHOW_TOAST]: (state, payload) => {
        return Object.assign({}, state, {
            'show': true,
            'toastData':payload
        });
    },
    [HIDE_TOAST]: (state, payload) => {
        return Object.assign({}, state, {
            'show': false,
            'toastData':null
        });
    },
});