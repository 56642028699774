import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import {Modal, Button, Input} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import LinkedStateMixin from 'react-addons-linked-state-mixin';
import {getFullNameString} from '../../utils';

const NOTE_PREFIX = 'Closed by Supervisor - ';

class AdminCloseChartPopup extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      note: this._getDefaultNote(props)
    };
  }

  showMessage(message, title = 'Invalid Value', status = 'error') {
    this.props.commonActions.showToast({
      status: status,
      title: title,
      message: message
    });
    var interval = setInterval(()=> {
      clearInterval(interval);
      this.props.commonActions.hideToast();
    }, 2);
  }

  closeChart(e) {
    e.preventDefault();

    let note = this.state.note;

    if (note && note.trim()) {
      this.props.adminActions.closeChartByAdmin(this.props.openChart.ma_id, note.trim());

      this.setState({note: this._getDefaultNote()});

      this.props.onPopupHide();
    } else {
      this.showMessage('Please Provide a Note Before Closing the Chart', 'Note Missing');
    }

  }

  onCancel() {
    this.props.onPopupHide();
  }

  _getDefaultNote(props = this.props) {
    return `${NOTE_PREFIX} ${props.user.name}`;
  }

  render() {
    let openChart = this.props.openChart || {},
        maName = openChart.ma_name,
        patientName = getFullNameString(
            openChart.active_patient_first_name,
            openChart.active_patient_middle_name,
            openChart.active_patient_last_name
        );

    let noteLength = this.state.note ? this.state.note.length : 0;

    return (
        <Modal show={this.props.show}>
            <Modal.Header closeButton onHide={this.onCancel.bind(this)}></Modal.Header>
            <Modal.Body>
                <div style={{borderBottom: '1px solid gray', height: 70}}>
                    <p>Are you sure you want to close chart for <strong>{ patientName }</strong>?</p>
                    <p>Chart opened by <strong>{ maName }</strong>.</p>
                </div>
                <div style={{overflow:'auto',height:150, marginTop: 10}}>
                    <form onSubmit={this.closeChart.bind(this)}>
                      <div>
                        <Input type="textarea"
                               label="Note"
                               maxLength="1024"
                               valueLink={this.linkState('note')}/>
                        <div style={{display:'flex',justifyContent:'flex-end'}}>
                            {`${noteLength}/1024`}
                        </div>          
                        </div>
                        <div style={{borderTop:'1px solid gray', height: 50, textAlign: 'center', paddingTop: 15}}>
                            <Button onClick={this.onCancel.bind(this)}
                                    style={{float:'left'}}>
                                Cancel
                            </Button>
                            <Button bsStyle="danger"
                                    type="submit"
                                    style={{float:'right'}}>
                                Close Chart
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );

  }
}
reactMixin(AdminCloseChartPopup.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
  user: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  adminActions: bindActionCreators(adminActionCreator, dispatch),
  commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminCloseChartPopup);
