import React from 'react';
import { Button, Panel, FormControl} from 'react-bootstrap';
import DynamicFormInput from '../../components/DynamicFormInput'
import _ from 'lodash';


class AssessmentCategoryRow extends React.Component {

	header() {
		if(this.props.hideTitle){
			return null;
		}
		const rotate = this.props.open ? ' rotate' : '';
		const { shortName, title, riskObject } = this.props;
		return (
			<div onClick={()=>this.props.handleToggle(shortName)} className="clearfix" id={title}>
				<i className={'fa fa-angle-down circleBorder' + rotate} aria-hidden="true" />{title}
				{ riskObject.display &&
					<div className="pull-right">
						<span>Score:&nbsp;</span>
						<div style={{display:'inline-block'}}>
							<FormControl type="text" value={riskObject.text} disabled style={{height:'24px !important', textAlign:'center', marginBottom:'0'}}/>
						</div>
					</div>
				}
			</div>
		)
	}

	getSections() {
		const assessment = this.props.assessmentQuestions;
		if ( assessment.sub_sections && assessment.sub_sections.length ) {
			return _.map(assessment.sub_sections, (sub_section) => {
				return (
					<div key={sub_section.id} id={sub_section.title}>
						{
							!this.props.hideTitle && <h3>{sub_section.title}</h3>
						}

						<div style={{marginLeft: '20px'}}>
							{this.parseAssessment(sub_section)}
						</div>
					</div>
				)
			})
		} else {
			return this.parseAssessment(assessment)
		}
	}

	parseAssessment(assessment) {
		const { answerCollection,assessmentQuestionCollection, shortName, updateInput ,clearAnswer,editAnswer,sectionId,resetAll,
			disabled} = this.props;
		return _.map(assessment.questions, (question) => {
			return (
				<DynamicFormInput
					key={ question.id }
					editAnswer={editAnswer}
					sectionId={sectionId}
					resetAll={resetAll}
					question={ question }
					disabled={disabled}
					answerCollection={ answerCollection }
					assessmentQuestionCollection={assessmentQuestionCollection}
					updateInput={ updateInput.bind(this, shortName) }
					clearAnswer={clearAnswer.bind(this, shortName)}
				/>
			)
		})
	}

	render() {
		return (
			<div>
				<Panel header={this.header()} bsStyle={this.props.bsStyle} collapsible expanded={this.props.open}>
					<div className={(this.props.shortName === 'assess_med_adherence' || this.props.shortName === 'pw_assess_essa') && 'col-xs-8'}>
						{this.getSections()}
					</div>
					{this.props.shortName === 'pw_assess_essa' ?
							<div className="col-xs-4" style={{border:'1px solid #3c763d'}}>
								<h5>Ranges:</h5>
								<p>0-10 Normal range in healthy adults</p>
								<p>11–14 Mild sleepiness</p>
								<p>15–17 Moderate sleepiness</p>
								<p>18 or higher Severe sleepiness</p>
							</div>
							:
							''
					}
				</Panel>
			</div>
		)
	}

}

export default AssessmentCategoryRow;
