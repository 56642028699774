import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import {Modal,Button} from 'react-bootstrap';
import LinkedStateMixin from 'react-addons-linked-state-mixin';

class ToggleCallSchedulingModal extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            schedulable: props.patient.schedulable ? 'No' : 'Yes'
        }
    }

    onCancel() {
        this.setState({reason: null});
        this.props.onPopupHide();
    }

    onSchedulableChange(e) {
        this.setState({schedulable:e.target.value});
    }

    onSubmit(){
        const {schedulable} = this.state;
        this.props.adminActions.updateCallSchedulingPreference(this.props.patient,
            schedulable,(response)=>{
                this.props.onPopupHide();
            });

    }

    render(){
        return (
            <Modal show={this.props.show}>
                <Modal.Header closeButton onHide={this.onCancel.bind(this)}>
                    <Modal.Title>
                        <div className="text-align-center">
                            <div>Call Scheduling Preference</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{borderBottom:'1px solid gray'}}>
                        <p>Permanently disable call scheduling for the patient?</p>
                    </div>
                    <div style={{ marginTop: '10px'}}>
                        <form onSubmit={this.onSubmit.bind(this)}>
                            <div>
                                <div style={{display:'flex'}}>
                                    <div style={{display:'flex'}}>
                                        <input type="radio" name="preferred_language"
                                               style={{height:'15px !important'}}
                                               value={'No'}
                                               checked={this.state.schedulable == 'No'}
                                               onChange={this.onSchedulableChange.bind(this)}/>
                                        <div style={{marginLeft:15}}>
                                            No
                                        </div>
                                    </div>
                                    <div style={{display:'flex',marginLeft:25}}>
                                        <input type="radio" name="preferred_language"
                                               style={{height:'15px !important'}}
                                               value={'Yes'}
                                               checked={this.state.schedulable == 'Yes'}
                                               onChange={this.onSchedulableChange.bind(this)}/>
                                        <div style={{marginLeft:15}}>
                                            Yes
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                    <div style={{borderTop:'1px solid gray',height: 50,textAlign: 'center',paddingTop: 15, marginTop: '15px'}}>
                        <Button onClick={this.onCancel.bind(this)} style={{float:'left'}}>
                            Cancel
                        </Button>
                        <Button bsStyle="success" type="submit"
                                style={{float:'right'}}
                                onClick={this.onSubmit.bind(this)} id="saveContactPreference">
                            Save
                        </Button>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}


reactMixin(ToggleCallSchedulingModal.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    'user': state.auth.user,
    'patientServicesReasons': state.admin.patientServicesReasons,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ToggleCallSchedulingModal);
