import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmChartEnterModal = ({ show, onConfirm, onHide }) =>
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton style={{background: '#c9302c'}}>
            <Modal.Title>Warning: Patient Unverified</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{borderBottom:'1px solid gray',height:100}}>
                <p>Warning! This patient has not yet been verified into CCM.</p>
                <p>Only proceed with opening the chart if you have been authorized.</p>
            </div>
            <div style={{height:50,textAlign:'center',paddingTop:15}}>

                <Button bsStyle="danger" onClick={onHide} style={{float:'left'}}>
                    Cancel
                </Button>
                <Button bsStyle="success" onClick={onConfirm} style={{float:'right'}}>
                    Open Chart
                </Button>
            </div>
        </Modal.Body>
    </Modal>;

export default ConfirmChartEnterModal;
