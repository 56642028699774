import React from 'react';
import {connect} from 'react-redux';
import {pushState} from 'redux-router';
import { isMA } from '../utils';

export function requireAuthentication(Component) {

    class AuthenticatedComponent extends React.Component {

        componentWillMount () {
            this.checkAuth();
        }

        componentWillReceiveProps (nextProps) {
            this.checkAuth( nextProps );
        }

        checkAuth ( nextProps ) {
            if (!this.props.isAuthenticated) {
                let redirectAfterLogin = this.props.location.pathname;
                const phoneQuery = this.props.location.query.phone;

                if(redirectAfterLogin == '/'){
                    redirectAfterLogin = 'home';
                }
                if(redirectAfterLogin) {
                    if(phoneQuery){
                        this.props
                            .dispatch(pushState(null, `/login?next=${redirectAfterLogin}&phone=${phoneQuery}`));
                    }else{
                        this.props
                            .dispatch(pushState(null, `/login?next=${redirectAfterLogin}${this.props.location.search}`));
                    }

                }
            }else{
                let redirectAfterLogin = this.props.location.pathname;
                if ( redirectAfterLogin == '/' && !nextProps ){
                    this.props
                        .dispatch( pushState( null, `/home` ) );
                }
                if ( nextProps && nextProps.activeUser && nextProps.activeUser.center && ( redirectAfterLogin == '/' || redirectAfterLogin == '/home' || nextProps.location.pathname == '/' || nextProps.location.pathname == '/home' ) ) {
                    if ( nextProps.activeUser.center.ce_flag == true ) {
                        this.props
                            .dispatch( pushState( null, `/home/unverified` ) );
                    } else if(nextProps.activeUser.center.rpm_flag == true) {
                        this.props
                            .dispatch( pushState( null, `/home/rpmenrollment` ) );
                    } else if(nextProps.activeUser.center.gaps_flag == true) {
                        this.props
                            .dispatch( pushState( null, `/home/active` ) );
                    } else if ( nextProps.activeUser.center.support_flag == true ) {
                    	this.props
		                    .dispatch( pushState( null, `/home/patientServices` ) );
                    } else if ( isMA(nextProps.user.roles) ) {
                    	this.props
		                    .dispatch( pushState( null, `/home/myPatients` ) );
                    } else {
                        this.props
                            .dispatch( pushState( null, `/home/records` ) );
                    }
                }
            }
        }

        render () {
            return (
                <div>
                    {this.props.isAuthenticated === true
                        ? <Component {...this.props}/>
                        : null
                    }
                </div>
            )

        }
    }

    const mapStateToProps = (state) => ({
        token: state.auth.token,
        userName: state.auth.userName,
        isAuthenticated: state.auth.isAuthenticated,
        activeUser: state.admin.activeUser,
        user: state.auth
    });

    return connect(mapStateToProps)(AuthenticatedComponent);

}
