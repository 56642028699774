import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { OPT_OUT_STATUSES , RPM_OPT_OUT_STATUSES} from '../../constants/other';
import _ from 'lodash'


class OptOutModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			opt_out_status: '',
			opt_out_reason:'',
			notes:''
		}
	}

	updateSelectedStatus(e) {
		this.setState({
			opt_out_status: Number(e.target.value),
			opt_out_reason:'',
			notes:''
		})
	}

	updateOptOutReason(e) {
		this.setState({
			opt_out_reason: Number(e.target.value),
			notes:''
		})
	}

	updateNotes(e){
		this.setState({notes:e.target.value});
	}

	onHide() {
		this.setState({
			opt_out_status: '',
			opt_out_reason:'',
			notes:''
		});
		this.props.onHide();
	}

	onConfirm() {
		this.props.onConfirm(this.state.opt_out_status,this.state.opt_out_reason,this.state.notes)
	}

	render() {
		const {show, patient, declineText,optedOutReasons,isRPM} = this.props;
		const {opt_out_status,opt_out_reason,notes} = this.state;
		let declineDisableStatus = true;
		if(opt_out_status){
			if(opt_out_status === 103) {
				if(opt_out_reason) {
					if (opt_out_reason !== 5) {
						declineDisableStatus = false;
					} else if (notes) {
						declineDisableStatus = false;
					}
				}
			}else{
				declineDisableStatus = false;
			}
		}

		let calculatedReasons = [];
		if(isRPM && opt_out_status){
			calculatedReasons = _.filter(optedOutReasons,(reason)=>{
				return reason.applicable_status_id == opt_out_status;
			})
		}

		let showReasons = false;
		if(isRPM){
			showReasons = calculatedReasons.length > 0;
		}else{
			showReasons = this.state.opt_out_status == 103;
		}


		let options = isRPM ? RPM_OPT_OUT_STATUSES : OPT_OUT_STATUSES;
		if(this.props.currentQueue === "Optout"){
			options = _.filter(OPT_OUT_STATUSES,(status)=>{
				return status.id  !== 103;
			})
		}
		return (
			<Modal show={show} onHide={this.onHide.bind(this)}>
				<Modal.Header closeButton>
					<Modal.Title>Confirm {declineText}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						Clicking "{declineText}" will {declineText} {patient.first_name} {patient.last_name} of the
						program. If
						you would not like to {declineText} this patient, please
						click "Cancel."
					</p>
					<p>
						Select a Decline status:
					</p>
					<select style={{ padding: '6px 12px' }} className="form-control" onChange={this.updateSelectedStatus.bind(this)} defaultValue={-1}>
						<option value="-1" disabled>Select an option</option>
						{
							_.map( options, ( status ) => {
								return <option value={status.id} key={status.label + status.id}>{status.label}</option>
							})
						}
					</select>
					{
						showReasons &&
							<div style={{marginTop: '10px'}}>
								<p>
									Select a reason:
								</p>
								<select style={{ padding: '6px 12px' }} className="form-control"
										onChange={this.updateOptOutReason.bind(this)} defaultValue={-1}>
									<option value="-1" disabled>Select an reason</option>
									{
										_.map( isRPM ? calculatedReasons : optedOutReasons, ( reason ) => {
											return <option value={reason.id} key={reason.id + reason.reason}>{reason.reason}</option>
										})
									}
									{
										isRPM && <option value={5}>Other</option>
									}

								</select>
								{
									this.state.opt_out_reason == 5 &&
									<div>
											<textarea onChange={ this.updateNotes.bind(this) }
											  value={ this.state.notes || '' }
											  maxLength="1024"
											  style={{
												  width: '100%',
												  padding: '5px',
												  height: '175px',
												  overflowY: 'auto',
												  border: '1px solid #000',
												  marginTop: '10px',
												  marginBottom: '1rem'
											  }}/>
											<div style={{display:'flex',justifyContent:'flex-end'}}>
												{`${this.state.notes ? this.state.notes.length : 0}/1024`}
											</div>
									</div>			  
								}


							</div>
					}
					<div style={{marginTop: '10px', fontSize: '12px'}}>
						{
							this.props.currentQueue  !== "Optout" &&
							<p style={{margin: '2px'}}><em>Decline - Patient has declined but could be revisited at a later date</em></p>
						}

						<p style={{margin: '2px'}}><em>Do NOT Call - Patient has declined and should NOT be revisited</em></p>
						<p style={{margin: '2px'}}><em>Removed from Queue - Management ONLY: needs practice review</em></p>
						{
							isRPM && <p style={{margin: '2px'}}><em>Incorrect phone number: Patients phone number is incorrect</em></p>
						}

					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button bsStyle="default" onClick={this.onHide.bind(this)}>
						Cancel
					</Button>
					<Button bsStyle="danger" onClick={this.onConfirm.bind(this)} disabled={declineDisableStatus}>
						{declineText}
					</Button>
				</Modal.Footer>
			</Modal>
		)
	}
}

export default OptOutModal;
