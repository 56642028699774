/**
 * Created by synerzip on 23/01/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Panel} from 'react-bootstrap';
import _ from 'lodash';

class OrganizationCell extends React.Component{

    render(){
        var {data} = this.props;
        return(
            <div>
                <div style={{clear:'both'}}>
                    <div className="value-box">{data.organization}</div>
                </div>

                <div style={{clear:'both'}}>
                    <div className="value-box">
                        <div>{data.address1}</div>
                        <div>{data.address2}</div>
                        <div>{data.city + ","+data.state+" "+data.zip_code}</div>
                    </div>
                </div>
                <div style={{clear:'both'}}>
                    <div className="value-box">{data.phone}</div>
                </div>
            </div>
        );
    }
}

export default OrganizationCell;