import React from 'react';
import PatientQueue from './PatientQueue';

const ITMedicalSummary = () =>
    <PatientQueue
        showQueueCount
        patientNameColumn
        providerDetailColumn
        timeBilledColumn
        todoItemColumn
        showFilters
        showProgramTypeFilter
        dobColumn
        chartColumn
        lastWorkedByColumn
        page
        queue="urgentITMedicalRecord"
        endpoint="Urgent?typesToInclude=17"
        label="Urgent - Medical Summary"
    />;

export default ITMedicalSummary;
