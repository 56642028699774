import React from 'react';
import {bindActionCreators} from 'redux';
import * as adminActionCreator from '../../actions/admin';
import {connect} from 'react-redux';
import {Panel, Input, Button, Grid, Row, Col} from 'react-bootstrap';
import {Table, Column, Cell} from 'fixed-data-table-2';
import CollapseCell from './CollapseCell';
import ResponsiveFixedDataTable2 from '../../components/ResponsiveFixedDataTable2';
import _ from 'lodash';

class FaxMatchingPatientSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollToRow: null,
            collapsedRows: new Set()
        }
        this._handleCollapseClick = this._handleCollapseClick.bind(this);
        this._subRowHeightGetter = this._subRowHeightGetter.bind(this);
        this._rowExpandedGetter = this._rowExpandedGetter.bind(this);
    }

   async  _handleCollapseClick(rowIndex) {
        const {collapsedRows} = this.state;
        let shallowCopyOfCollapsedRows = new Set([...collapsedRows]);
        let scrollToRow = rowIndex;
        if (shallowCopyOfCollapsedRows.has(rowIndex)) {
            shallowCopyOfCollapsedRows.delete(rowIndex);
            scrollToRow = null
            this.props.adminActions.resetUnmatchData();
        } else {
            shallowCopyOfCollapsedRows = new Set([]);
            shallowCopyOfCollapsedRows.add(rowIndex);
            const {dataList} = this.props;
            const selectedData = dataList[rowIndex];
            // console.log(selectedData);
            this.props.adminActions.getUnmatchedData(selectedData);
        }

        this.setState({
            scrollToRow: scrollToRow,
            collapsedRows: shallowCopyOfCollapsedRows
        });
    }

    _subRowHeightGetter(index) {
        return this.state.collapsedRows.has(index) ? 200 : 0;
    }

    _rowExpandedGetter({rowIndex, width, height}) {
        if (!this.state.collapsedRows.has(rowIndex)) {
            return null;
        }

        const style = {
            height: height,
            width: width - 2,
        };

        const {ummatchedData} = this.props;

        let unmatchedRows = [];
        let previouslyUnmatchedRows = [];
        if(ummatchedData){
            unmatchedRows = _.map(ummatchedData.unmatched,(data)=>{
                    return <tr key={data.id}>
                        <td style={{padding:5,fontSize:12}}>{data.name}</td>
                        <td style={{padding:5,fontSize:12}}>{data.request_start}</td>
                        <td style={{padding:5,fontSize:12}}>{data.request_end}</td>
                    </tr>;
            });

            previouslyUnmatchedRows= _.map(ummatchedData.previouslyMatched,(data)=>{
                return <tr key={data.id}>
                    <td style={{padding:5,fontSize:12}}>{data.name}</td>
                    <td style={{padding:5,fontSize:12}}>{data.request_start}</td>
                    <td style={{padding:5,fontSize:12}}>{data.request_end}</td>
                </tr>;
            });
        }

        return (
            <div style={style}>
                <div style={
                    {
                        'background-color': 'white',
                        border: '1px solid #d3d3d3',
                        'box-sizing': 'border-box',
                        padding: '20px',
                        overflow: 'hidden',
                        width: '100%',
                        height: '100%',
                        marginTop:50,
                        backgroundColor:'rgb(237,241,244)'
                    }
                }>
                    <div style={{float:'left',width:'50%'}}>
                        <div style={{fontSize:11,fontStyle:'italic'}}>Unmatched</div>
                        <div>
                            <table>
                                {unmatchedRows}
                            </table>
                        </div>
                    </div>
                    <div style={{float:'left',width:'50%'}}>
                        <div style={{fontSize:11,fontStyle:'italic'}}>Previously Matched</div>
                        <div>
                            <table>
                                {previouslyUnmatchedRows}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onStep2Submit(){
        this.props.onStep2Submit();
    }

    render() {
        let {collapsedRows, scrollToRow} = this.state;
        const {dataList,ummatchedData} = this.props;

        return (
            <div>
                <div style={{height: 300}}>
                    <ResponsiveFixedDataTable2
                        scrollToRow={scrollToRow}
                        rowHeight={50}
                        rowsCount={dataList ? dataList.length : 0}
                        subRowHeightGetter={this._subRowHeightGetter}
                        rowExpanded={this._rowExpandedGetter}
                        width={400}
                        height={400}
                        headerHeight={50}>
                        <Column
                            cell={<CollapseCell callback={this._handleCollapseClick} collapsedRows={collapsedRows}/>}
                            fixed={true}
                            width={30}
                        />
                        <Column
                            columnKey="name"
                            header={<Cell style={{fontWeight:'600',color:'rgb(112,112,112)'}}>Name</Cell>}
                            cell={ ({rowIndex, data, columnKey, ...props}) => (
                                <Cell {...props}>
                                    <div onClick={()=>this._handleCollapseClick(rowIndex)} style={{cursor:'pointer'}}>
                                        {dataList[rowIndex].first_name + ' '+dataList[rowIndex].last_name}
                                    </div>
                                </Cell>
                            )}
                            fixed={true}
                            width={130}
                        />
                        <Column
                            columnKey="date"
                            header={<Cell style={{fontWeight:'600',color:'rgb(112,112,112)'}}>DOB</Cell>}
                            cell={ ({rowIndex, data, columnKey, ...props}) => (
                                <Cell {...props}>
                                    <div style={{cursor:'pointer'}} onClick={()=>this._handleCollapseClick(rowIndex)}>{dataList[rowIndex].date_of_birth}</div>
                                </Cell>
                            )}
                            fixed={true}
                            width={100}
                        />
                        <Column
                            columnKey="contactNumber"
                            header={<Cell style={{fontWeight:'600',color:'rgb(112,112,112)'}}>Phone</Cell>}
                            cell={ ({rowIndex, data, columnKey, ...props}) => (
                                <Cell {...props}>
                                    <div style={{cursor:'pointer'}} onClick={()=>this._handleCollapseClick(rowIndex)}>
                                        {
                                            dataList[rowIndex].mobile ?
                                            dataList[rowIndex].mobile.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") :
                                            dataList[rowIndex].phone ? dataList[rowIndex].phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
                                                : ''}
                                    </div>
                                </Cell>
                            )}
                            width={300}
                        />
                        <Column
                            columnKey="providerName"
                            header={<Cell style={{fontWeight:'600',color:'rgb(112,112,112)'}}>Provider</Cell>}
                            cell={ ({rowIndex, data, columnKey, ...props}) => (
                                <Cell {...props}>
                                    <div style={{cursor:'pointer'}} onClick={()=>this._handleCollapseClick(rowIndex)}>{dataList[rowIndex].provider.provider}</div>
                                </Cell>
                            )}
                            width={200}
                        />
                        <Column
                            columnKey="status"
                            header={<Cell style={{fontWeight:'600',color:'rgb(112,112,112)'}}>Status</Cell>}
                            cell={ ({rowIndex, data, columnKey, ...props}) => (
                                <Cell {...props}>
                                    <div style={{cursor:'pointer'}} onClick={()=>this._handleCollapseClick(rowIndex)}>{dataList[rowIndex].status_description}</div>
                                </Cell>
                            )}
                            width={200}
                        />
                    </ResponsiveFixedDataTable2>
                </div>
                <div style={{padding:10,paddingLeft:0,paddingRight:0}}>
                    <div style={{float:'left'}}>
                    <div className="bottom-button" onClick={() => this.props.onStepBack(1)}>
                        <i className="fa fa-arrow-left fa-2x" style={{color:'rgb(89,125,150)',float:'left'}}></i>
                        <div style={{padding:10,paddingTop:0,float:'left'}}>Back</div>
                    </div>
                    </div>
                    <div style={{float:'right'}}>
                        <div className="bottom-button">
                            <Button bsStyle="primary" style={{width:150}} disabled={!ummatchedData} onClick={this.onStep2Submit.bind(this)}>Next</Button>
                        </div>
                    </div>
                </div>
            </div>);
    }


}

const mapStateToProps = (state) => ({
    'dataList': state.faxMatching.patientList,
    'ummatchedData': state.faxMatching.ummatchedData
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FaxMatchingPatientSearch);
