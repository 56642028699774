/**
 * Created by synerzip on 27/02/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Input,Modal,Button} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
var LinkedStateMixin = require('react-addons-linked-state-mixin');

class InactivityCloseChartPopup extends React.Component{

    constructor(props) {
        super(props);
        this.closeChartSecondsCounter = 0;
        this.closeChartInterval = null;
        this.closeChartTimeOut = 60;
        this.checkCloseChartTimeOut = this.checkCloseChartTimeOut.bind(this);
    }

    componentDidMount() {

    }

    componentWillUnmount(){
        this.stopTimer();
    }

    startTimer(){
        if(this.closeChartInterval === null){
            this.closeChartInterval = setInterval(this.checkCloseChartTimeOut, 1000);
        }
    }

    stopTimer(){
        clearInterval(this.closeChartInterval);
        this.closeChartInterval = null;
        this.closeChartSecondsCounter = 0;
    }

    checkCloseChartTimeOut(){
        this.closeChartSecondsCounter++;
        if (this.closeChartSecondsCounter >= this.closeChartTimeOut) {
            this.stopTimer();
            this.props.onPopupHide();
            this.props.adminActions.closeChart(this.props.selectedPatient.id, 'Chart is auto closed as user is idle for 5 mins', 9999, this.props.selectedQueue, this.props.activeUser.id);
        }
    }

    onSubmit(e){
        e.preventDefault();
        this.props.onPopupHide();
        this.stopTimer();
    }
    onCancel() {
        this.props.onPopupHide();
        this.stopTimer();
    }
    render(){
        if(this.props.show){
            this.startTimer();
        }
        return (
            <Modal show={this.props.show}>
                <Modal.Header closeButton onHide={()=>{
                                this.stopTimer();
                                this.props.onPopupHide();
                            }}>
                    <Modal.Title>
                        <div style={
                            {
                            textAlign: 'center'
                            }
                        }>
                            <div>Cancel Auto Close</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{overflow:'auto',height:'auto'}}>
                        <form onSubmit={this.onSubmit.bind(this)}>
                           <label style={{padding:'15px',textAlign:'center'}}>Your account has been inactive for 5 minutes. The chart you are working on will be closed in 1 minute. Please click the OK button to continue working on the chart.</label>
                            <div style={{borderTop:'1px solid gray',height:100,textAlign:'center',paddingTop:30}}>
                                <Button bsStyle="success" type="submit" className="text-center">
                                    OK
                                </Button>

                            </div>
                        </form>
                    </div>


                </Modal.Body>

            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedPatient: state.app.selectedPatient,
    selectedQueue:state.app.selectedQueue,
    activeUser: state.admin.activeUser,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(InactivityCloseChartPopup);