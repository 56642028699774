import React from 'react';
import PatientQueue from './PatientQueue';

const ITReconcile = () =>
    <PatientQueue
        showQueueCount
        patientNameColumn
        providerDetailColumn
        timeBilledColumn
        todoItemColumn
        showFilters
        showProgramTypeFilter
        dobColumn
        chartColumn
        lastWorkedByColumn
        page
        queue="urgentITReconcile"
        endpoint="Urgent?typesToInclude=22"
        label="Urgent - IT Reconcile"
    />;

export default ITReconcile;
