import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import {Panel,Accordion,Button,Modal} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import CSPanel from '../../components/CSPanel'
import ViewLabResultTable from './ViewLabResultTable';
import LabEdit from './LabEdit';

class Labs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            currentLab: null,
            showModal: false,
            deleteLab: null
        };
    }

    componentWillMount() {
        this.props.adminActions.fetchLabs(this.props.selectedPatient.id);
        this.props.adminActions.getLabUnits();
    }

    componentWillReceiveProps(nextProps,nextState){
        if(nextProps.labDetails != this.props.labDetails) {
            var sortedLabs = _.chain(nextProps.labDetails)
                    .sortBy('lab_date')
                    .reverse()
                    .value()
            this.setState({sortedLabs: sortedLabs})
        }
    }

    launchLabEdit(lab) {
        this.setState({currentLab: lab, edit: true});
    }

    launchNewLab() {
        this.setState({edit: true});
    }

    cancelEdit() {
        this.props.showSecondaryTimer();
        this.setState({currentLab: null, edit: false});
    }

    closeModal() {
        this.props.showSecondaryTimer();
        this.setState({ showModal: false });
    }

    submitEdit(lab) {
        var newLabDetail = lab.lab_detail.map((labDetail)=> {
            var newLabItem = {}
            newLabItem['lab_name'] = labDetail['lab_name'].trim();
            newLabItem['lab_unit_id'] = Number(labDetail['lab_unit_id']);
            newLabItem['lab_value'] = labDetail['lab_value'];
            return newLabItem;
        })
        var index = this.props.labDetails.map(function(x){return x.id}).indexOf(lab.id);
        if(index === -1) {
            lab = {lab_date: lab.lab_date, lab_detail: newLabDetail}
            this.props.adminActions.createLab(this.props.selectedPatient.id, lab);
        } else {
            lab = {id: lab.id, lab_date: lab.lab_date, lab_detail: newLabDetail}
            this.props.adminActions.updateLab(this.props.selectedPatient.id, lab.id, lab);
        }

        this.setState({currentLab: null, edit: false});
    }

    launchDeleteModal(lab) {
        this.props.showSecondaryTimer();
        this.setState({showModal: true, deleteLab: lab})
    }

    deleteLab() {
        this.props.showSecondaryTimer();
        this.props.adminActions.deleteLab(this.props.selectedPatient.id, this.state.deleteLab.id);
        this.setState({deleteLab: null, showModal: false});
    }

    accordionPanelHeader(lab) {
        return (
            <div style={{height:35}}>
                <div style={{fontSize:18, textAlign:"center"}}>
                    <i className="fa fa-times" aria-hidden="true" style={{float:"left"}}onClick={this.launchDeleteModal.bind(this, lab)}></i>
                    <i className="fa fa-chevron-down labIconPadding" aria-hidden="true"></i>
                    {moment(lab.lab_date).format('MM/DD/YYYY')}
                    <i className="fa fa-pencil labIconPadding" aria-hidden="true" onClick={this.launchLabEdit.bind(this, lab)}></i>
                </div>
            </div>
        )
    }

    render(){
        return (
            <div>
                <CSPanel titleText="Labs" bodyClassName="aligncenter">
                    {!this.state.edit &&
                        <div className="col-md-12">
                            <Button
                                bsStyle="success"
                                className="aligncenter addLabButton"
                                onClick={this.launchNewLab.bind(this)}>
                                Add Lab Results
                            </Button>
                        </div>
                    }
                    {!this.state.edit && this.props.labDetails && this.props.labUnits &&
                        <Accordion className="col-md-offset-3 col-md-6">
                            {_.map(this.state.sortedLabs, (lab)=>
                                <Panel header={this.accordionPanelHeader(lab)} eventKey={lab.id} key={lab.id}>
                                    <ViewLabResultTable labUnits={this.props.labUnits} {...lab} />
                                </Panel>
                            )}
                        </Accordion>
                    }
                    {this.state.edit &&
                        <LabEdit
                            submitEdit={this.submitEdit.bind(this)}
                            cancelEdit={this.cancelEdit.bind(this)}
                            currentLab={this.state.currentLab}
                            {...this.props}
                        />
                    }
                </CSPanel>
                <Modal show={this.state.showModal} onHide={this.closeModal.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Are you sure you want to delete this lab from {this.state.deleteLab && this.state.deleteLab.lab_date ? moment.utc(this.state.deleteLab.lab_date).local().format('MM/DD/YYYY') : ""}?
                        </Modal.Title>
                        <Modal.Footer>
                            <Button id="deleteLabButton" bsStyle="success" onClick={this.deleteLab.bind(this)}>Yes</Button>
                            <Button onClick={this.closeModal.bind(this)}>Cancel</Button>
                        </Modal.Footer>
                    </Modal.Header>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    'labDetails': state.levelTwoDashboard.labDetails,
    'labUnits': state.levelTwoDashboard.labUnits,
    'userId': state.auth.id,
    'selectedPatient': state.app.selectedPatient
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(Labs)
