import React from 'react';
import PatientQueue from './PatientQueue';

const PatientServicesQueue = () =>
	<PatientQueue
		showQueueCount
		supportDetailColumn
		providerDetailColumn
		patientDetailColumn
		chartColumn
		lastWorkedByColumn
		queue="patientServices"
		endpoint="Support"
		label="Patient Services"
	/>;

export default PatientServicesQueue;
