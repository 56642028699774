/**
 * Created by synerzip on 30/01/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Panel,Input,Button,OverlayTrigger,Popover} from 'react-bootstrap';
import {Table, Column, Cell} from 'fixed-data-table';
import _ from 'lodash';
import ResponsiveFixedDataTable from '../../components/ResponsiveFixedDataTable';
import AddGAPSPlan from './AddGAPSPlan';
import moment from 'moment';
import CarePlanComp from './CarePlanComp'
import GAPSPlanAssessment from './GAPSPlanAssessment'

class GAPSPlanList extends React.Component {

    constructor(props) {
        super(props);
        this.selectedTodos = {};
        this.state = {
            columnWidths: {
                todoDate: 200,
                todoLabel: 500,
                completed: 250,
                flags:200,
            },
            showCompleteTodo:false,
            selectedCarePlan:null
        };

        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    }
    componentDidMount() {
        this.props.adminActions.getCarePlanTypes();
        this.props.adminActions.getCarePlans(this.props.selectedPatient.id)
        this.props.adminActions.getGAPSHistory(this.props.selectedPatient.id)
        //ccm/v1/gaps/careplantypes
    }

    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({columnWidths}) => ({
            columnWidths: {
                ...columnWidths,
                [columnKey]: newColumnWidth,
            }
        }));
    }

    onPopupHide() {
        this.props.showSecondaryTimer();
        this.setState({show: false});
        this.setState({selectedCarePlan:null});
    }

    onCompletedPopupHide() {
        this.props.showSecondaryTimer();
        this.setState({showCompleteTodo: false});
    }

    markCompleted(){
        console.log(this.selectedTodos);
        this.props.adminActions.markComplete(this.props.selectedPatient.id,this.selectedTodos);
        this.selectedTodos = {};
    }

    onCompleted(data, e) {
      const { activeUser } = this.props;
      const ce_flag = activeUser && activeUser.center && activeUser.center.ce_flag;
      const isRetSpec = activeUser && activeUser.center && activeUser.center.support_flag;
        this.props.showSecondaryTimer();
        //console.log(data);
       // console.log(e.target.checked);
       // if (e.target.checked) {
       //     this.selectedTodos[data.id] = true;
       //
       // } else {
       //     delete this.selectedTodos[data.id];
       // }
       //
       // this.setState({refresh: true});
        var dataObj = {};
        dataObj[data.id] = true;

        const urgent = data.urgent;

        if(urgent && activeUser && activeUser.center && !ce_flag && !isRetSpec) {
            this.setState({
                patientId: this.props.selectedPatient.id,
                selectedTodoData: dataObj,
                showCompleteTodo: true
            });
        }else{
            this.props.adminActions.markComplete(this.props.selectedPatient.id,dataObj);
        }
    }

    onEditTodo(data,e){
        this.props.showSecondaryTimer();
        this.currentTodoData = data;
        this.setState({
            show:true,
            editTodo:true
        })
    }

    clearCurrentTodoData(){
        this.currentTodoData = null;
        this.setState({
            show:false,
            editTodo:false
        })
    }

    todoPrefix( todo ) {
        if ( todo.urgent ) {
            return `${todo.urgent_todo_type_name} - `
        }
    }

    onReopen(data){
        this.props.adminActions.markUnComplete(this.props.selectedPatient.id,data.id);
    }

    handleAddTodo() {
        this.props.showSecondaryTimer();
        this.setState({ show:true });
    }

    onPlanSelected(data){
        this.props.adminActions.getGAPSEngagements(data.gaps_care_plan_id)
            .then(result => {
                this.props.adminActions.getGAPSAssessmentAnswers(data.gaps_care_plan_id)
                this.props.adminActions.getCarePlanQuestions(data.gaps_care_plan_type_page_short_name);
                this.setState({selectedCarePlan:data,engagements:result});
            })

    }

    onCloseAssessment(){
        this.setState({selectedCarePlan:null});
    }

	popoverHoverFocus(description) {
        if(!description){
            return <div/>
        }
		return (
			<Popover style={{wordWrap:'normal', whiteSpace: 'pre-wrap', wordBreak: 'normal'}} id="popover-trigger-hover-focus">
				<span>{description}</span>
			</Popover>
		)
	}

    render() {

        const header =
            <div style={{height:35}}>
                <div style={{fontSize:18,float:'left'}}>GAPS</div>
                <div style={{float:'right',marginRight:10}}>
                    <Button bsStyle="primary"
                                id="addGAPS"
                                onClick={ this.handleAddTodo.bind( this )}>
                    New GAPS Plan
                </Button></div>
            </div>;

        const historyHeader =
            <div style={{height:35}}>
                <div style={{fontSize:18,float:'left'}}>GAPS History</div>
                <div style={{float:'right',marginRight:10,display:'flex',alignItems:'center'}}>
                    <div style={{display:'flex',alignItems:'center'}}>
                    <i style={{
                    fontSize: 26,
                    color: 'gray',
                    paddingRight: 5}} className="fa fa-minus-circle" aria-hidden="true"></i>
                        No GAPS need
                    </div>
                    <div style={{display:'flex',alignItems:'center',paddingLeft:15}}>
                    <i style={{
                        fontSize: 26,
                        color: 'red',
                        paddingRight: 5}} className="fa fa-flag" aria-hidden="true"></i>
                        GAPS action needed
                    </div>
                </div>
            </div>;

        const {columnWidths} = this.state;
        const {gapsPlanList,gapsHistory} = this.props;

        const gapsPlanListData = _.chain(gapsPlanList).values().sortBy('sortIndex').value();
        return (
            <div>
                <Panel header={historyHeader} bsClass={"gaps"}>
                    {
                        gapsHistory && gapsHistory.length == 0 ?
                            <div style={{padding:15,display:'flex',justifyContent:'center',alignItems:'center'}}
                                 id={'gapsHistroy'} data-config={gapsPlanListData.length}>
                                No Gaps History Available
                            </div>
                            :
                            <div style={{height:300}} id={'gapsHistoryList'}>
                                {
                                    _.map(gapsHistory,(data) => {
                                        return <div key={data.id} style={{
                                            display:'flex',
                                            justifyContent:'space-between',
                                            backgroundColor:'#FFFFFF',
                                            border:'',
                                            alignItems:'center',
                                            cursor:'pointer',
                                            fontWeight:400,
                                            marginBottom:15,
                                            padding:15}}>
                                            <div style={{display:'flex',flex:1}}>Created: {moment(data.alarm_ts).format('MM-DD-YYYY')}</div>
                                            <div style={{display:'flex',flex:1,alignItems:'center'}}>
                                                Colorectal Screening: {
                                                data.colorectal_screening ? <i style={{
                                                    fontSize: 26,
                                                    color: 'red',
                                                    paddingLeft: 5}} className="fa fa-flag" aria-hidden="true"></i> :
                                                    <i style={{
                                                        fontSize: 26,
                                                        color: 'gray',
                                                        paddingLeft: 5}} className="fa fa-minus-circle" aria-hidden="true"></i>
                                            }</div>
                                            <div style={{display:'flex',flex:1,alignItems:'center'}}>Breast Cancer Screening: {
                                                data.breast_cancer_screening ? <i style={{
                                                        fontSize: 26,
                                                        color: 'red',
                                                        paddingLeft: 5}} className="fa fa-flag" aria-hidden="true"></i> :
                                                    <i style={{
                                                        fontSize: 26,
                                                        color: 'gray',
                                                        paddingLeft: 5}} className="fa fa-minus-circle" aria-hidden="true"></i>
                                            }</div>
                                            <div style={{display:'flex',alignItems:'center'}}>Diabetes Eye Exam: {
                                                data.diabetes_eye_exam ? <i style={{
                                                        fontSize: 26,
                                                        color: 'red',
                                                        paddingLeft: 5}} className="fa fa-flag" aria-hidden="true"></i> :
                                                    <i style={{
                                                        fontSize: 26,
                                                        color: 'gray',
                                                        paddingLeft: 5}} className="fa fa-minus-circle" aria-hidden="true"></i>
                                            }</div>
                                        </div>
                                    })
                                }
                            </div>
                    }
                </Panel>
                <Panel header={header}>
                    {
                        gapsPlanListData.length == 0 ?
                            <div style={{padding:15,display:'flex',justifyContent:'center',alignItems:'center'}}
                                 id={'gapsList'} data-config={gapsPlanListData.length}>
                                No Gaps in Care Plans
                            </div>
                            :
                            <div style={{height:300}} id={'gapsList'} data-config={gapsPlanListData.length}>
                                {
                                    _.map(gapsPlanListData,(data) => {
                                        return <CarePlanComp key={data.id} carePlan={data}
                                                             onPlanSelected={this.onPlanSelected.bind(this)}/>
                                    })
                                }
                            </div>
                    }

                </Panel>



                <AddGAPSPlan showSecondaryTimer={this.props.showSecondaryTimer}
                              onPopupHide={this.onPopupHide.bind(this)}
                              onCloseEdit={this.clearCurrentTodoData.bind(this)}
                              show={this.state.show}
                              editMode={this.state.editTodo}
                              onOptionSelect={this.props.onOptionSelect}/>

                <GAPSPlanAssessment carePlan={this.state.selectedCarePlan}
                                    engagements={this.state.engagements}
                                    showSecondaryTimer={this.props.showSecondaryTimer}
                                    onPopupHide={this.onPopupHide.bind(this)}
                                    onCloseAssessment={this.onCloseAssessment.bind(this)}
                                    show={this.state.selectedCarePlan && true}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    'gapsPlanList': state.app.patientCarePlans,
    'userId': state.auth.id,
    'selectedPatient':state.app.selectedPatient,
    'activeUser': state.admin.activeUser,
    "gapsHistory":state.admin.gapsHistory
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(GAPSPlanList);
