/**
 * Created by synerzip on 08/12/15.
 */
import React from 'react';

class MaskComp extends React.Component {
    constructor(props){
        super(props);
    }
    render() {

        if (this.props.show) {
            return ( <div className='masking'>
                <div className="three-bounce">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div>);
        } else {
            return (<div/>);
        }


    }
}


export default MaskComp;