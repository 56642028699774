import React from 'react';

class TODOFlagCell extends React.Component {
    render() {
        var {urgent,id} = this.props;
        var statusImg = <span>  </span>;
        if (urgent == 1) {
            statusImg = <span style={{color:'red'}}><i className="fa fa-exclamation-circle"></i></span>;
        }
        return (
            <div style={{textAlign:'center'}} id={id}>
                <span>{this.props.dateString}</span>{statusImg}
            </div>
        );
    }
}

export default TODOFlagCell;
