import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentQueueRefill = () =>
    <PatientQueue
        showQueueCount
        patientNameColumn
        providerDetailColumn
        timeBilledColumn
        todoItemColumn
        showFilters
        showProgramTypeFilter
        showScheduleCallFilter
        statusColumn
        chartColumn
        lastWorkedByColumn
        page
        queue="urgentRefill"
        endpoint="Urgent?typesToInclude=8"
        label="Urgent - Refill"
    />;

export default UrgentQueueRefill;
