import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as appActionCreator from '../../actions/app';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import {Panel,Input,Button,Grid, Row, Col} from 'react-bootstrap';
import PageHeader from '../../components/PageHeader';
import Stepper from 'react-stepper-horizontal';
import moment from "moment";
import FaxMatchingPatientSearch from './FaxMatchingPatientSearch';
import PatientSearchStep1 from './PatientSearchStep1';
import MatchRecordScreen from './MatchRecordScreen';
import FaxDeleteConfirmation from './FaxDeleteConfirmation';

const searchIcon = require('../../images/search_icon.png');
const userIcon =require( '../../images/user.png');
const  matchRecord = require('../../images/matchRecord.png');

class FaxMatchingScreen extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            activeStep: 0
        }
        this.onNextRecord = this.onNextRecord.bind(this);
        this.onFixRecord = this.onFixRecord.bind(this);
        this.onArchiveRecord = this.onArchiveRecord.bind(this);
        this.resetSteps = this.resetSteps.bind(this);
        this.previewFile = this.previewFile.bind(this);
        this.onMatchRecord = this.onMatchRecord.bind(this);
        this.onReload = this.onReload.bind(this);
        this.onSearchRecord = this.onSearchRecord.bind(this);
    }

    componentDidMount() {

        this.props.adminActions.getInboundNextMedicalRecord(null,this.state.searchText);
    }
    onReload() {
        this.props.adminActions.getInboundNextMedicalRecord(null,this.state.searchText);
    }

    resetSteps(){
        this.setState({activeStep:0});
        this.props.adminActions.resetUnmatchData();
    }

    onNextRecord(e) {
        e.stopPropagation();
        const {queueRecordWrapper} = this.props;
        this.props.adminActions.getInboundNextMedicalRecord(queueRecordWrapper.record,this.state.searchText);
        this.resetSteps();

    }

    onSearchRecord(e) {
        e.stopPropagation();
        this.props.adminActions.getInboundNextMedicalRecord(null,this.state.searchText);
        this.resetSteps();

    }

    onClear(e) {
        e.stopPropagation();
        this.setState({searchText:''})
        const {queueRecordWrapper} = this.props;
        this.props.adminActions.getInboundNextMedicalRecord(null,'');
        this.resetSteps();

    }

    previewFile(e){
        e.stopPropagation();
        const {queueRecordWrapper} = this.props;
        this.props.adminActions.openRecordFile(queueRecordWrapper.record);
    }

    onFixRecord() {
        const {queueRecordWrapper} = this.props;
        if(!queueRecordWrapper){
            return;
        }
        this.props.adminActions.fixRecord(queueRecordWrapper.record);
        this.resetSteps();
    }

    onArchiveRecord() {
        const {queueRecordWrapper} = this.props;
        this.setState({
            showDeleteConfirmation: true,
            recordIdToBeDeleted: queueRecordWrapper ? queueRecordWrapper.record.id : 0
        });

    }

    handleDelete = () => {
        const {queueRecordWrapper} = this.props;
        this.props.adminActions.archiveRecord(queueRecordWrapper.record);
        this.setState({ showDeleteConfirmation: false });
        this.resetSteps();
    }

    onStep1Submit(){
        this.setState({activeStep:1});
    }

    onStep2Submit(){
        this.setState({activeStep:2});
    }

    onStepBack(currentStep){
        this.setState({activeStep:currentStep-1});
        if(currentStep-1 == 0) {
            this.props.adminActions.resetUnmatchData();
        }
    }

    onMatchRecord(data,patientId){
        const {queueRecordWrapper} = this.props;
        this.props.adminActions.matchRecordV2(data,patientId,queueRecordWrapper.record);
        this.resetSteps();
    }

    onSearchInboundNumber(e) {
        this.setState({
            searchText:e.target.value
        })
    }

    render(){
        const {queueRecordWrapper,isFetching} = this.props;
        let title = '';
        if(this.state.activeStep == 0){
            title = 'Patient Search';
        }else if(this.state.activeStep == 1){
            title = 'Select Patient';

        }else if(this.state.activeStep == 2){
            title = 'Match Record';

        }else if(this.state.activeStep == 3){
            title = 'Patient Search';
        }

        const recordAvailable  = queueRecordWrapper && queueRecordWrapper.record;
        let queueRecord = null;
        let totalRecord =0;
        if(recordAvailable){
            queueRecord = queueRecordWrapper.record;
            totalRecord = queueRecordWrapper.total_records;
        }
        return <div>
                <PageHeader>
                    Fax Matching - {totalRecord}
                </PageHeader>
            <div className="consent-form-left-panel" style={{width:'100%'}}>
                <Grid style={{width:'100%'}}>
                    <Row>
                        <Col xs={3}>
                            <div style={{
                                display:'flex',
                                width:'90%',
                                alignItems: 'center',
                                padding: '15px 0'}}>
                                <Input type="text" style={{height:'36px !important',flex:1, margin:0}} 
                                placeholder="Search by Inbound Number"
                                onChange={this.onSearchInboundNumber.bind(this)}
                                value={this.state.searchText}/>
                                <div className="bottom-button"
                                            onClick={this.onSearchRecord}
                                            style={{padding:'2px 10px', background:'rgb(89,125,150)', 
                                            borderRadius:5,
                                            margin:0,marginLeft:10}}>
                                    <i className="fa fa-search" style={{color:'#FFFFFF'}}></i>
                                </div>
                                <div className="bottom-button"
                                            onClick={(e) => {this.onClear(e);}}
                                            style={{padding:'2px 10px', 
                                            background:'gray', borderRadius:5,
                                            margin:0,marginLeft:10}}>
                                    <i className="fa fa-times" style={{color:'#FFFFFF'}}></i>
                                </div>
                                
                            </div>
                       
                            <Panel className="record-box" style={{width:'90%'}}>
                                <div>
                                    <div className="header-title"
                                         style={{color:'#337ab7',fontSize:22,fontWeight:600}}>Faxed Record</div>
                                    <div className="fax-record-body" onClick={this.previewFile}>
                                        {
                                            recordAvailable &&  <div className="bottom-button"
                                                                     onClick={this.onNextRecord}
                                                                     style={{width:90,position:'absolute',top:105,right:-95}}>
                                                <i className="fa fa-arrow-right fa-2x" style={{color:'rgb(89,125,150)'}}></i>
                                            </div>
                                        }
                                        {
                                            recordAvailable ?
                                                <div style={{display:'flex',flexDirection:'column',alignItems:'center',flex:1}}>
                                                    <div style={{display:'flex',flexDirection:'column',alignItems:'center',flex:1}}>
                                                        <div style={{textAlign:'center',paddingBottom:15}}>
                                                            <div style={{color:'#337ab7'}}>
                                                                Inbound Fax Number
                                                            </div>
                                                            <div>
                                                                {queueRecord && queueRecord.caller_id ? queueRecord.caller_id.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") : '-'}
                                                            </div>
                                                        </div>
                                                        <div style={{textAlign:'center',paddingBottom:15}}>
                                                            <div style={{color:'#337ab7'}}>
                                                                Fax Received
                                                            </div>
                                                            <div>
                                                                {queueRecord && queueRecord.create_ts ? moment.utc(queueRecord.create_ts).local().format('MM-DD-YYYY hh:mm A') : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="fax-record-view-link" style={{color:'#337ab7'}} onClick={this.previewFile}>
                                                        Click to Preview
                                                    </div>
                                                </div>
                                                : <div style={{textAlign:'center'}}>
                                                    <Button bsStyle="primary"  style={{width:180,whiteSpace:'pre-wrap'}} onClick={this.onReload}>
                                                        No Record Available in Queue, Click to reload
                                                    </Button>
                                                </div>
                                        }

                                    </div>
                                    <div className="bottom-button-bar" style={{textAlign:'center',paddingTop:30}}>
                                        {
                                            recordAvailable && <div style={{display:'flex',justifyContent:'space-between'}}>
                                                <Button bsStyle="primary"  style={{whiteSpace:'pre-wrap',width:113}} onClick={this.onFixRecord}>
                                                    Fix Record <i className="fa fa-cogs" style={{color:'#FFFFFF'}}></i>
                                                </Button>

                                                <Button bsStyle="danger"  style={{whiteSpace:'pre-wrap',width:113}} onClick={this.onArchiveRecord}>
                                                    Trash Fax <i className="fa fa-archive" style={{color:'#FFFFFF'}}></i>
                                                </Button>
                                            </div>
                                        }


                                    </div>
                                </div>
                            </Panel>
                        </Col>
                        <Col xs={9}>
                            <Panel style={{height:550}}>
                                <div>
                                     <div className="header-title"></div>
                                     <div style={{float:'left',width:'30%'}}>
                                         <h2 style={{color:'#337ab7'}}>
                                             {title}
                                         </h2>
                                     </div>
                                     <div style={{float:'right',width:685}}>
                                        <Stepper steps={ [
                                         {icon: searchIcon},
                                            {icon: userIcon},
                                            {icon: matchRecord}] }
                                                 activeStep={ this.state.activeStep } size={45}
                                                 circleFontSize={2}
                                                activeColor={'#337ab7'}
                                                completeColor={'#337ab7'}/>

                                     </div>
                                    <div style={{clear:'both'}}>
                                        {
                                            this.state.activeStep == 0 ?
                                                <PatientSearchStep1 onStep1Submit={this.onStep1Submit.bind(this)}/>
                                            : this.state.activeStep == 1 ?
                                               <FaxMatchingPatientSearch onStep2Submit={this.onStep2Submit.bind(this)}
                                                                         onStepBack={this.onStepBack.bind(this)}/>
                                            : this.state.activeStep == 2 ?
                                                    <MatchRecordScreen onStep3Submit={()=>{}}
                                                                       onStepBack={this.onStepBack.bind(this)}
                                                                       onMatchRecord={this.onMatchRecord}/>
                                            :      <div>Step 4</div>
                                        }
                                    </div>
                                </div>
                            </Panel>
                        </Col>
                    </Row>
                </Grid>
            </div>
            <FaxDeleteConfirmation
                onDeleteConfirm={this.handleDelete}
                show={this.state.showDeleteConfirmation}
                onDeleteCancel={() => this.setState({ showDeleteConfirmation: false })}
            />
            </div>;
    }

}


const mapStateToProps = (state) => ({
    'inboundMedicalForms': state.consentForms.inboundMedicalForms,
    'medicalFormDocument': state.consentForms.medicalFormDocument,
    'medicalFormDocumentUrl': state.consentForms.medicalFormDocumentUrl,
    'selectedDoc':state.consentForms.selectedDoc,
    'userId': state.auth.id,
    queueRecordWrapper: state.faxMatching.queueRecord,
    isFetching: state.app.isFetching
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    appActions:bindActionCreators(appActionCreator,dispatch),
    commonActions:bindActionCreators(commonActionCreator,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FaxMatchingScreen);
