import {createReducer} from '../utils';
import CONSTANTS from '../constants';
import {pushState} from 'redux-router';
import jwtDecode from 'jwt-decode';

const {LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGIN_USER_FAILURE, LOGOUT_USER,USER_REQUEST_COMPLETE, USER_REQUEST}  = CONSTANTS;
const initialState = {
    "id": null,
    "username": null,
    "name": null,
    "changePassword": null,
    "roles": null,
    "isAuthenticating": false,
    "isAuthenticated": false,
};

export default createReducer(initialState, {
    [USER_REQUEST]: state => {
        return Object.assign({}, state, {
            'isAuthenticating': true
        });
    },
    [USER_REQUEST_COMPLETE]: state => {
        return Object.assign({}, state, {
            'isAuthenticating': false
        });
    },
    [LOGIN_USER_REQUEST]: (state, payload) => {
        return Object.assign({}, state, {
            'isAuthenticating': true,
            'authenticationStatus':null,
            'statusText': null
        });
    },
    [LOGIN_USER_SUCCESS]: (state, payload) => {
        return Object.assign({}, state, {
            'isAuthenticating': false,
            'isAuthenticated': true,
            "id": payload.token.id,
            "username": payload.token.username,
            "name": payload.token.name,
            "changePassword": payload.token.changePassword,
            "roles": payload.token.roles,
            'token': payload.token.token,
            "user_profiles":payload.token.user_profiles
        });

    },
    [LOGIN_USER_FAILURE]: (state, payload) => {
        return Object.assign({}, state, {
            'isAuthenticating': false,
            'isAuthenticated': false,
            "id": null,
            "username": null,
            "name": null,
            "changePassword": null,
            "roles": null,
            'token': null,
        });
    },
    [LOGOUT_USER]: (state, payload) => {
        return Object.assign({}, state, {
            'isAuthenticating': false,
            'isAuthenticated': false,
            "id": null,
            "username": null,
            "name": null,
            "changePassword": null,
            "roles": null
        });
    }
});
