import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import {Input,Modal,Button,FieldGroup} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
var LinkedStateMixin = require('react-addons-linked-state-mixin');

class AddGAPSPlan extends React.Component {

    constructor(props) {
        super(props);
        const {activeUser} = props;
        this.state = {
            selectedCarePlanType:'-1'
        }
    }

    showErrorMessage(title,message){
        this.props.commonActions.showToast({
            status: 'error',
            title: title,
            message: message
        });
        var interval = setInterval(()=> {
            clearInterval(interval);
            this.props.commonActions.hideToast();
        }, 2);
    }
    validateAndSubmitData(){
        const {selectedPatient} = this.props;
        const {selectedCarePlanType} = this.state;
        this.props.adminActions.createCarePlan(selectedPatient.id,selectedCarePlanType)
        this.onCancel();
    }
    onCarePlanTypeChanged(e){
        this.setState({
            selectedCarePlanType:e.target.value
        })
    }
    onSubmit(e) {
        e.preventDefault();
        this.validateAndSubmitData();

    }
    onCancel() {
        this.props.showSecondaryTimer();
        this.setState({});
        this.props.onCloseEdit();
        this.props.onPopupHide();
    }
    render() {
        const {activeUser} = this.props;
        const isRPMUser = activeUser && activeUser.center && activeUser.center.rpm_flag;
        const actionText = this.props.editMode ? 'Edit' : 'Choose GAPS Plan Type';
        return (
            <Modal show={this.props.show}>
                <Modal.Header closeButton onHide={()=>{
                    this.props.onPopupHide();
                }}>
                    <Modal.Title>
                        <div style={
                            {
                                textAlign: 'center'
                            }
                        }>
                            <div>{ actionText }</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="clearfix">
                        <form noValidate
                              ref={(el) => this.craeteCarePlanForm = el}>
                            <div style={{margin:15}}>
                                <label className="control-label">Choose GAPS Plan Type</label>
                                <select value={this.state.selectedCarePlanType} style={{ padding: '6px 12px' }} className="form-control"
                                    onChange={this.onCarePlanTypeChanged.bind(this)}>
                                    <option value="-1" selected disabled>Select an option</option>
                                    {
                                        _.map(this.props.carePlanTypes,(carePlan) => {
                                            return <option key={carePlan.id} value={carePlan.id}>
                                                {carePlan.name}
                                            </option>
                                        })
                                    }

                                </select>
                            </div>

                        </form>
                        <div style={{textAlign:'center',paddingTop:10}}>
                            <Button bsStyle="danger" onClick={this.onCancel.bind(this)} style={{float:'left'}}>
                                Cancel
                            </Button>
                            <Button disabled={this.state.selectedCarePlanType == '-1'} onClick={this.onSubmit.bind(this)} bsStyle="success"  style={{float:'right'}}>
                                Save
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

}

reactMixin(AddGAPSPlan.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    'activeUser': state.admin.activeUser,
    'user': state.auth.user,
    'selectedPatient':state.app.selectedPatient,
    'carePlanTypes': state.admin.carePlanTypes,
    'patient': state.app.selectedPatient,
    'selectedPatientPharmacyOptions':state.app.selectedPatientPharmacyOptions,
    'patientCcmProviders': state.app.patientCcmProviders,
    'selectedPatientChartspanProviders':state.app.selectedPatientChartspanProviders
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddGAPSPlan);
