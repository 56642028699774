/**
 * Created by synerzip on 10/01/16.
 */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
var ReactCSSTransitionGroup = require('react-addons-css-transition-group');
import {Input,Modal,Button} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';

class MessageReplyPopup extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            subject:null,
            messageBody:null
        }
    }

    componentWillReceiveProps(nextProps,nextState){
        if(nextProps.type == 'reply-to'){
            var quote = '\r\r-------------------------------------\rOriginal message:\r"' +
                nextProps.inputData.replyTo.messageBody + '"';
            this.setState({subject:nextProps.inputData.replyTo.subject,
                messageBody:quote,selectedContact:nextProps.inputData.replyTo.contact_id});
        }else if(nextProps.type == 'inbox-new-message'){
            this.setState({
                subject:null,
                messageBody:null,
                selectedContact:null
            });
        }
    }

    onSendClick(e) {
        e.preventDefault();

        if(this.props && this.props.type == 'contacts-new-message'){
            var selectedContacts = _.keys(this.props.inputData.selectedContacts);
            if(selectedContacts.length == 0) {
                this.props.sendMessage({
                    contact_id: this.state.selectedContact,
                    subject: this.state.subject,
                    body: this.state.messageBody
                });
            }else{
                this.props.sendMessage({
                    contact_ids: selectedContacts,
                    subject: this.state.subject,
                    body: this.state.messageBody
                });
            }
        }else {
            this.props.sendMessage({
                contact_id: this.state.selectedContact,
                subject: this.state.subject,
                body: this.state.messageBody
            });
        }
    }

    onToSelected(e){
        this.setState({selectedContact:e.target.value});
    }
    onContactPersonSelected(e){
        this.setState({selectedContactPerson:e.target.value});
    }
    onSubjectChange(e){
        this.setState({subject:e.target.value});
    }

    onBodyChange(e){
        this.setState({messageBody:e.target.value});
    }

    render() {
        var to = null;
        var contactPerson = null;
        if(this.props.type == 'reply-to'){
            to = <strong>{this.props.inputData.replyTo.acct_name}</strong>;
        }else if(this.props.type == 'inbox-new-message'){
            var optionList = [];
            var uniquePatients = _.uniq(_.values(this.props.inputData.contacts),'id');
            uniquePatients = _.sortBy(uniquePatients, 'acct_name');
            optionList = _.map(uniquePatients,(contact)=>{
                var dateString = moment.utc(contact.birth_date, 'x').format("MM/DD/YYYY");
                var birth_date = "(" + dateString + ")";
               return <option className="replyPatients" key={contact.id} value={contact.id}>
                   {contact.acct_name + ' '+ birth_date}
               </option>
            });
            to =<select id="modal-message-recipient"
                        className="modal-message-select" ref="meaningfulUse" onChange={this.onToSelected.bind(this)}>
                <option value="" disabled selected>Patient:</option>
                {/*<option className="replyPatients" style={{fontWeight:'bold'}} key={-1} value={-1}>
                    {'All Patients (Will send to All Patients)'}</option>*/}
                {optionList}
            </select>;
        }else if(this.props.type == 'sent-new-message'){
            var optionList = [];
            var uniquePatients = _.uniq(_.values(this.props.inputData.contacts),'id');
            uniquePatients = _.sortBy(uniquePatients, 'acct_name');
            optionList = _.map(uniquePatients,(contact)=>{
                var dateString = moment.utc(contact.birth_date, 'x').format("MM/DD/YYYY");
                var birth_date = "(" + dateString + ")";
                return <option className="replyPatients" key={contact.id} value={contact.id}>
                    {contact.acct_name + ' '+ birth_date}
                </option>
            });
            to =<select id="modal-message-recipient"
                        className="modal-message-select" ref="meaningfulUse" onChange={this.onToSelected.bind(this)}>
                <option value="" disabled selected>Patient:</option>
                {optionList}
            </select>;

            contactPerson = <select id="modal-message-recipient"
                                    className="modal-message-select" ref="meaningfulUse" onChange={this.onContactPersonSelected.bind(this)}>
                <option value="" disabled selected>Contact Person:</option>
                {optionList}
            </select>;
        }else if(this.props.type == 'contacts-new-message'){
            var optionList = [];
            var uniquePatients = _.uniq(_.values(this.props.inputData.contacts),'id');
            uniquePatients = _.sortBy(uniquePatients, 'acct_name');
            var selectedContacts = _.keys(this.props.inputData.selectedContacts);
            if(selectedContacts.length == 0) {
                optionList = _.map(uniquePatients, (contact)=> {
                    var dateString = moment.utc(contact.birth_date, 'x').format("MM/DD/YYYY");
                    var birth_date = "(" + dateString + ")";

                    return <option className="replyPatients" key={contact.id} value={contact.id}>
                        {contact.acct_name + ' ' + birth_date}
                    </option>;
                });
                to = <select id="modal-message-recipient"
                             className="modal-message-select" ref="meaningfulUse"
                             onChange={this.onToSelected.bind(this)}>
                    <option value="" disabled selected>Patient:</option>
                    {optionList}
                </select>;
            }else{
                var selectedContactList =  _.map(selectedContacts,(contactKey)=>{
                    var contact = this.props.inputData.contacts[contactKey];
                    var dateString = moment.utc(contact.birth_date, 'x').format("MM/DD/YYYY");
                    var birth_date = "(" + dateString + ")";
                    return <div>{contact.acct_name + ' ' + birth_date}</div>;
                });

                to = <div style={{marginTop:'none',listStyleType: 'none',paddingLeft:0,maxHeight:'60px',overflowY:'auto'}}>{selectedContactList}</div>;

            }

        }
        return (
            <Modal show={this.props.show}>
                <Modal.Header closeButton onHide={()=>{
                                this.props.onPopupHide();
                            }}>
                    <Modal.Title>
                        <div style={
                            {
                            padding: '10px',
                            color: '#FFFFFF'
                            }
                        }
                             className="green-tab">{this.props.title}</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-content">
                        <form onSubmit={this.onSendClick.bind(this)}>
                            <div className="modal-body">
                                <div className="modal-message-form-wrapper">
                                    <table className="modal-message-table">
                                        <tr>
                                            <td>To:</td>
                                            <td>
                                                <div className="modal-message-form-recipient">
                                                    <span id="modal-message-reply-recipient" data-contact-id=""></span>

                                                    {to} {contactPerson}

                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>From:</td>
                                            <td>
                                                <div className="modal-message-form-recipient">
                                                    <strong>{this.props.inputData ? this.props.inputData.from : ''}</strong>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Subject:</td>
                                            <td>
                                                <div className="modal-message-form-recipient">
                                                    <input type="text" id="modal-message-subject"
                                                           className="modal-message-input" placeholder="Enter title"
                                                           value={this.state.subject}
                                                           onChange={this.onSubjectChange.bind(this)}
                                                           required/>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                    <hr className="dark-grey-line"/>
                                    <div>&nbsp;&nbsp;Message:</div>
                                    <div className="modal-message-form-body">
                                        <textarea id="modal-message-textarea"
                                                  rows="11"
                                                  value={this.state.messageBody}
                                                  onChange={this.onBodyChange.bind(this)}
                                                  required/></div>
                                    <div className="modal-footer">
                                        <button className="btn btn-primary cs-button-blue float-right-no-margin" type="submit" >SEND</button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </Modal.Body>

            </Modal>
        );
    }
}

export default MessageReplyPopup;

