import React from 'react';
import moment from 'moment';

const LastWorkedCell = ({ data: { last_worked_on_ts, last_worked_by } }) =>
    <div>
        <div>
            {last_worked_by}
        </div>
        <div>
            {last_worked_by && last_worked_on_ts
                ? moment.utc(last_worked_on_ts).local().format('hh:mm a, MM/DD/YYYY')
                : <div style={{ textAlign: 'center' }}>-</div>
            }
        </div>
    </div>;

export default LastWorkedCell;
