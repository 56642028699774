import React from 'react';
import * as adminActionCreator from '../../actions/admin';
import * as appActionCreator from '../../actions/app';
import * as notificationsActionCreator from '../../actions/notifications';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Panel, Table, Button } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import RequestRecordTable from './RequestRecordTable';
import RequestRecordForm from './RequestRecordForm';
import RequestRecordPreview from './RequestRecordPreview';
import { reduxForm, getValues } from 'redux-form';

class RequestRecordContainer extends React.Component {

	constructor() {
		super();
		this.state = {
			showRequestRecord: false,
			showRequestPreview: false
		}
		this.handleShowRecord = this.handleShowRecord.bind(this);
		this.handleShowPreview = this.handleShowPreview.bind(this);
		this.validateForm = this.validateForm.bind(this);
		this.handleSubmitRequest = this.handleSubmitRequest.bind(this);
	}

	componentDidMount() {
		this.props.adminActions.fetchRequestHistory( this.props.app.selectedPatient );
		this.props.adminActions.fetchProviderList( this.props.app.selectedPatient );
	}

	handleShowRecord() {
		this.setState({
			showRequestRecord: !this.state.showRequestRecord
		});
	}

	handleShowPreview(e, stuff) {
		e.preventDefault()
		let formData = Object.assign({}, getValues(this.props.formData.requestRecord), getValues(this.props.formData.extendedRequestForm));
		let errors = this.validateForm(formData);
		if (this.objectContainsKeys(errors)) {
			this.props.adminActions.invalidRequest( errors );
		} else {
			this.props.adminActions.validRequest( formData );
			this.setState({
				showRequestRecord: false,
				showRequestPreview: true
				
			});
		}
	}	

	handleSubmitRequest() {
		let formData = this.props.levelTwoDashboard.requestRecord;
		let selectedPatient = this.props.app.selectedPatient;
		let errors = this.validateForm(formData);
		let provider = _.find(this.props.admin.providerList, { name: formData.doctorName });
		if (!this.objectContainsKeys(errors)) {
			this.props.adminActions.submitRequestRecord( formData, selectedPatient, provider.id );
			this.setState({
				showRequestPreview: false
			});
		} else {
			console.log(errors);
		}
	}

	validateForm(values = {}) {
		const errors = {};
		if (!values.doctorName) {
			errors.doctorName = 'Required';
		}
		if (!values.dateRangeBegin) {
			errors.dateRangeBegin = 'Required';
		} else if (!this.isValidDate(values.dateRangeBegin)) {
			errors.dateRangeBegin = 'Please provide a date in the following format: MM/DD/YYYY';
		} else if ( new Date(values.dateRangeBegin) > new Date() ) {
			errors.dateRangeBegin = 'Date cannot be in the future.';
		}
		if (!values.dateRangeEnd) {
			errors.dateRangeEnd = 'Required';
		} else if (!this.isValidDate(values.dateRangeEnd)) {
			errors.dateRangeEnd = 'Please provide a date in the following format: MM/DD/YYYY';
		} else if (new Date(values.dateRangeEnd) > new Date()) {
			errors.dateRangeEnd = 'Date cannot be in the future.';
		}
		this.setState({
			errors
		});
		return errors;
	}

	objectContainsKeys(obj) {
		for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
				return true;
			}
		}
		return false;
	} 

	phoneNumber(inputtxt) {
	  var phoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
	  if(inputtxt.match(phoneNum)) {
	    return true;
	  } else {
	    return false;
	  }
	}

	isValidDate(dateString) {
	    // First check for the pattern
	    if(!/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
	        return false;
	    }

	    // Parse the date parts to integers
	    var parts = dateString.split("-");
	    var day = parseInt(parts[1], 10);
	    var month = parseInt(parts[0], 10);
	    var year = parseInt(parts[2], 10);

	    // Check the ranges of month and year
	    if(year < 1000 || year > 3000 || month == 0 || month > 12)
	        return false;

	    var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

	    // Adjust for leap years
	    if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
	        monthLength[1] = 29;

	    // Check the range of the day
	    return day > 0 && day <= monthLength[month - 1];
	}

	componentWillReceiveProps(nextProps) {
		nextProps.admin.requestHistory && nextProps.admin.requestHistory.length && this.setState({ requestHistory: nextProps.admin.requestHistory });
	}

	render() {
		return (
			<div>
				<h3 className="col-md-10 col-md-offset-1" style={{ "backgroundColor": "white", "textAlign": "center", "padding": "15px 0" }}>Request Record</h3>
				{ !this.state.showRequestRecord == true ? 
					!this.state.showRequestPreview ? 
						<div 	className="col-md-4 col-md-offset-4 col-lg-2 col-lg-offset-5"
								style={{ 'padding' : '15px' }}> 
							<Button onClick={ this.handleShowRecord }
									bsStyle="success"
									block>
								Request Record
							</Button>
						</div> 
						:
						<RequestRecordPreview handleSubmitRequest={ this.handleSubmitRequest }/> 
					:
					<RequestRecordForm  previewRecord={ this.handleShowPreview } 
										validateForm={ this.validateForm } />
				}
				{ !this.state.showRequestPreview ? 
					<Panel className="col-md-10 col-md-offset-1"
							style={{ 'marginTop': '15px' }}>
						<RequestRecordTable requestHistory={ this.state.requestHistory } />
					</Panel> : null }
			</div>
		)
	}

}

const mapStateToProps = (state) => ({
	'formData': state.form,
	'levelTwoDashboard': state.levelTwoDashboard,
	'app': state.app,
	'admin': state.admin
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    appActions:bindActionCreators(appActionCreator,dispatch),
    notificationsAction:bindActionCreators(notificationsActionCreator,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestRecordContainer);