/**
 * Created by synerzip on 14/03/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Panel,Input,Button,Nav,NavItem} from 'react-bootstrap';
import {Table, Column, Cell} from 'fixed-data-table';
import MaskComp from '../MaskComp';
import config from '../../config';

const PAGE_TOP_FIXED_AREA_HEIGHT = 363;

class ChartspanWebComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            iframeLoading: true,
            iframeHeight: '',
            username:props.selectedPatient.email ? props.selectedPatient.email : (props.selectedPatient.first_name+"."+props.selectedPatient.last_name+
            "@patient.chartspan.com").toLowerCase(),
            linkAccountCreate:false
        }
    }

    iFrameLoaded() {
        this.setState({
            iframeLoading: false,
            iframeHeight: (top.innerHeight - PAGE_TOP_FIXED_AREA_HEIGHT) + 'px'
        });
    }

    componentDidMount() {
        var {selectedPatient} = this.props;
        if (selectedPatient.cs_userid == null) {
            //this.props.adminActions.getNonExistPatientInfo(selectedPatient);
        } else {
            this.props.adminActions.getSSODetail(selectedPatient.id);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.patientSSOToken != nextProps.patientSSOToken) {
            this.setState({showIframe: true});
        }
    }

    onUserNameChange(e){
        this.setState({username:e.target.value});
    }
    onCreate(){
        var {selectedPatient} = this.props;
        this.props.adminActions.createAndLinkAccount(this.state.username,selectedPatient.id);
    }
    onLink(){
        var {selectedPatient} = this.props;
        this.props.adminActions.linkAccount(selectedPatient.id);
    }

    onLinkAccountCreateCancel(){
        this.setState({linkAccountCreate:false});
    }

    onLinkAccountCreate(){
        var {selectedPatient} = this.props;
        this.setState({
            linkAccountCreate:true,
            username:(selectedPatient.first_name+"."+selectedPatient.last_name+"@patient.chartspan.com").toLowerCase()
        });
    }
    render() {
        if (this.state.showIframe) {
            return (
                <div>
                    <iframe
                        name="patient-profile"
                        src={config.CHART_WEB_URL+'?sso='+this.props.patientSSOToken}
                        style={{border:'1px solid #BDBDBD'}}
                        frameBorder="0" height={this.state.iframeHeight} width="100%"
                        onLoad={this.iFrameLoaded.bind(this)}>
                    </iframe>
                    <MaskComp show={this.state.iframeLoading}/>
                </div>
            )
        }
        var {selectedPatient,linkAccountAvailable,linkAccountData} = this.props;
        if (selectedPatient.cs_userid == null) {
            return (
                <div>
                    <Panel header="Create Account">
                        <div style={{height:300}}>
                            <Input type="text" placeholder="Enter Username" label="Username"
                                   onChange={this.onUserNameChange.bind(this)} value={this.state.username}/>
                            <Button bsStyle="success" onClick={this.onCreate.bind(this)} >Create</Button>
                        </div>
                    </Panel>
                </div>

            )
            {/* if(this.state.linkAccountCreate){
                return (
                    <div>
                        <Panel header="Create Account">
                            <div style={{height:300}}>
                                <Input type="text" placeholder="Enter Username" label="Username"
                                       onChange={this.onUserNameChange.bind(this)} value={this.state.username}/>
                                <div className="link-account-row" style={{border:'none !important'}}>
                                    <Button bsStyle="success" onClick={this.onCreate.bind(this)}
                                        style={{float:'right',margin:15}}>Create</Button>
                                    <Button bsStyle="danger" onClick={this.onLinkAccountCreateCancel.bind(this)}
                                            style={{float:'right',margin:15}}>Cancel</Button>
                                </div>
                            </div>
                        </Panel>
                    </div>

                )
            }
            return (
                <Panel header="Link Account">
                    <div style={{height:300}}>
                        <div className="link-account-row">
                            <div className="link-account-key">
                                Username:
                            </div>
                            <div className="link-account-value">
                                {linkAccountData.username}
                            </div>
                        </div>
                        <div className="link-account-row">
                            <div className="link-account-key">
                                First Name:
                            </div>
                            <div className="link-account-value">
                                {linkAccountData.firstname}
                            </div>
                        </div>
                        <div className="link-account-row">
                            <div className="link-account-key">
                                Last Name:
                            </div>
                            <div className="link-account-value">
                                {linkAccountData.lastname}
                            </div>
                        </div>
                        <div className="link-account-row">
                            <div className="link-account-key">
                                Date of Birth:
                            </div>
                            <div className="link-account-value">
                                {linkAccountData.date_of_birth}
                            </div>
                        </div>
                        <div className="link-account-row" style={{border:'none !important'}}>
                            <div style={{float:'right',margin:15}}>
                                <Button bsStyle="primary" onClick={this.onLinkAccountCreate.bind(this)} >Create</Button>
                            </div>
                            <div style={{float:'right',margin:15}}>
                                <Button bsStyle="success" onClick={this.onLink.bind(this)} >Link</Button>
                            </div>

                        </div>
                    </div>
                </Panel>

            )*/}

        }
        {/*else  if (selectedPatient.cs_userid == null && !linkAccountAvailable){


        }*/}
        return (
            <div>
                Loading...
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    'patientSSOToken': state.levelTwoDashboard.patientSSOToken,
    'selectedPatient': state.app.selectedPatient,
    'linkAccountAvailable':state.levelTwoDashboard.linkAccountAvailable,
    'linkAccountData':state.levelTwoDashboard.linkAccountData
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartspanWebComponent);
