import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as appActionCreator from '../../actions/app';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import {Panel,Input,Button,Grid, Row, Col} from 'react-bootstrap';
import moment from "moment";
import DateTimePicker from 'react-bootstrap-datetimepicker-custom-icons';

class PatientSearchStep1 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            patientName: ''
        }

        this.onPatientNameChange = this.onPatientNameChange.bind(this);
        this.onDobChanged = this.onDobChanged.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    onPatientNameChange(e) {
        console.log(e.target.value);
        this.setState({patientName:e.target.value});
    }

    onDobChanged(dob){
        this.setState({dateOfBirth:dob});
    }

    onSearch(){
        if(this.state.dateOfBirth == 'Invalid date'){
            this.props.commonActions.showToast({
                status: 'error',
                title: 'Invalid Date',
                message: "Please Enter Valid Date (MM/DD/YYYY)"

            });
            var interval = setInterval(()=> {
                clearInterval(interval);
                this.props.commonActions.hideToast();
            }, 2);
        }else{
            let dob = null;
            if(this.state.dateOfBirth && this.state.dateOfBirth != 'Invalid date'){
                dob = moment(this.state.dateOfBirth + '',"MM-DD-YYYY").format("MM/DD/YYYY");
            }

            if(!dob && (!this.state.patientName || this.state.patientName.length < 2)){
                return;
            }

            this.props.adminActions.getFaxMatchingPatientList(this.state.patientName,dob);
            this.props.onStep1Submit();
        }

    }


    render(){
        return <div className="step-container">
            <div style={{float:'left'}}>
                <Input type="text" style={{height:'36px !important'}} placeholder="Patient Last name" value={''}
                       onChange={this.onPatientNameChange}
                       value={this.state.patientName}/>
            </div>
            <div style={{float:'left',marginLeft: 15, width:250, position: 'relative'}}>
                <DateTimePicker format="MM-DD-YYYY"
                                inputFormat="MM/DD/YYYY"
                                dateTime={ moment().format("MM-DD-YYYY") }
                                maxDate={ moment() }
                                defaultText=""
                                ref="DOB"
                                inputProps={{
                                    maxLength: 10,
                                    style: { height: '36px !important' },
                                    ref: (ref) => { this.dateInput = ref },
                                    placeholder: 'Date of Birth (MM/DD/YYYY)',
                                }}
                                iconStyle={{
                                    class: 'fa',
                                    time: 'fa-clock-o',
                                    calendar: 'fa-calendar',
                                    up: 'fa-chevron-up',
                                    down: 'fa-chevron-down',
                                    right: 'fa-chevron-right',
                                    left: 'fa-chevron-left',
                                }}
                                onChange={this.onDobChanged}/>
            </div>
            <div style={{float:'left',marginLeft:15,height:50}}>
                <Button bsStyle="primary" style={{width:150}} onClick={this.onSearch}>Search</Button>
            </div>
        </div>;

    }
}


const mapStateToProps = (state) => ({
    'inboundMedicalForms': state.consentForms.inboundMedicalForms,
    'medicalFormDocument': state.consentForms.medicalFormDocument,
    'medicalFormDocumentUrl': state.consentForms.medicalFormDocumentUrl,
    'selectedDoc':state.consentForms.selectedDoc,
    'userId': state.auth.id
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    appActions:bindActionCreators(appActionCreator,dispatch),
    commonActions:bindActionCreators(commonActionCreator,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientSearchStep1);
