import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PageHeader from '../components/PageHeader';

const LoadingView = () =>
	<div>
		<PageHeader>
			Re-Routing
		</PageHeader>
	</div>;

export default LoadingView;
