/**
 * Created by synerzip on 21/03/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import DateTimePicker from 'react-bootstrap-datetimepicker-custom-icons';
import {Input,Modal,Button,FormControl,FormGroup,ControlLabel} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
var LinkedStateMixin = require('react-addons-linked-state-mixin');

class AddCarePlanAndGoal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            goal:null,
            step:null,
            step_title:null,
            otherNote:'',
            frequnecy:''
        }

        this.onDueDateChanged = this.onDueDateChanged.bind(this);
    }

    componentDidMount(){
        const {goalTypes,goalSources,stepTypes} = this.props;
        if(!goalTypes){
            this.props.adminActions.getGoalTypes();
        }
        if(!goalSources){
            this.props.adminActions.getGoalSources();
        }
    }

    handleNotesChange(e){
        this.setState({otherNote:e.target.value});
    }

    handleFrequenceChange(e){
        this.setState({frequnecy:e.target.value});
    }

    handleDiagonisisCode(e){
        this.setState({diagonsisCode:e.target.value});
    }

    onGoalTypeChanged(goalType){
        this.setState({goalTypeId:goalType.id});
    }
    onGoalTypeSourceChanged(source){
        this.setState({sourceId:source.id});
    }
    onSubmit(e) {
        e.preventDefault();
        let isError = false;
        const {selectedPatient,adminActions} = this.props;
        if( !this.state.diagonsisCode){
            this.setState({
                diagonsisCodeError: true
            })
            isError = true;
        }else{
            this.setState({
                diagonsisCodeError: false
            })
        }
        if( !this.state.goalTypeId){
            this.setState({
                goalTypeIdError: true
            })
            isError = true;
        }else{
            this.setState({
                goalTypeIdError: false
            })
        }
        if(!this.state.sourceId){
            this.setState({
                sourceIdError: true
            })
            isError = true;
        }else{
            this.setState({
                sourceIdError: false
            })
        }

        if(!this.state.frequnecy){
            this.setState({
                frequencyError: true
            })
            isError = true;
        }else{
            this.setState({
                frequencyError: false
            })
        }

        if(!this.state.dueDate){
            this.setState({
                dueDateError: true
            })
            isError = true;
        }else{
            this.setState({
                dueDateError: false
            })
        }

        if(!this.state.plan){
            this.setState({
                planError: true
            })
            isError = true;
        }else{
            this.setState({
                planError: false
            })
        }

        if(!isError){
            const dueDateString = moment(this.state.dueDate,'MM-DD-YYYY').format('YYYY-MM-DD')
            this.props.showSecondaryTimer();
            adminActions.addCarePlanAndGoal(selectedPatient.id,
                this.state.goalTitle,
                this.state.diagonsisCode,
                this.state.goalTypeId, 
                this.state.sourceId,
                this.state.problem,
                this.state.frequnecy,
                this.state.otherNote,
                this.state.action,
                this.state.plan,
                this.state.evaluation,
                dueDateString);
            this.setState(
                {
                    goalTitle:'',
                    diagonsisCode:'',
                    goalTypeId:'',
                    sourceId:'',
                    problem:'',
                    action:'',
                    plan:'',
                    evaluation:'',
                    dueDate:null,
                    diagonsisCodeError:false,
                    goalTypeIdError:false,
                    sourceIdError:false,
                    frequencyError:false,
                    dueDateError:false,
                    
                }
            );
            this.props.onPopupHide();            
        }
    }

    onCancel() {
        this.setState(
            {
                    goalTitle:'',
                    diagonsisCode:'',
                    goalTypeId:'',
                    sourceId:'',
                    problem:'',
                    action:'',
                    plan:'',
                    evaluation:'',
                    dueDate:null,
                    stepError:false,
                    diagonsisCodeError:false,
                    goalTypeIdError:false,
                    sourceIdError:false,
                    frequencyError:false,
                    dueDateError:false
            }
        );
        this.props.onCloseEdit();
        this.props.onPopupHide();
    }

    onDueDateChanged(dateValue){
        this.setState({dueDate:dateValue});
    }

    render(){
        const {goalReviewFrequency,goalTypes,
            goalSources,stepTypes,selectedPatient} = this.props;
        return (
            <Modal show={this.props.show} className="add-goal-modal">
                <Modal.Header closeButton onHide={()=>{
                                this.props.onPopupHide();
                            }}>
                    <Modal.Title>
                        <div style={
                            {
                            textAlign: 'center'
                            }
                        }>
                            <div>Add Goal and Care Goal</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{overflow:'auto',height:this.state.frequnecy == '5' ? 800 : 720}}>
                        <form onSubmit={this.onSubmit.bind(this)} novalidate>

                            <Input type="text" label="Care Goal Title" placeholder="Enter Care Goal Title"
                                   valueLink={this.linkState('goalTitle')} maxLength="255" required/>   
                            <FormGroup className={this.state.diagonsisCodeError}>                        
                                <ControlLabel>Diagnosis Code</ControlLabel>
                                <select style={{padding: '6px 12px'}} 
                                    className="form-control"
                                    onChange={this.handleDiagonisisCode.bind(this)} value={this.state.diagonsisCode}>
                                    <option value='' selected disabled>Choose Code</option>
                                    {
                                        selectedPatient.icd10code_1 && 
                                        <option value={`${selectedPatient.icd10code_1}-${selectedPatient.icd10code_1_desc}`}>
                                            {`${selectedPatient.icd10code_1}-${selectedPatient.icd10code_1_desc}`}
                                        </option>
                                    }
                                    {
                                        selectedPatient.icd10code_2 &&  
                                        <option value={`${selectedPatient.icd10code_2}-${selectedPatient.icd10code_2_desc}`}>
                                            {`${selectedPatient.icd10code_2}-${selectedPatient.icd10code_2_desc}`}
                                        </option>
                                    }
                                    <option value='Other - Patient Reported'>Other - Patient Reported</option>
                                    
                                    
                                </select>
                                {this.state.diagonsisCodeError && <span className="text-danger"><small>Please select diagonsis code</small></span>}
                            </FormGroup>
                            
                            
                            <div style={{display:'flex',justifyContent:'space-between',
                                       marginBottom:15}}>
                                <div>
                                    <ControlLabel>Goal Type</ControlLabel>
                                    <div>
                                        {
                                                _.map(goalTypes,(goalType) => {
                                                    return <div key={goalType.id} style={{display:'flex',alignItems:'center'}}>
                                                        <input type="radio" name="goalTypeRadio"
                                                               style={{height:'15px !important',marginBottom:3}}
                                                               value={goalType.id}
                                                               onChange={this.onGoalTypeChanged.bind(this,goalType)}/>
                                                        <div style={{marginLeft:5}}>
                                                            {goalType.name}
                                                        </div>
                                                    </div>
                                                })
                                            }
                                    </div>
                                    {this.state.goalTypeIdError && <span className="text-danger"><small>Please select goal type</small></span>}
                                    
                                </div>
                                <div>
                                    <ControlLabel>Source</ControlLabel>
                                    <div>
                                        {
                                                _.map(goalSources,(source) => {
                                                    return <div key={source.id} style={{display:'flex',alignItems:'center'}}>
                                                        <input type="radio" name="sourceRadio"
                                                               style={{height:'15px !important',marginBottom:3}}
                                                               value={source.id}
                                                               onChange={this.onGoalTypeSourceChanged.bind(this,source)}/>
                                                        <div style={{marginLeft:5}}>
                                                            {source.name}
                                                        </div>
                                                    </div>
                                                })
                                            }
                                    </div>
                                    {this.state.sourceIdError && <span className="text-danger"><small>Please select source</small></span>}
                                </div>
                                <div>
                                <FormGroup className={this.state.dueDateError}>                        
                                    <ControlLabel>Due Date</ControlLabel>
                                    <div style={{width:300, position: 'relative'}}>
                                    <DateTimePicker format="MM-DD-YYYY"
                                                    inputFormat="MM/DD/YYYY"
                                                    dateTime={ moment().format("MM-DD-YYYY") }
                                                    minDate={ moment() }
                                                    defaultText=""
                                                    ref="DueDateRef"
                                                    inputProps={{
                                                        maxLength: 10,
                                                        style: { height: '36px !important' },
                                                        ref: (ref) => { this.dateInput = ref },
                                                        placeholder: 'Due Date(MM/DD/YYYY)',
                                                    }}
                                                    iconStyle={{
                                                        class: 'fa',
                                                        time: 'fa-clock-o',
                                                        calendar: 'fa-calendar',
                                                        up: 'fa-chevron-up',
                                                        down: 'fa-chevron-down',
                                                        right: 'fa-chevron-right',
                                                        left: 'fa-chevron-left',
                                                    }}
                                                    onChange={this.onDueDateChanged}/>
                                    </div>   
                                    {this.state.dueDateError && <span className="text-danger"><small>Please select due date</small></span>}
                                </FormGroup>
                                </div>
                            </div>    
                            <Input type="text" label="Problem" placeholder="Enter Patient Problem" required
                                valueLink={this.linkState('problem')} maxLength="100"/>
                           
                            <FormGroup className={this.state.planError}>                        
                                <ControlLabel>Plan</ControlLabel>
                                <div>
                                    <FormControl componentClass="textarea" label="Plan" placeholder="Enter Plan"
                                        valueLink={this.linkState('plan')} maxLength="4096" style={{resize:'none',height:'100px'}}/>
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        {`${this.state.plan ? this.state.plan.length : 0}/4096`}
                                    </div>    
                                </div>
                                {this.state.planError && <span className="text-danger"><small>Please enter plan</small></span>}
                            </FormGroup> 
                            <FormGroup style={{marginTop:15}} className={this.state.frequencyError && 'has-error'}>                        
                                <ControlLabel>Care Goal Review Frequency</ControlLabel>
                                <select style={{padding: '6px 12px'}} 
                                    className="form-control"
                                    onChange={this.handleFrequenceChange.bind(this)} value={this.state.frequnecy}>
                                    <option value='' selected disabled>Select Care Goal Review Frequency</option>
                                    {
                                        _.map(goalReviewFrequency,(frequency) => {
                                            return <option value={frequency.id}>{frequency.frequency}</option>
                                        })
                                    }
                                </select>
                                {this.state.frequencyError && <span className="text-danger"><small>Please select frequency</small></span>}
                            </FormGroup>
                           
                           {
                            this.state.frequnecy == '5' && <div>
                                <textarea className="form-control" rows="5" id="notes"
                                maxLength={256}
                                value={this.state.otherNote}
                                placeholder="Enter Notes"
                                onChange={this.handleNotesChange.bind(this)}></textarea>
                                <div style={{display:'flex',justifyContent:'flex-end'}}>
                                    {`${this.state.otherNote ? this.state.otherNote.length : 0}/256`}
                                </div>  
                            </div>
                           }
                            
                            <div style={{textAlign:'center',paddingTop:10}}>
                                <Button bsStyle="danger" onClick={this.onCancel.bind(this)} style={{float:'left'}}>
                                    Cancel
                                </Button>
                                {
                                    (this.props.editMode)?
                                        <Button bsStyle="success" type="submit" style={{float:'right'}}>
                                            Update
                                        </Button>
                                        :
                                        <Button bsStyle="success" type="submit" style={{float:'right'}}>
                                            Save
                                        </Button>
                                }
                            </div>
                        </form>
                    </div>


                </Modal.Body>

            </Modal>
        );
    }
}

reactMixin(AddCarePlanAndGoal.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    'user': state.auth.user,
    'selectedPatient':state.app.selectedPatient,
    'goalReviewFrequency':state.admin.goalReviewFrequency,
    'stepTypes':state.levelTwoDashboard.stepTypes,
    'goalTypes':state.levelTwoDashboard.goalTypes,
    'goalSources':state.levelTwoDashboard.goalSources,
    'goalCompletionStatuses':state.levelTwoDashboard.goalCompletionStatuses,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCarePlanAndGoal);