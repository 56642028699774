import React, { Component } from 'react';

class ActionCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitDisabled: false,
        };
    }

    handleQuickWorkItem = () => {
        this.setState({ submitDisabled: true });
        return this.props.onCreateQuickWorkItem(
            this.props.patient.id,
            { call_log_code_id: this.quickWorkItem.value }
        )
        .then(() => {
            this.quickWorkItem.value = this.props.callLogCodes[0].id;
            this.setState({ submitDisabled: false });
        });
    }

    render() {
        return (
            <div style={{ display: 'flex', alignItems: 'flex-start' ,flexDirection:'column'}}>
                <button
                    type="button"
                    className="btn btn-primary"
                    style={{ padding: '2px 12px', marginRight: '1rem' }}
                    onClick={() => this.props.onPatientSelect(this.props.patient)}
                >
                    Open Chart
                </button>
                {
                   this.props.autoDial &&  <div style={{color:'#f52b04'}}>
                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    <span style={{fontSize:12,paddingLeft:2}}>In Autodial Queue</span>
                    </div>
                }
                <div style={{ flex: 1 }} />
                {/* 
                    <select
                        className="form-control"
                        ref={(ref) => { this.quickWorkItem = ref; }}
                        style={{ maxWidth: '275px', height: '26px', fontSize: '12px', marginRight: '1rem' }}
                    >
                        {this.props.callLogCodes.map((code) =>
                            <option key={code.id} value={code.id}>
                                {code.code_description}
                            </option>
                        )}
                    </select>
                    <button
                        className="btn btn-success"
                        style={{ padding: '2px 12px' }}
                        onClick={this.handleQuickWorkItem}
                        disabled={this.state.submitDisabled}
                    >
                        Submit
                    </button>
                */}
            </div>
        );
    }
}

export default ActionCell;
