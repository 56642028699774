/**
 * Created by synerzip on 28/12/15.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import config from '../config';
import MaskComp from './MaskComp';
import * as authActionCreators from '../actions/authactions';
import * as commonActionCreator from '../actions/common';
var ReactCSSTransitionGroup = require('react-addons-css-transition-group');
import {Grid, Row, Col, Input, ButtonInput} from 'react-bootstrap';
var LinkedStateMixin = require('react-addons-linked-state-mixin');

class LoginView extends React.Component{
    constructor(props) {
        super(props);
        let redirectRoute = this.props.location.query.next || '/home';
        if(this.props.location.query.phone){
            redirectRoute += `?phone=${this.props.location.query.phone}`
        }
        this.state = {
            email: '',
            password: '',
            redirectTo: redirectRoute,
            signIn:false
        };
    }
    handleSubmit(e){
        e.preventDefault();
        this.login(e);
    }
    onSignIn(){
        this.setState({signIn:true})
    }
    componentWillReceiveProps(nextProps,nextState){
        if(nextProps.authenticationStatus == 'failed') {
            this.props.commonActions.showToast({
                status:'error',
                title:"Authentication Error",
                message:nextProps.statusText
            });
            var interval = setInterval(()=>{
                clearInterval(interval);
                this.props.commonActions.hideToast();
            },2);

        }
    }
    login(e) {
        e.preventDefault();
        this.props.actions.loginUser(this.state.email, this.state.password, this.state.redirectTo);
    }
    componentDidMount() {
        var email = document.querySelector("input[type=email]");
        var checkEmail = (e) => {
            email.setCustomValidity('');

            if(e.target.value.length == 0) {
                email.setCustomValidity("Please fill out the field.");
            }
            else if(e.target.value.indexOf("@") == -1) {
                email.setCustomValidity("Please include an '@' in the email address. '" + e.target.value + "' is missing an '@'.");
            }
            else if (e.target.value.indexOf("@") == e.target.value.length - 1) {
                email.setCustomValidity("Please enter a valid domain following the '@'. '" + e.target.value + "' is incomplete.");
            }
            else if(e.target.value.indexOf("@.") > -1) {
                email.setCustomValidity("'.' is used at a wrong position in '" + e.target.value.substr(e.target.value.indexOf("@"),e.target.length) + "'.");
            }
        };
        if(email) {
            email.oninvalid = (e) => {
                checkEmail(e);
            };
            email.oninput = (e) => {
                checkEmail(e);
            };
        }
    }

    render() {
        return (
            <ReactCSSTransitionGroup transitionName="example"
                                     transitionAppear={true}
                                     transitionAppearTimeout={1000}
                                     transitionEnterTimeout={500}
                                     transitionLeaveTimeout={1000}>
                <div className="container cs-login">
                    <Grid>
                        <Row>
                            <Col xsHidden md={4}></Col>
                            <Col xs={12} md={4}>

                                <h2 className="cs-login-heading">
                                    <a href="#" title="Login screen">ChartSpan CCM</a>
                                </h2>
                                <h4 style={{textAlign:'center',margin:10,marginBottom:30,color:'#56B1DB'}}>
                                    Chronic Care Management Login
                                </h4>
                            </Col>
                            <Col xsHidden md={4}></Col>
                        </Row>
                        <Row>
                            <Col xsHidden md={4}/>
                            <Col xs={12} md={4}>
                                <p className="text-center" style={{margin:10}}>
                                    <a href={`${config.BASE_URL}/saml/login`} className="cs-login-forgot-password">
                                        <img src={'images/btn_google_signin_light_normal_web@2x.png'} style={{height:50}}/>
                                    </a>
                                </p>
                            </Col>
                            <Col xsHidden md={4}></Col>
                        </Row>
                        <Row>
                            <Col xsHidden md={4}/>
                            <Col xs={12} md={4}>
                                <p className="text-center" style={{margin:10}}>
                                    <hr style={{borderTop:'1px solid rgb(210,210,210)'}}/>
                                </p>
                            </Col>
                            <Col xsHidden md={4}></Col>
                        </Row>
                        {
                            !this.state.signIn && [
                                <Row key={1}>
                                    <Col xsHidden md={4}/>
                                    <Col xs={12} md={4}>
                                        <p className="text-center" style={{margin: 10, color: 'rgb(136,136,136)'}}>
                                            Not a Google SSO user?
                                        </p>
                                    </Col>
                                    <Col xsHidden md={4}></Col>
                                </Row>,
                                <Row key={2}>
                                    <Col xsHidden md={4}/>
                                    <Col xs={12} md={4}>
                                        <p style={{
                                            textAlign: 'center',
                                            margin: 10,
                                            marginBottom: 30,
                                            color: '#56B1DB',
                                            fontSize: 15,
                                            cursor: 'pointer'
                                        }}
                                           onClick={this.onSignIn.bind(this)}>
                                            Sign In Here
                                        </p>
                                    </Col>
                                    <Col xsHidden md={4}></Col>
                                </Row>
                            ]
                        }
                        {
                            this.state.signIn &&  [
                                <Row key={1}>
                                    <Col xsHidden md={4}/>
                                    <Col xs={12} md={4}>
                                        <p className="text-center" style={{margin:10,color:'rgb(136,136,136)'}}>
                                            CCM User Login
                                        </p>
                                    </Col>
                                    <Col xsHidden md={4}></Col>
                                </Row>,
                                <Row key={2}>
                                    <Col xsHidden md={4}/>
                                    <Col xs={12} md={4}>
                                        <p className="text-center" style={{margin:10,color:'rgb(136,136,136)'}}>
                                            To sign in using your CCM username, you must be on a Secure VPN Connection
                                        </p>
                                    </Col>
                                    <Col xsHidden md={4}></Col>
                                </Row>,
                                <Row key={3}>
                                    <Col xsHidden md={4}></Col>
                                    <Col xs={12} md={4}>
                                        <form className="span7" onSubmit={this.handleSubmit.bind(this)} style={{margin:10,marginTop:20}}>
                                            <Input id="loginInputEmail"
                                                   type="email" label="" placeholder="Enter Email"
                                                   valueLink={this.linkState('email')}
                                                   required autofocus />
                                            <Input id="loginInputPassword"
                                                   type="password" label=""
                                                   valueLink={this.linkState('password')}
                                                   placeholder="Enter Password"
                                                   required autofocus/>



                                            <ButtonInput id="loginButtonSignin" type="submit"  bsStyle="success"
                                                         style={{backgroundColor:'rgb(41,171,221)',
                                                             height:'45px !important',
                                                             width:210,
                                                             margin:'0 auto',
                                                             borderColor:'rgb(41,171,221)'}} bsSize="large" block>Sign In</ButtonInput>


                                        </form>
                                    </Col>
                                    <Col xsHidden md={4}></Col>
                                </Row>
                            ]
                        }

                    </Grid>
                    <MaskComp show={this.props.isAuthenticating} />
                </div>
            </ReactCSSTransitionGroup>
        );
    }
}

reactMixin(LoginView.prototype,LinkedStateMixin);

const mapStateToProps = (state) => ({
    isAuthenticating: state.auth.isAuthenticating,
    statusText: state.auth.statusText,
    authenticationStatus:state.auth.authenticationStatus
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(authActionCreators, dispatch),
    commonActions:bindActionCreators(commonActionCreator,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
