import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import { connect } from 'react-redux';
import { Modal, Button, Panel } from 'react-bootstrap';
import AssessmentCategoryRow from './AssessmentCategoryRow';
import _ from 'lodash';
import $ from 'jquery';
import moment from 'moment';

import ConfirmResetAssessmentPopup from './ConfirmResetAssessmentPopup'


class AssessmentView extends React.Component{

	constructor() {
		super();
		this.state = {
			openAssessment: null,
			unsavedAssessments: [],
			showModal: false,
			editAnswer:false
		}
	}

	componentWillMount() {
		this.props.adminActions.getAssessmentQuestions("assessments");
		this.props.adminActions.getAssessmentAnswers(this.props.selectedPatient.id,"assessments");
		$(window).scroll( () => {
			const scrollTop     = $(window).scrollTop(),
				  elementOffset = $('.assessmentsPanel').offset().top,
				  distance      = (elementOffset - scrollTop),
				  height        = $('nav').outerHeight();
			if (distance < height) {
				$('.assessmentsPanel .panel-heading:first').addClass('sticky');
				$('.assessmentsPanel .panel-heading:first').css({top: height});
				$('.assessmentsPanel .panel-body:first').addClass('stickyHeaderMargin')
			} else {
				$('.assessmentsPanel .panel-heading:first').removeClass('sticky');
				$('.assessmentsPanel .panel-body:first').removeClass('stickyHeaderMargin')
			}
		})
	}

	componentWillUnmount() {
		$(".patient-summary-box").removeClass("disabledButton");
		$(".patient-top-bar").removeClass("disabledButton");
		$(".disabledContainer").unbind('click');
		$(window).off('scroll');
	}

	getCurrentAssessment( shortName ) {
		return _.find(this.props.assessmentQuestionCollection.sections, (assessment) => {
			return assessment.short_name === shortName;
		});
	}

	handleToggle(assessmentKey) {
		this.setState({
			openAssessment: assessmentKey === this.state.openAssessment ? null : assessmentKey,
		});
	}

	toggleCancelConfirmation(e) {
		e.preventDefault();
		this.setState({ showModal: !this.state.showModal });
	}

	isUnsaved(assessmentKey) {
		return _.indexOf(this.state.unsavedAssessments, assessmentKey) > -1 ? 'warning' : 'success';
	}


	header() {
		const mandatoryFilled = this.isMandatoryFilled();
		return (
			<div style={{height:35}}>
				<div style={{fontSize:18,float:'left'}}>CCM Assessments</div>
				<div style={{float:'right',marginRight:140}}>
					<Button bsStyle="success" disabled={!mandatoryFilled} style={{marginRight: '20px'}} onClick={this.saveForm.bind(this)}>Save Changes</Button>
					<Button bsStyle="danger" onClick={this.resetForm.bind(this)}>Reset Changes</Button>
				</div>
			</div>
		)
	}

	initScore( answerCollection, assessments, short_name ) {
		let riskObject = { text: '', display: false };
		if ( short_name === 'assess_fallrisk' || short_name === 'assess_sd' || short_name === 'assess_cognitive' || short_name === 'assess_ad8') {
			let questions = _.find( assessments.sections, ( assessment ) => {
				return assessment.short_name === short_name;
			});
			let isAnswered = _.some(questions.questions, ( question ) => {
				return _.findIndex(answerCollection, {'question_id':question.id}) > -1;
			});
			let score = this.calcScore( answerCollection, questions.questions, 0 );
			riskObject.text = this.getRiskText( score, short_name, isAnswered );
			riskObject.display = true;
		}
		return riskObject;
	}

	getRiskText( score, short_name, isAnswered ) {
		let riskText;
		if ( short_name === 'assess_fallrisk' ) {
			if ( score >= 0 && score <= 24 ) {
				riskText = 'No Risk'
			} else if( score >= 25 && score <= 50 ) {
				riskText = 'Low Risk'
			} else if ( score >= 51 ){
				riskText = 'High Risk'
			}
		} else if ( short_name === 'assess_sd') {
			if (!score){
				riskText = '--'
			}else if ( score >= 11 ) {
				riskText = 'May Need Assistance'
			} else {
				riskText = 'No Assistance Indicated'
			}
		} else if ( short_name === 'assess_cognitive' ) {
			if (!isAnswered){
				riskText = '--'
			}else if ( score <= 2 ) {
				riskText = 'May Need Assistance'
			} else {
				riskText = 'No Assistance Indicated'
			}
		} else if ( short_name === 'assess_ad8' ) {
			if (!isAnswered){
				riskText = '--'
			}else if ( score >= 2 ) {
				riskText = 'Cognitive Impairment'
			} else {
				riskText = 'Normal Cognition'
			}
		}
		return riskText;
	}

	calcScore( answers, questions, score ) {
		_.each( questions, ( question ) => {
			let index = _.findIndex(answers, {'question_id':question.id});
			if ( index > -1 ) {
				_.each( question.options, ( option ) => {
					if ( answers[index].value === option.short_name ) {
						score = score + option.score;
						if ( option.questions.length ) {
							score = this.calcScore( answers, option.questions, score );
						}
					}
				})
			}
		});
		return score;
	}
	isMandatoryFilled() {
		let error = false;
		const {unsavedAssessments} = this.state;
		const {assessmentAnswerCollection,assessmentQuestionCollection} = this.props;
		_.each(unsavedAssessments,(assessment)=>{
			const sectionArray = _.filter(assessmentQuestionCollection.sections,(section)=>{
				return section.short_name == assessment;
			});
			const questionObject = {};
			if(sectionArray.length > 0){
				const section = sectionArray[0];
				_.each(section.questions,(que)=>{
					questionObject[que.id] = que;
					// if(que.required){
					let validAnswerIndex = '';
					if(section.short_name == 'assess_sd'){
						 validAnswerIndex = _.find(assessmentAnswerCollection,(answer)=>{
							return que.type === "INFO_PHS" || que.type === "TEXT_AREA" || answer.question_id == que.id && answer.value
						});
					}else{
						 validAnswerIndex = _.find(assessmentAnswerCollection,(answer)=>{
							return que.type === "INFO" || answer.question_id == que.id && answer.value
						});

					}
					if(!validAnswerIndex){
						error = true;
					}

					// }
					// _.each(que.options,(option)=>{
					// 	_.each(option.questions,(optionQue)=>{
					// 		questionObject[optionQue.id] = optionQue;
					// 		if(optionQue.required){
					// 			const validAnswerIndex = _.find(assessmentAnswerCollection,(answer)=>{
					// 				return answer.question_id == optionQue.id && answer.value
					// 			});
					// 			if(!validAnswerIndex){
					// 				error = true;
					// 			}
					// 		}
					// 	})
					// });
				});
			}
		});
		return !error;
	}

	resetAll(sectionId){
		return new Promise((resolve,reject)=>{
			const {assessmentQuestionCollection} = this.props;
			const sectionArray = _.filter(assessmentQuestionCollection.sections,(section)=>{
				return section.id == sectionId;
			});
			const questionObject = {};
			if(sectionArray.length > 0){
				const section = sectionArray[0];
				_.each(section.questions,(que)=>{
					questionObject[que.id] = que;
					_.each(que.options,(option)=>{
						_.each(option.questions,(optionQue)=>{
							questionObject[optionQue.id] = optionQue;
						})
					});
				})
			}
			const cleanAnswerCollection = [];
			_.each(this.props.assessmentAnswerCollection,(answer)=>{
				const queId =  answer.question_id;
				const questionObj = questionObject[queId];
				if(questionObj){
					//PICK_MANY
					cleanAnswerCollection.push({ value: null, question_id: answer.question_id ,dataWipe: true, changed: true})
				}else{
					cleanAnswerCollection.push(answer);
				}

			});
			//set final answer list
			this.props.adminActions.updateAssessmentAnswers(cleanAnswerCollection);
			resolve();
		})
		//{ value: null, question_id: answer.question_id }

	}

	clearAnswer(assessment,questionsToRemove,answerObject){

		let answerCollection = _.cloneDeep( this.props.assessmentAnswerCollection );
		_.each(questionsToRemove,(que)=>{
			//Collect all existing answers other than current answer of current 'que'
			answerCollection = _.filter(answerCollection,(answer)=>{
				return answer.question_id !== que.id;
			});

			if(que.type ==='PICK_MANY'){
				const quesAnsArray  = _.filter(answerCollection,(answer)=>{
					return answer.question_id === que.id;
				});
				if(quesAnsArray.length === 0){
					// Check the answerCollection for any answers related to this question
					// If there are no answers, push a null answer to the collection to wipe previous answers in the DB
					answerCollection.push({ value: null, question_id: que.id, dataWipe: true, changed: true });
				}

			}
		});
		//After above loop, all answers are removed, now add newly selected radio option in the answers aray
		answerCollection.push(answerObject);

		//reset editAnswer flag
		this.setState({editAnswer:true});
		//set final answer list
		this.props.adminActions.updateAssessmentAnswers(answerCollection);
		if ( _.indexOf(this.state.unsavedAssessments, assessment ) === -1 ) {
			let unsavedAssessments = _.cloneDeep(this.state.unsavedAssessments);
			unsavedAssessments.push(assessment);
			this.setState({ unsavedAssessments: unsavedAssessments });
		}
	}

	updateInput( assessment, type, question_id, value, score ) {
		let answerObject = { value, question_id, changed:true, score };
		let answerCollection = _.cloneDeep( this.props.assessmentAnswerCollection );

		if ( type === 'checkbox' ) {
			// Add changed key to all old answers associated with this question
			_.forEach( answerCollection, ( answer ) => {
				if ( answer.question_id === question_id ) {
					answer.changed = true;
				}
			});
			const answerIndex = _.indexOf( answerCollection, _.find( answerCollection, { value }));
			answerIndex > -1 ? answerCollection.splice( answerIndex, 1 ) : answerCollection.push( answerObject );
			// Check the answerCollection for any answers related to this question
			// If there are no answers, push a null answer to the collection to wipe previous answers in the DB
			const emptyCheckIndex = _.indexOf(answerCollection, _.find(answerCollection, { question_id }));
			emptyCheckIndex === -1 && answerCollection.push({ value: null, question_id: question_id, dataWipe: true, changed: true })
		} else {
			// Get the index of the old answer for this question in the answerCollection
			const answerIndex = _.indexOf( answerCollection, _.find( answerCollection,
				{ question_id }));
			// If there is an old answer, remove it and replace with new answerObject
			// If not, push the new answerObject onto the end of the answerCollection
			answerIndex > -1 ? answerCollection.splice( answerIndex, 1, answerObject ) : answerCollection.push( answerObject );
		}

		//reset editAnswer flag
		this.setState({editAnswer:true});

		this.props.adminActions.updateAssessmentAnswers(answerCollection);
		if ( _.indexOf(this.state.unsavedAssessments, assessment ) === -1 ) {
			let unsavedAssessments = _.cloneDeep(this.state.unsavedAssessments);
			unsavedAssessments.push(assessment);
			this.setState({ unsavedAssessments: unsavedAssessments });
		}
		$(".patient-summary-box").addClass("disabledButton");
		$(".patient-top-bar").addClass("disabledButton");
		$(".disabledContainer").bind('click', () => {this.setState({showModal: true})});
	}

	getQuestions( section, all_questions ) {
		_.forEach( section.questions, ( question ) => {
			all_questions.push( question );
			if ( question.options && question.options.length ) {
				_.forEach( question.options, ( option ) => {
					this.getQuestions( option, all_questions );
				})
			}
		});
		return all_questions;
	}

	deleteArtifacts( question, answer, payload ) {
		_.forEach( question.options, ( option ) => {
			if ( option.short_name !== answer.value && option.questions.length ) {
				_.forEach( option.questions, ( next_question ) => {
					payload.push({ value: null, question_id: next_question.id });
					if ( next_question.options.length ) {
						this.deleteArtifacts( next_question, answer, payload );
					}
				});
			}
		});
		return payload;
	}

	saveForm( e ) {
		e.preventDefault();
		const { assessmentAnswerCollection, assessmentQuestionCollection } = this.props;

		let all_questions = [];

		_.forEach( assessmentQuestionCollection.sections, ( section ) => {
			if ( section.questions && section.questions.length ) {
				all_questions = this.getQuestions( section, all_questions );
			}
			if ( section.sub_sections && section.sub_sections.length ) {
				_.forEach( section.sub_sections, ( sub_section ) => {
					all_questions = this.getQuestions( sub_section, all_questions );
				});
			}
		});
		let payload = [];
		_.forEach( assessmentAnswerCollection, ( answer ) => {
			if ( answer.changed ) {
				const answerObject = { value: answer.value, question_id: answer.question_id, score: answer.score || 0 };
				// Check the payload array for answers to the same question
				let questionIndex = _.indexOf(payload, _.find(payload, {question_id: answer.question_id}));
				// If there are no other answers for this question, push a blank value object to payload
				// This will clear any existing answers in the DB to this question
				questionIndex === -1 && !answer.dataWipe && payload.push({ value: null, question_id: answer.question_id });
				// Add answerObject to the payload array
				payload.push( answerObject );
				let question = _.find( all_questions, ( question ) => {
					return question.id === answer.question_id;
				});
				// Find any questions nested under non selected options and remove any answers for those that are in the DB
				// Used when editing an assessment
				if ( question && question.options && ( question.type === 'PICK_ONE' || question.type === 'YES_NO' ) ) {
					payload = this.deleteArtifacts( question, answer, payload );
				}
			}
		});
		this.props.adminActions.saveAssessmentAnswers(this.props.selectedPatient.id, payload,"assessments");

		this.setState({ openAssessment: null, unsavedAssessments: [], showModal: false,editAnswer:false });
		$(".patient-summary-box").removeClass("disabledButton");
		$(".patient-top-bar").removeClass("disabledButton");
		$(".disabledContainer").unbind('click');
	}

	resetForm() {
		this.props.adminActions.updateAssessmentAnswers([]);
		this.props.adminActions.getAssessmentAnswers(this.props.selectedPatient.id,"assessments");
		this.setState({openAssessment: null, unsavedAssessments: [], showModal: false,editAnswer:false})
		$(".patient-summary-box").removeClass("disabledButton");
		$(".patient-top-bar").removeClass("disabledButton");
		$(".disabledContainer").unbind('click');
	}

	render() {
		const { assessmentQuestionCollection, assessmentAnswerCollection, selectedPatient } = this.props;
		const age = moment().diff(selectedPatient && selectedPatient.date_of_birth, 'years');

		return (
			<div className="assessmentsPanel">
				<Panel header={this.header()}>
					{ assessmentQuestionCollection && _.map(_.sortBy(assessmentQuestionCollection.sections, 'sequence'), ( section ) => {
						if ( section.short_name !== 'assess_fallrisk'  || ( section.short_name === 'assess_fallrisk' && age >= 65  ) ) {
							return (
								<AssessmentCategoryRow
									key={section.id}
									sectionId={section.id}
									editAnswer={this.state.editAnswer}
									resetAll={this.resetAll.bind(this)}
									title={section.title}
									shortName={section.short_name}
									open={section.short_name === this.state.openAssessment}
									handleToggle={this.handleToggle.bind(this)}
									assessmentQuestions={this.getCurrentAssessment(section.short_name)}
									answerCollection={assessmentAnswerCollection}
									assessmentQuestionCollection={assessmentQuestionCollection}
									updateInput={this.updateInput.bind(this)}
									clearAnswer={this.clearAnswer.bind(this)}
									bsStyle={this.isUnsaved(section.short_name)}
									riskObject={this.initScore(assessmentAnswerCollection, assessmentQuestionCollection, section.short_name)}
								/>
							)
						}
					})}
				</Panel>
				<Modal show={this.state.showModal} onHide={this.toggleCancelConfirmation.bind(this)}>
					<Modal.Header closeButton>
						<Modal.Title>Confirm Assessments</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>You have unsaved changes to assessments. You must either save your changes, clear your changes, or you can click cancel to continue editing</p>
					</Modal.Body>
					<Modal.Footer>
						<Button bsStyle="success" style={{marginRight: '10px'}} onClick={this.saveForm.bind(this)}>Save Changes</Button>
						<Button bsStyle="danger" style={{marginRight: '10px'}} onClick={this.resetForm.bind(this)}>Reset Changes</Button>
						<Button bsStyle="default" onClick={this.toggleCancelConfirmation.bind(this)}>Cancel</Button>
					</Modal.Footer>
				</Modal>
				<ConfirmResetAssessmentPopup />
			</div>
		)
	}

}

const mapStateToProps = (state) => ({
	'user': state.auth.user,
	'selectedPatient':state.app.selectedPatient,
	'assessmentQuestionCollection': state.levelTwoDashboard.assessmentQuestions,
	'assessmentAnswerCollection': state.levelTwoDashboard.assessmentAnswers,
});

const mapDispatchToProps = (dispatch) => ({
	adminActions: bindActionCreators(adminActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentView);
