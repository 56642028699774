import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import {Input,Modal,Button,FieldGroup} from 'react-bootstrap';
import {Grid, Row, Col} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import _ from 'lodash';
import moment from 'moment';
import DynamicFormInput from "../../components/DynamicFormInput";
import AssessmentCategoryRow from "./AssessmentCategoryRow";
import DateTimeField from "react-bootstrap-datetimepicker-custom-icons";
import {actionPlans,actionPlans2} from './actionPlans'
import EngagementComp from './EngagementComp'
class GAPSPlanAssessment extends React.Component {

    constructor(props) {
        super(props);
        const {activeUser,carePlan} = props;
        this.state = {
            unsavedAssessments: []
        }
    }

    showErrorMessage(title,message){
        this.props.commonActions.showToast({
            status: 'error',
            title: title,
            message: message
        });
        var interval = setInterval(()=> {
            clearInterval(interval);
            this.props.commonActions.hideToast();
        }, 2);
    }
    validateAndSubmitData(gaps_care_plan_status_id){
        const {assessmentAnswerCollection,carePlan,gapsDisabled,selectedPatient} = this.props;
        const date = this.dateInput && this.dateInput.value ?
            moment(this.dateInput.value).isValid()
            ? moment(this.dateInput.value).format('MM/DD/YYYY')
            : '' : '';
        const time = this.timeInput && this.timeInput.value ? this.timeInput.value : '';
        const location = this.locationInput && this.locationInput.value ? this.locationInput.value : '';
        const {notes,actionPlan} = this.state;
        const engagement_type = actionPlan ? actionPlan.code : '';
        const carePlanId = carePlan.gaps_care_plan_id;
        const pageId = carePlan.gaps_care_plan_type_id;
        //save answer
        const answers = _.map(assessmentAnswerCollection,(data) => {
            return {
                value:data.value,
                question_id:data.question_id
            }
        });
        let attributes = [];
        if(actionPlan && actionPlan.attributes){
            attributes = [
                {
                    "attr":"date",
                    "attr_value":date
                },
                {
                    "attr":"time",
                    "attr_value":time
                },
                {
                    "attr":"location",
                    "attr_value":location
                }
            ]
        }

        this.props.adminActions.saveGAPSEngagement(carePlanId,answers,pageId,
            engagement_type,gaps_care_plan_status_id,notes,attributes,gapsDisabled,
            selectedPatient.id)

        this.setState({ openAssessment: null, unsavedAssessments: [], showModal: false,editAnswer:false });
        this.onCancel();

    }
    onSubmit(gaps_care_plan_status_id,e) {
        e.preventDefault();
        this.validateAndSubmitData(gaps_care_plan_status_id);

    }

    onAddNewEngagement(){
        this.setState({newEngagement:true})
    }
    onCancel() {
        this.props.showSecondaryTimer();
        this.setState({});
        this.props.onCloseAssessment();
        this.props.onPopupHide();
    }

    updateInput(assessment, type, question_id, value, score){
        let answerObject = { value, question_id, changed:true, score };
        let answerCollection = _.cloneDeep( this.props.assessmentAnswerCollection );

        if ( type === 'checkbox' ) {
            // Add changed key to all old answers associated with this question
            _.forEach( answerCollection, ( answer ) => {
                if ( answer.question_id === question_id ) {
                    answer.changed = true;
                }
            });
            const answerIndex = _.indexOf( answerCollection, _.find( answerCollection, { value }));
            answerIndex > -1 ? answerCollection.splice( answerIndex, 1 ) : answerCollection.push( answerObject );
            // Check the answerCollection for any answers related to this question
            // If there are no answers, push a null answer to the collection to wipe previous answers in the DB
            const emptyCheckIndex = _.indexOf(answerCollection, _.find(answerCollection, { question_id }));
            emptyCheckIndex === -1 && answerCollection.push({ value: null, question_id: question_id, dataWipe: true, changed: true })
        } else {
            // Get the index of the old answer for this question in the answerCollection
            const answerIndex = _.indexOf( answerCollection, _.find( answerCollection,
                { question_id }));
            // If there is an old answer, remove it and replace with new answerObject
            // If not, push the new answerObject onto the end of the answerCollection
            answerIndex > -1 ? answerCollection.splice( answerIndex, 1, answerObject ) : answerCollection.push( answerObject );
        }
        this.setState({editAnswer:true});
        this.props.adminActions.updateGAPSAssessmentAnswers(answerCollection);
        if ( _.indexOf(this.state.unsavedAssessments, assessment ) === -1 ) {
            let unsavedAssessments = _.cloneDeep(this.state.unsavedAssessments);
            unsavedAssessments.push(assessment);
            this.setState({ unsavedAssessments: unsavedAssessments });
        }
    }

    getCurrentAssessment( shortName ) {
        return _.find(this.props.carePlanQuestions.sections, (assessment) => {
            return assessment.short_name === shortName;
        });
    }

    isUnsaved(assessmentKey) {
        return false;
    }

    isMandatoryFilled() {
        let error = false;
        const {unsavedAssessments} = this.state;
        const {assessmentAnswerCollection,carePlanQuestions,gapsDisabled} = this.props;
        if(gapsDisabled == false && unsavedAssessments.length == 0){
            return false;
        }
        _.each(unsavedAssessments,(assessment)=>{
            const sectionArray = _.filter(carePlanQuestions.sections,(section)=>{
                return section.short_name == assessment;
            });
            const questionObject = {};
            if(sectionArray.length > 0){
                const section = sectionArray[0];
                _.each(section.questions,(que)=>{
                    questionObject[que.id] = que;
                    // if(que.required){
                    let validAnswerIndex = '';
                    if(section.short_name == 'assess_sd'){
                        validAnswerIndex = _.find(assessmentAnswerCollection,(answer)=>{
                            return que.type === "TEXT_AREA" || answer.question_id == que.id && answer.value
                        });
                    }else{
                        validAnswerIndex = _.find(assessmentAnswerCollection,(answer)=>{
                            return answer.question_id == que.id && answer.value
                        });

                    }
                    if(!validAnswerIndex){
                        error = true;
                    }
                });
            }
        });
        return !error;
    }

    resetAll(sectionId){
        return new Promise((resolve,reject)=>{
            const {carePlanQuestions,assessmentAnswerCollection} = this.props;
            const sectionArray = _.filter(carePlanQuestions.sections,(section)=>{
                return section.id == sectionId;
            });
            const questionObject = {};
            if(sectionArray.length > 0){
                const section = sectionArray[0];
                _.each(section.questions,(que)=>{
                    questionObject[que.id] = que;
                    _.each(que.options,(option)=>{
                        _.each(option.questions,(optionQue)=>{
                            questionObject[optionQue.id] = optionQue;
                        })
                    });
                })
            }
            const cleanAnswerCollection = [];
            _.each(assessmentAnswerCollection,(answer)=>{
                const queId =  answer.question_id;
                const questionObj = questionObject[queId];
                if(questionObj){
                    //PICK_MANY
                    cleanAnswerCollection.push({ value: null, question_id: answer.question_id ,dataWipe: true, changed: true})
                }else{
                    cleanAnswerCollection.push(answer);
                }

            });
            //set final answer list
            this.props.adminActions.updateGAPSAssessmentAnswers(cleanAnswerCollection);
            resolve();
        })
        //{ value: null, question_id: answer.question_id }

    }

    clearAnswer(assessment,questionsToRemove,answerObject) {

        let answerCollection = _.cloneDeep(this.props.assessmentAnswerCollection);
        _.each(questionsToRemove, (que) => {
            //Collect all existing answers other than current answer of current 'que'
            answerCollection = _.filter(answerCollection, (answer) => {
                return answer.question_id !== que.id;
            });

            if (que.type === 'PICK_MANY') {
                const quesAnsArray = _.filter(answerCollection, (answer) => {
                    return answer.question_id === que.id;
                });
                if (quesAnsArray.length === 0) {
                    // Check the answerCollection for any answers related to this question
                    // If there are no answers, push a null answer to the collection to wipe previous answers in the DB
                    answerCollection.push({value: null, question_id: que.id, dataWipe: true, changed: true});
                }

            }
        });
        //After above loop, all answers are removed, now add newly selected radio option in the answers aray
        answerCollection.push(answerObject);

        //reset editAnswer flag
        this.setState({editAnswer: true});
        //set final answer list
        this.props.adminActions.updateGAPSAssessmentAnswers(answerCollection);
        //set final answer list
        if ( _.indexOf(this.state.unsavedAssessments, assessment ) === -1 ) {
            let unsavedAssessments = _.cloneDeep(this.state.unsavedAssessments);
            unsavedAssessments.push(assessment);
            this.setState({ unsavedAssessments: unsavedAssessments });
        }
    }

    getSectionQuestions(){
        const {carePlanQuestions,assessmentAnswerCollection,gapsDisabled} = this.props;
        if(carePlanQuestions){
            const section = carePlanQuestions.sections[0];
            return <AssessmentCategoryRow
                hideTitle={true}
                disabled={gapsDisabled}
                key={section.id}
                sectionId={section.id}
                editAnswer={this.state.editAnswer}
                resetAll={this.resetAll.bind(this)}
                title={section.title}
                shortName={section.short_name}
                open={true}
                handleToggle={() => {}}
                assessmentQuestions={this.getCurrentAssessment(section.short_name)}
                answerCollection={assessmentAnswerCollection}
                assessmentQuestionCollection={carePlanQuestions}
                updateInput={this.updateInput.bind(this)}
                clearAnswer={this.clearAnswer.bind(this)}
                bsStyle={this.isUnsaved(section.short_name)}
                riskObject={{ text: '', display: false }}
            />
        }
        return [];
    }

    onActionPlanChanged(actionPlan){
        this.setState({actionPlan});
    }

    onNotesChanged(e){
        this.setState({notes:e.target.value})
    }

    onModalClose(){
        const section = this.props.carePlanQuestions.sections[0];
        this.resetAll(section.id);
        this.setState({
            newEngagement:false,
            notes:'',
            unsavedAssessments:[],
            actionPlan:null
        })
        this.props.onPopupHide()
    }
    render() {
        const {activeUser,carePlan,assessmentAnswerCollection,gapsDisabled,engagements} = this.props;
        const{newEngagement} = this.state;
        const isRPMUser = activeUser && activeUser.center && activeUser.center.rpm_flag;
        const actionText = carePlan && `GAPS ${carePlan.gaps_care_plan_type}`;
        const disabled = !this.isMandatoryFilled();
        const addDisabled = carePlan && (carePlan.gaps_care_plan_status_id == 3 ||
            carePlan.gaps_care_plan_status_id == 4);
        return (
            <Modal show={this.props.show} dialogClassName={'modal-70w'}>
                <Modal.Header closeButton onHide={this.onModalClose.bind(this)}>
                    <Modal.Title>
                        <div style={
                            {
                                textAlign: 'center'
                            }
                        }>
                            <div>{ actionText }</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="clearfix">
                        {this.getSectionQuestions()}
                        <div style={{padding:15,paddingTop:0}}>
                            <label className={'control-label'}>Action Plan Engagement</label>
                            {
                                newEngagement || gapsDisabled == false ? <div className={'actionPlanBox'}>
                                    <div style={{display:'flex',flex:1}}>
                                        <div className={'actionPlanOptions1'}>
                                            {
                                                _.map(actionPlans,(actionPlan) => {
                                                    return <div style={{display:'flex'}} key={actionPlan.label}>
                                                        <input type="radio" name="actionPlanRadio"
                                                               style={{height:'15px !important'}}
                                                               value={actionPlan.code}
                                                               onChange={this.onActionPlanChanged.bind(this,actionPlan)}/>
                                                        <div style={{marginLeft:15,fontWeight:actionPlan.attributes ? 600 : 'normal'}}>
                                                            {actionPlan.label}
                                                        </div>
                                                    </div>
                                                })
                                            }


                                        </div>
                                        <div className={'actionPlanOptions2'}>
                                            {
                                                _.map(actionPlans2,(actionPlan) => {
                                                    return <div style={{display:'flex'}} key={actionPlan.label}>
                                                        <input type="radio" name="actionPlanRadio"
                                                               style={{height:'15px !important'}}
                                                               value={actionPlan.code}
                                                               onChange={this.onActionPlanChanged.bind(this,actionPlan)}/>
                                                        <div style={{marginLeft:15,fontWeight:actionPlan.attributes ? 600 : 'normal'}}>
                                                            {actionPlan.label}
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                    {
                                        this.state.actionPlan && this.state.actionPlan.attributes &&
                                        <div className={'actionPlanOptions1'}>
                                            <div style={{paddingBottom:15,fontStyle:'italic'}}>Appointment Detail</div>
                                            <Grid style={{width:'100%'}}>
                                                <Row>
                                                    <Col xs={6}>
                                                        <div style={{display:'flex',alignItems:'center'}}>
                                                            <div style={{width:85}}>Date</div>
                                                            <div style={{display:'flex',flex:1}}>
                                                                <DateTimeField id="appointmentDate"
                                                                               style={{width:'100%'}}
                                                                               mode="date"
                                                                               minDate={moment()}
                                                                               inputFormat="MM/DD/YYYY"
                                                                               defaultText=''
                                                                               inputProps={{
                                                                                   maxLength: 10,
                                                                                   style: { height: '30px !important' },
                                                                                   ref: (ref) => { this.dateInput = ref },
                                                                                   placeholder: 'Date (MM/DD/YYYY)',
                                                                               }}
                                                                               iconStyle={{
                                                                                   class: 'fa',
                                                                                   time: 'fa-clock-o',
                                                                                   calendar: 'fa-calendar',
                                                                                   up: 'fa-chevron-up',
                                                                                   down: 'fa-chevron-down',
                                                                                   right: 'fa-chevron-right',
                                                                                   left: 'fa-chevron-left',
                                                                               }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <div style={{display:'flex',alignItems:'center'}}>
                                                            <div style={{width:85}}>Time</div>
                                                            <div style={{display:'flex',flex:1}}>
                                                                <input id="time"
                                                                       type="text"
                                                                       className="form-control"
                                                                       style={{ height: '30px !important',  width:'100%',marginRight: '30px' }}
                                                                       ref={(ref) => { this.timeInput = ref; }}
                                                                       placeholder="Time"
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12}>
                                                        <div style={{marginTop:15,display:'flex',alignItems:'center'}}>
                                                            <div style={{width:85}}>Location</div>
                                                            <div style={{display:'flex',flex:1}}>
                                                                <input id="location"
                                                                       type="text"
                                                                       className="form-control"
                                                                       style={{ height: '30px !important', width:'100%',marginRight: '30px' }}
                                                                       ref={(ref) => { this.locationInput = ref; }}
                                                                       placeholder="Location"
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Grid>
                                        </div>
                                    }
                                    <div>
                                        <label className={'control-label'}>Engagement Notes</label>
                                        <div>
                                            <textarea style={{padding:10,width:'100%'}}
                                                    id="contactPreferenceTextArea"
                                                    value={this.state.notes}
                                                    onChange={this.onNotesChanged.bind(this)}
                                                    maxLength={250}/>
                                            <div style={{display:'flex',justifyContent:'flex-end'}}>
                                                {`${this.state.notes ? this.state.notes.length : 0}/250`}
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                    :
                                    <div>
                                        {
                                            _.map(engagements,(data) => {
                                                return <EngagementComp key={data.gaps_care_plan_engagement_id} engagement={data}/>
                                            })
                                        }
                                    </div>
                            }


                        </div>

                        <div style={{textAlign:'center',paddingTop:10}}>

                            {
                                newEngagement || gapsDisabled == false?
                                    <div>
                                        <Button onClick={this.onSubmit.bind(this,3)} bsStyle="success"
                                                style={{float:'right',marginRight:15}}>
                                            Complete Action Plan
                                        </Button>
                                        <Button  bsStyle="primary" onClick={this.onSubmit.bind(this,2)}
                                                style={{float:'right',marginRight:15,width:160}}>
                                            Keep Open
                                        </Button>
                                        <Button onClick={this.onSubmit.bind(this,4)} bsStyle="danger"
                                        style={{float:'right',marginRight:15,width:160}}>
                                        Close Out
                                        </Button>
                                    </div>
                                    :
                                    <Button disabled={addDisabled || disabled} bsStyle="primary" onClick={this.onAddNewEngagement.bind(this)}
                                            style={{float:'right',marginRight:15,width:160}}>
                                        Add New Engagement
                                    </Button>
                            }



                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

}

const mapStateToProps = (state) => ({
    'activeUser': state.admin.activeUser,
    'user': state.auth.user,
    'selectedPatient':state.app.selectedPatient,
    'carePlanQuestions':state.admin.carePlanQuestions,
    'carePlanTypes': state.admin.carePlanTypes,
    'patient': state.app.selectedPatient,
    'selectedPatientPharmacyOptions':state.app.selectedPatientPharmacyOptions,
    'patientCcmProviders': state.app.patientCcmProviders,
    'selectedPatientChartspanProviders':state.app.selectedPatientChartspanProviders,
    'assessmentAnswerCollection': state.admin.gapsAssessmentAnswers,
    'gapsDisabled':state.admin.gapsDisabled
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GAPSPlanAssessment);
