import { checkHttpStatus, parseJSON } from '../../utils';
import CONSTANTS from '../../constants';
import config from '../../config';
import {updateAccessToken} from '../authactions';
import {showToast,hideToast,get,post,deleteRequest,put} from '../common';
import {pushState} from 'redux-router';
import _ from 'lodash';
import moment from 'moment';

const {
    CCM_NOTIFICATION_UNREAD_REQUEST_SENT,
    CCM_NOTIFICATION_UNREAD_REQUEST_SUCCESS,
    CCM_NOTIFICATION_UNREAD_REQUEST_FAIL,
    CCM_NOTIFICATION_THREADS_REQUEST_SENT,
    CCM_NOTIFICATION_THREADS_REQUEST_SUCCESS,
    CCM_NOTIFICATION_THREADS_REQUEST_FAIL,
    CCM_NOTIFICATION_PATIENT_DATA_REQUEST_SENT,
    CCM_NOTIFICATION_PATIENT_DATA_REQUEST_SUCCESS,
    CCM_NOTIFICATION_PATIENT_DATA_REQUEST_FAIL,
    CCM_PATIENT_NOTIFICATION_UNREAD_REQUEST_SENT,
    CCM_PATIENT_NOTIFICATION_UNREAD_REQUEST_SUCCESS,
    CCM_PATIENT_NOTIFICATION_UNREAD_REQUEST_FAIL,
    CCM_PATIENT_NOTIFICATION_MESSAGE_REQUEST_SENT,
    CCM_PATIENT_NOTIFICATION_MESSAGE_REQUEST_SUCCESS,
    CCM_PATIENT_NOTIFICATION_MESSAGE_REQUEST_FAIL,
    RESET_CCM_NOTIFICATION_THREAD_MESSAGE,
    CCM_READ_NOTIFICATION_THREAD_REQUEST_SENT,
    CCM_READ_NOTIFICATION_THREAD_REQUEST_SUCCESS,
    CCM_READ_NOTIFICATION_THREAD_REQUEST_FAIL,
    CCM_NOTIFICATION_REPLY_REQUEST_SENT,
    CCM_NOTIFICATION_REPLY_REQUEST_SUCCESS,
    CCM_NOTIFICATION_REPLY_REQUEST_FAIL,
	REQUEST_SENT,
	REQUEST_COMPLETE,
    CCM_CASE_MESSAGES_UNREAD_REQUEST_SUCCESS,
    CCM_CASE_MESSAGES_UNREAD_REQUEST_FAILED,
    CCM_CASE_MESSAGES_LIST_REQUEST_SUCCESS,
    CCM_CASE_MESSAGES_LIST_REQUEST_FAILED,
    PATIENT_CASE_MESSAGES_REQUEST_SUCCESS,
    PATIENT_CASE_MESSAGES_UNREAD_CHECK_SUCCESS,
    PATIENT_CASE_DETAIL_MESSAGES_SUCCESS,
    RESET_PATIENT_CASES
} = CONSTANTS;

export function getNotificationsUnreadCount(){
    return function (dispatch) {
        dispatch({type:CCM_NOTIFICATION_UNREAD_REQUEST_SENT});
        var endpoint = encodeURI('/v1/notifications/ccm/unreadcount')
        get(endpoint)
            .then((result)=> {
                dispatch({
                    type: CCM_NOTIFICATION_UNREAD_REQUEST_SUCCESS,
                    payload: result
                });
            }).catch(error => {
            dispatch({
                type: CCM_NOTIFICATION_UNREAD_REQUEST_FAIL
            });
            if(error.code !== 'session_timeout'){
                dispatch(showToast({
                    status: 'error',
                    title: "Fail",
                    message: "Failed to get unread count for the notifications"
                }));
                var interval = setInterval(()=> {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                parseJSON(error).then((errorObj)=> {
                    if (errorObj.message && errorObj.message.indexOf('JWT expired') != -1) {
                        dispatch(pushState(null, '/login'));
                    }
                    console.log(errorObj);
                })
            }

        })
    }
}

export function getNotificationThreads(pageNum,pageSize){
    return function (dispatch) {
        dispatch({type:CCM_NOTIFICATION_THREADS_REQUEST_SENT});
        var endpoint = encodeURI('/v1/notifications/ccm/threads');
        var hreadersParam = {
            'X-Page':pageNum,
            'X-Page-Size':pageSize
        }
        get(endpoint,hreadersParam)
            .then((result)=> {
                dispatch({
                    type: CCM_NOTIFICATION_THREADS_REQUEST_SUCCESS,
                    payload: result
                });
            }).catch(error => {
            dispatch({
                type: CCM_NOTIFICATION_THREADS_REQUEST_FAIL
            });
            dispatch(showToast({
                status: 'error',
                title: "Fail",
                message: "Failed to get notifications detail"
            }));
            var interval = setInterval(()=> {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
            parseJSON(error).then((errorObj)=> {
                if (errorObj.message && errorObj.message.indexOf('JWT expired') != -1) {
                    dispatch(pushState(null, '/login'));
                }
                console.log(errorObj);
            })
        })
    }
}

export function getPatientData(patientId){
    return function (dispatch) {
        dispatch({type:CCM_NOTIFICATION_PATIENT_DATA_REQUEST_SENT});
        var endpoint = encodeURI('/v1/patients/'+patientId);
        get(endpoint)
            .then((result)=> {
                dispatch({
                    type: CCM_NOTIFICATION_PATIENT_DATA_REQUEST_SUCCESS,
                    payload: result
                });
            }).catch(error => {
            dispatch({
                type: CCM_NOTIFICATION_PATIENT_DATA_REQUEST_FAIL
            });
            dispatch(showToast({
                status: 'error',
                title: "Fail",
                message: "Failed to get details for the selected patient"
            }));
            var interval = setInterval(()=> {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
            parseJSON(error).then((errorObj)=> {
                if (errorObj.message && errorObj.message.indexOf('JWT expired') != -1) {
                    dispatch(pushState(null, '/login'));
                }
                console.log(errorObj);
            })
        })
    }
}
export function getPatientNotificationsUnreadCount(patientId){
    return function (dispatch) {
        dispatch({type:CCM_PATIENT_NOTIFICATION_UNREAD_REQUEST_SENT});
        var endpoint = encodeURI('/v1/notifications/ccm/'+patientId+'/unreadcount')
        get(endpoint)
            .then((result)=> {
                dispatch({
                    type: CCM_PATIENT_NOTIFICATION_UNREAD_REQUEST_SUCCESS,
                    payload: result
                });
            }).catch(error => {
            dispatch({
                type: CCM_PATIENT_NOTIFICATION_UNREAD_REQUEST_FAIL
            });
            dispatch(showToast({
                status: 'error',
                title: "Fail",
                message: "Failed to get unread count for the patient"
            }));
            var interval = setInterval(()=> {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
            parseJSON(error).then((errorObj)=> {
                if (errorObj.message && errorObj.message.indexOf('JWT expired') != -1) {
                    dispatch(pushState(null, '/login'));
                }
                console.log(errorObj);
            })
        })
    }
}

export function getPatientNotificationMessage(provider_id, patientId){
    return function (dispatch) {
        dispatch({type: CCM_PATIENT_NOTIFICATION_MESSAGE_REQUEST_SENT});
        var endpoint = encodeURI('/v1/notifications/ccm/thread/patient/'+patientId);
        get(endpoint)
            .then((result)=> {
                // dispatch({
                //     type: CCM_PATIENT_NOTIFICATION_MESSAGE_REQUEST_SUCCESS,
                //     payload: result
                // });
                getPatientNotificationProviders(result)(dispatch)
            }).catch(error => {
            dispatch({
                type: CCM_PATIENT_NOTIFICATION_MESSAGE_REQUEST_FAIL
            });
            dispatch(showToast({
                status: 'error',
                title: "Fail",
                message: "Failed to get notifications for the selected patient"
            }));
            var interval = setInterval(()=> {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
            parseJSON(error).then((errorObj)=> {
                if (errorObj.message && errorObj.message.indexOf('JWT expired') != -1) {
                    dispatch(pushState(null, '/login'));
                }
                console.log(errorObj);
            })
        })
    }
}

export function getPatientNotificationProviders(notifications, second){
	return function (dispatch) {
		dispatch({type: REQUEST_SENT});
		let endpoint = '/v1/providers';
		let provider_ids = [];
		_.forEach(notifications['items'], (notification) => {
			if ( _.indexOf(provider_ids, notification['provider_id']) === -1 ) {
				if ( provider_ids.length === 0 ) {
					endpoint = `${endpoint}?providerIds=${notification['provider_id']}`
				} else {
					endpoint = `${endpoint}&providerIds=${notification['provider_id']}`
				}
				provider_ids.push(notification['provider_id'])
			}
		});
		if ( provider_ids.length ) {
			get(endpoint)
				.then((result)=> {
					_.forEach(notifications['items'], (notification) => {
						notification['provider_object'] = _.find(result, {'id': notification['provider_id']})
					});
					dispatch({
						type: CCM_PATIENT_NOTIFICATION_MESSAGE_REQUEST_SUCCESS,
						payload: notifications
					});
					dispatch({
						type: REQUEST_COMPLETE
					})
				}).catch(error => {
				dispatch({
					type: REQUEST_COMPLETE
				});
				dispatch(showToast({
					status: 'error',
					title: "Fail",
					message: "Failed to get notification providers for the selected patient"
				}));
				let interval = setInterval(()=> {
					clearInterval(interval);
					dispatch(hideToast());
				}, 2);
				parseJSON(error).then((errorObj)=> {
					if (errorObj.message && errorObj.message.indexOf('JWT expired') != -1) {
						dispatch(pushState(null, '/login'));
					}
					console.log(errorObj);
				})
			})
		} else {
			dispatch({
				type: CCM_PATIENT_NOTIFICATION_MESSAGE_REQUEST_SUCCESS,
				payload: notifications
			});
			dispatch({
				type: REQUEST_COMPLETE
			})
		}

	}
}

export function resetNotificationThreadMessage(){
    return function (dispatch) {
        dispatch({type: RESET_CCM_NOTIFICATION_THREAD_MESSAGE});
    }
}

export const markThreadRead = (patientId) => {
    return function (dispatch) {
        dispatch({type: CCM_READ_NOTIFICATION_THREAD_REQUEST_SENT});
        const endpoint = encodeURI('/v1/notifications/ccm/thread/patient/'+patientId+'/read');
        put(endpoint, {})
            .then((result)=> {
                dispatch({
                    type: CCM_READ_NOTIFICATION_THREAD_REQUEST_SUCCESS,
                    payload: result
                });
                getNotificationsUnreadCount()(dispatch);
                getPatientNotificationsUnreadCount(patientId)(dispatch)
            })
            .catch(error => {
                dispatch({type: CCM_READ_NOTIFICATION_THREAD_REQUEST_FAIL});
                dispatch(showToast({
                    status: 'error',
                    title: "Fail",
                    message: "Failed to mark message status as 'Read'"
                }));
                var interval = setInterval(()=> {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                parseJSON(error).then((errorObj)=> {
                    if (errorObj.message && errorObj.message.indexOf('JWT expired') != -1) {
                        dispatch(pushState(null, '/login'));
                    }
                    console.log(errorObj);
                })
            });
    }
}

export function markNotificationRead(patientId,readNotificationData){
    return function (dispatch) {
        dispatch({type: CCM_READ_NOTIFICATION_THREAD_REQUEST_SENT});
        var endpoint = encodeURI('/v1/notifications/ccm');
        var notificationDetails = readNotificationData;

        put(endpoint, notificationDetails)
            .then((result)=> {
                dispatch({
                    type: CCM_READ_NOTIFICATION_THREAD_REQUEST_SUCCESS,
                    payload: result
                });
                getNotificationsUnreadCount()(dispatch);
                getPatientNotificationsUnreadCount(patientId)(dispatch)
            })
            .catch(error => {
                dispatch({type: CCM_READ_NOTIFICATION_THREAD_REQUEST_FAIL});
                dispatch(showToast({
                    status: 'error',
                    title: "Fail",
                    message: "Failed to mark message status as 'Read'"
                }));
                var interval = setInterval(()=> {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                parseJSON(error).then((errorObj)=> {
                    if (errorObj.message && errorObj.message.indexOf('JWT expired') != -1) {
                        dispatch(pushState(null, '/login'));
                    }
                    console.log(errorObj);
                })
            });
    }
}

export function sentNotificationReply(notificationReplyData){
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch({type: CCM_NOTIFICATION_REPLY_REQUEST_SENT});
        const endpoint = encodeURI('/v1/notifications/ccm');
        post(endpoint,notificationReplyData)
            .then((result)=> {
                dispatch({
                    type: CCM_NOTIFICATION_REPLY_REQUEST_SUCCESS
                });
                getPatientNotificationMessage(notificationReplyData.provider_id,notificationReplyData.patient_id)(dispatch);
                resolve({ ok: true })
            })
            .catch(error => {
                dispatch({
                    type: CCM_NOTIFICATION_REPLY_REQUEST_FAIL,
                });
                dispatch(showToast({
                    status: 'error',
                    title: "Fail",
                    message: "Failed to send notification"
                }));
                const interval = setInterval(()=> {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                parseJSON(error).then((errorObj)=> {
                    if (errorObj && errorObj.message && errorObj.message.indexOf('JWT expired') != -1) {
                        dispatch(pushState(null, '/login'));
                    }
                    console.log(errorObj);
                });
                resolve({ ok: false })
            });
    })
}

export const unreadThread = (patientId) => {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch({type: CCM_NOTIFICATION_REPLY_REQUEST_SENT});
        const endpoint = encodeURI(`/v1/notifications/thread/patient/${patientId}/unread`);
        put(endpoint,{})
        .then(result => {
            getNotificationsUnreadCount()(dispatch);
            getPatientNotificationsUnreadCount(patientId)(dispatch);
            resolve({ ok: true })
        })
        .catch(error => {
                dispatch({
                    type: CCM_NOTIFICATION_REPLY_REQUEST_FAIL,
                });
                dispatch(showToast({
                    status: 'error',
                    title: "Fail",
                    message: "Failed to mark unread"
                }));
                const interval = setInterval(()=> {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                resolve({ ok: false })
        })

    })
}

export const searchByUserInput = ( userInput = null, DOB = null, queryTags=null,queryPriority=null,pageNum = 0, pageSize = 20 ) => {
    return ( dispatch ) => {
        let loggedinUser = localStorage.getItem('ccmLoggedinUser');
        let loggedinUserObj = null;
        if (loggedinUser !== null) {
            loggedinUserObj = JSON.parse(loggedinUser);
        }
        let endpoint = '/v1/notifications/ccm/search?';
        let tagQueries = '';
        _.each(queryTags,(tag)=>{
            tagQueries += '&queryTags='+tag;
        });
        tagQueries = tagQueries.substring(1);
        let params = '';
        if(userInput){
            params = 'queryFields=' + userInput;
        }

        if(DOB){
            if(params){
                params += '&queryDob=' + moment(DOB).format('MM/DD/YYYY');
            }else{
                params += 'queryDob=' + moment(DOB).format('MM/DD/YYYY');
            }
        }

        if(tagQueries){
            if(params){
                params += '&'+tagQueries;
            }else{
                params += tagQueries;
            }
        }
        if(queryPriority){
            if(params){
                params += '&queryPriority=1';
            }else{
                params += 'queryPriority=1';
            }
        }

        endpoint += params;

        let headers = {
            'X-Page': pageNum,
            'X-Page-Size': pageSize,
            'X-AUTH-TOKEN': loggedinUserObj.token
        }
        dispatch({ type: CCM_NOTIFICATION_THREADS_REQUEST_SENT });
        get( endpoint, headers )
            .then( ( response ) => {
                dispatch({ type: CCM_NOTIFICATION_THREADS_REQUEST_SUCCESS, payload: response });
            })
            .catch( ( error ) => {
                dispatch({ type: CCM_NOTIFICATION_THREADS_REQUEST_FAIL });
                dispatch( showToast({
                    status: 'error',
                    title: "Fail",
                    message: "Failed to get notifications detail"
                }));
                var interval = setInterval( () => {
                    clearInterval( interval );
                    dispatch( hideToast() );
                }, 2);
                parseJSON( error ).then( ( errorObj ) => {
                    if ( errorObj.message && errorObj.message.indexOf( 'JWT expired' ) != -1 ) {
                        dispatch( pushState( null, '/login' ) );
                    }
                    console.log( errorObj );
                });
            });
    }
}


export function checkCaseMessagesUnread(){
    return function (dispatch) {
        var endpoint = encodeURI('/v1/cases/check-unread')
        get(endpoint)
            .then((result)=> {
                dispatch({
                    type: CCM_CASE_MESSAGES_UNREAD_REQUEST_SUCCESS,
                    payload: result
                });
            }).catch(error => {
            dispatch({
                type: CCM_CASE_MESSAGES_UNREAD_REQUEST_FAILED
            });
            if(error.code !== 'session_timeout'){
                dispatch(showToast({
                    status: 'error',
                    title: "Fail",
                    message: "Failed to check unread case messages"
                }));
                var interval = setInterval(()=> {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
                parseJSON(error).then((errorObj)=> {
                    if (errorObj.message && errorObj.message.indexOf('JWT expired') != -1) {
                        dispatch(pushState(null, '/login'));
                    }
                    console.log(errorObj);
                })
            }

        })
    }
}

export function getCaseMessages(){
    return function (dispatch) {
        var endpoint = encodeURI('/v1/cases/unread');
        get(endpoint)
            .then((result)=> {
                dispatch(checkCaseMessagesUnread());
                dispatch({
                    type: CCM_CASE_MESSAGES_LIST_REQUEST_SUCCESS,
                    payload: result
                });
            }).catch(error => {
            dispatch({
                type: CCM_CASE_MESSAGES_LIST_REQUEST_FAILED
            });
            dispatch(showToast({
                status: 'error',
                title: "Fail",
                message: "Failed to get case messages"
            }));
            var interval = setInterval(()=> {
                clearInterval(interval);
                dispatch(hideToast());
            }, 2);
            parseJSON(error).then((errorObj)=> {
                if (errorObj.message && errorObj.message.indexOf('JWT expired') != -1) {
                    dispatch(pushState(null, '/login'));
                }
                console.log(errorObj);
            })
        })
    }
}

export function getEHRPatientData(ccmOrgId, patientEhrId){
    return function (dispatch) {
        return new Promise((resolve,reject) => {
            dispatch({type:CCM_NOTIFICATION_PATIENT_DATA_REQUEST_SENT});
            var endpoint = encodeURI(`/v1/organizations/${ccmOrgId}/patients/${patientEhrId}`);
            get(endpoint)
                .then((result)=> {
                    dispatch({
                        type: CCM_NOTIFICATION_PATIENT_DATA_REQUEST_SUCCESS,
                        payload: result
                    });
                    resolve(result)
                }).catch(error => {
                    dispatch({
                        type: CCM_NOTIFICATION_PATIENT_DATA_REQUEST_FAIL
                    });
                    parseJSON(error).then((errorObj)=> {
                        dispatch(showToast({
                            status: 'error',
                            title: "Fail",
                            message: errorObj.message
                        }));
                        var interval = setInterval(()=> {
                            clearInterval(interval);
                            dispatch(hideToast());
                        }, 2);

                        if (errorObj.message && errorObj.message.indexOf('JWT expired') != -1) {
                            dispatch(pushState(null, '/login'));
                        }
                        console.log(errorObj);
                    })
                    reject(error)
                })
        })
       
    }
}

export function checkUnreadMessageForPatient (patient_id) {
    return async dispatch => {
        const checkMessagesEndpoint = encodeURI(`/v1/patient/${patient_id}/has-unread-cases`);  
        const unreadCasesResult = await get(checkMessagesEndpoint);
        dispatch({type:PATIENT_CASE_MESSAGES_UNREAD_CHECK_SUCCESS, payload:{
            unreadCases:unreadCasesResult,
        }});
    }
}
export function getPatientRinconInfo(ccmOrgId, patientEhrId) {
    return function (dispatch) {
        return new Promise(async(resolve, reject) => {
            try{
                const endpoint = encodeURI(`/v1/rincon/organizations/${ccmOrgId}`);
                const result = await get(endpoint);
                const rinconOrgId = result.id;
                const patientRinconIDEndpoint = encodeURI(`/v2/rincon/organizations/${rinconOrgId}/patients/${patientEhrId}`);   
                const patientRinconData = await get(patientRinconIDEndpoint);
                if(patientRinconData.patient_id){
                    const payload = {
                        rinconOrgId,
                        patientRinconId:patientRinconData.patient_id,
                        providerRinconId:patientRinconData.provider_id
                    };

                    dispatch({type:PATIENT_CASE_MESSAGES_UNREAD_CHECK_SUCCESS, payload});
                    resolve(payload);
                }
                reject({message:"patient_id not found"})
            }catch(error){
                reject(error);
            }

        })
    }
}
export function getPatientUnreadMessages(ccmOrgId, patientEhrId) {
    return function (dispatch) {
        return new Promise(async(resolve,reject) => {
            try{
                dispatch({type:CCM_NOTIFICATION_PATIENT_DATA_REQUEST_SENT});
                const endpoint = encodeURI(`/v1/rincon/organizations/${ccmOrgId}`);
                const result = await get(endpoint);
                const rinconOrgId = result.id;
                const patientRinconIDEndpoint = encodeURI(`/v2/rincon/organizations/${rinconOrgId}/patients/${patientEhrId}`);   
                const patientRinconData = await get(patientRinconIDEndpoint);
                if(patientRinconData.patient_id){
                    
                    dispatch({type:PATIENT_CASE_MESSAGES_UNREAD_CHECK_SUCCESS, payload:{
                        rinconOrgId,
                        patientRinconId:patientRinconData.patient_id,
                        providerRinconId:patientRinconData.provider_id
                    }});
                    dispatch(checkUnreadMessageForPatient(patientRinconData.patient_id));
                }else{
                    dispatch({type:PATIENT_CASE_MESSAGES_UNREAD_CHECK_SUCCESS});
                }
            }catch(error){
                dispatch({type:PATIENT_CASE_MESSAGES_UNREAD_CHECK_SUCCESS});
            }
        })
    }
}

export function getPatientCases (rinconOrgId,patientRinconId) {
    return function (dispatch) {
        return new Promise(async(resolve,reject) => {
            try{
            dispatch({type:CCM_NOTIFICATION_PATIENT_DATA_REQUEST_SENT});
            if(patientRinconId){
                const caseMessagesEndpoint = encodeURI(`/v1/patient/${patientRinconId}/cases`);    
                const caseMessages = await  get(caseMessagesEndpoint);
                dispatch({
                    type: PATIENT_CASE_MESSAGES_REQUEST_SUCCESS,
                    payload: caseMessages
                });
                //get cases
                resolve(caseMessages);
            }else{
                    dispatch({
                        type: PATIENT_CASE_MESSAGES_REQUEST_SUCCESS,
                        payload: []
                    });
                }
                    //catch
            }catch(error){
                parseJSON(error).then((errorObj)=> {
                    dispatch(showToast({
                        status: 'error',
                        title: "Fail",
                        message: errorObj.message
                    }));
                    var interval = setInterval(()=> {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);

                    if (errorObj.message && errorObj.message.indexOf('JWT expired') != -1) {
                        dispatch(pushState(null, '/login'));
                    }
                    console.log(errorObj);
                });
                reject(error)
            }
        })
    };
}


export function resetCaseMessages(){
    return dispatch => {
        dispatch({
            type: PATIENT_CASE_DETAIL_MESSAGES_SUCCESS,
            payload: undefined
        });
    }
}

export function resetPatientCases(){
    return dispatch => {
        dispatch({
            type: RESET_PATIENT_CASES
        });
    }
}


export function getPatientCaseMessagesById (caseId) {
    return function (dispatch) {
        return new Promise(async(resolve,reject) => {
            try{
                dispatch({type:CCM_NOTIFICATION_PATIENT_DATA_REQUEST_SENT});
                const caseMessagesEndpoint = encodeURI(`/v1/rincon/cases/${caseId}/messages`);    
                const caseMessages = await  get(caseMessagesEndpoint);
                dispatch({
                    type: PATIENT_CASE_DETAIL_MESSAGES_SUCCESS,
                    payload: caseMessages
                });
                resolve(caseMessages);
                    //catch
            }catch(error){
                parseJSON(error).then((errorObj)=> {
                    dispatch(showToast({
                        status: 'error',
                        title: "Fail",
                        message: errorObj.message
                    }));
                    var interval = setInterval(()=> {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);

                    if (errorObj.message && errorObj.message.indexOf('JWT expired') != -1) {
                        dispatch(pushState(null, '/login'));
                    }
                    console.log(errorObj);
                });
                reject(error)
            }
        })
    };
}

export function createCaseMessage(caseId, rinconOrgId,message) {
    return dispatch => {
        return new Promise(async(resolve,reject) => {
            try{
                dispatch({type:CCM_NOTIFICATION_PATIENT_DATA_REQUEST_SENT});
                const payload = {
                    "rincon_org_id": rinconOrgId,
                    "message": message
                };
                const caseMessagesEndpoint = encodeURI(`/v1/rincon/cases/${caseId}/messages`);    
                const result = await  post(caseMessagesEndpoint,payload);
                dispatch(getPatientCaseMessagesById(caseId));
                resolve(result)
            }catch(error){
                parseJSON(error).then((errorObj)=> {
                    dispatch(showToast({
                        status: 'error',
                        title: "Fail",
                        message: errorObj.message
                    }));
                    var interval = setInterval(()=> {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);

                    if (errorObj.message && errorObj.message.indexOf('JWT expired') != -1) {
                        dispatch(pushState(null, '/login'));
                    }
                    console.log(errorObj);
                });
                reject(error)
            }
           
        })
    }
}

export function markReadCaseMessages(caseId, messageIds,rinconOrgId,patientRinconId) {
    return dispatch => {
        return new Promise(async(resolve,reject) => {
            try{
                dispatch({type:CCM_NOTIFICATION_PATIENT_DATA_REQUEST_SENT});
                const payload = {
                    "case_message_ids": messageIds
                };
                const caseMessagesEndpoint = encodeURI(`/v1/rincon/cases/${caseId}/messages`);    
                const result = await  put(caseMessagesEndpoint,payload);
                dispatch(getPatientCases(rinconOrgId,patientRinconId));
                dispatch(checkUnreadMessageForPatient(patientRinconId));
                dispatch(checkCaseMessagesUnread());
                resolve(result)
            }catch(error){
                dispatch({type:CCM_NOTIFICATION_PATIENT_DATA_REQUEST_FAIL});
                parseJSON(error).then((errorObj)=> {
                    dispatch(showToast({
                        status: 'error',
                        title: "Fail",
                        message: errorObj.message
                    }));
                    var interval = setInterval(()=> {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);

                    if (errorObj.message && errorObj.message.indexOf('JWT expired') != -1) {
                        dispatch(pushState(null, '/login'));
                    }
                    console.log(errorObj);
                });
                reject(error)
            }
           
        })
    }
}



export function createPatientCase (rinconOrgId,ehr_pid,ehr_provider_id,
     locationEHRId,subject,priority, description, type,patientRinconId) {
   
    return dispatch => {
        return new Promise(async(resolve,reject) => {
            dispatch({type:CCM_NOTIFICATION_PATIENT_DATA_REQUEST_SENT});
            try{
                const payload = {
                    "org_id": rinconOrgId,
                    "ehr_pid": ehr_pid,
                    "location_ehr_id": locationEHRId,
                    "priority": priority,
                    "ehr_provider_id": ehr_provider_id,
                    "subject": subject,
                    "description":description,
                    "category":type
                };
                const caseMessagesEndpoint = encodeURI(`/v1/patient/${patientRinconId}/cases`);    
                await  post(caseMessagesEndpoint,payload);
                dispatch(getPatientCases(rinconOrgId,patientRinconId))
                resolve();

            }catch(error){
                dispatch({type:CCM_NOTIFICATION_PATIENT_DATA_REQUEST_FAIL});
                parseJSON(error).then((errorObj)=> {
                    dispatch(showToast({
                        status: 'error',
                        title: "Fail",
                        message: errorObj.message
                    }));
                    var interval = setInterval(()=> {
                        clearInterval(interval);
                        dispatch(hideToast());
                    }, 2);

                    if (errorObj.message && errorObj.message.indexOf('JWT expired') != -1) {
                        dispatch(pushState(null, '/login'));
                    }
                    console.log(errorObj);
                    reject(errorObj)
                });
               
            }
        });
    }
}