import React from 'react';
import PatientQueue from './PatientQueue';

const UnderSixtyFiveUnverifiedQueue = () =>
    <PatientQueue
	    showQueueCount
	    showFilters
        patientDetailColumn
        providerDetailColumn
        actionsColumn
        page
        lastOutCallColumn
        lastHistoryColumn
        queue="unverifiedUnder65"
        endpoint="UnverifiedUnder65"
        label="Unverified Under 65"
	    showSort
	    sortOptions={[
		    {
			    'name': 'Last Called Ascending',
			    'key': 'lastCallOutTs&dir=asc',
		    },
		    {
			    'name': 'Last Called Descending',
			    'key': 'lastCallOutTs&dir=desc',
		    }
	    ]}
    />;

export default UnderSixtyFiveUnverifiedQueue;
