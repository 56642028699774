import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Input,Modal,Button} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import LinkedStateMixin from 'react-addons-linked-state-mixin';

const UNENROLL_RADIO_LABELS = [
    "Patient has requested to be unenrolled. Notified patient to contact CCM provider to complete unenrollment form",
    "Patient is deceased",
    "Enrolled with another provider",
    "Received a bill",
    "Too many phone calls",
    "No longer with the provider",
];

class UnenrollPatientPopup extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            reason: null,
            notes: '',
            showNotes: false,
        }
    }

    showMessage(message, title = 'Invalid Value', status = 'error') {
        this.props.commonActions.showToast({
            status: status,
            title: title,
            message: message
        });
        var interval = setInterval(()=> {
            clearInterval(interval);
            this.props.commonActions.hideToast();
        }, 2);
    }

    onSubmit(e) {
        e.preventDefault();

        let {patient} = this.props;
        let {reason, notes} = this.state;

        if ( !reason ) {
            this.showMessage('Please Provide Reason for Unenrolling', 'Reason Missing');
            return;
        }
        if ( reason === 6 && !notes.trim() ) {
            this.showMessage( 'When selecting "other," notes must be provided', 'Reason missing' );
            return;
        }

        if(reason === 6){
            notes = "";
        }

        this.props.adminActions.unenrollPatient( patient.id, notes.trim(), reason );

        this.setState({ reason: null, notes: '' });

        this.props.onPopupHide();
    }

    onReasonChanged( e ) {
        let selectedReason = _.find( this.props.unenrollReasons, ( reason ) => reason.id === Number( e.target.value ) );
        if ( selectedReason.id === 6 ) {
            this.setState({ reason: selectedReason.id, showNotes: true });
            return;
        }
        this.setState({ reason: selectedReason.id, notes: selectedReason.reason, showNotes: false });
    }

    onCancel() {
        this.setState({reason: null});

        this.props.onPopupHide();
    }

    updateNotes( e ) {
        this.setState({ notes: e.target.value });
    }

    render() {
        let {patient} = this.props,
            name = '',
            provider = '',
            organization = '';

        if (patient) {
            name = patient.first_name + ' ' + patient.last_name;
            if (patient.provider) {
                provider = patient.provider.provider;
                organization = patient.provider.organization;
            }
        }

        let patientMarkup = <div className="value-box">{name}</div>,
            providerMarkup = <div>
                <div style={{clear:'both'}}>
                    <div className="value-box">{provider}</div>
                </div>

                <div style={{clear:'both'}}>
                    <div className="value-box">
                        <div>{organization}</div>
                    </div>
                </div>
            </div>;

        return (
            <Modal show={this.props.show}>
                <Modal.Header closeButton onHide={this.onCancel.bind(this)}>
                    <Modal.Title>
                        <div className="text-align-center">
                            <div>Unenroll Patient</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{borderBottom:'1px solid gray',height:100}}>
                        <div className="header-box">
                            <div className="header-label">
                                Patient
                            </div>
                            <div className="header-label">
                                Provider
                            </div>
                        </div>
                        <div className="ma-value-box">
                            <div className="value-label">
                                {patientMarkup}
                            </div>
                            <div className="value-label">
                                {providerMarkup}
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '10px'}}>
                        <form onSubmit={this.onSubmit.bind(this)}>
                            <p><b>Reason for Unenrolling:</b></p>

                            <select className="form-control" id="unenrollReasons" onChange={ this.onReasonChanged.bind( this )}>
                                <option value={0}>Select a reason for unenrolling patient</option>
                                {
                                    _.map( this.props.unenrollReasons, ( reasonObject ) => {
                                        return (
                                            <option key={ reasonObject.id } value={ reasonObject.id }>{ reasonObject.reason }</option>
                                        )
                                    })
                                }
                            </select>
                            { this.state.showNotes ?
                                <div style={{ marginTop: '15px' }}>
                                    <p><b>Notes:</b></p>
                                    <div>
                                        <textarea className="form-control" name="" id="" rows="10" value={ this.state.notes } onChange={ this.updateNotes.bind( this ) }></textarea>
                                        <div style={{display:'flex',justifyContent:'flex-end'}}>
											{`${this.state.notes ? this.state.notes.length : 0}/1024`}
										</div> 
                                    </div>    
                                </div>
                                :
                                null
                            }

                            <div style={{borderTop:'1px solid gray',height: 50,textAlign: 'center',paddingTop: 15, marginTop: '15px'}}>
                                <Button onClick={this.onCancel.bind(this)} style={{float:'left'}}>
                                    Cancel
                                </Button>
                                <Button bsStyle="danger" type="submit"  style={{float:'right'}}>
                                    Unenroll
                                </Button>

                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        );

    }
}
reactMixin(UnenrollPatientPopup.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    'user': state.auth.user,
    'unenrollReasons': state.admin.unenrollReasons,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UnenrollPatientPopup);
