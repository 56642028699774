import React from 'react';
import * as adminActionCreator from '../../actions/admin';
import * as appActionCreator from '../../actions/app';
import * as notificationsActionCreator from '../../actions/notifications';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, FormGroup, FormControl, InputGroup, Col, ControlLabel, Button, Grid, Row } from 'react-bootstrap';
import { reduxForm, getValues } from 'redux-form';
import _ from 'lodash';
import moment from 'moment';
import RequestRecordExtendedForm from './RequestRecordExtendedForm';

class RequestRecordForm extends React.Component {

	constructor() {
		super();
		this.state = {
			selectedProvider: false
		}
		this.returnProviders = this.returnProviders.bind(this);
	}

	componentWillReceiveProps( nextProps ) {
		if ( nextProps.admin.providerList && nextProps.admin.providerList.length ) {
			let providerList = nextProps.admin.providerList
			let formData = getValues(nextProps.formData && nextProps.formData && nextProps.formData.requestRecord) || {};
			let selectedProvider = _.find(providerList, (provider) => provider.name == formData.doctorName);
			this.setState({
				selectedProvider
			});
		}
	}

	returnProviders() {
		if (this.props.admin.providerList && this.props.admin.providerList.length) {
			return this.props.admin.providerList.map( (provider, idx) => {
				return (
					<option key={ idx } value={ provider.name }>{ provider.name } - { provider.specialty }</option>
				)
			});
		}
	}

	render() {
		const { fields: { doctorName }, previewRecord } = this.props;
		const inputStyle = { 'width': '100%', 'paddingLeft': '5px', 'borderRadius': '4px', 'border': '1px solid rgb(204, 204, 204)', 'height': '50px' };
		const inputStyleError = { 'width': '100%', 'paddingLeft': '5px', 'border': '1px solid rgba(169, 67, 66, 0.8)' };
		const labelStyle = { 'lineHeight': '50px' };
		const labelStyle2 = { 'margin': 'auto', 'display': 'block', 'marginBottom': '20px', 'textAlign': 'center' };
		const formStyle = { 'paddingBottom': '35px' };
		return (
			<Grid style={ formStyle }>
				<Row>
				<form onSubmit={ previewRecord }>
					<Col 	sm={12}
							style={{ 'marginTop': '15px' }}>
						<Col smOffset={1} sm={2}>
							<label style={ labelStyle }>Doctor Name</label>
						</Col>
						<Col sm={6}>
							<div style={{ 'lineHeight': '50px' }}>
								<select style={ inputStyle }
										{ ...doctorName }>
									<option>Please select a doctor</option>
									{ this.returnProviders() }
								</select>
							</div>
						</Col>
						<Col sm={12}>
							<span 	className="text-danger"
									style={ labelStyle2 }>
								<small>{ this.props.levelTwoDashboard.recordErrors && this.props.levelTwoDashboard.recordErrors.doctorName }</small>
							</span>
						</Col>
					</Col>
					{ this.state.selectedProvider && <RequestRecordExtendedForm initialValues={this.state.selectedProvider} />}
				</form>
				</Row>
			</Grid>
		)
	}
}

RequestRecordForm = reduxForm({
	form: 'requestRecord',
	fields: [ 'clinicName', 'doctorName', 'specialty', 'faxNumber', 'dateRangeBegin', 'dateRangeEnd' ]
})(RequestRecordForm);

const mapStateToProps = (state) => ({
	'levelTwoDashboard': state.levelTwoDashboard,
	'formData': state.form,
	'admin': state.admin
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    appActions:bindActionCreators(appActionCreator,dispatch),
    notificationsAction:bindActionCreators(notificationsActionCreator,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestRecordForm);