import React from 'react';
import { msToTime } from '../../utils';

const TimeBilledCell = ({ data: { work_millis_logged } }) => {
    let color = '#f52b04';
    if (work_millis_logged >= 1080000 && work_millis_logged < 1200000) color = '#d76800';
    if (work_millis_logged < 1080000 && work_millis_logged > 0) color = '#067809';

    return (
        <div style={{ textAlign: 'center', fontSize: 18 }}>
            {!work_millis_logged &&
                <span> - </span>
            }
            {work_millis_logged > 0 &&
                <span style={{ color }}>
                    {msToTime(work_millis_logged)}
                </span>
            }
        </div>
    );
};

export default TimeBilledCell;
