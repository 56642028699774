/**
 * Created by synerzip on 30/01/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Panel,Input,Button,OverlayTrigger,Popover} from 'react-bootstrap';
import {Table, Column, Cell} from 'fixed-data-table';
import _ from 'lodash';
import UnassignedPatientCell from '../admin/UnassignedPatientCell';
import OrganizationCell from '../admin/OrganizationCell';
import ResponsiveFixedDataTable from '../../components/ResponsiveFixedDataTable';
import AddToDoPopup from './AddToDoPopup';
import moment from 'moment';
import TODOFlagCell from './TODOFlagCell';
import CompleteTODOPopup from './CompleteTODOPopup';

class TODOList extends React.Component {

    constructor(props) {
        super(props);
        this.selectedTodos = {};
        this.state = {
            columnWidths: {
                todoDate: 200,
                todoLabel: 500,
                completed: 250,
                flags:200,
            },
            showCompleteTodo:false
        };

        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    }

    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({columnWidths}) => ({
            columnWidths: {
                ...columnWidths,
                [columnKey]: newColumnWidth,
            }
        }));
    }

    onPopupHide() {
        this.props.showSecondaryTimer();
        this.setState({show: false});
    }

    onCompletedPopupHide() {
        this.props.showSecondaryTimer();
        this.setState({showCompleteTodo: false});
    }

    markCompleted(){
        console.log(this.selectedTodos);
        this.props.adminActions.markComplete(this.props.selectedPatient.id,this.selectedTodos);
        this.selectedTodos = {};
    }

    onCompleted(data, e) {
      const { activeUser } = this.props;
      const ce_flag = activeUser && activeUser.center && activeUser.center.ce_flag;
      const isRetSpec = activeUser && activeUser.center && activeUser.center.support_flag;
        this.props.showSecondaryTimer();
        //console.log(data);
       // console.log(e.target.checked);
       // if (e.target.checked) {
       //     this.selectedTodos[data.id] = true;
       //
       // } else {
       //     delete this.selectedTodos[data.id];
       // }
       //
       // this.setState({refresh: true});
        var dataObj = {};
        dataObj[data.id] = true;

        const urgent = data.urgent;

        if(urgent && activeUser && activeUser.center && !ce_flag && !isRetSpec) {
            this.setState({
                patientId: this.props.selectedPatient.id,
                selectedTodoData: dataObj,
                showCompleteTodo: true
            });
        }else{
            this.props.adminActions.markComplete(this.props.selectedPatient.id,dataObj);
        }
    }

    onEditTodo(data,e){
        this.props.showSecondaryTimer();
        this.currentTodoData = data;
        this.setState({
            show:true,
            editTodo:true
        })
    }

    clearCurrentTodoData(){
        this.currentTodoData = null;
        this.setState({
            show:false,
            editTodo:false
        })
    }

    todoPrefix( todo ) {
        if ( todo.urgent ) {
            return `${todo.urgent_todo_type_name} - `
        }
    }

    onReopen(data){
        this.props.adminActions.markUnComplete(this.props.selectedPatient.id,data.id);
    }

    handleAddTodo() {
        this.props.showSecondaryTimer();
        this.setState({ show:true });
    }

	popoverHoverFocus(description) {
        if(!description){
            return <div/>
        }
		return (
			<Popover style={{wordWrap:'normal', whiteSpace: 'pre-wrap', wordBreak: 'normal'}} id="popover-trigger-hover-focus">
				<span>{description}</span>
			</Popover>
		)
	}

    render() {

        var header =
            <div style={{height:35}}>
                <div style={{fontSize:18,float:'left'}}>To Do List</div>
                <div style={{float:'right',marginRight:10}}><Button bsStyle="primary"
                                                                    id="addToDo"
                                                                    onClick={ this.handleAddTodo.bind( this )}>Add To
                    Do</Button></div>
            </div>;

        var {columnWidths} = this.state;
        var {todoLists,completedToDoList} = this.props;

        var todoListData = _.chain(todoLists).values().sortBy('sortIndex').value();
        var completedToDoListData = _.chain(completedToDoList).values().sortBy('sortIndex').sortBy("completed_ts","desc").value();
        return (
            <div>
                <Panel header={header}>
                    <div style={{height:300}} id={'totalTodosList'} data-config={todoListData.length}>
                        <ResponsiveFixedDataTable
                            rowHeight={80}
                            headerHeight={40}
                            rowsCount={todoListData.length}
                            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                            isColumnResizing={false}
                            width={800}
                            height={300}
                            {...this.props}>
                            <Column
                                columnKey="todoDate"
                                header={<Cell>Due Date</Cell>}
                                cell={ ({rowIndex, data, columnKey, ...props}) => (
                                    <Cell {...props}>
                                    <TODOFlagCell
                                        id={'TODODate_'+rowIndex}
                                        dateString={moment(todoListData[rowIndex].due_date).format('MM/DD/YYYY')}
                                        urgent={todoListData[rowIndex].urgent}/>
                                    </Cell>
                                )}
                                width={columnWidths.todoDate}
                                />
                            <Column
                                columnKey="description"
                                header={<Cell>Description</Cell>}
                                cell={ ({rowIndex, data, columnKey, ...props}) => (
	                                <OverlayTrigger trigger={['hover', 'focus']} placement='top' overlay={this.popoverHoverFocus(todoListData[rowIndex][columnKey])}>
		                                <Cell style={{overflow:'auto', wordWrap:'normal', whiteSpace: 'pre-wrap', wordBreak: 'normal'}} {...props}>
			                                <div style={{overflowY: 'auto', height: '67px'}} id={'Description_'+rowIndex}>
	                                            {this.todoPrefix(todoListData[rowIndex])}{todoListData[rowIndex].description}
			                                </div>
	                                    </Cell>
	                                </OverlayTrigger>
                                )}
                                width={800}
                                flexGrow={1}
                                />
                            {/*<Column
                                columnKey="flags"
                                header={<Cell>Status</Cell>}
                                cell={ ({rowIndex, data, columnKey, ...props}) => (
                                    <Cell {...props}>
                                        <TODOFlagCell urgent={todoListData[rowIndex].urgent}/>
                                    </Cell>
                                )}
                                width={columnWidths.flags}
                                isResizable={true}
                                />*/}

                            <Column
                                columnKey="completed"
                                header={<Cell>Actions</Cell>}
                                cell={ ({rowIndex, data, columnKey, ...props}) => (
                                    <Cell {...props}>
                                        <Button bsStyle="primary" onClick={this.onCompleted.bind(this,todoListData[rowIndex])}>Completed</Button>
                                        <Button bsStyle="success" className="todo-edit-button" onClick={this.onEditTodo.bind(this,todoListData[rowIndex])}>Edit</Button>
                                    </Cell>
                                )}
                                width={columnWidths.completed}
                                />

                            {/*<Column
                                columnKey="completed"
                                header={<Cell></Cell>}
                                cell={ ({rowIndex, data, columnKey, ...props}) => (
                                    <Cell {...props}>
                                        <Input type="checkbox" label="Completed"
                                        onChange={(e)=>this.onCompleted(todoListData[rowIndex],e)} />
                                    </Cell>
                                )}
                                width={columnWidths.completed}
                                isResizable={true}
                                />*/}
                        </ResponsiveFixedDataTable>
                    </div>
                </Panel>

                <Panel header={<div style={{height:35,textAlign:'left'}}>
                        <div style={{fontSize:18}}>Completed List</div>
                    </div>}>
                    <div style={{height:300}}>
                        <ResponsiveFixedDataTable
                            rowHeight={60}
                            headerHeight={40}
                            rowsCount={completedToDoListData.length}
                            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                            isColumnResizing={false}
                            width={800}
                            height={300}
                            {...this.props}>
                            <Column
                                columnKey="todoDate"
                                header={<Cell>Completed Date</Cell>}
                                cell={ ({rowIndex, data, columnKey, ...props}) => (
                                    <Cell {...props} style={{textAlign:'center'}}>
                                        {moment.utc(completedToDoListData[rowIndex].completed_ts).local().format('MM/DD/YYYY')}
                                    </Cell>
                                )}
                                width={columnWidths.todoDate}
                                />
                            <Column
                                columnKey="Description"
                                header={<Cell>Description</Cell>}
                                cell={ ({rowIndex, data, columnKey, ...props}) => (
                                    <Cell {...props}>
                                        {completedToDoListData[rowIndex].description}
                                    </Cell>
                                )}
                                width={800}
                                flexGrow={1}
                                />

                            <Column
                                columnKey="completed"
                                header={<Cell>Actions</Cell>}
                                cell={ ({rowIndex, data, columnKey, ...props}) => (
                                    <Cell {...props}>
                                        <Button bsStyle="primary" disabled={completedToDoListData[rowIndex].urgent === 1}
                                        onClick={this.onReopen.bind(this,completedToDoListData[rowIndex])}>Reopen</Button>
                                    </Cell>
                                )}
                                width={columnWidths.completed}
                                />
                        </ResponsiveFixedDataTable>
                    </div>
                </Panel>
                <AddToDoPopup showSecondaryTimer={this.props.showSecondaryTimer}
                              onPopupHide={this.onPopupHide.bind(this)}
                              onCloseEdit={this.clearCurrentTodoData.bind(this)}
                              show={this.state.show} editMode={this.state.editTodo}
                              todoDetails={this.currentTodoData}
                              onOptionSelect={this.props.onOptionSelect}/>
                <CompleteTODOPopup showSecondaryTimer={this.props.showSecondaryTimer}
                                   onPopupHide={this.onCompletedPopupHide.bind(this)}
                                   patientId={this.state.patientId}
                                   show={this.state.showCompleteTodo}
                                   selectedTodoData={this.state.selectedTodoData}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    'todoLists': state.levelTwoDashboard.todoLists,
    'completedToDoList': state.levelTwoDashboard.completedToDoList,
    'userId': state.auth.id,
    'selectedPatient':state.app.selectedPatient,
    'activeUser': state.admin.activeUser,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TODOList);
