/**
 * Created by synerzip on 23/01/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Panel} from 'react-bootstrap';
import _ from 'lodash';

class UnassignedPatientCell extends React.Component{

    render(){
        var {data} = this.props;
        return(
          <div>
              <div style={{clear:'both'}}>
                  <div className="key-box">Patient Name:</div>
                  <div className="value-box">{data.first_name+' '+data.last_name}</div>
              </div>
              <div style={{clear:'both'}}>
                  <div className="key-box">Date of Birth:</div>
                  <div className="value-box">{data.date_of_birth}</div>
              </div>
              <div style={{clear:'both'}}>
                  <div className="key-box">Address:</div>
                  <div className="value-box">
                      <div>{data.address1}</div>
                      <div>{data.address2}</div>
                      <div>{data.city + ","+data.state+" "+data.zip_code}</div>
                  </div>
              </div>
              <div style={{clear:'both'}}>
                  <div className="key-box">Phone:</div>
                  <div className="value-box">{data.phone}</div>
              </div>
              <div style={{clear:'both'}}>
                  <div className="key-box">Email Address:</div>
                  <div className="value-box">{data.email}</div>
              </div>
          </div>
        );
    }
}

export default UnassignedPatientCell;