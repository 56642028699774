import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import {Input,Modal,Button,FormControl,FormGroup,ControlLabel} from 'react-bootstrap';
import _ from 'lodash';
import { connect } from 'react-redux';

import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import moment from 'moment';

const LinkedStateMixin = require('react-addons-linked-state-mixin');

class PatientEducationItem extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            patientEducation:props.data.education_desc,
            title:props.data.title,
            stepError:false
        }
    }

    onSubmit(e) {
        e.preventDefault();

        let {selectedPatient,adminActions,data} = this.props;
        if(this.state.title.trim() == '') {
            this.setState({
                stepError: true
            })
        } else {
            adminActions.updatePatientEducation(data.id,selectedPatient.id,this.state.title,this.state.patientEducation);
            this.props.handleCancelDetail();      
        }
    }

    onCancel() {
        this.setState(
            {
                patientEducation:this.props.data.education_desc,
                title:this.props.data.title,
                stepError:false
            }
        );
        this.props.handleCancelDetail();
    }

    handleDelete(){
        let {selectedPatient,adminActions,data} = this.props;
        adminActions.deletePatientEducation(data.id,selectedPatient.id)
    }

    render(){
        return (
            <div style={{display:'flex',justifyContent:'center'}}>
                    <div style={{overflow:'auto',height:400,maxWidth:600,flex:1}}>
                        <form onSubmit={this.onSubmit.bind(this)} novalidate>

                            <Input type="text" label="Title" placeholder="Enter Title" required
                                   valueLink={this.linkState('title')} maxLength="255"/>

                           
                            <FormGroup className={this.state.stepError && 'has-error'}>                        
                                <ControlLabel>Patient Education</ControlLabel>
                                <div>
                                    <FormControl componentClass="textarea" label="Patient Education" placeholder="Patient Education" required
                                        valueLink={this.linkState('patientEducation')} maxLength="4096" style={{resize:'none',height:'200px'}}/>
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        {`${this.state.patientEducation ? this.state.patientEducation.length : 0}/4096`}
                                    </div>       
                                </div>       
                                {this.state.stepError && <span className="text-danger"><small>Please enter a patient education</small></span>}
                            </FormGroup>
                            <div style={{textAlign:'center',paddingTop:10}}>
                                <Button bsStyle="danger" onClick={this.onCancel.bind(this)} style={{float:'left'}}>
                                    Cancel
                                </Button>
                                {
                                    (this.props.editMode)?
                                        <Button bsStyle="success" type="submit" style={{float:'right'}}>
                                            Update
                                        </Button>
                                        :
                                        <Button bsStyle="success" type="submit" style={{float:'right'}}>
                                            Save
                                        </Button>
                                }
                            </div>
                        </form>
                    </div>
                    <div style={{padding:30}}>
                        <Button bsStyle="danger" onClick={ this.handleDelete.bind(this) }>Delete</Button>
                    </div>
                </div>
        )
    }
}

reactMixin(PatientEducationItem.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    'user': state.auth.user,
    'selectedPatient':state.app.selectedPatient
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientEducationItem);
