import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentQueueNoAnswer = () =>
	<PatientQueue
		showQueueCount
		patientNameColumn
		providerDetailColumn
		timeBilledColumn
		todoItemColumn
		showFilters
		showProgramTypeFilter
        showScheduleCallFilter
		dobColumn
		chartColumn
		lastWorkedByColumn
		page
		queue="urgentNoAnswer"
		endpoint="Urgent?typesToInclude=20"
		label="Urgent - No Answer"
	/>;

export default UrgentQueueNoAnswer;
