import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentQueueRetSpec = () =>
	<PatientQueue
		showQueueCount
		patientNameColumn
		timeBilledColumn
		todoItemColumn
        providerDetailColumn
		showFilters
		showProgramTypeFilter
        showScheduleCallFilter
		statusColumn
		chartColumn
		lastWorkedByColumn
		page
		queue="urgentSupport"
		endpoint="Urgent"
		label="Urgent - Patient Services"
	/>;

export default UrgentQueueRetSpec;
