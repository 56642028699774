import React from 'react';
import PatientQueue from './PatientQueue';

const PatientServicesOpenChartsQueue = () =>
	<PatientQueue
		showQueueCount
		supportDetailColumn
		providerDetailColumn
		patientDetailColumn
		openCloseChartColumn
		openedByColumn
		queue="patientServicesOpenCharts"
		endpoint="Support?patientsWithOpenChart=1"
		label="Patient Services - Open Charts"
	/>;

export default PatientServicesOpenChartsQueue;
