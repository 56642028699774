import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Panel,Input,Button,Popover,OverlayTrigger} from 'react-bootstrap';
import {Table, Column, Cell} from 'fixed-data-table';
import _ from 'lodash';
import UnassignedPatientCell from '../admin/UnassignedPatientCell';
import OrganizationCell from '../admin/OrganizationCell';
import ResponsiveFixedDataTable from '../../components/ResponsiveFixedDataTable';
import AddToDoPopup from './AddToDoPopup';
import moment from 'moment';
import TODOFlagCell from './TODOFlagCell';

class CaseHistoryView extends React.Component {

    constructor(props) {
        super(props);
        this.selectedTodos = {};
        this.state = {
            columnWidths: {
                item: 150,
                date: 150,
                enteredBy: 150,
                center: 150,
                patientContact: 250,
                description: 550
            }
        };

        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    }
    componentDidMount(){
        this.props.adminActions.fetchCaseHistory(this.props.selectedPatient.id);
    }

    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({columnWidths}) => ({
            columnWidths: {
                ...columnWidths,
                [columnKey]: newColumnWidth,
            }
        }));
    }

    popoverHoverFocus(description) {
        if(!description){
            return <div/>
        }
        return (
            <Popover id="popover-trigger-hover-focus">
                <span>{description}</span>
            </Popover>
        )
    }


    render() {


        var {columnWidths} = this.state;
        var {caseHistory} = this.props;
        if (!caseHistory) {
            caseHistory = [];
        }

        return (

            <div style={{height:600}}>
                <ResponsiveFixedDataTable
                    rowHeight={70}
                    headerHeight={40}
                    rowsCount={caseHistory.length}
                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    isColumnResizing={false}
                    width={800}
                    height={300}
                    overflowY="auto"
                    {...this.props}>
                    <Column
                        columnKey="item"
                        header={<Cell>Item</Cell>}
                        cell={ ({rowIndex, data, columnKey, ...props}) => (
                                    <Cell {...props}>
                                        {caseHistory[rowIndex].item_type}
                                    </Cell>
                                )}
                        width={columnWidths.item}
                        isResizable={true}
                        />
                    <Column
                        columnKey="date"
                        header={<Cell>Date</Cell>}
                        cell={ ({rowIndex, data, columnKey, ...props}) => (
                                    <Cell {...props}>
                                        {moment.utc(caseHistory[rowIndex].item_date).local().format('MM/DD/YYYY h:mm a')}
                                    </Cell>
                                )}
                        width={columnWidths.date}
                        isResizable={true}
                        />

                    <Column
                        columnKey="enteredBy"
                        header={<Cell>Entered By</Cell>}
                        cell={ ({rowIndex, data, columnKey, ...props}) => (
                                    <Cell {...props}>
                                        {caseHistory[rowIndex].item_user}
                                    </Cell>
                                )}
                        width={columnWidths.enteredBy}
                        isResizable={true}
                        />

                    <Column
                        columnKey="center"
                        header={<Cell>Center</Cell>}
                        cell={ ({rowIndex, data, columnKey, ...props}) => (
                                    <Cell {...props}>
                                        {caseHistory[rowIndex].item_center}
                                    </Cell>
                                )}
                        width={columnWidths.center}
                        isResizable={true}
                        />

                    {/*
                    <Column
                        columnKey="patientContact"
                        header={<Cell>Patient Contact</Cell>}
                        cell={ ({rowIndex, data, columnKey, ...props}) => (
                                    <Cell {...props}>
                                        {caseHistory[rowIndex].item_user}
                                    </Cell>
                                )}
                        width={columnWidths.patientContact}
                        isResizable={true}
                        />
                    */}

                    <Column
                        columnKey="description"
                        header={<Cell>Description</Cell>}
                        cell={ ({rowIndex, data, columnKey, ...props}) => (
                                    <OverlayTrigger trigger={['hover', 'focus']} placement='top' overlay={this.popoverHoverFocus(caseHistory[rowIndex].item_description)}>
                                        <Cell {...props}>
                                            <span className="break-word-all">{caseHistory[rowIndex].item_description}</span>
                                        </Cell>
                                    </OverlayTrigger>
                                )}
                        width={columnWidths.description}
                        isResizable={true}
                        flexGrow={1}
                        />


                </ResponsiveFixedDataTable>
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    'caseHistory': state.levelTwoDashboard.caseHistory,
    'userId': state.auth.id,
    'selectedPatient': state.app.selectedPatient
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseHistoryView);
