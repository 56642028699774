import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as appActionCreator from '../../actions/app';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import {Panel,Input,Button,Col,Table,FormGroup,FormControl,ControlLabel} from 'react-bootstrap';
import moment from 'moment';
import ReportsForm from './ReportsForm';
import { reduxForm, getValues } from 'redux-form';
import PageHeader from '../../components/PageHeader';

class ReportsContainer extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {
            tableType: false
        };
    }

    populateTableRows( user ) {
        return this.iterateOverUser( user );
    }

    iterateOverUser( userObj ) {
        let rowData = [];
        let idx = 0;
        for ( let key in userObj ) {
            if ( typeof userObj[ key ] != 'object' ) {
                rowData.push(<td key={idx}>{userObj[key]}</td>);
                idx++;
            } else {
                return this.iterateOverUser( userObj[ key ] );
            }
        }
        return rowData;
    }

    objectContainsKeys(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return true;
            }
        }
        return false;
    }

    validateForm(values = {}) {
        const errors = {};
        if (!values.report_type) {
            errors.doctorName = 'Required';
        }
        if (!values.start_range) {
            errors.start_range = 'Required';
        } else if (!this.isValidDate(values.start_range)) {
            errors.start_range = 'Please provide a date in the following format: MM/YYYY';
        } else if ( new Date(values.start_range) > new Date() ) {
            errors.start_range = 'Date cannot be in the future.';
        }
        if (!values.end_range) {
            errors.end_range = 'Required';
        } else if (!this.isValidDate(values.end_range)) {
            errors.end_range = 'Please provide a date in the following format: MM/YYYY';
        } else if (new Date(values.end_range) > new Date()) {
            errors.end_range = 'Date cannot be in the future.';
        }
        this.setState({
            errors
        });
        return errors;
    }

    isValidDate(dateString) {
        // First check for the pattern
        if(!/^\d{2}-\d{4}$/.test(dateString)) {
            return false;
        }

        // Parse the date parts to integers
        var parts = dateString.split("-");
        var month = parseInt(parts[0], 10);
        var year = parseInt(parts[1], 10);

        // Check the ranges of month and year
        if(year < 1000 || year > 3000 || month == 0 || month > 12)
            return false;

        return true;
    }

    determineTableType( tableHeaders, data ) {
        if ( !_.isEqual( this.state.recordsData, data ) ) {
            if ( this.state.tableType != 3 ) {
                return this.tableTypeOne( tableHeaders, data );
            }
            return this.tableTypeTwo( data );
        }
        if ( data && !data.length ) {
            return <div style={{ 'paddingLeft': '30px' }}><p>No data for this employee.</p></div>
        }
    }

    sortByAscending( key, e ) {
        e && e.preventDefault();
        this.props.adminActions.sortReportData('ascending', key);
    }

    sortByDescending( key, e ) {
        e && e.preventDefault();
        this.props.adminActions.sortReportData('descending', key);
    }

    tableTypeOne( tableHeaders, data ) {
        if ( data && !data.length ) {
            return <div style={{ 'paddingLeft': '30px' }}><p>No data for this employee.</p></div>
        }
        return (
            <Table striped>
                <thead>
                    <tr>
                        {
                            tableHeaders.map( ( header, idx ) => {
                                return (
                                    <th key={header.key}>
                                        {header.altKey}
                                        <span style={{ 'display': 'inline-block', 'marginLeft': '10px', 'verticalAlign': 'middle', 'whiteSpace': 'nowrap' }}>
                                            <i className="fa fa-angle-up" onClick={ this.sortByAscending.bind( this, header.key )} style={{ 'display': 'block' }}></i>
                                            <i className="fa fa-angle-down" onClick={ this.sortByDescending.bind( this, header.key )}></i>
                                        </span>
                                    </th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    { data.map( ( user ) => {
                        return (
                            <tr key={ user.user_id }>
                                {this.populateTableRows( user )}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        )
    }

    tableTypeTwo( data ) {
        return (
            <div>
                { data.map( ( row ) => {
                    return (
                        <div>
                            <Col sm={12} md={6} lg={4} style={{ 'display': 'block' }}>
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th style={{ 'height': '57px' }}>
                                                { row.title }
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.extractRowContent( row ) }
                                    </tbody>
                                </Table>
                            </Col>
                        </div>
                    )
                })}
            </div>
        )
    }

    extractHeaderContent( row ) {
        let content = [];
        for ( let key in row.values ) {
            content.push(<th>{key}</th>);
        }
        return content;
    }

    extractRowContent( row ) {
        let content = [];
        for ( let key in row.values ) {
            content.push(<tr key={key}><td>{row.values[key]}</td></tr>)
        }
        return content;
    }

    triggerPatientsNeedingWork( e ) {
        e && e.preventDefault();
        this.props.adminActions.getReportsToken( 'patientsNeedingWork' );
    }

    triggerPatientTracking( e ) {
        e && e.preventDefault();
        this.props.adminActions.getReportsToken( 'patientTracking' );
    }

    triggerEmployeeChartTime( e ) {
        e && e.preventDefault();
        let formData = this.props.form && getValues( this.props.form.reportsForm );
        let errors = this.validateForm( formData );
        if ( !this.objectContainsKeys( errors ) ) {
            this.props.adminActions.getReportsToken( 'employeeChartTime', formData );
        } else {
            this.setState({
                errors
            });
            console.log('errors:', errors);
        }
    }

    triggerChartByEmployee( e ) {
        e && e.preventDefault();
        let formData = this.props.form && getValues( this.props.form.reportsForm );

        if ( formData.employee_id ) {
            this.props.adminActions.getReportsToken( 'chartByEmployee', formData );
        } else {
            console.log( 'error' );
        }
    }

    componentWillMount() {
        this.props.adminActions.getReportsToken();
        this.props.adminActions.getALLMedicalAssistants();
    }

    componentWillReceiveProps( nextProps ) {
        let formData = this.props.form && getValues( this.props.form.reportsForm );
        if ( (nextProps.admin && nextProps.admin.reportsData) && !_.isEqual( nextProps.admin.reportsData, this.state.reportsData && formData ) ){
            let reportsData = nextProps.admin.reportsData;
            this.setState({ formData,
                            tableType: formData.report_type,
                            reportsData,
                            employeeList: nextProps.admin.medicalAssistants
            });
        } else {
            formData && this.setState({ formData,
                                        tableType: formData.report_type,
                                        employeeList: nextProps.admin.medicalAssistants
            });
        }
    }

    render() {

        let tableHeaders = [];
        let data;
        if ( this.state.reportsData && this.state.reportsData.length ) {
            data = [];
            data = data.concat(this.state.reportsData);
            for ( let key in data[0] ) {
                let altKey = key;
                altKey = altKey.replace(/_/g, '\u00a0');
                altKey = altKey.charAt(0).toUpperCase() + altKey.slice(1);
                tableHeaders.push({ key, altKey });
            }
        } else if ( this.state.reportsData ) {
            data = [];
        }

        return (
            <div>
                <PageHeader>
                    Reports
                </PageHeader>
                <Panel className="container-fluid" style={{ 'paddingTop': '45px', 'paddingBottom': '45px' }}>
                    <ReportsForm    errors={ this.state.errors }
                                    formData={ this.state.formData }
                                    employeeList={ this.state.employeeList }
                                    triggerPatientsNeedingWork={ this.triggerPatientsNeedingWork.bind( this ) }
                                    triggerEmployeeChartTime={ this.triggerEmployeeChartTime.bind( this ) }
                                    triggerChartByEmployee={ this.triggerChartByEmployee.bind( this ) }
                                    triggerPatientTracking={ this.triggerPatientTracking.bind( this ) } />
                    { this.state.tableType && this.determineTableType( tableHeaders, data ) }
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    form: state.form,
    admin: state.admin
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    appActions:bindActionCreators(appActionCreator,dispatch),
    commonActions:bindActionCreators(commonActionCreator,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsContainer);
