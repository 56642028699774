import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import {assignedPatients} from '../../actions/admin'
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import {Input,Modal,Button,FormGroup,InputGroup,FormControl} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
var DateTimeField = require('react-bootstrap-datetimepicker-custom-icons');
var LinkedStateMixin = require('react-addons-linked-state-mixin');
import FormInput from '../../components/FormInput';

class AssignPatientPopup extends React.Component {
    constructor(props){
        super(props);
        this.state={
            noOfPatients:'',
            errorMessage:''
        }
    }

    handleCancelOkAction = () => {
        this.setState({errorMessage:''})
        this.props.onPopupHide();
    }

    handleSubmit = async() => {
        const {unassignedCount,selectedClinician,activeUser,selectedTeam,
            selectedOrg} = this.props;
        const assigned_patients = selectedClinician.assigned_patients;
        const threshold = selectedClinician.threshold;
        const curentlyAssigned = threshold - assigned_patients;
        const {noOfPatients} = this.state;
        if(unassignedCount < noOfPatients){
            this.setState({errorMessage:"Number of patients can't be more than Unassigned patients"})
            return;
        }
        if(noOfPatients > curentlyAssigned){
            this.setState({errorMessage:"Number of patients can't be more than thershold"})
            return;
        }

        try{
            assignedPatients(activeUser.id,
                selectedTeam,selectedClinician.id,noOfPatients,selectedOrg)
                .then(() => {
                    this.props.onPopupHide(selectedTeam,selectedOrg);
                });
                
        }catch(error){
            this.setState({errorMessage:"Unable to assign."})
        }
    }

    onPatientNoChanged = (e) => {
        this.setState({noOfPatients:e.target.value})
    }

    render(){
        const {noOfPatients} = this.state;
        const {selectedClinician} = this.props;
        if(!selectedClinician){
            return <div/>
        }
        const isCne = this.props.activeUser && this.props.activeUser.center && this.props.activeUser.center.ce_flag;
        const rpm_flag = this.props.activeUser && this.props.activeUser.center && this.props.activeUser.center.rpm_flag;
        return (
            <Modal bsSize="large" show={this.props.show}>
                <Modal.Header closeButton onHide={()=>{
                                this.props.onPopupHide();
                            }}>
                    <Modal.Title>
                        <div style={
                            {
                            textAlign: 'center'
                            }
                        }>
                            <div>Assign Patients</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="form-horizontal edit-patient-information-form" style={{display:'inline-block',width:'100%'}}>
                        <div className="col-md-12">
                            <div className="col-md-offset-1 col-md-11 edit-patient-info-container">
                                <div className="form-group">
                                    <label className="col-md-3 control-label text-left">
                                        Unassigned Patients: {this.props.unassignedCount}
                                    </label>
                                    <div className="col-md-9 remove-side-padding">
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12" style={{marginBottom:15}}>
                            <div className="col-md-offset-1 col-md-11 edit-patient-info-container">
                                <div className="form-group">
                                    <label className="col-md-12 control-label text-left">
                                        How many patients would you like to assign to {selectedClinician.name}?
                                    </label>
                                    <label style={{color:'red'}}>
                                        {this.state.errorMessage}
                                    </label>
                                    <div className="col-md-9 remove-side-padding">
                                        <Input isRequired ref="inputNoOfPatients" 
                                        type="number" onChange={this.onPatientNoChanged}
                                        initialValue={noOfPatients}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="col-md-offset-1 col-md-11 edit-patient-info-container">
                            <div className="col-md-12" style={{display:'flex',justifyContent:'space-between'}}>
                                
                                <Button bsStyle="default" onClick={this.handleCancelOkAction}>
                                    Cancel
                                </Button>
                                <Button bsStyle="success" style={{marginLeft:15}} onClick={this.handleSubmit}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                        
                    </form>    
                </Modal.Body>

            </Modal>)
    }
}

reactMixin(AssignPatientPopup.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    'user': state.auth.user,
    'activeUser': state.admin.activeUser,
    'selectedPatient':state.app.selectedPatient,
    'isPatientDetailsUpdated':state.app.isPatientDetailsUpdated,
    'adminUser': state.app.adminUser
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignPatientPopup);