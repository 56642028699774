import React from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationBar = ({ pageCount, currentPage, visibleBtns, onPageSelected }) => {
    if (!pageCount || !currentPage || pageCount < 2) return <div />;

    return (
        <div style={{ float: 'right', marginRight: 20 }}>
            <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                bsSize="large"
                items={pageCount}
                activePage={currentPage}
                maxButtons={visibleBtns || 2}
                onSelect={(e) => onPageSelected(e)}
            />
        </div>
    );
};

export default  PaginationBar;
