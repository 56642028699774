import React from 'react';
import { Cell } from 'fixed-data-table';
const SortTypes = {
    ASC: 'asc',
    DESC: 'desc',
};

const reverseSortDirection = (sortDir) => {
    return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}


class SortHeaderCell extends React.Component {
    constructor(props) {
        super(props);

        this._onSortChange = this._onSortChange.bind(this);
    }

    render() {
        const {columnHeaderStyle,onSortChange, sortDir, children, ...props} = this.props;
        return (
            <Cell {...props} style={{cursor:'pointer'}}>
                <div onClick={this._onSortChange}>
                    {children} {sortDir ? (sortDir === SortTypes.DESC ? '↓' : '↑') : ''}
                </div>
            </Cell>
        );
    }

    _onSortChange(e) {
        e.preventDefault();

        if (this.props.onSortChange) {
            this.props.onSortChange(
                this.props.columnKey,
                this.props.sortDir ?
                    reverseSortDirection(this.props.sortDir) :
                    SortTypes.DESC
            );
        }
    }
}

export default SortHeaderCell;
