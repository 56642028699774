/**
 * Created by synerzip on 23/03/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Input,Modal,Button} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
var LinkedStateMixin = require('react-addons-linked-state-mixin');

class AddStepPopup extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            step:null
        }
    }
    onSubmit(e) {
        e.preventDefault();

        var {selectedPatient,adminActions,data} = this.props;
        adminActions.addStepForGoal(selectedPatient.id,data.id,this.state.step);
        this.props.onPopupHide();

        // clear the step field for next use
        this.setState({step: null});
    }

    onCancel() {
        this.setState(
            {
                step:null
            }
        );
        this.props.onPopupHide();
    }
    render(){
        var {data} = this.props;
        var goal = data ? data.goal : '';
        return (
            <Modal show={this.props.show}>
                <Modal.Header closeButton onHide={()=>{
                                this.props.onPopupHide();
                            }}>
                    <Modal.Title>
                        <div style={
                            {
                            textAlign: 'center'
                            }
                        }>
                            <div>{'Add Step for '+goal}</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{overflow:'auto',height:200}}>
                        <form onSubmit={this.onSubmit.bind(this)} novalidate>


                            <Input type="text" label="Step" placeholder="Enter Step" required
                                   valueLink={this.linkState('step')} maxLength="255"/>

                            <div style={{textAlign:'center',paddingTop:10}}>
                                <Button bsStyle="danger" onClick={this.onCancel.bind(this)} style={{float:'left'}}>
                                    Cancel
                                </Button>
                                <Button bsStyle="success" type="submit" style={{float:'right'}}>
                                    Save
                                </Button>
                            </div>
                        </form>
                    </div>


                </Modal.Body>

            </Modal>
        )
    }

}
reactMixin(AddStepPopup.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    'user': state.auth.user,
    'selectedPatient':state.app.selectedPatient
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AddStepPopup);