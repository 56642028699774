import React from 'react';
import moment from 'moment';

const LastOutCallCell = ({ data: { last_out_call_ts, last_out_call_by } }) =>
    <div>
        <div>
            {last_out_call_by}
        </div>
        <div>
            {last_out_call_by && last_out_call_ts
                ? moment.utc(last_out_call_ts).local().format('hh:mm a, MM/DD/YYYY')
                : <div style={{ textAlign: 'center' }}>-</div>
            }
        </div>
    </div>;

export default LastOutCallCell;
