let config ={
    BASE_URL: "https://ccm-api.dev.aws.chartspan.com",
	// BASE_URL: "http://192.168.43.174:8443/ccm",
	CHART_WEB_URL:'https://app.dev.aws.chartspan.com/',
	ENV: 'DEV',
	CE_OUTBOUND_NUMBER:'855-880-9664',
	CLINICAL_OUTBOUND_NUMBER:'855-880-9664',
};


export default config;
