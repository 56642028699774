/**
 * Created by synerzip on 03/03/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Panel,Input,Button,Col,Accordion} from 'react-bootstrap';
import {Table, Column, Cell} from 'fixed-data-table';
import _ from 'lodash';
import moment from 'moment';
import CarePlanGoalItem from './CarePlanGoalItem';
import AddCarePlanAndGoal from './AddCarePlanAndGoal';
import GoalEditDeleteModal from './GoalEditDeleteModal';
import AddCareGoalIntervention from './AddCareGoalIntervention'
import UpdateCareGoalIntervention from './UpdateCareGoalIntervention'
import ConfirmDeleteInterventionPopup from './ConfirmDeleteInterventionPopup'
import EditCareGoal from './EditCareGoal'

class CarePlanAndGoal extends React.Component{

    constructor(props){
        super(props);
        this.state={
            openId:null,
            goalId: null,
            actionType: null,
            showGoalEditDeleteModal: false,
            goalTitle: '',
            showGoalIntervention:false
        }
    }

    componentDidMount(){
        this.props.adminActions.getGoals(this.props.selectedPatient.id);
        this.props.adminActions.getGoalCompletionStatuses();
        const {goalTypes,goalSources,stepTypes} = this.props;
        if(!goalTypes){
            this.props.adminActions.getGoalTypes();
        }
        if(!goalSources){
            this.props.adminActions.getGoalSources();
        }
    }

    onPopupHide(){
        this.props.showSecondaryTimer();
        this.setState({
            goalId:null,
            goalStepId:null,
            statusId:null,
            show:false,
            showGoalIntervention:false,
            showEditGoalIntervention:false,
            showDeleteGoalIntervention:false,
            showEditGoal:false});
    }

    handleGoalAction = ( goalId, actionType ) => {
        this.props.showSecondaryTimer();
        let goalTitle,frequency,otherNote,goalTypeId,
        sourceId,diagonsisCode,dueDate = '';
        this.props.carePlanAndGoals && this.props.carePlanAndGoals.length && this.props.carePlanAndGoals.forEach( ( goal ) => {
            if ( goalId === goal.id ) {
                goalTitle = goal.goal;
                frequency = goal.review_frequency_id;
                otherNote = goal.frequency_note;
                goalTypeId = goal.goal_type_id;
                sourceId = goal.goal_source_id;
                diagonsisCode = goal.icd10code;
                dueDate = goal.due_date;
            }
        });
        this.setState({ goalId, actionType, goalTitle,frequency,otherNote,
        goalTypeId,sourceId,diagonsisCode,dueDate }, 
            this.setState({showEditGoal:true}) );
    };

    onDeleteIntervention = (goalId,goalStep) => {
        const goalStepId = goalStep.id;
        this.setState({ goalId, goalStepId }, 
            this.setState({showDeleteGoalIntervention:true}) );
    }

    deleteInterventionConfirm = (data) => {
        const goalId = data.goalId;
        const stepId = data.stepId;
        const { selectedPatient } = this.props;
        this.props.adminActions.deleteGoalStep( selectedPatient.id, 
            goalId,stepId);
    }

    onEditIntervention = (goalId,goalStep) => {
        this.props.showSecondaryTimer();
        let goalTitle,icd10code = '';
        let dueDate = '';
        const goalStepId = goalStep.id;
        const problem = goalStep.step_title;
        let statusId = goalStep.completion_status_id;
        const action = goalStep.action;
        const plan = goalStep.step;
        const evaluation = goalStep.evaluation;
        
        this.props.carePlanAndGoals && this.props.carePlanAndGoals.length && this.props.carePlanAndGoals.forEach( ( goal ) => {
            if ( goalId === goal.id ) {
                goalTitle = goal.goal;
                icd10code = goal.icd10code;
                statusId = goal.completion_status_id;
                dueDate = goal.due_date;
            }
        });
        this.setState({ goalId, goalTitle,
            icd10code,goalStepId,
            problem,statusId,dueDate,action,
            plan,evaluation }, 
            this.setState({showEditGoalIntervention:true}) );
    }

    handleGoalIntervention = (goalId) => {
        this.props.showSecondaryTimer();
        let goalTitle,icd10code,statusId = '';
        this.props.carePlanAndGoals && this.props.carePlanAndGoals.length && this.props.carePlanAndGoals.forEach( ( goal ) => {
            if ( goalId === goal.id ) {
                goalTitle = goal.goal;
                icd10code = goal.icd10code;
                statusId = goal.completion_status_id;
            }
        });
        this.setState({ goalId, goalTitle,icd10code,statusId }, this.setState({showGoalIntervention:true}) );
    }

    toggleGoalEditDeleteModal = () => {
        this.setState({ showGoalEditDeleteModal: !this.state.showGoalEditDeleteModal });
    };

    handleGoalModalAction = (e) => {
    	e.preventDefault();
        let { goalId, actionType, goalTitle,frequency,otherNote } = this.state;
        let { selectedPatient } = this.props;

        if ( selectedPatient.id ) {
            if ( actionType === 'edit' ) {
                if(frequency != '5'){
                    otherNote = '';
                }
                this.props.adminActions.updateGoal( selectedPatient.id, goalId, goalTitle,
                    frequency,otherNote );
                return;
            }

            if ( actionType === 'delete' ) {
                this.props.adminActions.deleteGoal( selectedPatient.id, goalId );
                return;
            }
        } else {
            console.log( 'No patient id available' );
        }
    };

    goalTitleUpdate = ( e ) => {
        this.setState({ goalTitle: e.target.value });
    };

    handleFrequenceChange = (e) => {
        this.setState({frequency:e.target.value});
    }

    handleNotesChange = (e) => {
        this.setState({otherNote:e.target.value});
    }

    header( data ) {
        let rotate = this.state.openId == data.id ? ' rotate' : '';
        const {goalReviewFrequency,goalCompletionStatuses} = this.props;
        let frequencyTitle = '-',completionStatus = '-';
        let dueDate = '-';

       const frequnecyDataArray =  _.filter(goalReviewFrequency,(frequency) => {
            return frequency.id == data.review_frequency_id
        });
        if(frequnecyDataArray && frequnecyDataArray.length > 0){
            frequencyTitle = frequnecyDataArray[0].frequency;
        }

        const completionStatusArray = _.filter(goalCompletionStatuses,(status) => {
            return status.id == data.completion_status_id
        });
        if(completionStatusArray && completionStatusArray.length > 0){
            completionStatus = completionStatusArray[0].name;
        }
        if(data.due_date){
            dueDate = moment(data.due_date).format('MM/DD/YYYY')
        }
        
        let style = {
            marginTop: '-10px',
            marginBottom: '-10px',
            marginLeft: '-15px',
            marginRight: '-15px',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '15px',
            paddingRight: '15px',
            overflow: 'auto',
        }
        return (
            <div>
                <div style={style}>
                    <div style={{display:'flex',alignItems:'center',marginRight:-15}}>
                        <div style={{flex:1}}><i className={'fa fa-angle-down circleBorder ' + rotate} aria-hidden="true"></i>{data.goal} | {moment.utc(data.create_ts).local().format('DD MMM YYYY hh:mm a')} </div>
                        <div style={{flex:0.5}}>{'Frequency: ' + frequencyTitle} </div>
                        <div style={{flex:0.5}}>{'Due: ' + dueDate} </div>
                        <div style={{display:'flex',flex:0.5}}>{'Status: ' + completionStatus} </div>
                        <div style={{display:'flex',marginLeft:15,justifyContent:'flex-end'}}>
                            <Button bsStyle="danger" style={{ marginRight: '10px' }} 
                            onClick={(e)=> {
                                e.preventDefault();
                                e.stopPropagation();
                                this.handleGoalAction(data.id) 
                                }}>
                                Edit Care Goal
                            </Button>
                            <Button bsStyle="primary" style={{ marginRight: '10px' }} 
                                onClick={(e)=> {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                this.handleGoalIntervention(data.id);
                                            } }>
                                    Add Intervention
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    toggleOpen( id ) {
        this.props.showSecondaryTimer();
        if(this.state.openId != id) {
            this.setState({openId:id})
        } else {
            this.setState({openId:null})
        }
    }

    handleAddGoal() {
        this.props.showSecondaryTimer();
        this.setState({ show:true });
    }

    componentWillReceiveProps( nextProps ) {
        this.setState({ showGoalEditDeleteModal: nextProps.showEditDeleteModal });
    }

    render(){
        var header =
            <div style={{height:35}}>
                <div style={{fontSize:18,float:'left'}}>Care Goal</div>
                <div style={{float:'right',marginRight:10}}>
                    <Button bsStyle="primary" id="addGoalButton" onClick={ this.handleAddGoal.bind( this )}>Add Goal</Button>
                </div>
            </div>;

        var goals = [];

        var {carePlanAndGoals,goalCompletionStatuses,stepTypes} = this.props;
        goals =  _.map(carePlanAndGoals,(data)=>{
            let goalSteps = _.sortBy(data.goal_steps, ['create_ts'])
            return (
                <Panel header={this.header(data)} bsStyle={
                    data.completion_status_id == 1 ? 'info' : 
                    data.completion_status_id == 2 ? 'warning' : 
                    data.completion_status_id == 3 ? 'success' : 
                    data.completion_status_id == 4 ? 'danger' : 'info'} key={data.id} eventKey={data.id} style={{marginBottom:'20px'}}>
                    {
                        _.map(goalSteps.reverse(),(goalStep) => {
                            
                            return <div style={{padding:15,paddingLeft:0,
                            paddingRight:0,borderBottom:'1px solid lightgray'}} 
                            key={goalStep.id}>
                                <div style={{fontWeight:600}}>
                                    {goalStep.step_title}
                                </div>
                                <div style={{display:'flex'}}>
                                    <div style={{display:'flex',flex:1,flexDirection:"column"}}>
                                        {
                                            goalStep.evaluation && <div><span style={{fontWeight:600}}>Evaluation:</span> {goalStep.evaluation}</div>
                                        }
                                        {
                                            goalStep.step && <div><span style={{fontWeight:600}}>Plan:</span> {goalStep.step}</div>
                                        }
                                        
                                        <div>{moment.utc(goalStep.create_ts).local().format('DD MMM YYYY hh:mm a')}</div>
                                    </div>
                                    <div style={{display:'flex',flexDirection:'column'}}>
                                        <div style={{cursor:'pointer',marginBottom:15}} 
                                            onClick={this.onEditIntervention.bind(this,data.id,goalStep)}>
                                            <i style={{fontSize:18,width:28,height:28,display:'flex',
                                                alignItems:'center',justifyContent:'center'}} 
                                                className={'fa fa-pencil circleBorder'} aria-hidden="true"></i>
                                        </div>
                                        <div style={{cursor:'pointer'}}
                                            onClick={this.onDeleteIntervention.bind(this,data.id,goalStep)}>
                                            <i style={{color:'#d43f3a',fontSize:28}} 
                                            className={'fa fa-trash'} aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </Panel>
            )
        })

        return (
            <div>
                <Panel header={header}>
                    <Accordion>
                        {goals}
                    </Accordion>
                </Panel>
                <ConfirmDeleteInterventionPopup
                    show={this.state.showDeleteGoalIntervention}
                    dataToBeDeleted={
                        {
                            goalId:this.state.goalId,
                            stepId:this.state.goalStepId
                        }
                    }
                    onDeleteConfirm={this.deleteInterventionConfirm.bind(this)}
                    onPopupHide={this.onPopupHide.bind(this)}
                    />
                <AddCareGoalIntervention 
                    goalTitle={ this.state.goalTitle } 
                    icd10code={this.state.icd10code}
                    goalId={this.state.goalId}
                    statusId={this.state.statusId}
                    show={this.state.showGoalIntervention}
                    onCloseEdit={this.onPopupHide.bind(this)}
                    onPopupHide={this.onPopupHide.bind(this)}
                    showSecondaryTimer={ this.props.showSecondaryTimer.bind( this ) }/>
                <EditCareGoal 
                    goalTitle={ this.state.goalTitle } 
                    icd10code={this.state.icd10code}
                    goalId={this.state.goalId}
                    frequency={this.state.frequency} 
                    otherNote={this.state.otherNote} 
                    goalTypeId={this.state.goalTypeId} 
                    sourceId={this.state.sourceId} 
                    diagonsisCode={this.state.diagonsisCode} 
                    dueDate={this.state.dueDate}
                    show={this.state.showEditGoal}
                    onCloseEdit={this.onPopupHide.bind(this)}
                    onPopupHide={this.onPopupHide.bind(this)}
                    showSecondaryTimer={ this.props.showSecondaryTimer.bind( this ) }/>    

                <UpdateCareGoalIntervention 
                    goalTitle={ this.state.goalTitle } 
                    icd10code={this.state.icd10code}
                    goalId={this.state.goalId}
                    stepId={this.state.goalStepId}
                    problem={this.state.problem}
                    statusId={this.state.statusId}
                    action={this.state.action}
                    plan={this.state.plan}
                    evaluation={this.state.evaluation}
                    isPlan={!this.state.evaluation}
                    show={this.state.showEditGoalIntervention}
                    onCloseEdit={this.onPopupHide.bind(this)}
                    onPopupHide={this.onPopupHide.bind(this)}
                    showSecondaryTimer={ this.props.showSecondaryTimer.bind( this ) }/>    

                <AddCarePlanAndGoal show={this.state.show}
                                    onCloseEdit={this.onPopupHide.bind(this)}
                                    onPopupHide={this.onPopupHide.bind(this)}
                                    showSecondaryTimer={ this.props.showSecondaryTimer.bind( this ) }/>

                <GoalEditDeleteModal show={ this.state.showGoalEditDeleteModal }
                                     onHide={ this.toggleGoalEditDeleteModal }
                                     modalAction={ this.handleGoalModalAction }
                                     actionType={ this.state.actionType }
                                     goalTitleUpdate={ this.goalTitleUpdate }
                                     handleFrequenceChange={this.handleFrequenceChange}
                                     handleNotesChange={this.handleNotesChange}
                                     goalTitle={ this.state.goalTitle } 
                                     frequency={this.state.frequency}
                                     otherNote={this.state.otherNote}/>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    'carePlanAndGoals': state.levelTwoDashboard.carePlanAndGoals,
    'goalCompletionStatuses':state.levelTwoDashboard.goalCompletionStatuses,
    'userId': state.auth.id,
    'goalTypes':state.levelTwoDashboard.goalTypes,
    'goalSources':state.levelTwoDashboard.goalSources,
    'selectedPatient': state.app.selectedPatient,
    'showGoalEditDeleteModal': state.levelTwoDashboard.showGoalEditDeleteModal || false,
    'goalReviewFrequency':state.admin.goalReviewFrequency
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CarePlanAndGoal);