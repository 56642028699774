import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentQueueRemoveFromQueue = () =>
	<PatientQueue
		showQueueCount
		patientNameColumn
		providerDetailColumn
		timeBilledColumn
		todoItemColumn
		showFilters
		showProgramTypeFilter
        showScheduleCallFilter
		statusColumn
		chartColumn
		lastWorkedByColumn
		page
		queue="urgentRemoveFromQueue"
		endpoint="Urgent?typesToInclude=19"
		label="Urgent - Remove From Queue"
	/>;

export default UrgentQueueRemoveFromQueue;
