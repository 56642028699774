import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import _ from 'lodash';
import {
    PATIENT_STATUS_PENDING,
    PATIENT_STATUS_UNENROLLED,
    PATIENT_STATUS_REJECTED,
    PATIENT_STATUS_OPTED_OUT,
    PATIENT_STATUS_UNENROLL_PENDING,
    PATIENT_STATUS_RPM_UNVERIFIED,
    PATIENT_STATUS_RPM_INCORRECT_PHONE_NUMBER,
    PATIENT_STATUS_EXHAUSTED

} from '../../constants/other';
import { isAutoDial } from '../../utils';

class StatusActionsColumn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartOpenedBy: {},
        };
    }

    getChartOpenBy = () =>
        this.props.onGetChartOpenBy(this.props.patient.id)
            .then((chartOpenedBy) => {
                this.setState({ chartOpenedBy });
            })
            .catch(() => false);

    handleRequestChartClosure = () => {
        this.props.requestChartClosure( this.props.patient.id );
    };

    render() {
        const { patient, isUserAdmin,activeUser } = this.props;
        const isRPM = activeUser && activeUser.center && activeUser.center.rpm_flag;
        const { chartOpenedBy } = this.state;
        const chartIsOpen = patient.active_work_item_id;
        let inPendingStatus = patient.status === PATIENT_STATUS_PENDING;
        let eligibleToOpen = patient.status !== PATIENT_STATUS_UNENROLLED &&
            patient.status !== PATIENT_STATUS_UNENROLL_PENDING &&
            patient.status !== PATIENT_STATUS_REJECTED &&
            patient.status !== PATIENT_STATUS_OPTED_OUT &&
            patient.status !== PATIENT_STATUS_EXHAUSTED;
        let eligibleToUndecline = patient.status === PATIENT_STATUS_OPTED_OUT || patient.status === PATIENT_STATUS_EXHAUSTED;
        let showPhsDownload = patient.status === PATIENT_STATUS_UNENROLLED || patient.status === PATIENT_STATUS_UNENROLL_PENDING;
        const latest_checkout_ts = patient.latest_checkout_ts;
        const latest_checkin_ts = patient.latest_checkin_ts;
        const inAutoDial = isAutoDial(latest_checkout_ts,latest_checkin_ts);
       
        if(isRPM){
            inPendingStatus = false;
            eligibleToUndecline = false;
            eligibleToOpen = false;
            showPhsDownload = false;
            if(patient.status == PATIENT_STATUS_RPM_UNVERIFIED  || patient.status == PATIENT_STATUS_RPM_INCORRECT_PHONE_NUMBER){
                eligibleToOpen = true;
            }else{
                showPhsDownload = true;
            }
        }
        return (
            <div style={{ textAlign: 'center' }}>
                {chartIsOpen && !_.isEmpty(chartOpenedBy) &&
                <div>
                    <span>{chartOpenedBy.name}</span>
                </div>
                }
                {chartIsOpen && _.isEmpty(chartOpenedBy) &&
                <div>
                    <Button
                        bsSize="small"
                        type="button"
                        onClick={this.getChartOpenBy}
                    >
                        Opened By?
                    </Button>
                </div>
                }
                {!chartIsOpen && inPendingStatus &&
                <div>
                    <Button
                        bsStyle="danger"
                        bsSize="small"
                        type="button"
                        style={{ marginRight: '10px' }}
                        onClick={() => this.props.onShowOptOut(patient)}
                    >
                        Opt Out
                    </Button>
                    <Button
                        bsStyle="success"
                        bsSize="small"
                        type="button"
                        onClick={() => this.props.onVerifyPatient(patient)}
                    >
                        Verify
                    </Button>
                </div>
                }
                {!chartIsOpen && !inPendingStatus && eligibleToOpen &&
                <Button
                    bsStyle="primary"
                    bsSize="small"
                    type="button"
                    onClick={() => this.props.onPatientSelect(patient)}
                >
                    Open Chart
                </Button>
                }
                {eligibleToUndecline && isUserAdmin &&
                <Button
                    bsStyle="primary"
                    bsSize="small"
                    type="button"
                    onClick={() => this.props.onShowUnDecline(patient)}
                >
                    Move to Unverified
                </Button>
                }
                {!chartIsOpen && showPhsDownload &&
                <Button
                    bsStyle="primary"
                    bsSize="small"
                    type="button"
                    onClick={() => this.props.onShowPhs(patient)}
                >
                    Open PHS PDF
                </Button>
                }
                {
                    inAutoDial && <div style={{color:'#f52b04'}}>
                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        <span style={{fontSize:12,paddingLeft:2}}>In Autodial Queue</span>
                        </div>
                }
            </div>
        );
    }
}

export default StatusActionsColumn;
