import React from 'react';
import reactMixin from 'react-mixin';
import LinkedStateMixin from 'react-addons-linked-state-mixin';
import {
    Modal, Button, FormControl, FormGroup, ControlLabel,
    Grid, Row, Col
} from 'react-bootstrap';
import _ from 'lodash';
import moment from "moment";
import { bindActionCreators } from "redux";
import * as adminActionCreator from "../../actions/admin";
import DatePicker from "react-datepicker";

import { connect } from "react-redux";

class ScheduleNextCallModal extends React.Component {

    constructor(props) {
        super(props);
        let scheduleDate = '';
        if(props.patient.call_scheduled_ts){
            scheduleDate = moment(props.patient.call_scheduled_ts);
            if(scheduleDate.isBefore(moment())){
                scheduleDate = null;
            }else{
                scheduleDate = moment(props.patient.call_scheduled_ts,'YYYY-MM-DD HH:mm');
            }
        }
        this.state = {
            selectedDate: scheduleDate,
            selectedSlot: null,
            slots: []
        }
    }

    async componentDidMount(){
        const {patient} = this.props;
        const {selectedDate} = this.state;
        const call_scheduled_slot_id = patient.call_scheduled_slot_id;
        if(selectedDate && patient.call_scheduled_ts){
            const selectedDateString = moment(patient.call_scheduled_ts,'YYYY-MM-DD HH:mm').format('MM/DD/YYYY');
            const selectedTime = moment(patient.call_scheduled_ts,'YYYY-MM-DD HH:mm').format('hh:mm a');
            const timeZone = patient.local_time_zone;
            const slots = await this.props.adminActions.getSlots(selectedDateString,timeZone);
            let selectedSlot= null;

            if(call_scheduled_slot_id == 0){
                selectedSlot = {id:0}
            }else{
                for (let i = 0; i < slots.length; i++) {
                    const slot = slots[i];
                    const slotTime1 = slot[0].slotLabel;
                    if (selectedTime == slotTime1) {
                        selectedSlot = slot[0];
                    } else if (slot[1]) {
                        const slotTime2 = slot[1].slotLabel;
                        if (selectedTime == slotTime2) {
                            selectedSlot = slot[1];
                        }
                    }

                }
            }
            this.setState({slots,selectedSlot:selectedSlot});
        }else{
            const selectedDateValue = moment();
            if( this.props.isCne){
                selectedDateValue.add(3,'days');
            }
            const selectedDateString = selectedDateValue.format('MM/DD/YYYY');
            const timeZone = patient.local_time_zone;
            const slots = await this.props.adminActions.getSlots(selectedDateString,timeZone);
            let selectedSlot = null;
            if(call_scheduled_slot_id == 0){
                selectedSlot = {id:0}
            }
            this.setState({slots,selectedSlot:selectedSlot,selectedDate:selectedDateValue});
        }
    }

    async onDateSelected(date) {
        const {patient} = this.props;
        const {selectedDate} = this.state;
        const call_scheduled_slot_id = patient.call_scheduled_slot_id;
        const selectedDateString = moment(date).format('MM/DD/YYYY');
        const timeZone = this.props.patient.local_time_zone;
        const slots = await this.props.adminActions.getSlots(selectedDateString,timeZone);
        this.setState({ selectedDate: date, slots,selectedSlot:call_scheduled_slot_id == 0 ? {id:0} : null});
        this.props.onScheduleDateSelected(date);
    }

    onSlotSelected(slot) {
        if(slot.id !== undefined ) {
            this.setState({selectedSlot: slot});
            this.props.onScheduleSlotSelected(slot);
        }
    }

    render() {
        const { patient, isCne ,scheduleNextCallDate} = this.props;
        const { selectedSlot } = this.state;
        const currentDate = new Date();
        let minDateValue = new Date();
        if(isCne){
            minDateValue = moment(minDateValue.setDate(currentDate.getDate()+3))
        }
        return (<div style={{ display: 'flex', padding: 15 }}>
            <div style={{ display: 'flex', flex: 0.6, flexDirection: 'column', padding: 15 }}>
                <ControlLabel>Please select a callback time for this patient</ControlLabel>
                <div style={{ clear: 'both', textAlign: 'left', paddingTop: 15 }}>
                    <div className="value-box" style={{ width: '100%', padding: 0 }}>
                        <span style={{ fontWeight: 'bold', paddingRight: 10 }}>Practice Time Zone:</span>
                        {patient.provider && patient.provider.local_time_zone ? patient.provider.local_time_zone : '-'}
                    </div>
                </div>
                <div style={{ marginTop: 15 }}>
                    <DatePicker inline={true}
                                selected={scheduleNextCallDate ? scheduleNextCallDate : this.state.selectedDate}
                        minDate={minDateValue}
                        onChange={this.onDateSelected.bind(this)} />
                </div>
            </div>
            <div style={{ display: 'flex', flex: 0.4, flexDirection: 'column', padding: 15 }}>
                <Grid style={{ width: '100%' }}>
                    <Row>
                        <Col xs={12} style={{ padding: 0, paddingTop: 10 }}>
                            <div style={{ padding: 10, borderRadius: 5, border: '1px solid #337ab7', 
                                          textAlign: 'center' , cursor:'pointer',
                                          backgroundColor: selectedSlot && selectedSlot.id == 0  ? '#337ab7' : 'unset'}}
                                 onClick={this.onSlotSelected.bind(this, {id:0})}>
                                <span style={{ fontSize: 16, color: selectedSlot && selectedSlot.id == 0 ? '#FFFFFF' : '#337ab7' }}>
                                    Patient has no call preference
                                </span>
                            </div>
                        </Col>
                    </Row>
                    {
                        _.map(this.state.slots, (slotData) => {
                            return <Row key={slotData[0].slotLabel}>
                                <Col xs={6} style={{ padding: 0, paddingTop: 10, paddingRight: 5 }}>
                                    <div style={{
                                        padding: 10, borderRadius: 5,
                                        border: !slotData[0] .id ? '1px solid rgb(171,166,166)': '1px solid #337ab7',
                                        backgroundColor:  !slotData[0] .id ? 'rgb(228,228,228)' :selectedSlot == slotData[0] ? '#337ab7' : 'unset',
                                        textAlign: 'center', cursor: !slotData[0] .id ? '' : 'pointer'
                                    }}
                                        onClick={this.onSlotSelected.bind(this, slotData[0])}>
                                        <span style={{ fontSize: 16, color: !slotData[0].id ? 'rgb(154,147,147)' :selectedSlot == slotData[0] ? '#FFFFFF' : '#337ab7' }}>
                                            {slotData[0].slotLabel}
                                        </span>
                                    </div>
                                </Col>
                                {
                                    slotData[1] && <Col xs={6} style={{ padding: 0, paddingTop: 10, paddingLeft: 5 }}>
                                    <div style={{
                                        padding: 10, borderRadius: 5,
                                        border: !slotData[1] .id ? '1px solid rgb(171,166,166)': '1px solid #337ab7',
                                        backgroundColor:  !slotData[1] .id ? 'rgb(228,228,228)' :selectedSlot == slotData[1] ? '#337ab7' : 'unset',
                                        textAlign: 'center',  cursor: !slotData[1] .id ? '' : 'pointer'
                                    }}
                                        onClick={this.onSlotSelected.bind(this, slotData[1])}>
                                        <span style={{ fontSize: 16, color: !slotData[1].id ? 'rgb(154,147,147)' : selectedSlot == slotData[1] ? '#FFFFFF' : '#337ab7' }}>
                                        {slotData[1].slotLabel}
                                        </span>
                                    </div>
                                </Col>
                                }
                                
                            </Row>
                        })
                    }

                </Grid>
            </div>
        </div>)
    }
}

const mapStateToProps = (state) => ({
    'ccdaAvailable': state.levelTwoDashboard.ccdaAvailable,
    'medSummaryAvailable': state.levelTwoDashboard.medSummaryAvailable,
    'userId': state.auth.id,
    'userRole': state.auth.roles,
    'user': state.auth,
    'patientSSOToken': state.levelTwoDashboard.patientSSOToken,
    'app': state.app,
    'generalNotes': state.levelTwoDashboard.generalNotes,
    'patient': state.app.selectedPatient,
    'patientNotificationCount': state.notifications.patientNotificationCount,
    'form': state.form,
    'levelTwoDashboard': state.levelTwoDashboard,
    'activeUser': state.admin.activeUser,
    'patientSurveyQuestion': state.admin.patientSurveyQuestion,
    'patientSurveySubmitted': state.admin.patientSurveySubmitted,
    'chartClosureRequested': state.admin.chartClosureRequested,
    'selectedQueue': state.app.selectedQueue,
    'selectedPatient': state.app.selectedPatient,
    'organizationNote': state.levelTwoDashboard.organizationNote,
    'associatedCharts': state.levelTwoDashboard.associatedCharts,
    'configParams': state.admin.configParams,
    'optedOutReasons': state.levelTwoDashboard.optedOutReasons,
    'companies': state.admin.companies,
    'currentQueue': state.queue.currentQueue
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleNextCallModal);