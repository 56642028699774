import React from 'react';
import * as adminActionCreator from '../../actions/admin';
import * as appActionCreator from '../../actions/app';
import * as notificationsActionCreator from '../../actions/notifications';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, FormGroup, FormControl, InputGroup, Col, ControlLabel, Button, Grid, Row } from 'react-bootstrap';
import { reduxForm, getValues } from 'redux-form';
import _ from 'lodash';
import moment from 'moment';
import DateTimePicker from 'react-bootstrap-datetimepicker-custom-icons';

class ReportsForm extends React.Component {

	constructor() {
		super();
		this.state = {
			start_range: new Date(),
			end_range: new Date()
		}
	}

	determineFields( report_type, start_range, end_range, employee_id ) {
		let reportObj = getValues(this.props.formData.reportsForm);
		if ( reportObj && reportObj.report_type) {
			switch( reportObj.report_type ) {
				case '1':
					return this.showEmployeeSelection( employee_id );
					break;
				case '2':
					return this.showDateRangeForm( start_range, end_range );
					break;
				case '3':
					return this.showSubmitPatientTracking();
					break;
				case '4':
					return this.showSubmitPatientsNeeded();
					break;
				default:
					break;
			}
		}
	}

	showEmployeeSelection( employee_id ) {
		const inputStyle = { 'width': '100%', 'paddingLeft': '5px', 'borderRadius': '4px', 'border': '1px solid rgb(204, 204, 204)', 'height': '50px' };
		if ( this.props.employeeList && this.props.employeeList.length ) {
			return (
				<div>
					<Col md={4} sm={12}>
		            	<label>Select an employee</label>
		            	<select  style={ inputStyle } { ...employee_id  } >
		            		{ this.props.employeeList.map( ( employee ) => {
		            			return (
		            				<option value={ employee.id }>{ employee.name }</option>
		            			)
		            		}) }
						</select>
					</Col>
					<div style={{ 'paddingLeft': '15px', 'paddingTop': '15px', 'clear': 'both' }}>
						<Button type="submit" 
								bsStyle="success" 
								onClick={ this.props.triggerChartByEmployee }>
							Submit
						</Button>
					</div>
				</div>
			)
		}
	}

	showSubmitPatientsNeeded() {
		return (
			<div style={{ 'paddingLeft': '15px', 'paddingTop': '15px', 'clear': 'both' }}>
				<Button type="submit" bsStyle="success" onClick={ this.props.triggerPatientsNeedingWork }>
					Submit
				</Button>
			</div>
		)
	}

	showSubmitPatientTracking() {
		return (
			<div style={{ 'paddingLeft': '15px', 'paddingTop': '15px', 'clear': 'both' }}>
				<Button type="submit" bsStyle="success" onClick={ this.props.triggerPatientTracking }>
					Submit
				</Button>
			</div>
		)
	}

	showDateRangeForm( start_range, end_range ) {
		const buttonStyle = {  'margin': '15px' };
		return (
			<div>
				<Col md={4} sm={12}>
					<label>Begin Date Range</label>
					<DateTimePicker format="MM-YYYY"
									inputFormat="MM/YYYY"
									defaultText=""
									dateTime={this.state.start_range}
									inputProps={{'placeholder': 'MM/YYYY'}}
									iconStyle={{
										class: 'fa',
										time: 'fa-clock-o',
										calendar: 'fa-calendar',
										up: 'fa-chevron-up',
										down: 'fa-chevron-down',
										right: 'fa-chevron-right',
										left: 'fa-chevron-left',
									}}
									{ ...start_range } />
				</Col>
				<Col md={4} sm={12}>
					<label>End Date Range</label>
					<DateTimePicker format="MM-YYYY"
									inputFormat="MM/YYYY"
									defaultText=""
									dateTime={this.state.end_range}
									inputProps={{'placeholder': 'MM/YYYY'}}
									{ ...end_range } />
				</Col>
				<Button onClick={ this.props.triggerEmployeeChartTime }
						style={ buttonStyle }
						bsStyle="success" 
						type="submit">
					Submit
				</Button>
			</div>
		)
	}

	render() {
		const { fields: { report_type, start_range, end_range, employee_id } } = this.props;
		const inputStyle = { 'width': '100%', 'paddingLeft': '5px', 'borderRadius': '4px', 'border': '1px solid rgb(204, 204, 204)', 'height': '50px' };
		return (
			<form className="col-xs-12" style={{ 'marginBottom': '50px' }}>
				<Col md={4} sm={12}>
	            	<label>Select a report</label>
	            	<select  style={ inputStyle } { ...report_type } >
						<option value="0">None selected</option>
	                    <option value="1">Charts by employee</option>
	                    <option value="2">Employee chart time</option>
	                    <option value="3">Patient tracking</option>
	                    <option value="4">Patients needing work</option>
					</select>
				</Col>
				{ this.determineFields( report_type, start_range, end_range, employee_id ) }
				
			</form>
		)
	}
}

ReportsForm = reduxForm({
	form: 'reportsForm',
	fields: [ 'report_type', 'start_range', 'end_range', 'employee_id' ]
})(ReportsForm);

const mapStateToProps = (state) => ({
	'formData': state.form
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    appActions:bindActionCreators(appActionCreator,dispatch),
    notificationsAction:bindActionCreators(notificationsActionCreator,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsForm);