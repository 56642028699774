import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import {Input,Modal,Button,FieldGroup} from 'react-bootstrap';
import _ from 'lodash';

class CompleteTODOPopup extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            selectedValue:null,
            notes:null
        }
        this.onSelectedValueChanged = this.onSelectedValueChanged.bind(this);
        this.onComplete = this.onComplete.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.updateNotes = this.updateNotes.bind(this);
    }

    componentWillMount(){
        this.props.adminActions.getSocialDeterminants();
    }

    onSelectedValueChanged(e){
        this.setState({selectedValue:e.target.value,notes:null});
    }

    updateNotes(e){
        this.setState({notes:e.target.value});
    }

    showErrorMessage(title,message){
        this.props.commonActions.showToast({
            status: 'error',
            title: title,
            message: message
        });
        var interval = setInterval(()=> {
            clearInterval(interval);
            this.props.commonActions.hideToast();
        }, 2);
    }

    onComplete(e){
        e.preventDefault();
        if(!this.state.selectedValue || this.state.selectedValue == '-1'){
            this.showErrorMessage('Invalid Value','Please provide a valid social determinant value');
            return;
        }else if(this.state.selectedValue == 11 && !this.state.notes){
            this.showErrorMessage('Invalid Value','Please provide a valid social determinant description');
            return;
        }
        this.props.adminActions.markComplete(this.props.patientId,this.props.selectedTodoData,
            this.state.selectedValue,this.state.notes);
        this.props.showSecondaryTimer();
        this.props.onPopupHide();
        this.setState({
            selectedValue:null,
            notes:null
        });
    }

    onCancel(){
        this.props.showSecondaryTimer();
        this.props.onPopupHide();
        this.setState({
            selectedValue:null,
            notes:null
        });
    }

    render(){

        return (
            <Modal show={this.props.show}>
                <Modal.Header closeButton onHide={()=>{
                    this.onCancel();
                }}>
                    <Modal.Title>
                        <div style={
                            {
                                textAlign: 'center'
                            }
                        }>
                            <div>Value and Social Determinant</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="clearfix">
                        <form onSubmit={this.onComplete} noValidate>
                            <div className="col-sm-12" style={{ paddingBottom: '10px' }} >
                                Please choose the value and/or social determinant impacted by completing this Urgent.
                            </div>
                            <div className="col-sm-12" style={{ paddingBottom: '25px' }} >
                                <select onChange={this.onSelectedValueChanged} value={this.state.selectedValue}>
                                    <option value="-1">Select a Value</option>
                                    {
                                        _.map( this.props.socialDeterminants, ( socialDeterminant ) => {
                                                return <option value={socialDeterminant.id} key={socialDeterminant.id}>{socialDeterminant.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-sm-12">
                                <div>
                                    <textarea onChange={ this.updateNotes.bind(this) }
                                            value={ this.state.notes || '' }
                                            autoFocus="true"
                                            maxLength="1024"
                                            style={{
                                                display: this.state.selectedValue == 11 ? 'block' : 'none',
                                                width: '100%',
                                                padding: '5px',
                                                height: '175px',
                                                overflowY: 'auto',
                                                border: '1px solid #000',
                                                marginBottom: '1rem',
                                                backgroundColor:this.state.selectedValue != 11 ? '#bdbcb9' : '#FFFFFF'
                                            }}/>
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        {`${this.state.notes ? this.state.notes.length : 0}/1024`}
                                    </div>    
                                </div>      
                            </div>
                            <div style={{textAlign:'center',paddingTop:10}}>
                                <Button bsStyle="danger" onClick={this.onCancel} style={{float:'left'}}>
                                    Cancel
                                </Button>
                                <Button bsStyle="success" onClick={this.onComplete} type="submit" style={{float:'right'}}>
                                    Confirm
                                </Button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    'activeUser': state.admin.activeUser,
    'user': state.auth.user,
    'selectedPatient':state.app.selectedPatient,
    'socialDeterminants': state.app.socialDeterminants,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteTODOPopup);
