import React from 'react';
import {Input, Modal, Button, FormGroup, FormControl, Table} from 'react-bootstrap';

class ConfirmResetAssessmentPopup extends React.Component {

    render() {
        return (
            <Modal bsSize="large" show={this.props.show}>
                <Modal.Header closeButton onHide={this.props.onCancel}>
                    <Modal.Title>
                        <div className="text-align-center">
                            <div>{'Reset Confirmation'}</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{overflow: 'auto', height: 'auto'}}>
                        <div style={{padding:15}}>
                            <strong>Changing answer will reset the assessments. Do you want to continue?</strong>
                        </div>
                        <div style={{borderTop: '1px solid gray', height: 50, textAlign: 'center', paddingTop: 15}}>
                            <Button id="patientContactCancel" onClick={this.props.onCancel} style={{float: 'left'}}>
                                Cancel
                            </Button>
                            <Button id="patientContactClose" bsStyle="danger" onClick={this.props.onContinue} style={{float: 'right'}}>
                                Ok
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ConfirmResetAssessmentPopup;
