import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import * as notificationsActionCreator from "../../actions/notifications";
import { Panel, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Table, Column, Cell } from "fixed-data-table";
import Switch from "react-switch";
import PageHeader from "../../components/PageHeader";
import AddNewCasePopup from "./AddNewCasePopup";
import CaseMessageDetail from "./CaseMessageDetail";
import categories from "./categories.json"
const unidecode = require('unidecode');



const Message = (props) => {
  const { message, onMessageSelect, unreadOnly } = props;
  const isUnread = message.unread_messages;
  const status = message.status;
  if(unreadOnly && !isUnread){
    return null;
  }
  let barStyle = {};
  if (status === 'OPEN') {
    barStyle = {
      color: "#337ab7",
      fontSize: 16,
      padding: 15,
      display: "flex",
      flex: 1,
      margin: 10,
      background: "#d9edf7",
      borderRadius: 4,
      border: "1px solid #bce8f1",
      cursor: "pointer",
    };
  } else {
    barStyle = {
      color: "#333",
      fontSize: 16,
      padding: 15,
      display: "flex",
      flex: 1,
      margin: 10,
      background: "#D8D8D8",
      borderRadius: 4,
      border: "1px solid #d6e9c6",
      cursor: "pointer",
    };
  }

  return (
    <div style={{...barStyle, fontWeight: isUnread ? '600' : 'normal' , 
        display:'flex', flex:1}} onClick={() => {
          onMessageSelect(message);
        }}>
    <div style={{width:175,  display: "flex",
          width: 150,
          justifyContent: "flex-start",
          alignItems: "center"}}>
      {isUnread ? (
          <i className="fa fa-envelope" style={{fontSize: 22,paddingRight:5}}></i>
        ) :
        (
          <i className="fa fa-envelope-open-o" style={{fontSize: 22,paddingRight:5}}></i>
        )}
        {
        message.priority === 'HIGH' && 
          <div style={{background:'red', 
          borderRadius:15, 
          maxHeight:25,
          color:'#FFFFFF', 
          fontWeight:600,
          fontSize:11, 
          marginLeft:15,
          padding:5}}>
            High Priority
          </div>
        }
    </div>
    <div style={{width:150}}>{message.case_id}</div>
    <div style={{display:'flex', flex:0.9}}>{message.subject}</div>
    <div style={{display:'flex', flex:0.5}}>{message.category ? categories[message.category] : '--'}</div>
    <div style={{width:150, textAlign:'center'}}>{message.status}</div>
    <div style={{width:200, textAlign:'center'}}> {moment(message.last_message_time).format("MMM DD, YYYY hh:mm a")}</div>
</div>  
  )
};

class ChartCaseMessagesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddNew: false,
      showDetail: false,
      unreadOnly:false
    };
  }

  componentDidMount() {
    const { selectedPatient } = this.props;
    const { rinconOrgId, patientRinconId } = selectedPatient;
    if(!rinconOrgId){
      this.props.notificationsAction.getPatientRinconInfo(this.props.selectedPatient.provider.organization_id,
        this.props.selectedPatient.ehr_pid).then((result) => {
          this.props.notificationsAction.getPatientCases(
            result.rinconOrgId,
            result.patientRinconId
          );
        })
    }else{
      this.props.notificationsAction.getPatientCases(
        rinconOrgId,
        patientRinconId
      );
    }
  }

  onPopupHide() {
    this.setState({ selectedPatient: null, show: false });
  }

  handleAddCase = () => {
    this.setState({ showAddNew: true });
  };

  onAddNewHide = () => {
    this.setState({ showAddNew: false });
  };

  onMessageSelect = (message) => {
    console.log(message);
    this.setState({ showDetail: true, selectedMessage: message });
    this.props.showSecondaryTimer();
  };

  onCaseMessageDetailHide = () => {
    this.setState({ showDetail: false, selectedMessage: null });
  };

  toggleUnread = () => {
    const {unreadOnly} = this.state;
    this.setState({unreadOnly:!unreadOnly});
  }

  render() {
    const { caseMessages } = this.props;
    const { selectedPatient } = this.props;
    const { unreadOnly } = this.state;
    const recordLength = caseMessages ? caseMessages.length : 0;

    const header = (
      <div style={{ height: 35 }}>
        <div style={{ fontSize: 18, float: "left" }}>Cases</div>
        <div style={{ float: "right", marginRight: 10, display:'flex', alignItems:'center' }}>
          <Button
            bsStyle="primary"
            id="addGoalButton"
            onClick={this.handleAddCase}
            disabled={!(selectedPatient &&
              selectedPatient.provider &&
              selectedPatient.provider.location_ehr_location_id &&
              selectedPatient.provider.ehr_provider_id )}
          >
            Add New
          </Button>
          {
            !(selectedPatient &&
              selectedPatient.provider &&
              selectedPatient.provider.location_ehr_location_id &&
              selectedPatient.provider.ehr_provider_id ) && 
              <OverlayTrigger placement="bottom" overlay={<Tooltip bsStyle="background:'#D8D8D8'">
                <p>EHR ID not configured for provider or location</p>
              </Tooltip>}>
                <span style={{marginLeft:15, color:'#D8D8D8'}}><i className="fa fa-info-circle fa-lg" aria-hidden="true"></i></span>
              </OverlayTrigger>
              
          }
        </div>
      </div>
    );

    return (
      <div>
        <div
          style={{
            display: "flex",
            marginBottom: "1rem",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <PageHeader></PageHeader>
          <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
            <Panel header={header} style={{ width: "100%" }}>
              <div style={{ position: "relative" }}>
                {caseMessages !== undefined && caseMessages.length === 0 ? (
                  <div>There are no unread case messages.</div>
                ) : (
                  <div>
                    <div style={{paddingLeft:10,display:'inline-block'}}>
                        <Switch
                            onColor="#337ab7"
                            onChange={
                                () => { this.toggleUnread()}
                            }
                            checked={unreadOnly}
                            className="react-switch"
                            /> <span style={{paddingTop:5, fontWeight:400}}>Show Unread Only</span>
                    </div>
                    <div style={{display:'flex', flex:1, fontWeight:600, margin:'0 10px', padding:'0 15px'}}>
                            <div style={{width:150}}/>
                            <div style={{width:150}}>Case ID</div>
                            <div style={{display:'flex', flex:0.9}}>Subject</div>
                            <div style={{display:'flex', flex:0.5}}>Type</div>
                            <div style={{width:150, textAlign:'center'}}>Status</div>
                            <div style={{width:200, textAlign:'center'}}>Activity</div>
                    </div>  
                    {_.map(caseMessages, (message) => {
                      return (
                        <Message
                          unreadOnly={unreadOnly}
                          message={message}
                          key={message.id}
                          onMessageSelect={this.onMessageSelect}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </Panel>
          </div>
        </div>
        <AddNewCasePopup
          show={this.state.showAddNew}
          onPopupHide={this.onAddNewHide}
          showSecondaryTimer={this.props.showSecondaryTimer}
        />
        <CaseMessageDetail
          show={this.state.showDetail}
          onPopupHide={this.onCaseMessageDetailHide}
          showSecondaryTimer={this.props.showSecondaryTimer}
          selectedMessage={this.state.selectedMessage}
        />
        {/* <SelectPatientConfirmPopup show={this.state.show} onPopupHide={this.onPopupHide.bind(this)}
                                           data={this.state.selectedPatient}
                                           fromCaseMessages/> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedPatient: state.app.selectedPatient,
  caseMessages: state.notifications.patientCaseMessages,
});

const mapDispatchToProps = (dispatch) => ({
  notificationsAction: bindActionCreators(notificationsActionCreator, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChartCaseMessagesList);
