import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentQueueCcm = () =>
    <PatientQueue
        showQueueCount
        patientNameColumn
        timeBilledColumn
        providerDetailColumn
        todoItemColumn
        showFilters
        showProgramTypeFilter
        showScheduleCallFilter
        statusColumn
        chartColumn
        lastWorkedByColumn
        page
        queue="urgentCcm"
        endpoint="Urgent?typesToExclude=1,8,16,18,19"
        label="Urgent"
    />;

export default UrgentQueueCcm;
