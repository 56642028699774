import React from "react";

const PageHeader = ({ style, children }) => {
  const styleObject = style || {};
  return (
    <header>
      <h1
        style={{
          ...styleObject,
          margin: "0 0 1rem",
          fontSize: 24,
          fontWeight: "bold",
        }}
      >
        {children}
      </h1>
    </header>
  );
};

export default PageHeader;
