import * as Sentry from "@sentry/browser";
import React from 'react';
import ReactDOM from 'react-dom';
import { pushState } from 'redux-router';
import Root from './containers/Root';
import configureStore from './store/configureStore';
import {loginUserSuccess} from './actions/authactions';
import config from './config';

import 'font-awesome/css/font-awesome.min.css';
import './styles/main.css'
import './styles/animate.css'
import './styles/bootstrap-datetimepicker.css'
import './styles/daterangepicker.css'
import './styles/fixed-data-table-base.min.css'
import './styles/fixed-data-table-style.min.css'
import './styles/react-datepicker.css'
import './styles/three-bounce.css'
import './styles/toastr.min.css'
import './styles/Typeahead.css'
import 'bootstrap/dist/css/bootstrap.min.css';


// TODO: Remove guard if we proceed with implementation after analyzing
// data scrubbing in dev environment
if (!process.env.NODE_ENV.includes('prod')) {
    Sentry.init({
        dsn: config.SENTRY_DSN,
        environment: config.ENV,
        tracesSampleRate: 1.0,
    });
}


const target = document.getElementById('root');

const store = configureStore(window.__INITIAL_STATE__);

const node = (
    <Root store={store} />
);
config.store = store;

let token = localStorage.getItem('ccmLoggedinUser');
if (token !== null) {
    var loggedinUser = JSON.parse(token);
    var currentRoute = store.getState().router.location.pathname;
    if(currentRoute.indexOf('/home/patient/') !== -1){
        currentRoute = '/home';
        store.dispatch(pushState(null,currentRoute));
    }
    store.dispatch(loginUserSuccess(loggedinUser,currentRoute));
}

ReactDOM.render(node, target);
