import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import {Modal,Button} from 'react-bootstrap';
import { FormControl, ControlLabel } from 'react-bootstrap';
import LinkedStateMixin from 'react-addons-linked-state-mixin';

class PowerOfAttorney extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            poa: props.patient.poa_name ? props.patient.poa_name : ''
        }
    }

    componentWillReceiveProps(nextProps){
        if(this.props.patient !== nextProps.patient){
            this.setState({poa:nextProps.patient.poa_name})
        }
    }

    onCancel() {
        this.setState({poa: ''});
        this.props.onPopupHide();
    }

    onSchedulableChange(e) {
        this.setState({poa:e.target.value});
    }

    onSubmit(){
        const {poa} = this.state;
        this.props.adminActions.updatePOA(this.props.patient.id,
            this.state.poa,(response)=>{
                this.onCancel();
            });

    }

    onDelete(){
        const {poa} = this.state;
        this.props.adminActions.deletePOA(this.props.patient.id,(response)=>{
                this.onCancel();
            });

    }

    render(){
        return (
            <Modal show={this.props.show}>
                <Modal.Header closeButton onHide={this.onCancel.bind(this)}>
                    <Modal.Title>
                        <div className="text-align-center">
                            <div>Power of Attorney</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ marginTop: '10px'}}>
                        <form onSubmit={this.onSubmit.bind(this)}>
                            <ControlLabel>Power of Attorney</ControlLabel>
                            <FormControl
                                type="text"
                                required
                                valueLink={this.linkState('poa')}
                                placeholder="Power of Attorney"
                                style={{marginBottom: '10px'}}
                            />

                        </form>
                    </div>
                    <div style={{borderTop:'1px solid gray',height: 50,textAlign: 'center',paddingTop: 15, marginTop: '15px'}}>
                        <Button onClick={this.onCancel.bind(this)} style={{float:'left'}}>
                            Cancel
                        </Button>
                        
                        <Button bsStyle="success" type="submit"
                                style={{float:'right'}}
                                onClick={this.onSubmit.bind(this)} id="saveContactPreference">
                            Save
                        </Button>
                        {
                            this.props.patient.poa_name &&  <Button bsStyle="danger" type="button"
                            style={{marginRight:15,float:'right'}}
                            onClick={this.onDelete.bind(this)} id="saveContactPreference">
                        Delete
                    </Button>
                        }
                       

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}


reactMixin(PowerOfAttorney.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    'user': state.auth.user
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PowerOfAttorney);
