/**
 * Created by synerzip on 07/03/16.
 */
import React from 'react';
import {msToTime} from '../../utils';

class WorkLoggedCell extends React.Component {
    constructor(props) {
        super(props);
        this.elapsedTime = 0;
        this.state = {
            work_millis_logged: this.props.data.work_millis_logged,
            work_millis_target: this.props.data.work_millis_target
        }
    }

    calculateTime(incrementTime) {
        var {work_millis_logged,work_millis_target} = this.state;
        return (work_millis_logged + this.elapsedTime);

    }

    componentDidMount() {
        this.scheduleInterval = setInterval(()=> {
            this.elapsedTime += 1000;
            this.setState({timeSpend: this.calculateTime()});
        }, 1000);
    }

    componentWillUnmount() {
        if (this.scheduleInterval) {
            clearInterval(this.scheduleInterval);
        }
    }

    render() {
        var {timeSpend,work_millis_target} = this.state;
        if (!timeSpend) {
            timeSpend = this.calculateTime();
        }
        var statusImg = <span> - </span>;

        var targetYellowBoundary = (85/100)*work_millis_target;
        if (timeSpend) {
            if (timeSpend < targetYellowBoundary) {
                //Green
                statusImg = <div style={{ color:'#067809' }}>{msToTime(timeSpend)}</div>;
            } else if (timeSpend >= targetYellowBoundary && timeSpend < work_millis_target) {
                //Yellow
                statusImg = <div style={{ color:'#D76800' }}>{msToTime(timeSpend)}</div>;
            } else {
                //Red
                statusImg = <div style={{ color:'#F52B04' }}>{msToTime(timeSpend)}</div>;
            }
        }
        return (
            <div style={{ fontSize: 36, fontWeight: 'bold' }}>
                {statusImg}
            </div>
        )
    }
}

export default WorkLoggedCell;
