import React from 'react';
import DateTimePickerPlugin from 'react-bootstrap-datetimepicker';
import moment from 'moment';

class DateTimePicker extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			dateError: false
		}
	}

	handleDateFocus(e) {
		e.target.value = ""
	}

	handleDateBlur(e) {
		if(this.props.dateTime == "Invalid date") {
			this.setState({dateError: true})
		} else {
			this.setState({dateError: false})
		};
		if(e.target.value == "") {
			e.target.value = moment(this.props.dateTime, 'YYYY-MM-DD').format('MM/DD/YYYY');
		};
	}

	render() {
		var inputProps = this.props.inputProps || {};
		inputProps.onFocus = e => this.handleDateFocus(e);
		inputProps.onBlur = e => this.handleDateBlur(e);

		return (
			<div className={this.state.dateError ? "has-error" : ""}>
				<DateTimePickerPlugin 
					dateTime={this.props.dateTime}
					daysOfWeekDisabled={this.props.daysOfWeekDisabled}
					defaultText={this.props.defaultText}
					format={this.props.format}
					inputFormat={this.props.inputFormat}
					inputProps={inputProps}
					maxDate={this.props.maxDate}
					minDate={this.props.minDate}
					mode={this.props.mode}
					onChange={this.props.onChange}
					size={this.props.size}
					showToday={this.props.showToday}
					viewMode={this.props.viewMode}
					ref={this.props.ref}
					inline={true}
				/>
				{this.state.dateError && 
					<span className="text-danger"><small>Please enter a valid date (MM/DD/YYYY)</small></span>
				}
			</div>
		)
	}
}

export default DateTimePicker;