/**
 * Created by synerzip on 02/01/16.
 */
import React from 'react';
var ReactCSSTransitionGroup = require('react-addons-css-transition-group');

class AnimatedLinearProgress extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            percentage: null
        }
    }

    animate(percentage){

        this.setState({percentage: percentage});
    }

    componentDidMount(){
        this.animate(this.props.percentage);
    }

    componentWillReceiveProps(nextProps,nextState){
        if(nextProps.percentage != this.props.percentage) {
            this.animate(nextProps.percentage);
        }
    }

    render(){
        if(this.state.percentage != null) {
            return (
                <ReactCSSTransitionGroup transitionName="example" transitionAppear={true} transitionAppearTimeout={1000}
                                         transitionEnterTimeout={500}
                                         transitionLeaveTimeout={1000}>
                    <div className="line-graph-outer">
                        <div className="line-graph-innner" style={{width:this.state.percentage+'%'}}></div>
                    </div>
                </ReactCSSTransitionGroup>
            );
        }else{
            return(<div />);
        }
    }

}

export default AnimatedLinearProgress;