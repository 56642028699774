export const PATIENT_STATUS_NEW = 1;
export const PATIENT_STATUS_ACTIVE = 2;
export const PATIENT_STATUS_UNENROLL_PENDING = 3;
export const PATIENT_STATUS_UNENROLLED = 4;
export const PATIENT_STATUS_SUPPORT = 10;
export const PATIENT_STATUS_PENDING = 100;
export const PATIENT_STATUS_REJECTED = 101;
export const PATIENT_STATUS_AE_APPROVED = 102;
export const PATIENT_STATUS_OPTED_OUT = 103;
export const PATIENT_STATUS_UNVERIFIED = 104;
export const PATIENT_STATUS_RPM_UNVERIFIED = 204;
export const PATIENT_STATUS_RPM_INCORRECT_PHONE_NUMBER = 208;
export const PATIENT_STATUS_EXHAUSTED = 108;
export const DATE_DISPLAY_FORMAT = 'MM/DD/YYYY';
export const DATE_API_EXCHANGE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_DISPLAY_FORMAT = 'MM/DD/YYYY HH:MM';
export const DATE_TIME_API_EXCHANGE_FORMAT = 'yyyy-MM-dd HH:MM';
export const ADMIN_ROLE = 'ROLE_SUPERVISOR';
export const MA_ROLE = 'ROLE_MA';
export const ROLE_MULTITEAM = 'ROLE_MULTITEAM';
export const ROLE_TRIAGE = 'ROLE_TRIAGE'

export const StateData = [
	{id: 'AL', name: 'Alabama'},
	{id: 'AK', name: 'Alaska'},
	{id: 'AZ', name: 'Arizona'},
	{id: 'AR', name: 'Arkansas'},
	{id: 'CA', name: 'California'},
	{id: 'CO', name: 'Colorado'},
	{id: 'CT', name: 'Connecticut'},
	{id: 'DE', name: 'Delaware'},
	{id:'DC',name:'District of Columbia'},
	{id: 'FL', name: 'Florida'},
	{id: 'GA', name: 'Georgia'},
	{id: 'HI', name: 'Hawaii'},
	{id: 'ID', name: 'Idaho'},
	{id: 'IL', name: 'Illinois'},
	{id: 'IN', name: 'Indiana'},
	{id: 'IA', name: 'Iowa'},
	{id: 'KS', name: 'Kansas'},
	{id: 'KY', name: 'Kentucky'},
	{id: 'LA', name: 'Louisiana'},
	{id: 'ME', name: 'Maine'},
	{id: 'MD', name: 'Maryland'},
	{id: 'MA', name: 'Massachusetts'},
	{id: 'MI', name: 'Michigan'},
	{id: 'MN', name: 'Minnesota'},
	{id: 'MS', name: 'Mississippi'},
	{id: 'MO', name: 'Missouri'},
	{id: 'MT', name: 'Montana'},
	{id: 'NE', name: 'Nebraska'},
	{id: 'NV', name: 'Nevada'},
	{id: 'NH', name: 'New Hampshire'},
	{id: 'NJ', name: 'New Jersey'},
	{id: 'NM', name: 'New Mexico'},
	{id: 'NY', name: 'New York'},
	{id: 'NC', name: 'North Carolina'},
	{id: 'ND', name: 'North Dakota'},
	{id: 'OH', name: 'Ohio'},
	{id: 'OK', name: 'Oklahoma'},
	{id: 'OR', name: 'Oregon'},
	{id: 'PA', name: 'Pennsylvania'},
	{id: 'RI', name: 'Rhode Island'},
	{id: 'SC', name: 'South Carolina'},
	{id: 'SD', name: 'South Dakota'},
	{id: 'TN', name: 'Tennessee'},
	{id: 'TX', name: 'Texas'},
	{id: 'UT', name: 'Utah'},
	{id: 'VT', name: 'Vermont'},
	{id: 'VA', name: 'Virginia'},
	{id: 'WA', name: 'Washington'},
	{id: 'WV', name: 'West Virginia'},
	{id: 'WI', name: 'Wisconsin'},
	{id: 'WY', name: 'Wyoming'}
];

export const OPT_OUT_STATUSES = [
	{
		'label': 'Decline',
		'id': 103
	},
	{
		'label': 'Do NOT Call',
		'id': 106
	},
	{
		'label': 'Remove From Queue',
		'id': 107
	}
];

export const RPM_OPT_OUT_STATUSES = [
	{
		'label': 'Opt Out',
		'id': 203
	},
	{
		'label': 'Do NOT Call',
		'id': 206
	},
	{
		'label': 'Remove From Queue',
		'id': 207
	},
	{
		'label': 'Incorrect Phone Number',
		'id': 208
	}
];


export const STATUSES = {
	1: 'New',
	2: 'Active',
	3: 'Pending Unenroll',
	4: 'Unenrolled',
	10: 'Support',
	100: 'Pending',
	101: 'Rejected',
	102: 'AE-Approved',
	103: 'Opted Out',
	104: 'Unverified',
	105: 'Needs Attention',
	106: 'Do Not Call',
	107: 'Remove From Queue'
}
