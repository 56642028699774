import React from 'react';
import { Modal, Button } from 'react-bootstrap';


function get_options(question, rating, updateComponent) {
	let options = [];
	for ( let i = question['range_start']; i <= question['range_end']; i = i + question['range_step'] ) {
		options.push(
			<label style={{marginRight: '40px'}} key={`satisfaction_level_${i}`}>
				<input style={{height: '16px !important'}} name="satisfaction_level" checked={rating == i} onChange={updateComponent.bind(this, 'satisfactionRating')} type='radio' value={i} />
				{i}
			</label>
		)
	}
	options.push(
		<label style={{marginRight: '40px'}} key={`decline`}>
			<input style={{height: '16px !important'}} name="satisfaction_level" checked={rating == 'decline'} onChange={updateComponent.bind(this, 'satisfactionRating')} type='radio' value="decline" />
			Decline
		</label>
	);
	return options;
}


const PatientSatisfactionModal = ({ show, onHide, question, onConfirm, updateComponent, rating, notes }) =>
    <Modal bsSize="large" show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Patient Satisfaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
	            {question && question['text']}
            </p>
            <div>
			    <label className="control-label">Satisfaction Level: (required)</label>
                    <div className="radio" style={{marginTop: '0px !important'}}>
	                    {
		                    question ? get_options(question, rating, updateComponent) : ''
	                    }
                    </div>
		    </div>
            <div className="form-group">
				<label className="control-label">Notes:</label>
				<div>
					<textarea onChange={updateComponent.bind(this, 'satisfactionNotes')} value={notes} className="form-control" placeholder="Notes"  />
					<div style={{display:'flex',justifyContent:'flex-end'}}>
						{`${notes ? notes.length : 0}/4096`}
					</div> 
				</div>
			</div>
	        <p style={{marginBottom:0, fontStyle:'italic'}}>I certify that the above selection and notes are true and accurate. I understand that a false statement will result in disciplinary action.</p>
        </Modal.Body>
        <Modal.Footer>
            <Button bsStyle="danger" style={{float: 'left'}} onClick={onHide}>
                Cancel
            </Button>
            <Button bsStyle="success" onClick={onConfirm}>
                Save
            </Button>
        </Modal.Footer>
    </Modal>;

export default PatientSatisfactionModal;
