/**
 * Created by synerzip on 25/01/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Input,Modal,Button} from 'react-bootstrap';
import _ from 'lodash';
import UnassignedPatientCell from './UnassignedPatientCell';
import OrganizationCell from './OrganizationCell';
import moment from 'moment';

class AssignMAModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }
    onMASelected(e){
        this.setState({selectedMA:e.target.value});
    }

    assignMA(){
        var selectedMAId = this.state.selectedMA;
        if(!selectedMAId){
            this.props.commonActions.showToast({
                status:'error',
                title:'Invalid Entry',
                message:'Please Select Medical Assistant'

            });
            var interval = setInterval(()=>{
                clearInterval(interval);
                this.props.commonActions.hideToast();
            },2);
            return;
        }

        this.props.adminActions.assignMA(this.props.selectedPatient.id,this.state.selectedMA);
        this.props.onPopupHide();
    }

    render(){
        var {medicalAssistants,selectedPatient} = this.props;
        var to = null;
        var patient = "";
        var provider = "";
        if(selectedPatient){
            patient = <div>
                <div>{selectedPatient.first_name + ' ' + selectedPatient.last_name}</div>
                <div>{"("+selectedPatient.date_of_birth+")"}</div>
            </div>;

            provider = <div>
                <div>{selectedPatient.provider.provider}</div>
                <div>{selectedPatient.provider.organization}</div>
            </div>;
        }
        var optionList = _.map(_.keys(medicalAssistants),(maKey)=>{

            return <option className="replyPatients" key={maKey} value={maKey}>
                {medicalAssistants[maKey].name}
            </option>
        });
        to =<select id="modal-message-recipient"
                    className="ma-select" ref="meaningfulUse" onChange={this.onMASelected.bind(this)}>
            <option value="" disabled selected>Select Medical Assistant</option>
            {optionList}
        </select>;

        var selectedMAName,activePatients = null;
        if(this.state.selectedMA){
            var selectedMA = medicalAssistants[this.state.selectedMA];
            selectedMAName = selectedMA.name;
            activePatients = selectedMA.active_patient_count;
        }

        return (
            <Modal show={this.props.show}>
                <Modal.Header closeButton onHide={()=>{
                                this.props.onPopupHide();
                            }}>
                    <Modal.Title>
                        <div style={
                            {
                            textAlign: 'center'
                            }
                        }>{to}</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                        <div style={{borderBottom:'1px solid gray',height:100}}>
                            <div className="header-box">
                                <div className="header-label">
                                    Medical Assistant
                                </div>
                                <div className="header-label">
                                    Active Patients
                                </div>
                            </div>
                            <div className="ma-value-box">
                                <div className="value-label">
                                    {selectedMAName}
                                </div>
                                <div className="value-label">
                                    {activePatients}
                                </div>
                            </div>
                        </div>
                        <div style={{borderBottom:'1px solid gray',height:100}}>
                            <div className="header-box">
                                <div className="header-label">
                                    Patient
                                </div>
                                <div className="header-label">
                                    Provider
                                </div>
                            </div>
                            <div className="ma-value-box">
                                <div className="value-label">
                                    {patient}
                                </div>
                                <div className="value-label">
                                    {provider}
                                </div>
                            </div>
                        </div>

                    <div style={{height:100,textAlign:'center',paddingTop:30}}>
                        <Button bsStyle="success" onClick={this.assignMA.bind(this)}>Assign Medical Assistant</Button>
                    </div>
                </Modal.Body>

            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    'user': state.auth.user,
    'medicalAssistants': state.admin.medicalAssistants
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions:bindActionCreators(commonActionCreator,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignMAModal);