import {createReducer} from '../utils';
import CONSTANTS from '../constants';

const {
    FAX_MATCHING_PATIENT_LIST_RECEIVED,
    FAX_QUEUE_RECORD_RECEIVED,
    RECORD_UMMATCHED_DATA_RECEIVED,
    RESET_UNMATCHED_DATA,
    INBOUND_RECORDS_RECEIVED_TO_FIX_QUEUE,
    FAX_APPROVAL_QUEUE_RECORD_RECEIVED
} = CONSTANTS;

const initialState = {
    patientList:null,
    queueRecord:null,
    ummatchedData:null,
    inboundRecordsToFixQueue:null,
    approvalQueueRecord:null
};

export default createReducer(initialState, {
    [FAX_MATCHING_PATIENT_LIST_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            patientList:payload
        });
    },
    [INBOUND_RECORDS_RECEIVED_TO_FIX_QUEUE]:(state,payload) => {
        return Object.assign({}, state, {
            inboundRecordsToFixQueue:payload
        });
    },
    [FAX_QUEUE_RECORD_RECEIVED]: (state,payload) => {
        return Object.assign({}, state, {
            queueRecord:payload
        });
    },
    [FAX_APPROVAL_QUEUE_RECORD_RECEIVED]:(state,payload) => {
        return Object.assign({}, state, {
            approvalQueueRecord:payload
        });
    },
    [RECORD_UMMATCHED_DATA_RECEIVED]:(state,payload) => {
        return Object.assign({}, state, {
            ummatchedData:payload
        });
    },
    [RESET_UNMATCHED_DATA]:state => {
        return Object.assign({}, state, {
            ummatchedData:null
        });
    },
});