import React from 'react';
import { FormGroup, ControlLabel, Radio, Checkbox, FormControl } from 'react-bootstrap';
import _ from 'lodash';
import ConfirmResetAssessmentPopup from "../views/patientCare/ConfirmResetAssessmentPopup";

class DynamicFormInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			resetPopupShow:false
		}
	}
	onCancel(){
		this.setState({resetPopupShow:false});
	}
	parseQuestions( questions, margin ) {
		let newMargin = margin + 30;
		return _.map( questions, ( question ) => {
			return (
				this.createQuestion( question, newMargin )
			)
		})
	}

	createQuestion( question, margin ) {
		return (
			<FormGroup className="dynamicFormGroup" id={question.id} key={ question.id } style={ {marginLeft: margin + 'px'} }>
				{ question.title && <ControlLabel>{ question.title }</ControlLabel> }
				{ question.ref && <div><i>REF: { question.ref }</i></div>}
				{ this.determineQuestionType( question, margin ) }
			</FormGroup>
		)
	}

	async onContinue() {
		const {question,option,value} = this.state;
		switch( this.props.question.type ) {
			case 'YES_NO':
			case 'PICK_ONE':
				await this.props.resetAll(this.props.sectionId);
				this.setState({resetPopupShow:false});
				if(option) {
					this.onRadioValueChange(question, option);
				}
				break;
			case 'PICK_MANY':
				await this.props.resetAll(this.props.sectionId);
				this.setState({resetPopupShow:false});
				this.props.updateInput('checkbox', question.id, option.short_name, option.score)
				break;
			case 'TEXT_AREA':
				await this.props.resetAll(this.props.sectionId);
				this.setState({resetPopupShow:false});
				this.props.updateInput('textArea', question.id, value, 0)
				break;
			case 'NUMBER':
				await this.props.resetAll(this.props.sectionId);
				this.setState({resetPopupShow:false});
				this.props.updateInput('textArea', question.id, value, 0)
				break;
		}
	}

	//this.getUpdateValue.bind(this, 'textArea', question.id, 0)
	onRadioValueChange(question,option){
		const optionArray = _.filter(question.options,(qOption)=>{
			return qOption.id !== option.id;
		});

		if(optionArray.length > 0){
			//Prepare answer of newly selected radio option
			const ansObject = { value:option.short_name, question_id:question.id, changed:true, score:option.score };

			//Prepare array of questions which answer need to reset
			let questionsToRemove = [question];
			_.each(optionArray,(yesNoOption) => {
				if(yesNoOption.questions && yesNoOption.questions.length  > 0){
					questionsToRemove = questionsToRemove.concat(yesNoOption.questions)
				}
			});

			this.props.clearAnswer(questionsToRemove,ansObject);
		}
	}

	getSectionAnswer(){
		const {editAnswer,answerCollection,assessmentQuestionCollection,sectionId} = this.props;
		const sectionArray = _.filter(assessmentQuestionCollection.sections,(section)=>{
			return section.id == sectionId;
		});
		const questionObject = {};
		if(sectionArray.length > 0){
			const section = sectionArray[0];
			_.each(section.questions,(que)=>{
				questionObject[que.id] = que;
				_.each(que.options,(option)=>{
					_.each(option.questions,(optionQue)=>{
						questionObject[optionQue.id] = optionQue;
					})
				});
			})
		}
		const sectionAnswerCollection = [];
		_.each(this.props.answerCollection,(answer)=>{
			const queId =  answer.question_id;
			const questionObj = questionObject[queId];
			if(questionObj){
				//PICK_MANY
				sectionAnswerCollection.push(answer)
			}
		});
		return sectionAnswerCollection;
	}

	updateRadioValueChanged(e,question,option){
			const {editAnswer,answerCollection,assessmentQuestionCollection,sectionId} = this.props;
			const sectionAnswerCollection = this.getSectionAnswer();

			if(!editAnswer && (sectionAnswerCollection && sectionAnswerCollection.length > 0)){
				//sectionId
				this.setState({resetPopupShow:true,question,option});
				return;
			}else{
				this.onRadioValueChange(question,option);
			}
		   //Get other radio options of this question, which need to be reset along with its child questions

	}
	updateCheckboxValueChanged(question,option){
		const {editAnswer,answerCollection} = this.props;
		if(!editAnswer && (answerCollection && answerCollection.length > 0)){
			//sectionId
			this.setState({resetPopupShow:true,question,option});
			return;
		}else{
			this.props.updateInput('checkbox', question.id, option.short_name, option.score)
		}
	}


	updateTextAreaValueChanged(question,value){
		const {editAnswer,answerCollection} = this.props;
		if(!editAnswer && (answerCollection && answerCollection.length > 0)){
			//sectionId
			this.setState({resetPopupShow:true,question,value});
			return;
		}else{
			this.props.updateInput('textArea', question.id, value, 0)
		}
	}
	updateNumberValueChanged(question,value){
		const {editAnswer,answerCollection} = this.props;
		if(!editAnswer && (answerCollection && answerCollection.length > 0)){
			//sectionId
			this.setState({resetPopupShow:true,question,value});
			return;
		}else{
			this.props.updateInput('number', question.id, value, 0)
		}
	}


	determineQuestionType( question, margin ) {
		let result;
		switch( question.type ) {
			case 'YES_NO':
				result = this.renderRadio( question, margin );
				break;
			case 'PICK_ONE':
				result = this.renderRadio( question, margin );
				break;
			case 'PICK_MANY':
				result = this.renderCheckbox( question, margin );
				break;
			case 'TEXT_AREA':
				result = this.renderTextArea( question, margin );
				break;
			case 'NUMBER':
				result = this.renderNumberInput( question, margin );
				break;
			case 'INFO':
				result = this.renderLabel( question, margin );
				break;
		}
		return ( result )
	}

	renderRadio( question, margin ) {
		return (
			_.map( question.options, ( option ) => {
				const checked = !!_.find( this.props.answerCollection, ( answer ) => {
					return answer.question_id === question.id && answer.value === option.short_name
				});
				return (
					<div key={ option.id }>
						<Radio
							name={ question.shortName }
							disabled={this.props.disabled}
							id={ option.short_name }
							checked={ checked }
							onChange={ (e)=>this.updateRadioValueChanged(e,question,option) }
						>
							{ option.title }
						</Radio>
						{ option.questions.length && checked ?
							this.parseQuestions( option.questions, margin )
							: null
						}
					</div>
				)
			})
		)
	}

	renderCheckbox( question, margin ) {
		return (
			_.map( question.options, ( option ) => {
				const checked = !!_.find( this.props.answerCollection, ( answer ) => {
					return answer.question_id === question.id && answer.value === option.short_name
				});
				return (
					<div key={ option.id }>
						<Checkbox
							disabled={this.props.disabled}
							name={ question.shortName }
							checked={ checked }
							onChange={ ()=>this.updateCheckboxValueChanged(question,option) }
						>
							{ option.title }
						</Checkbox>
						{ option.questions.length && checked ?
							this.parseQuestions(option.questions, margin)
							: null
						}
					</div>
				)
			})
		)
	}

	getUpdateValue( type, questionId, score, e ) {
		this.props.updateInput(type, questionId, e.target.value, score)
	}

	renderTextArea( question, margin ) {
		const answer = _.find(this.props.answerCollection, (answer) => {
			return answer.question_id === question.id;
		});
		const value = answer ? answer.value : '';
		return (
			<div>
				<FormControl
					componentClass="textarea"
					value={ value ? value : '' }
					style={ {width: 'calc(100% - ' + margin + 'px)', resize: 'vertical', maxHeight: '200px'} }
					placeholder={ question.description }
					disabled={this.props.disabled}
					onChange={ (e) => this.updateTextAreaValueChanged(question,e.target.value)}
				/>
			</div>
		)
	}

	renderLabel(question,margin){
		return (
			<div>
				<div>
					{question.description}
				</div>
				<ul>
				{
					_.map(question.options,(option)=>{
						return <li key={option.id}>{option.title}</li>
					})
				}
				</ul>
			</div>
		)
	}

	renderNumberInput( question, margin ) {
		const answer = _.find(this.props.answerCollection, (answer) => {
			return answer.question_id === question.id;
		});
		const value = answer ? answer.value : '';
		return (
			<div>
				<input
					onKeyDown={ this.checkValue.bind(this, question.minValue, question.maxValue) }
					onKeyUp={ this.checkValue.bind(this, question.minValue, question.maxValue) }
					type="number"
					disabled={this.props.disabled}
					min={ question.minValue }
					max={ question.maxValue }
					style={ {resize: 'vertical', maxHeight: '200px'} }
					placeholder={ question.description }
					defaultValue={ value }
					onChange={ (e) => this.updateNumberValueChanged(question,e.target.value)}
				/>
			</div>
		)
	}

	checkValue( min, max, e ) {
		if ( e.target.value && ( e.target.value > max && e.keyCode !== 46 && e.keyCode !== 8 ) ) {
			e.preventDefault();
			e.target.value = max
		} else if ( e.target.value && ( e.target.value < min && e.keyCode !== 46 && e.keyCode !== 8 ) ) {
			e.preventDefault();
			e.target.value = min
		}
	}

	render() {
		return (
			<div style={ {borderBottom: '1px solid #3c763d', marginBottom: '15px'} }>
				{ this.createQuestion(this.props.question, 0) }

				<ConfirmResetAssessmentPopup show={this.state.resetPopupShow}
											 onCancel={this.onCancel.bind(this)}
											 onContinue={this.onContinue.bind(this)}/>
			</div>
		)
	}

}

export default DynamicFormInput
