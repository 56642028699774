import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentQueueRoundTwo = () =>
	<PatientQueue
		showQueueCount
		patientNameColumn
		providerDetailColumn
		timeBilledColumn
		todoItemColumn
		showFilters
		showProgramTypeFilter
        showScheduleCallFilter
		statusColumn
		chartColumn
		lastWorkedByColumn
		lastOutCallColumn
		callAttemptsCol
		showSort
		sortOptions={[
			{
				'name': 'Last Called Ascending',
				'key': 'lastOutCallTs&dir=asc',
			},
			{
				'name': 'Last Called Descending',
				'key': 'lastOutCallTs&dir=desc',
			}
		]}
		page
		queue="urgentRoundTwo"
		endpoint="Urgent?typesToInclude=21"
		label="Urgent - Round Two"
	/>;

export default UrgentQueueRoundTwo;
