import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Panel,Input,Button,Accordion,FormControl,FormGroup} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
var LinkedStateMixin = require('react-addons-linked-state-mixin');

class CarePlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step:this.props.goal.step,
            stepError:false
        }
    }

    stepHeader(step) {
        let rotate = this.state.open ? ' rotate' : '';
        let style = {
            marginTop: '-10px',
            marginBottom: '-10px',
            marginLeft: '-15px',
            marginRight: '-15px',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '15px',
            paddingRight: '15px'
        }
        return (
            <div className="clearfix">
                <div className="clearfix" style={style}>
                    <div className="pull-left">
                        <i className={'fa fa-angle-down circleBorder ' + rotate} aria-hidden="true"></i>{step.step_title}
                    </div>
                    <div className="text-center" style={{margin:'0 auto', width:'200px'}}>
                        <span>{moment.utc(step.create_ts).format('MM/DD/YYYY')}</span>
                    </div>
                </div>
            </div>
        )
    }

    clearAndToggleEdit() {
        this.setState({
            step:this.props.goal.step
        });
        this.props.toggleEdit(this.props.goal.id, this.props.goal.step_title);
    }

    saveEdit( stepId, step, e ) {
        e.preventDefault();
        if(step.trim() == '') {
            this.setState({stepError:true});
        } else {
            this.props.saveEdit(stepId, step);
            this.setState({stepError:false});
        }
    }

    render() {
        return (
            <div>
                {!this.props.edit && 
                    <div>
                        <span style={{whiteSpace:'pre-line'}}>{this.props.goal.step}</span>
                        <div className="clearfix">
                            {!this.props.complete && 
                                <Button 
                                    bsStyle="success"
                                    className="addLabButton pull-right"
                                    style={{marginTop:'10px'}}
                                    id={'goalEditButton' + this.props.goal.id}
                                    onClick={this.props.toggleEdit.bind(this, this.props.goal.id, this.props.goal.step_title)}>
                                    Edit
                                </Button> 
                            }
                        </div>
                    </div>
                }
                {this.props.edit &&
                    <div>
                        <form onSubmit={this.saveEdit.bind(this, this.props.goal.id, this.state.step)}>
                            <FormGroup className={this.state.stepError && 'has-error'}>
                                <div>
                                    <FormControl 
                                        componentClass="textarea" 
                                        placeholder="Enter care goal"
                                        maxLength="4096"
                                        style={{resize:'vertical',height:'150px'}}
                                        valueLink={this.linkState('step')}
                                        required
                                    />
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        {`${this.state.step ? this.state.step.length : 0}/4096`}
                                    </div> 
                                </div>
                                {this.state.stepError && <span className="text-danger"><small>Please enter a care goal</small></span>}
                            </FormGroup>
                            <div className="pull-right">
                                <Button 
                                    bsStyle="success"
                                    style={{marginTop:'15px',marginRight:'20px'}}
                                    type="submit">
                                    Save
                                </Button>  
                                <Button 
                                    bsStyle="danger"
                                    style={{marginTop:'15px'}}
                                    onClick={this.clearAndToggleEdit.bind(this, this.props.goal.id, this.state.step_title)}>
                                    Cancel
                                </Button>  
                            </div>
                        </form>
                    </div>
                }
            </div>
        )
    }
}

reactMixin(CarePlan.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    'carePlanAndGoals': state.levelTwoDashboard.carePlanAndGoals,
    'userId': state.auth.id,
    'selectedPatient': state.app.selectedPatient
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CarePlan);