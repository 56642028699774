import React from 'react';
import PatientQueue from './PatientQueue';

const GAPSBreastCancerQueue = () =>
    <PatientQueue
        showQueueCount
        patientNameColumn
        providerDetailColumn
        timeBilledColumn
        todoItemColumn
        showFilters
        statusColumn
        chartColumn
        lastWorkedByColumn
        page
        queue="gAPSBreastCancer"
        endpoint="GAPSBreastCancer"
        label="GAPSBreastCancer"
    />;

export default GAPSBreastCancerQueue;
