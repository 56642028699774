import React from 'react';

const AddressCell = ({ data: { address1, address2, city, state, zip_code } }) =>
    <div>
        <div>
            {address1}
        </div>
        <div>
            {address2}
        </div>
        <div>
            {city && state && zip_code && `${city}, ${state} ${zip_code}`}
        </div>
    </div>;

export default AddressCell;
