import React from 'react';

const Filter = ({ options, value, onChange, label,disabled=false,width="auto" }) =>
    <div style={{ marginLeft: '1rem' }}>
        <label>{label}</label>
        <select
            value={value}
            className="form-control"
            disabled={disabled}
            style={{ width, minWidth: '200px' }}
            onChange={(e) => onChange(
               typeof e.target.value  == Number ? Number(e.target.value) : e.target.value)}
        >
            <option value="">
                --
            </option>
            {options.map((option) =>
                <option key={option.id} value={option.id}>
                    {option.secondary_name ?  option.secondary_name : option.name}
                </option>
            )}
        </select>
    </div>

export default Filter;
