import React from 'react';
import { Modal } from 'react-bootstrap';

const FaxDeleteConfirmation = ({ show, onDeleteConfirm, onDeleteCancel }) =>
    <Modal show={show}>
        <Modal.Header closeButton onHide={onDeleteCancel}>
            <Modal.Title>
                Delete Fax Record
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{ padding: '15px 0' }}>
                Are you sure you want to permanently delete this document?
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-default" onClick={onDeleteCancel}>
                Cancel
            </button>
            <button className="btn btn-danger" onClick={onDeleteConfirm}>
                Delete
            </button>
        </Modal.Footer>
    </Modal>;

export default FaxDeleteConfirmation;
