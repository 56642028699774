import CONSTANTS from '../../constants';
import { pushState } from 'redux-router';
import { showToast, hideToast, post } from '../common';

const {MAKE_CALL_SENT,
    MAKE_CALL_SUCCESS,
    MAKE_CALL_FAIL,
    NAV_LINK_CHANGED,} =CONSTANTS;
    
export function redirectTo(path) {
    return (dispatch) => {
        dispatch(pushState(null, path));
    };
}

export const setActiveNavLink = (key) => ({ type: NAV_LINK_CHANGED, payload: key });

export function sentMakeCallRequest({id, phone}){
    return function (dispatch) {
        dispatch({type: MAKE_CALL_SENT});
        post(encodeURI(`/v1/patients/${id}/vbx/${phone}`))
            .then((result)=> {
                dispatch({
                    type: MAKE_CALL_SUCCESS
                });
            })
            .catch(error => {
                dispatch({
                    type: MAKE_CALL_FAIL
                });
                dispatch(showToast({
                    status: 'error',
                    title: "Make Call Failed",
                    message: "Failed to make a call to patient"
                }));
                var interval = setInterval(()=> {
                    clearInterval(interval);
                    dispatch(hideToast());
                }, 2);
            });
    }
}
