import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentQueueCne = () =>
    <PatientQueue
        showQueueCount
        patientDetailColumn
        timeBilledColumn
        providerDetailColumn
        todoItemColumn
        showFilters
        showProgramTypeFilter
        showScheduleCallFilter
        statusColumn
        chartColumn
        lastWorkedByColumn
        lastOutCallColumn
        callAttemptsCol
        showSort
        sortOptions={[
            {
                'name': 'Last Called Ascending',
                'key': 'lastOutCallTs&dir=asc',
            },
            {
                'name': 'Last Called Descending',
                'key': 'lastOutCallTs&dir=desc',
            }
        ]}
        page
        queue="urgentCne"
        endpoint="Urgent?typesToExclude=6,16,18,19"
        label="Urgent"
    />;

export default UrgentQueueCne;
