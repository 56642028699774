import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import {Modal,Button} from 'react-bootstrap';
import _ from 'lodash';
import LinkedStateMixin from 'react-addons-linked-state-mixin';

class UnenrollPatientPopup extends React.Component {

	constructor(props){
		super(props);

		this.state = {
			reason: null,
			notes: '',
			showNotes: false,
		}
	}

	showMessage(message, title = 'Invalid Value', status = 'error') {
		this.props.commonActions.showToast({
			status: status,
			title: title,
			message: message
		});
		let interval = setInterval(()=> {
			clearInterval(interval);
			this.props.commonActions.hideToast();
		}, 2);
	}

	onSubmit(e) {
		e.preventDefault();
		let {reason, notes} = this.state;
		if ( !reason ) {
			this.showMessage('Please Provide Reason for Sending Patient To Patient Services', 'Reason Missing');
			return;
		}
		if ( reason === 8 && !notes.trim() ) {
			this.showMessage( 'When selecting "other," notes must be provided', 'Notes missing' );
			return;
		}
		this.props.adminActions.changeStatusToSupport( this.props.patient.id, reason, notes );
		this.setState({ reason: null, notes: '', showNotes: false });
		this.props.onPopupHide();
	}

	onReasonChanged( e ) {
		let selectedReason = _.find( this.props.patientServicesReasons, ( reason ) => reason.id === Number( e.target.value ) );
		this.setState({ reason: selectedReason.id, notes: '', showNotes: true });
	}

	onCancel() {
		this.setState({reason: null});
		this.props.onPopupHide();
	}

	updateNotes( e ) {
		this.setState({ notes: e.target.value });
	}

	render() {
		return (
			<Modal show={this.props.show}>
				<Modal.Header closeButton onHide={this.onCancel.bind(this)}>
					<Modal.Title>
						<div className="text-align-center">
							<div>Send to Patient Services</div>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div style={{borderBottom:'1px solid gray'}}>
						<p>Clicking "Confirm" will allow the patient to be worked by Patient Services</p>
					</div>
					<div style={{ marginTop: '10px'}}>
						<form onSubmit={this.onSubmit.bind(this)}>
							<p><b>Reason for sending patient to Patient Services:</b></p>
							<select className="form-control" id="patientServicesReasons" onChange={ this.onReasonChanged.bind( this )} defaultValue={0}>
								<option value={0} disabled>Select a reason for sending patient to Patient Services</option>
								{
									_.map( this.props.patientServicesReasons, ( reasonObject ) => {
										return (
											<option key={ reasonObject.id } value={ reasonObject.id }>{ reasonObject.reason }</option>
										)
									})
								}
							</select>
							{ this.state.showNotes ?
								<div style={{ marginTop: '15px' }}>
									<p><b>Notes:</b></p>
									<div>
										<textarea className="form-control" name="" id="" rows="10" value={ this.state.notes } onChange={ this.updateNotes.bind( this ) } maxLength={1024}/>
										<div style={{display:'flex',justifyContent:'flex-end'}}>
											{`${this.state.notes ? this.state.notes.length : 0}/1024`}
										</div> 
									</div>	
								</div>
								:
								null
							}
							<div style={{borderTop:'1px solid gray',height: 50,textAlign: 'center',paddingTop: 15, marginTop: '15px'}}>
								<Button onClick={this.onCancel.bind(this)} style={{float:'left'}}>
									Cancel
								</Button>
								<Button bsStyle="danger" type="submit"  style={{float:'right'}}>
									Confirm
								</Button>

							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		);

	}
}

reactMixin(UnenrollPatientPopup.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
	'user': state.auth.user,
	'patientServicesReasons': state.admin.patientServicesReasons,
});

const mapDispatchToProps = (dispatch) => ({
	adminActions: bindActionCreators(adminActionCreator, dispatch),
	commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UnenrollPatientPopup);
