/**
 * Created by synerzip on 22/01/16.
 */
import {createReducer} from '../utils';
import CONSTANTS from '../constants';
import {pushState} from 'redux-router';
import _ from 'lodash';

const {
    REQUEST_SENT,
    REQUEST_FAIL,
    CONSENT_FORMS_RECEIVED,
    MEDICAL_RECORD_RECEIVED,
    CLEAR_MATCH_APPROVAL_PATIENT,
    PATIENT_DATA_RECEIVED,
    UNASSIGNED_PATIENTS_RECEIVED,
    MA_ASSIGNED_SUCCESSFULLY,
    MA_DETAIL_RECEIVED,
    LOGOUT_USER,
    CASE_HISTORY_RECEIVED,
    ASSESSMENTS_RECEIVED,
    RECEIVED_PROVIDER_LIST,
    RECEIVED_REQUEST_HISTORY,
    SUBMIT_REQUEST_VALID,
    SENT_RECORD_REQUESTS_SUCCESS,
    ACTIVE_USER_RECEIVED,
    COMPANIES_RECEIVED,
    RECEIVED_REPORTING_DATA,
    MEDICAL_ASSISTANTS_RECEIVED,
    SORT_REPORTS_ASCENDING,
    SORT_REPORTS_DESCENDING,
    MATCH_APPROVAL_PATIENT_SELECTED,
    UTILIZATION_RECEIVED,
    WHITEBOARD_EXISTS,
    WHITEBOARD_CONTENT,
    UPDATE_WHITEBOARD_CONTENT,
    OPEN_WHITEBOARD,
    CNE_PERFORMANCE_RECEIVED,
    WORK_SUMMARY_RECEIVED,
    CLEAR_WORK_SUMMARY,
    RECEIVED_UNENROLL_REASONS,
    REQUEST_CHART_CLOSURE,
    REQUEST_CHART_CLOSURE_COMPLETED,
    CHART_CLOSURE_REQUEST_FOUND,
    REMOVE_CHART_CLOSURE_REQUEST,
    RECEIVED_PATIENT_SURVEY_QUESTION,
    RECEIVED_PATIENT_SURVEY_SUBMISSTION,
    RECEIVED_PATIENT_SERVICES_REASONS,
    CARE_PLAN_TYPES,
    CARE_PLAN_QUESTION_RECEIVED, ASSESSMENT_ANSWERS_RECEIVED,
    UPDATE_GAPS_ASSESSMENT_ANSWERS,
    GAPS_ANSWERS_RECEIVED, GAPS_HISTORY_RECEIVED,
    ON_GOAL_REVIEW_FREQUENCY_RECEVIED,
    ON_TEAMS_RECEVIED,
    ON_CLINICIANS_RECEIVED,
    ON_OPEN_CHART_SUMMARY,
    ON_CLOSE_CHART_SUMMARY,
    ON_ALL_CLINICIANS_RECEIVED
} = CONSTANTS;
 
const initialState = {
    medicalRecords: null,
    patients: null,
    unassignedPatients: null,
    medicalAssistants: null,
    selectedMADetail: null,
    carePlanTypes:[],
    goalReviewFrequency:[],
    teams:[],
    clinicians:[],
    allClinicians: [],
    openChartSummary:false
};

export default createReducer(initialState, {

    [LOGOUT_USER]: (state, payload) => {
        return Object.assign({}, state, {
            medicalRecords: null,
            patients: null,
            unassignedPatients: null,
            medicalAssistants: null,
            selectedMADetail: null
        });
    },

    [UNASSIGNED_PATIENTS_RECEIVED]: (state, payload) => {
        var dataBlob = {};
        var rowIndex = 0;
        _.each(payload.unAssignedPatients, (data)=> {
            dataBlob[rowIndex++] = data;
        });

        var medicalAssistantDataBlob = {};
        _.each(payload.medicalAssistants, (data)=> {
            medicalAssistantDataBlob[data.id] = data;
        });

        return Object.assign({}, state, {
            'unassignedPatients': dataBlob,
            'medicalAssistants': medicalAssistantDataBlob
        });
    }
    ,
    [MA_ASSIGNED_SUCCESSFULLY]: (state, payload) => {
        var _state = _.cloneDeep(state);
        //TODO - its stored as array index.. change it
        var index = 0;
        var newUnassignedPatients = {};
        _.each(_.keys(_state.unassignedPatients), (currentIndex)=> {
            if (_state.unassignedPatients[currentIndex].id != payload) {
                newUnassignedPatients[index++] = _state.unassignedPatients[currentIndex];
            }
        });
        _state.unassignedPatients = newUnassignedPatients;
        return _state;
    },
    [MA_DETAIL_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            'selectedMADetail': payload
        });
    },
    [ACTIVE_USER_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            'activeUser': payload
        });
    },
    [ON_TEAMS_RECEVIED]: (state, payload) => {
        return Object.assign({}, state, {
            'teams': payload
        });
    },
    [ON_CLINICIANS_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            'clinicians': payload
        });
    },
    [ON_ALL_CLINICIANS_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            'allClinicians': payload
        });
    },
    [COMPANIES_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            'companies': payload
        });
    },
    [RECEIVED_PROVIDER_LIST]: ( state = {}, payload ) => {
        return Object.assign({}, state, {
            providerList: payload
        });
    },
    [RECEIVED_REQUEST_HISTORY]: ( state = {}, payload ) => {
        return Object.assign({}, state, {
            requestHistory: payload
        });
    },
    [ON_GOAL_REVIEW_FREQUENCY_RECEVIED]:( state = {}, payload ) => {
        return Object.assign({}, state, {
            goalReviewFrequency: payload
        });
    },
    [SUBMIT_REQUEST_VALID]: ( state = {}, payload ) => {
        let requestHistory = state.requestHistory || [];
        return Object.assign({}, state, {
            requestHistory: [ ...requestHistory, payload ]
        });
    },
    [SENT_RECORD_REQUESTS_SUCCESS]: ( state = {}, payload ) => {
        return Object.assign({}, state, {
            sentRecords: payload
        });
    },
    [MATCH_APPROVAL_PATIENT_SELECTED]: (state, payload) => {
        return Object.assign({}, state, {
            matchApprovalPatient: payload
        });
    },
    [CLEAR_MATCH_APPROVAL_PATIENT]: (state) => {
        return Object.assign({}, state, {
            matchApprovalPatient: null
        });
    },
    [RECEIVED_REPORTING_DATA]: ( state = {}, payload ) => {
        return Object.assign({}, state, {
            reportsData: payload
        });
    },
    [MEDICAL_ASSISTANTS_RECEIVED]: ( state = {}, payload ) => {
        return Object.assign({}, state, {
            medicalAssistants: payload
        });
    },
    [WHITEBOARD_EXISTS]: ( state = {}, payload ) => {
        return Object.assign({}, state, {
            whiteboardExists: payload
        });
    },
    [WHITEBOARD_CONTENT]: ( state = {}, payload ) => {
        return Object.assign({}, state, {
            whiteboardContent: payload.note || '',
            whiteboardId: payload.id
        });
    },
    [UPDATE_WHITEBOARD_CONTENT]: ( state = {}, payload ) => {
        return Object.assign({}, state, {
            whiteboardContent: payload,
        });
    },
    [OPEN_WHITEBOARD]: ( state = {}, payload ) => {
        return Object.assign({}, state, {
            openWhiteboard: true,
        });
    },
    [SORT_REPORTS_ASCENDING]: ( state = {}, payload ) => {
        let sortedData = state.reportsData;
        switch ( payload ) {
            case 'ccmuser':
            case 'first_name':
            case 'last_name':
            case 'notes':
            case 'name':
            case 'provider':
            case 'location':
            case 'organization':
            case 'status':
            case 'urgent':
            case 'urgent_note':
            case 'date_of_birth':
            case 'start_time':
            case 'end_time':
                const sortStrings = ( a, b ) => {
                    if ( a < b ) {
                        return -1;
                    } else if ( a > b ) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
                sortedData.sort( ( a, b ) => {
                    return sortStrings( a[payload], b[payload]);
                });
                break;
            case 'id':
            case 'user_id':
            case 'minutes_worked':
            case 'center_id':
            case 'billable':
            case 'billable_min':
            case 'overage':
            case 'overage_min':
            case 'ratio':
                sortedData.sort( ( a, b ) => {
                    return a[payload] - b[payload];
                });
                break;
            default:

        }
        return Object.assign({}, state, {
            reportsData: sortedData
        });
    },
    [SORT_REPORTS_DESCENDING]: ( state = {}, payload ) => {
        let sortedData = state.reportsData;
        switch ( payload ) {
            case 'ccmuser':
            case 'first_name':
            case 'last_name':
            case 'notes':
            case 'name':
            case 'provider':
            case 'location':
            case 'organization':
            case 'status':
            case 'urgent':
            case 'urgent_note':
            case 'date_of_birth':
            case 'start_time':
            case 'end_time':
                const sortData = ( a, b ) => {
                    if ( a > b ) {
                        return -1;
                    } else if ( a < b ) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
                sortedData.sort( ( a, b ) => {
                    return sortData( a[payload], b[payload]);
                });
                break;
            case 'id':
            case 'user_id':
            case 'minutes_worked':
            case 'center_id':
            case 'billable':
            case 'billable_min':
            case 'overage':
            case 'overage_min':
            case 'ratio':
                sortedData.sort( ( a, b ) => {
                    return b[payload] - a[payload];
                });
                break;
            default:

        }
        return Object.assign({}, state, {
            reportsData: sortedData
        });
    },
    [UTILIZATION_RECEIVED]: ( state = {}, payload ) => {
        return Object.assign({}, state, {
            utilizationBillable: payload.billable,
            utilizationOverage: payload.overage,
            utilizationPercentBillable: payload.percent_billable,
        });
    },
    [CNE_PERFORMANCE_RECEIVED]: ( state={}, payload ) => {
        return Object.assign({}, state, {
            consents: payload.consents,
            declines: payload.declines,
        });
    },
    [WORK_SUMMARY_RECEIVED]: ( state={}, payload ) => {
        return Object.assign( {}, state, {
            workSummary: payload,
        });
    },
    [CLEAR_WORK_SUMMARY]: ( state={} ) => {
        return Object.assign( {}, state, {
            workSummary: [],
        });
    },
    [RECEIVED_UNENROLL_REASONS]: ( state={}, payload ) => {
        return Object.assign( {}, state, {
            unenrollReasons: payload,
        });
    },
	[RECEIVED_PATIENT_SERVICES_REASONS]: ( state={}, payload ) => {
		return Object.assign( {}, state, {
			patientServicesReasons: payload,
		});
	},
	[RECEIVED_PATIENT_SURVEY_QUESTION]: ( state={}, payload ) => {
		return Object.assign( {}, state, {
			patientSurveyQuestion: payload,
		});
	},
	[RECEIVED_PATIENT_SURVEY_SUBMISSTION]: ( state={}, payload ) => {
		return Object.assign( {}, state, {
			patientSurveySubmitted: payload['survey_submitted'],
		});
	},
    [REQUEST_CHART_CLOSURE]: ( state={}, payload ) => {
        return Object.assign( {}, state, {
            chartClosureRequested: {
                data: payload,
                showPatientDataModal: true,
            }
        });
    },
    [REQUEST_CHART_CLOSURE_COMPLETED]: ( state={}, payload ) => {
        return Object.assign( {}, state, {
            chartClosureRequested: {
                data: payload,
                showPatientDataModal: false,
            }
        });
    },
    [CHART_CLOSURE_REQUEST_FOUND]: ( state={}, payload ) => {
        return Object.assign( {}, state, {
            chartClosureRequested: {
                data: payload,
                showNotificationOfChartClosureRequest: true,
            },
        });
    },

    [REMOVE_CHART_CLOSURE_REQUEST]: ( state={}, payload ) => {
        return Object.assign( {}, state, {
            chartClosureRequested: null,
        });
    },
    [CARE_PLAN_TYPES]:( state={}, payload ) => {
        return Object.assign( {}, state, {
            carePlanTypes: payload,
        });
    },
    [CARE_PLAN_QUESTION_RECEIVED]:( state={}, payload ) => {
        return Object.assign( {}, state, {
            carePlanQuestions: payload,
        });
    },
    [UPDATE_GAPS_ASSESSMENT_ANSWERS]: (state, payload) => {
        return Object.assign({}, state, {
            'gapsAssessmentAnswers': payload
        });
    },
    [GAPS_ANSWERS_RECEIVED]: (state, payload) => {
        const disabled = payload && payload.length > 0;
        return Object.assign({}, state, {
            'gapsAssessmentAnswers': payload,
            "gapsDisabled":disabled
        });
    },
    [GAPS_HISTORY_RECEIVED]: (state, payload) => {
        return Object.assign({}, state, {
            'gapsHistory': payload
        });
    },
    [ON_OPEN_CHART_SUMMARY]: (state) => {
        return Object.assign({}, state, {
            'openChartSummary': true
        });
    },
    [ON_CLOSE_CHART_SUMMARY]: (state) => {
        return Object.assign({}, state, {
            'openChartSummary': false
        });
    },

});

