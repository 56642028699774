import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import {Modal, Button} from 'react-bootstrap';

const NotesModal = ({show, organizationNote, onHide, patient, notes, isRetSpec,
    call_preference_description,engagementHistory}) => {
    return <Modal show={show} bsSize="lg">
        <Modal.Header onHide={onHide}>
            <Modal.Title>Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                patient.program_type == 'pathways_stroke_care' ?
                    <div>
                        <div>
                            <h5 style={{color: 'red'}}>Pathways - Stroke Care</h5>
                        </div>
                    </div>
                    :
                    ''
            }
            {patient.from_support ?
                <div>
                    <div>
                        <h5 style={{color: 'red'}}>Recently in Patient Services</h5>
                        <p className="notes-content">
                            Patient’s last call concluded with Patient Services. Please proceed with Care.

                        </p>
                    </div>
                    <hr/>
                </div>
                :
                ''
            }
            {
                patient.reconsent && <div style={{padding:15,
                background:'rgb(196,75,65)',display:'flex'}}>
                       <div style={{display:'flex',alignItems:'center',
                          justifyContent:'center'}}>
                            <li className='fa fa-exclamation-triangle' style={{fontSize:45,color:'#FFFFFF'}}/>
                        </div> 
                        <div style={{display:'flex',flex:1,alignItems:'center',
                          justifyContent:'center',fontSize:20,color:'#FFFFFF'}}>
                            Patient is Non-Billable - Re-Consent Required
                          </div>
                </div>
            }
            {
                engagementHistory && engagementHistory.length > 0 &&
                <div>
                    <h5>Engagement History</h5>
                    <div style={{display:'flex',flex:1,padding:15,overflowX:'auto',justifyContent:'center'}}>
                    {   
                        _.map(engagementHistory,(engData,index) => {
                            let label = '';
                            let icon = '';
                            let dateStr = '';
                            if(engData.engagement_type == 1){
                                label = 'No Answer';
                                icon = 'images/NoAnswerCall.png';
                                dateStr = moment(engData.engagement_ts).format('MM/DD/YYYY HH:mm')
                            }else if(engData.engagement_type == 2){
                                label = 'Outbound';
                                icon = 'images/outboundCall.png';
                                dateStr = moment(engData.engagement_ts).format('MM/DD/YYYY HH:mm')
                            }else if(engData.engagement_type == 3){
                                label = 'Inbound';
                                icon = 'images/InBoundCall.png';
                                dateStr = moment(engData.engagement_ts).format('MM/DD/YYYY HH:mm')
                            }
                            return <div className='engagement-box' key={index}>
                            <h5>{label}</h5>
                            <div style={{display:'flex',justifyContent:'center',
                            alignItems:'center'}}>
                               <img src={icon} style={{height:48}}/>     
                            </div>
                            <h6 style={{textAlign:'center',fontSize:11}}>{dateStr}</h6>
                        </div>
                        })
                    }
                    </div>
                    <hr/>
                        {
                            call_preference_description && 
                            <div>
                                <div>
                                    <h5 style={{color:'red'}}>Engagement Instruction</h5>
                                    <p className="notes-content">{call_preference_description}</p>
                                </div>
                                <hr/>
                            </div>
                        }
                        
                </div>

            }
             
           
            
            <div>
                <h5>Organization Notes</h5>
                <p className="notes-content">{organizationNote === undefined ? 'Loading...' : (!!organizationNote ? organizationNote : 'No organization notes available')}</p>
            </div>
            <hr/>
            {
                isRetSpec &&
                <div>
                    <div>
                        <h5>Patient Services Reason</h5>
                        <p className="notes-content">
                            {patient.support_reason_text}{patient.support_note && ` - ${patient.support_note}`}
                        </p>
                    </div>
                    <hr/>
                </div>

            }
            <div>
                <h5>Internal Notes</h5>
                <p className="notes-content"
                   style={{"whiteSpace": "pre-line"}}>{notes === undefined ? 'Loading...' : (notes === null ? 'No internal notes available' : notes)}</p>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button id="patientNotesConfirm" bsStyle="success" onClick={onHide}
                    disabled={organizationNote === undefined || notes === undefined}>
                Confirm
            </Button>
        </Modal.Footer>
    </Modal>
};

export default NotesModal;
