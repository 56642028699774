import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const UnDeclineModal = ({ show, onConfirm, onHide, patient, declineText }) =>
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Confirm Move to Unverified</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                Clicking "Confirm" will move {patient.first_name} {patient.last_name} to unverified from the program and change their status to "Unverified". If you would not like to move this patient to unverified, please
                click "Cancel."
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button bsStyle="default" onClick={onHide}>
                Cancel
            </Button>
            <Button bsStyle="danger" onClick={onConfirm}>
                Confirm
            </Button>
        </Modal.Footer>
    </Modal>;

export default UnDeclineModal;
