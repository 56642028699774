import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import { connect } from 'react-redux';
import {Button,MenuItem} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import $ from 'jquery';

class AssessmentsDropdown extends React.Component{

  constructor(props){
    super(props);
    this.state={
    }
  }

  getRecord( recordId ) {
    this.props.adminActions.getRecord( recordId, hasError => {
      if (!hasError) {
        window.open(this.props.recordPdfUrl, '_blank').focus();
      }
    });
  }

  onOptionSelect( optionKey ) {
    this.props.onOptionSelect( optionKey );
  }

  render(){
    return (
        <div style={containerStyle}>
          <div style={{padding:'5px'}} className="text-center">
            <div className="newRecordsContainer text-left" style={{marginTop: '10px'}}>
              <div className="clearfix" style={recordContainer}>
                <div className="col-sm-10">
                  <h4 style={recordHeadingStyle}>
                    <span style={{cursor: 'pointer'}} data-toggle="dropdown"
                          onClick={()=>{this.onOptionSelect('assessment')}}>
                      CCM Assessment
                      </span></h4>
                </div>
              </div>
            </div>
          </div>
          <div style={{padding:'5px'}} className="text-center">
            <div className="newRecordsContainer text-left" style={{marginTop: '10px'}}>
              <div className="clearfix" style={recordContainer}>
                <div className="col-sm-10">
                  <h4 style={recordHeadingStyle}>
                    <span style={{cursor: 'pointer'}} data-toggle="dropdown"
                          onClick={()=>{this.onOptionSelect('MIPSAssessment')}}>
                      MIPS Assessment
                      </span></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}
const containerStyle = {
  width: '230px',
}
const recordHeadingStyle = {
  fontWeight: 300,
  marginTop: 0,
  marginBottom: 0,
  color: '#337ab7',
}
const recordContainer = {
  padding: '5px',
  borderBottom: '1px solid grey',
  marginTop: '10px',
}
const mapStateToProps = (state) => ({
  'recordPdfUrl': state.levelTwoDashboard.recordPdfUrl,
  'selectedPatient': state.app.selectedPatient,
  'recordsHeaders': state.app.recordsHeaders,
});

const mapDispatchToProps = (dispatch) => ({
  adminActions: bindActionCreators(adminActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentsDropdown);