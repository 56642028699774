import React from 'react';
import {bindActionCreators} from 'redux';
import * as adminActionCreator from '../../actions/admin';
import {connect} from 'react-redux';
import {Panel, Input, Button, Grid, Row, Form} from 'react-bootstrap';
import _ from 'lodash';
import moment from "moment";
import DateTimePicker from 'react-bootstrap-datetimepicker-custom-icons';
import MatchRecordPopup from './MatchRecordPopup'

class MatchRecordScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visitDate: "",
            selectedRecord:null,
            flipRecord:false,
            showConfirmation:false
        }

        this.onVisitDateChanged = this.onVisitDateChanged.bind(this);
        this.onSelectedRecordChanged = this.onSelectedRecordChanged.bind(this);
    }

    onStep3Submit(){
        // console.log(this.state.visitDate);
        console.log(this.state.selectedRecord);

        const data = {
            "record_request_ids":[parseInt(this.state.selectedRecord)],
            "inbound_record_id":this.props.queueRecord.record.id,
            "flip":this.state.flipRecord,
            "encounter_date":this.state.visitDate
        };
        this.setState({showConfirmation:true,
            confirmationData:data,patientData:this.props.ummatchedData.selectedRowData});
        //this.props.onMatchRecord(data,this.props.ummatchedData.selectedRowData.id);
    }

    onPopupHide(){
        this.setState({showConfirmation:false,confirmationData:null});
    }

    onSelectedRecordChanged(e){
        this.setState({selectedRecord:e.currentTarget.value});
    }

    onVisitDateChanged(visitDate){
        console.log(visitDate);
        this.setState({visitDate});
    }

    onFlipRecordCheckChanged(e){
        this.setState({flipRecord:e.target.checked});
    }

    render(){
        const {ummatchedData} = this.props;
        // const {visitDate} = this.state;
        let unmatchedData = ummatchedData.unmatched;
        let previouslyMatchedData = ummatchedData.previouslyMatched;
        // if(visitDate && visitDate != 'Invalid date'){
        //     unmatchedData = _.filter(unmatchedData,(data)=>{
        //        return data.request_start == visitDate;
        //     });
        //     previouslyMatchedData = _.filter(previouslyMatchedData,(data)=>{
        //         return data.request_start == visitDate;
        //     });
        // }

        let unmatchedRows = [];
        let unmatchedTable=<table><tbody><tr><td>No Unmatched Record Found</td></tr></tbody></table>;
        let previouslyMatchedRows = [];
        let previouslyMatchedTable=<table><tbody><tr><td>No Previously Matched Record Found</td></tr></tbody></table>;
        if(ummatchedData){
            unmatchedRows = _.map(unmatchedData,(data)=>{
                return <tr key={data.id}>
                    <td><input type="radio" name="selectedRecord"
                        style={{margin: '0 !important',
                            height: '16px !important'}}
                        value={data.id}
                        checked={this.state.selectedRecord == data.id}
                        onChange={this.onSelectedRecordChanged} /></td>
                    <td style={{padding:5,fontSize:12}}>{data.name}</td>
                    <td style={{padding:5,fontSize:12}}>{data.request_start}</td>
                    <td style={{padding:5,fontSize:12}}>{data.request_end}</td>
                </tr>;
            });


            if(unmatchedRows.length > 0){
                unmatchedTable = <table><tbody>{unmatchedRows}</tbody></table>;
            }

            previouslyMatchedRows= _.map(previouslyMatchedData,(data)=>{
                return <tr key={data.id}>
                    <td><input type="radio" name="selectedRecord"
                               style={{margin: '0 !important',
                                   height: '16px !important'}}
                               value={data.id}
                        checked={this.state.selectedRecord == data.id}
                        onChange={this.onSelectedRecordChanged} /></td>
                    <td style={{padding:5,fontSize:12}}>{data.name}</td>
                    <td style={{padding:5,fontSize:12}}>{data.request_start}</td>
                    <td style={{padding:5,fontSize:12}}>{data.request_end}</td>
                </tr>;
            });

            if(previouslyMatchedRows.length > 0){
                previouslyMatchedTable = <table><tbody>{previouslyMatchedRows}</tbody></table>;
            }
        }
        return (
            <div style={{height: 400}}>
                <div className="header-table">
                    <div style={{float:'left',textAlign:'left',width:'20%'}}>
                        <div style={{paddingBottom:10,fontWeight:'600',color:'rgb(167,167,167)'}}>Name</div>
                        <div style={{paddingBottom:10}}>{ummatchedData.selectedRowData.first_name+' '+ummatchedData.selectedRowData.last_name}</div>
                    </div>
                    <div style={{float:'left',textAlign:'left',width:'20%'}}>
                        <div style={{paddingBottom:10,fontWeight:'600',color:'rgb(167,167,167)'}}>DOB</div>
                        <div style={{paddingBottom:10}}>{ummatchedData.selectedRowData.date_of_birth}</div>
                    </div>
                    <div style={{float:'left',textAlign:'left',width:'20%'}}>
                        <div style={{paddingBottom:10,fontWeight:'600',color:'rgb(167,167,167)'}}>Phone</div>
                        <div style={{paddingBottom:10}}>{ummatchedData.selectedRowData.mobile ?
                            ummatchedData.selectedRowData.mobile.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") :
                            ummatchedData.selectedRowData.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}
                        </div>
                    </div>
                    <div style={{float:'left',textAlign:'left',width:'20%'}}>
                        <div style={{paddingBottom:10,fontWeight:'600',color:'rgb(167,167,167)'}}>Provider</div>
                        <div style={{paddingBottom:10}}>{ummatchedData.selectedRowData.provider.provider}</div>
                    </div>
                    <div style={{float:'left',textAlign:'left',width:'20%'}}>
                        <div style={{paddingBottom:10,fontWeight:'600',color:'rgb(167,167,167)'}}>Status</div>
                        <div style={{paddingBottom:10}}>{ummatchedData.selectedRowData.status_description}</div>
                    </div>
                </div>
                <div style={
                    {
                        'background-color': 'white',
                        border: '1px solid #d3d3d3',
                        'box-sizing': 'border-box',
                        padding: '20px',
                        overflow: 'auto',
                        width: '100%',
                        height: '270px',
                        backgroundColor:'rgb(237,241,244)',
                        marginTop:10
                    }
                }>
                    <div>
                        <div style={{fontSize:11,fontStyle:'italic',padding:10,fontWeight:'bold'}}>Unmatched</div>
                        {unmatchedTable}
                    </div>
                    <div style={{marginTop:15}}>
                        <div style={{fontSize:11,fontStyle:'italic',padding:10,fontWeight:'bold'}}>Previously Matched</div>
                        {previouslyMatchedTable}
                    </div>
                </div>
                <div style={{padding:10,paddingRight:0,paddingLeft:0,height:80}}>
                    <div style={{float:'left'}}>
                        <div className="bottom-button" onClick={() => this.props.onStepBack(2)}>
                            <i className="fa fa-arrow-left fa-2x" style={{color:'rgb(89,125,150)',float:'left'}}></i>
                            <div style={{padding:10,paddingTop:0,float:'left'}}>Back</div>
                        </div>
                    </div>
                    <div style={{float:'right',width:600}}>
                        <div style={{float:'left',marginLeft: 15, paddingTop:15}}>
                            <input type="checkbox"  onChange={this.onFlipRecordCheckChanged.bind(this)}/>
                            <div style={{float:'right',marginLeft:10}}>Flip Record 180&#176;</div>
                        </div>
                        <div style={{float:'left',marginLeft: 15, width:250}}>
                            <DateTimePicker format="YYYY-MM-DD"
                                            inputFormat="MM/DD/YYYY"
                                            dateTime={ moment().format("YYYY-MM-DD") }
                                            defaultText=""
                                            ref="DOB"
                                            iconStyle={{
                                                class: 'fa',
                                                time: 'fa-clock-o',
                                                calendar: 'fa-calendar',
                                                up: 'fa-chevron-up',
                                                down: 'fa-chevron-down',
                                                right: 'fa-chevron-right',
                                                left: 'fa-chevron-left',
                                            }}
                                            inputProps={{ 'placeholder': 'Visit Date (MM/DD/YYYY)' }}
                                            onChange={this.onVisitDateChanged}/>
                        </div>


                        <div style={{float:'right'}}>
                            <div className="bottom-button" style={{marginRight:0}}>
                                {/*<Button bsStyle="default" style={{width:150,marginRight:10}} onClick={this.onStep3Submit.bind(this)}>No Record</Button>*/}
                                <Button bsStyle="success" style={{width:150}}
                                        disabled={(!this.state.visitDate || !this.state.selectedRecord)}
                                        onClick={this.onStep3Submit.bind(this)}>Match</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <MatchRecordPopup show={this.state.showConfirmation}
                                  onMatchRecord={this.props.onMatchRecord}
                                  confirmationData={this.state.confirmationData}
                                  patientData={this.state.patientData}
                                  onPopupHide={this.onPopupHide.bind(this)}/>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    'ummatchedData': state.faxMatching.ummatchedData,
    queueRecord: state.faxMatching.queueRecord
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchRecordScreen);
