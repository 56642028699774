import React from 'react';
import * as adminActionCreator from '../../actions/admin';
import * as appActionCreator from '../../actions/app';
import * as notificationsActionCreator from '../../actions/notifications';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Row, Button, Col, Panel } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import RequestRecordTable from './RequestRecordTable';
import RequestRecordForm from './RequestRecordForm';
import { reduxForm, getValues } from 'redux-form';

class RequestRecordPreview extends React.Component {

	constructor() {
		super();
		this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
	}

	formatPhoneNumber(phoneNumber) {
		let numbers = ( phoneNumber && phoneNumber.replace(/\D/g, '') ) || [];
	    let char = { 3: '-' , 6: '-' };
	    phoneNumber = '';
	    for ( var i = 0; i < numbers.length; i++ ) {
	        phoneNumber += ( char[i] ||'' ) + numbers[i];
	    }
	    return phoneNumber;
	}

	render() {
		let formData = this.props.levelTwoDashboard.requestRecord;
		let selectedPatient = this.props.app.selectedPatient;
		let providerObject = selectedPatient.provider;
		selectedPatient.address1 = selectedPatient.address1 || '';
		selectedPatient.city = selectedPatient.city || '';
		selectedPatient.state = selectedPatient.state || '';
		selectedPatient.zip_code = selectedPatient.zip_code || '';
		return(
			<Grid>
				<Row>
					<Panel>
						<Col sm={10} smOffset={1}>
							<h3>Patient Information</h3>
							<p>Patient Name: { selectedPatient.first_name + ' ' + selectedPatient.last_name }</p>
							<p>Patient Date of Birth: { moment(selectedPatient.date_of_birth).format('MM/DD/YYYY') }</p>
							<div>
								<span style={{ 'display': 'inline-block', 'verticalAlign': 'top' }}>
									Patient Address:
								</span>
								<div style={{ 'display': 'inline-block', 'marginLeft': '5px' }}>
									<p>{ selectedPatient.address1 || '' }</p>
									<p>
										{ selectedPatient.city + (selectedPatient.city && ', ') + selectedPatient.state + ' ' + selectedPatient.zip_code }
									</p>
								</div>
							</div>
							<p>Patient Telephone Number: { this.formatPhoneNumber( selectedPatient.phone ) }</p>
							<h3>Date Range for Requested Records:</h3>
							<p>{ moment(formData.dateRangeBegin,'MM-DD-YYYY').local().format('LL') } to { moment(formData.dateRangeEnd,'MM-DD-YYYY').local().format('LL') }</p>
							<h3>Requested Delivery Method:</h3>
							<p>Fax Records to <strong>{ formData.fax }</strong></p>
							<p>
								I, { selectedPatient.first_name + ' ' + selectedPatient.last_name } (Patient), hereby request the following healthcare records and information be sent to my Chronic Care Management Provider { providerObject.provider } at { providerObject.organization }.
							</p>
							<p>
								The purpose of this request is for continuity of care.
							</p>
							<p>
								This request is for Clinical Care Document in human Readable Form. I am also requesting all medical records related to services provided including but not limited to Provider Notes, Laboratory Reports, Radiology Reports, Copies of Radiology Films/Images, EKG, History & Physical Exam, Discharge Summary, Progress Notes, Consultation Reports, Specialist Notes, Department Records, Billing Record, or any other documents pertaining to my healthcare record.
							</p>
							<p>
								Patient Legal Digital Signature: { selectedPatient.first_name + ' ' + selectedPatient.last_name } <em>[ { moment(new Date()).format('LLLL') } ]</em>
							</p>
							<p>
								I hereby certify that I have granted { providerObject.provider } at { providerObject.organization } permission to request all records mentioned above on my behalf.
							</p>
							<br />
							<p>
								Please note that { providerObject.provider } is utilizing ChartSpan Medical Technologies, Inc. EHR to coordinate records for { providerObject.organization }. ChartSpan is a certified Health IT EHR with the Office of the National Coordinator for Health Information Technology. ChartSpan's CMS certification ID is 150123R00.
							</p>
							<div className="col-md-4 col-md-offset-4 col-lg-2 col-lg-offset-5" style={{"marginTop": "15px"}}>
								<Button bsStyle="success"
										id="submitRecordRequestButton"
										onClick={ this.props.handleSubmitRequest }>Submit Request</Button>
							</div>
						</Col>
					</Panel>
				</Row>
			</Grid>
		)
	}

}

const mapStateToProps = (state) => ({
	'app': state.app,
	'levelTwoDashboard': state.levelTwoDashboard
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    appActions:bindActionCreators(appActionCreator,dispatch),
    notificationsAction:bindActionCreators(notificationsActionCreator,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestRecordPreview);
