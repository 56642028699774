/**
 * Created by synerzip on 09/01/16.
 */
import React from 'react';
let ReactDOM = require('react-dom');
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as appActionCreator from '../../actions/app';
import * as commonActionCreator from '../../actions/common';
import * as notificationsActionCreator from '../../actions/notifications';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import reactMixin from 'react-mixin';
import {Collapse,Pagination} from 'react-bootstrap';
import MaskComp from '../MaskComp';
import LinkedStateMixin from 'react-addons-linked-state-mixin';
var ReactCSSTransitionGroup = require('react-addons-css-transition-group');

class NotificationInboxItemDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message:null,
            highPriority:false,
            selectedTags:{}
        };
        this.isStatusUpdated = false
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.patientMessages && nextProps.patientMessages.items.length > 0){
            var unreadMessages = this.getUnreadMessages(nextProps.patientMessages);
            if(unreadMessages.length > 0 && (!this.isStatusUpdated)){
                this.isStatusUpdated = true;
                this.props.notificationsAction.markThreadRead(nextProps.selectedPatient.id);
            }
        }
    }

    componentDidUpdate(){
        var element = document.getElementById('notification-message-container-'+this.props.selectedPatient.id);
        element.scrollTop = element.scrollHeight;
    }

    componentDidMount(){
        if(this.props.patientMessages && this.props.patientMessages.items.length > 0){
            var unreadMessages = this.getUnreadMessages(this.props.patientMessages);
            if(unreadMessages.length > 0 && (!this.isStatusUpdated)){
                this.isStatusUpdated = true;
                this.props.notificationsAction.markThreadRead(this.props.selectedPatient.id);
            }
        }
        var element = document.getElementById('notification-message-container-'+this.props.selectedPatient.id);
        element.scrollTop = element.scrollHeight;
    }

    setHighPriority(event){
        this.setState({highPriority:event.target.checked});
    }

    onTagClicked(tagId){
        const {selectedTags} = this.state;
        if(selectedTags[tagId]){
            delete selectedTags[tagId]
        }else{
            selectedTags[tagId]= true;
        }
        this.setState({selectedTags});
    }

    onUnreadMessage(e){
        e.preventDefault();
        try{
            this.props.notificationsAction.unreadThread(this.props.selectedPatient.id);
        }catch(error){
            this.props.commonActions.showToast({
                status: 'error',
                title: 'Failed',
                message: 'Unable to mark unread'
            });
            var interval = setInterval(()=> {
                clearInterval(interval);
                this.props.commonActions.hideToast();
            }, 2);
        }
    }
    replyMessage() {
        var replyMessage = this.state.message;
        if(replyMessage.trim() === '' || replyMessage.trim() === undefined){
            this.props.commonActions.showToast({
                status: 'error',
                title: 'Invalid Message',
                message: 'Please enter valid reply message'
            });
            var interval = setInterval(()=> {
                clearInterval(interval);
                this.props.commonActions.hideToast();
            }, 2);
        } else {
            var notificationReplyData = {
                provider_id:this.props.selectedPatient.provider.id,
                patient_id:this.props.selectedPatient.id,
                sender_id:this.props.user.id,
                body:replyMessage.trim(),
                priority:this.state.highPriority ? 1 : 0,
                tags:_.keys(this.state.selectedTags)
            };
            this.props.notificationsAction.sentNotificationReply(notificationReplyData)
                .then((result)=> {
                    if ( result.ok === true ) {
                        this.setState({
                            message:''
                        });
                    }
                });

        }
        //this.props.replyTo(msg);

    }

    getMessagesData(msg){
        if(!msg.items || msg.items.length === 0){
            return <div style={{textAlign:'center', color:'#333333', fontWeight:'bold'}}>There are no notifications for this patient</div>
        }
        var messages = _.chain(msg.items)
            .sortBy((item)=>{
                return moment(item.created_ts)
            })
            .map((item,index) => {
                if(item.Send_type === 'CCM'){
                    return (<div key={index} className="col-md-12" style={{marginBottom:'10px'}} id="message-container">
                        <div className="col-md-offset-3 col-md-9 notification-nurse-message">
                            <span id={item.body}>{item.body}</span>
                            <div className="col-md-offset-8 col-md-4">
                                <span className="notification-signature-container">{item.nurse_name + (item.title ? ', '+item.title : '')}</span>
                                {/*<span className="notification-signature-container">ChartSpan RN</span>*/}
                                <span className="notification-signature-container">Sent: { moment.utc(item.created_ts).local().format('MMM D, YYYY h:mm a')}</span>
                            </div>
                            <div className="col-md-12" style={{paddingLeft:0,display:'flex',justifyContent:'space-between'}}>
                                {item.priority ? <i id={item.body + '_highPriority'} className="fa fa-star" style={{color:'#337ab7',fontSize:26}}></i> : <i/>}
                                <div style={{display:'flex',justifyContent:'flex-end'}}>
                                    {
                                        _.map(item.tags,(tag)=>{
                                            return <div key={tag}
                                                        id={item.body + '_'+tag}
                                                        style={{backgroundColor:'#337ab7',padding:5,
                                                paddingLeft:15,paddingRight:15,
                                                fontWeight:600,fontSize:12,
                                                borderRadius:15,marginLeft:10}}>{tag}</div>
                                        })
                                    }

                                </div>

                            </div>
                        </div>

                    </div>)
                } else {
                    return (<div key={index} className="col-md-12" style={{marginBottom:'10px'}} id="message-container">
                        <div className="col-md-9 notification-provider-message">
                            {item.body}
                            <div className="col-md-offset-8 col-md-4">
                                <span className="notification-signature-container">{item.provider_object.provider}</span>
                                <span className="notification-signature-container">{item.provider_object.organization}</span>
                                <span className="notification-signature-container">Sent: { moment.utc(item.created_ts).local().format('MMM D, YYYY h:mm a')}</span>
                            </div>
                            <div className="col-md-12" style={{paddingLeft:0,display:'flex',justifyContent:'space-between'}}>
                                {item.priority ? <i className="fa fa-star" style={{color:'#337ab7',fontSize:26}}></i> : <i/>}
                                <div style={{display:'flex',justifyContent:'flex-end'}}>
                                {
                                    _.map(item.tags,(tag)=>{
                                        return <div key={tag} style={{backgroundColor:'#337ab7',padding:5,
                                            paddingLeft:15,paddingRight:15,
                                            fontWeight:600,fontSize:12,
                                            borderRadius:15,marginLeft:10}}>{tag}</div>
                                    })
                                }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">&nbsp;</div>
                    </div>)
                }
            }).value();
        return messages;
    }

    getUnreadMessages(msg){
        var unreadMessages = _.chain(msg.items)
            .filter((item) => {
                return (!item.read && item.Send_type === 'PROVIDER');
            })
            .map((item)=>{
                return item.id
            }).value();

        return unreadMessages
    }

    render() {
        const { patient }= this.props;
        const messagesAvailable = patient && patient.provider && patient.provider.integrated_notifications_enabled;
        var msg = this.props.patientMessages || {};
        var notificationMessages = this.getMessagesData(msg);

        const tags = [{id:1,label:'Nurse'},{id:2,label:'Refill'},{id:3,label:'Appointment'}];
        let messageLength = this.state.message ? this.state.message.length : 0;
        return (
            <ReactCSSTransitionGroup transitionName="example" transitionAppear={true} transitionAppearTimeout={1000}
                                     transitionEnterTimeout={500}
                                     transitionLeaveTimeout={1000}>
                <div className="notification-message-container" 
                id={'notification-message-container-'+this.props.selectedPatient.id} style={{maxHeight: messagesAvailable ? 
                    'calc(100vh - 550px)' : ''}}>
                    {notificationMessages}
                </div>
                <br/><br/>
                {
                    !messagesAvailable && <div className="notification-reply-container">
                    <div className="col-md-10">
                        <div style={{paddingLeft:5,border:'1px solid rgb(118, 118, 118)',
                            borderBottom:'none',background:'#FFFFFF',display:'flex',justifyContent:'space-between'}}>
                            <div style={{display:'flex',alignItems:'center'}}>
                                Send as high priority?
                                <input id="highPriorityNotificationCheck" style={{margin:0,marginLeft:5}} type="checkbox"
                                       value={this.state.highPriority}
                                       onChange={this.setHighPriority.bind(this)}/>
                            </div>
                            <div style={{display:'flex',alignItems:'center'}}>
                                 <span style={{marginRight:10}}>
                                    Choose optional notification tag
                                </span>
                                {
                                    _.map(tags,(tag)=>{
                                        let backgroundColor = '#FFFFFF';
                                        let color = '#337ab7';
                                        let border = '1px solid #337ab7';
                                        if(this.state.selectedTags[tag.label]){
                                             backgroundColor = '#337ab7';
                                             color = '#FFFFFF';
                                            border = '1px solid transparent'
                                        }
                                        return  <div key={tag.id} id={'Notification_'+tag.id} onClick={this.onTagClicked.bind(this,tag.label)}
                                                     style={{
                                                         marginRight:10,
                                                        backgroundColor:backgroundColor,padding:5,
                                                        paddingLeft:15,paddingRight:15,
                                                        fontWeight:600,fontSize:11,
                                                        color:color,
                                                        border:border,
                                                        minWidth:80,
                                                        display:'flex',
                                                        alignItems:'center',
                                                        justifyContent:'center',
                                                        cursor:'pointer',
                                                        borderRadius:15}}>
                                            {tag.label}
                                        </div>
                                    })
                                }


                            </div>
                        </div>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <textarea id="notificationTextAre" maxLength="1024" 
                                className="col-md-12 notification-reply-input" placeholder="Type your reply here" valueLink={this.linkState('message')}/>
                            <div style={{display:'flex',justifyContent:'flex-end'}}>
                                {`${messageLength}/1024`}
                            </div> 
                        </div>
                        <p style={{clear:'both',paddingTop:15}}>
                            <a className="mark-unread-button left" onClick={this.onUnreadMessage.bind(this)}>
                                <i className="fa fa-envelope-o" style={{fontSize: 22,paddingRight:5}}></i>
                                <span className="inline-block mark-unread-button-label">Mark as Unread</span>
                            </a>
                        </p>
                    </div>
                    <div className="col-md-2 notification-sendBtn-container text-center">
                        <div id="sendNotificationButton" className="btn btn-primary cs-button-blue" title="Reply"
                             onClick={this.replyMessage.bind(this)}>
                            <span className="white-text">Send</span>
                        </div>
                    </div>
                </div>
                }
                
            </ReactCSSTransitionGroup>
        );


    }
}
reactMixin(NotificationInboxItemDetail.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    'patientMessages':state.notifications.patientMessages,
    'isNotificationFetching':state.notifications.isFetching,
    'user':state.auth,
    'patient': state.app.selectedPatient,
});
const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    appActions:bindActionCreators(appActionCreator,dispatch),
    commonActions:bindActionCreators(commonActionCreator,dispatch),
    notificationsAction:bindActionCreators(notificationsActionCreator,dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(NotificationInboxItemDetail);
