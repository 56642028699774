import React from 'react';
import reactMixin from 'react-mixin';
import LinkedStateMixin from 'react-addons-linked-state-mixin';
import { Modal, Button, FormControl, FormGroup, ControlLabel } from 'react-bootstrap';

class AdditionalInformationEmailModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			phone_number: '',
		}
	}

	onConfirm() {
		const { phone_number } = this.state;
		this.props.onConfirm(phone_number);
	}

	render() {
		const { show, onHide } = this.props;
		return (
			<Modal show={show} onHide={onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Email Additional Information</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FormGroup>
						<ControlLabel>Call Back Phone Number</ControlLabel>
						<FormControl
							type="text"
							valueLink={this.linkState('phone_number')}
							placeholder="Call Back Phone Number"
							style={{marginBottom: '10px'}}
						/>
					</FormGroup>
				</Modal.Body>
				<Modal.Footer>
					<Button bsStyle="default" onClick={onHide}>
						Cancel
					</Button>
					<Button bsStyle="success" onClick={this.onConfirm.bind(this)}>
						Update
					</Button>
				</Modal.Footer>
			</Modal>
		)
	}

}

reactMixin(AdditionalInformationEmailModal.prototype, LinkedStateMixin);

export default AdditionalInformationEmailModal;
