import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { STATUSES } from '../../constants/other';

const AssociatedChartsModal = ({ patients, show, onHide }) =>
	<Modal show={show} onHide={onHide}>
		<Modal.Header closeButton>
			<Modal.Title>Associated Charts</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<div>
				{
					_.map( patients, ( patient ) => {
						return <p key={patient.id}>{patient.first_name} {patient.last_name} - {STATUSES[patient.status]}</p>
					})
				}
			</div>
		</Modal.Body>
		<Modal.Footer>
			<Button bsStyle="danger" onClick={onHide}>
				Close
			</Button>
		</Modal.Footer>
	</Modal>;

export default AssociatedChartsModal;
