import React from 'react';
import PatientQueue from './PatientQueue';

const RecordsQueue = () =>
    <PatientQueue
	    showQueueCount
	    patientNameColumn
        providerDetailColumn
        timeBilledColumn
        todoItemColumn
        statusColumn
        chartColumn
        page
        lastWorkedByColumn
        queue="records"
        endpoint="Records"
        label="Records"
    />;

export default RecordsQueue;
