import React from 'react';
import PatientQueue from './PatientQueue';

const GAPSColorectalQueue = () =>
    <PatientQueue
        showQueueCount
        patientNameColumn
        providerDetailColumn
        timeBilledColumn
        todoItemColumn
        showFilters
        statusColumn
        chartColumn
        lastWorkedByColumn
        page
        queue="gAPSColorectal"
        endpoint="GAPSColorectal"
        label="GAPSColorectal"
    />;

export default GAPSColorectalQueue;
