import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentAccount = () =>
    <PatientQueue
        showQueueCount
        patientNameColumn
        providerDetailColumn
        timeBilledColumn
        todoItemColumn
        dobColumn
        showFilters
        showScheduleCallFilter
        showProgramTypeFilter
        chartColumn
        lastWorkedByColumn
        page
        queue="urgentAccount"
        endpoint="Urgent?typesToInclude=4"
        label="Urgent - Account"
    />;

export default UrgentAccount;
