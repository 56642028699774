import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentQueueDoNotCall = () =>
	<PatientQueue
		showQueueCount
		patientNameColumn
		providerDetailColumn
		timeBilledColumn
		todoItemColumn
		showFilters
		showProgramTypeFilter
        showScheduleCallFilter
		statusColumn
		chartColumn
		lastWorkedByColumn
		page
		queue="urgentDoNotCall"
		endpoint="Urgent?typesToInclude=18"
		label="Urgent - Do Not Call"
	/>;

export default UrgentQueueDoNotCall;
