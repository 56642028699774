/**
 * Created by synerzip on 09/01/16.
 */
import React from 'react';
let ReactDOM = require('react-dom');
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as appActionCreator from '../../actions/app';
import * as commonActionCreator from '../../actions/common';
import * as notificationsActionCreator from '../../actions/notifications';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import {parseSubject} from '../../utils';
import reactMixin from 'react-mixin';
import {Collapse,Pagination} from 'react-bootstrap';
import NotificationInboxItemDetail from './NotificationInboxItemDetail';

class NotificationInboxItem extends React.Component {
    constructor(props) {
        super(props);
        this.unreadMessageClass='unread-notification'
        this.state = {
            notificationActionTitle:'Open Chart'
        }
    }

    componentWillReceiveProps(nextProps,nextState){
        if(nextProps.selected != this.state.selected){
            this.setState({selected:nextProps.selected});
        }
    }

    readMessage(patientId) {
        if(this.props.patientData && this.props.patientData.id === patientId){
            this.props.openPatientPopup()
        } else {
            this.props.notificationsAction.getPatientData(patientId);
        }

    }

    onMessageReplied() {
        this.setState({open: false});
    }

    render() {
        var {msg} = this.props;

        return (
            <div id={msg.id} title={msg.subject}>
                <div className="notification-email-client-row">
                    <div className="notification-email-table">
                        <table style={{width:'100%',minHeight:'55px'}}>
                            <tbody>
                                <tr className={(!msg.read)? this.unreadMessageClass:''}>
                                    <td className={ "text-center"} style={{width:'1%',padding:10}}>
                                        {msg.priority ?  <i className="fa fa-star" style={{color:'#337ab7',fontSize:26,width:25}}></i> :
                                            <i className="fa fa-star" style={{color:'#FFFFFF',fontSize:26,width:25}}></i>}
                                    </td>
                                    <td className="notification-email-sender notification-cell-padding" style={{width:'29%'}}>
                                        <div className="email-client-sender">
                                            {msg.patient_name}
                                        </div>
                                        <div className="email-client-sender">
                                            {msg.organization ? msg.organization : ''}
                                        </div>
                                    </td>
                                    <td className="notification-email-time" style={{width:'25%'}}>{ moment.utc(msg.last_message_date).local().format('MMM D, YYYY h:mm a')}</td>
                                    <td className={"text-center"} style={{width:'25%'}}>
                                        <div style={{display:'flex',justifyContent:'flex-end'}}>
                                            {
                                                _.map(msg.tags,(tag)=>{
                                                    return <div key={tag} style={{
                                                        marginRight:10,
                                                        backgroundColor:'#FFFFFF',
                                                        padding:5,
                                                        paddingLeft:15,paddingRight:15,
                                                        fontWeight:600,fontSize:11,
                                                        color:'#337ab7',
                                                        border:'1px solid #337ab7',
                                                        minWidth:80,
                                                        display:'flex',
                                                        alignItems:'center',
                                                        justifyContent:'center',
                                                        borderRadius:15}}>{tag}</div>
                                                })
                                            }

                                        </div>
                                    </td>
                                    <td className="notification-reply-btn-container" style={{width:'20%'}}>
                                        <div className="btn btn-primary cs-button-blue" title="Open Chart"
                                             onClick={this.readMessage.bind(this,msg.patient_id)} >
                                            <span className="white-text">{this.state.notificationActionTitle}</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );

    }
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    appActions:bindActionCreators(appActionCreator,dispatch),
    commonActions:bindActionCreators(commonActionCreator,dispatch),
    notificationsAction:bindActionCreators(notificationsActionCreator,dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(NotificationInboxItem);