import React from 'react';
import { msToTime } from '../../utils';

const MyPatientBilledCell = ({ data: { work_millis_logged, completed } }) => {
    let color = '#f52b04';
    if (work_millis_logged >= 1080000 && work_millis_logged < 1200000) color = '#d76800';
    if (work_millis_logged < 1080000 && work_millis_logged > 0) color = '#067809';

    return (
        <div>
            <div style={{ textAlign: 'center', fontSize: 18 }}>
                {!work_millis_logged &&
                    <span> - </span>
                }
                {work_millis_logged > 0 &&
                    <span style={{ color }}>
                        {msToTime(work_millis_logged)}
                    </span>
                }
            </div>
            {
                completed &&  <div style={{ display: 'flex',flexDirection:'column',
                        justifyContent:'center',alignItems:'center', color:'#FFFFFF' }}>
                        <div style={{maxWidth:300,minWidth:150}}>
                                <div className="new-patient-status" style={{background:'rgb(92,163,115)',border:'2px solid rgb(92,163,115)',padding:4}}>
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                        <span style={{ paddingLeft: 4 }}> Completed</span>
                                </div>
                            </div>
                        </div>
            }

        </div>

    );
};

export default MyPatientBilledCell;
