import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Filter from '../../components/Filter';
import _ from 'lodash'
import { Panel, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { Table, Column, Cell } from 'fixed-data-table';
import ResponsiveFixedDataTable from '../../components/ResponsiveFixedDataTable';
import PaginationBar from '../../components/PaginationBar'
import PageHeader from '../../components/PageHeader';
import AssignPatientPopup from './AssignPatientPopup'
import {getTeams,getClinicians,getTeamsUnAssignedPatients,
    getCliniciansByAsync,getOrganizationByTeam} from '../../actions/admin';
import { hideToast, showToast } from "../../actions/common";

class PCCPatientAssignment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAssignmentPopup:false,
            team:'',
            selectedOrgId:'',
            clinicians:[],
            unassignedCount:'--',
            columnWidths: {
                clinicalName: 330,
                threshold: 330,
                currentlyAssigned: 330,
                capacity: 300,
                actions: 240,
            },
        }
    }

    componentDidMount(){
        this.props.getTeams();
    }

    onPatientAssigned = (data) => {
        this.setState({showAssignmentPopup:true,selectedClinician:data})
    }

    onPopupHide =async (teamId,orgId) => {
        this.setState({showAssignmentPopup:false})
        if(orgId){
            await this.getCliniciansByOrg(orgId);
        }else if(teamId){
            await this.getClinicians(teamId,orgId);
        }
    }

    getClinicians =  (newTeam) => {
        if(newTeam){
            getCliniciansByAsync(newTeam ? newTeam : 0)
            .then( (listData) => {
                getOrganizationByTeam(newTeam)
                .then((organizations) => {
                    getTeamsUnAssignedPatients(newTeam)
                    .then(unassignedCount => {
                        this.setState({team:newTeam,unassignedCount,
                            organizations,selectedOrgId:''});
                        this.setState({clinicians:[...listData]});
                    })
                })
            })
            
        }else{
            this.setState({team:newTeam,clinicians:[],unassignedCount:'--',
            selectedOrgId:'',
            organizations:[]});
        }
        return true;
    }

    getCliniciansByOrg(orgId){
        const {team,organizations} = this.state;
        if(orgId){
            getCliniciansByAsync(team,orgId)
            .then( (listData) => {
                getTeamsUnAssignedPatients(team,orgId)
                    .then(unassignedCount => {
                        this.setState({unassignedCount,
                            organizations,selectedOrgId:orgId});
                        this.setState({clinicians:[...listData]});
                    })
            })
        }else{
            this.getClinicians(team);
        }
    }
    

    render(){
        const {team,columnWidths,showAssignmentPopup,
            clinicians,unassignedCount,selectedOrgId,organizations = []} = this.state;
        const {teams} = this.props;
        const rowLength = clinicians ? clinicians.length : 0;
        return (
            <div>
                <div style={{ display: 'flex', flexDirection:'column',
                    flexWrap: 'wrap', marginBottom: '1rem' }}>
                    <PageHeader>
                        PCC - Patient Assignment
                    </PageHeader>
                    <div style={{ display: 'flex',flex:1 }}>
                    <Panel
                    style={{width:'100%'}}
                    header={
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <div style={{display:'flex'}}>
                            <Filter
                                    label="Team"
                                    width={200}
                                    options={_.filter(teams,(data) => {
                                        return data.id != 1
                                    })}
                                    value={team}
                                    onChange={(newTeam) =>
                                        this.getClinicians(newTeam)
                                    }
                                />
                            <Filter
                                    label="Organization"
                                    options={organizations}
                                    width={200}
                                    value={selectedOrgId}
                                    onChange={(newOrgId) =>
                                        this.getCliniciansByOrg(newOrgId)
                                    }
                                />
                            </div>
                        
                                <div style={{display:'flex',alignItems:'flex-end'}}>
                                    <label>Unassigned Patients: {unassignedCount}</label>
                                </div>
                                </div>
                    }>
                        <div style={{ height: '750px', position: 'relative' }}>
                        <ResponsiveFixedDataTable
                            height={750}
                            width={1000}
                            rowHeight={50}
                            headerHeight={50}
                            isColumnResizing={false}
                            rowsCount={rowLength}
                            onColumnResizeEndCallback={this.handleColumnResize}
                            {...this.props}
                        >
                            <Column
                                    columnKey="clinicalName"
                                    fixed={true}
                                    flexGrow={1}
                                    isResizable={true}
                                    width={columnWidths.clinicalName}
                                    header={<Cell>Clinician Name</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                           {clinicians[rowIndex].name}
                                        </Cell>
                                    }
                                />
                                <Column
                                    columnKey="threshold"
                                    fixed={true}
                                    isResizable={true}
                                    width={columnWidths.threshold}
                                    header={<Cell style={{textAlign:'center'}}>Threshold</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                            <div style={{textAlign:'center'}}>
                                                {clinicians[rowIndex].threshold}
                                            </div>
                                        </Cell>
                                    }
                                />
                                <Column
                                    columnKey="currentlyAssigned"
                                    fixed={true}
                                    isResizable={true}
                                    width={columnWidths.currentlyAssigned}
                                    header={<Cell style={{textAlign:'center'}}>Currently Assigned</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                            <div style={{textAlign:'center'}}>
                                                {clinicians[rowIndex].assigned_patients}
                                            </div>
                                        </Cell>
                                    }
                                />
                                <Column
                                    columnKey="capacity"
                                    fixed={true}
                                    isResizable={true}
                                    width={columnWidths.capacity}
                                    header={<Cell style={{textAlign:'center'}}>Capacity</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                            <div style={{textAlign:'center'}}>
                                            {clinicians[rowIndex].threshold - 
                                            clinicians[rowIndex].assigned_patients}
                                            </div>
                                        </Cell>
                                    }
                                />
                                <Column
                                    columnKey="actions"
                                    fixed={true}
                                    isResizable={true}
                                    width={columnWidths.actions}
                                    header={<Cell style={{textAlign:'center'}}>Actions</Cell>}
                                    cell={({ rowIndex, data, columnKey, ...props }) =>
                                        <Cell {...props}>
                                            <div style={{textAlign:'center'}}>
                                           <button
                                                type="button"
                                                className="btn btn-primary"
                                                style={{ padding: '2px 12px', marginRight: '1rem' }}
                                                onClick={() => this.onPatientAssigned(clinicians[rowIndex])}
                                            >
                                                Assign Patients
                                            </button>
                                            </div>
                                        </Cell>
                                    }
                                />
                            </ResponsiveFixedDataTable>
                        </div>
                    </Panel>
                    </div>
                    
                </div>
                <AssignPatientPopup show={showAssignmentPopup}
                unassignedCount={unassignedCount}
                selectedTeam={team}
                selectedOrg={selectedOrgId}
                selectedClinician={this.state.selectedClinician}
                onPopupHide={this.onPopupHide}/>
            </div>)
    }
}

export default connect(
    (state, ownProps) => ({
        teams:state.admin.teams,
        activeUser: state.admin.activeUser,
        selectedTeam:state.queue.selectedTeam,
        organizations: state.queue.organizations,
    }),
    {
        getTeams,
        getClinicians
    })(PCCPatientAssignment);
