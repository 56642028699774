import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as adminActionCreator from './../actions/admin';
import * as commonActionCreator from './../actions/common';


class Whiteboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showWhiteboard: false,
        };
    }

    componentDidMount() {
        this.props.adminActions.checkWhiteboard(this.props.userId);
    }

    componentWillReceiveProps(nextProps) {
        if ( this.props.openWhiteboard == undefined && nextProps.openWhiteboard == true ) {
            this.setState({
                showWhiteboard: true
            })
            this.refs.whiteboardTextarea.focus();
        }
    }

    expandWhiteboard() {
        this.setState({
            showWhiteboard: true,
        })
        this.refs.whiteboardTextarea.focus();
    }

    _handleKeyPress( action, e ) {
        if ( e.key == "Enter" ) {
            this[action]();
        }
    }

    onBodyChange(e){
        this.props.adminActions.changeWhiteboard( e.target.value )
    }

    saveChanges(e) {
        if ( this.props.whiteboardExists ) {
            this.props.adminActions.updateWhiteboard( this.props.userId, this.props.whiteboardContent, this.props.whiteboardId )
        } else {
            this.props.adminActions.createWhiteboard( this.props.userId, this.props.whiteboardContent )
        }
        this.setState({
            showWhiteboard: false,
        })
    }

    cancelChanges() {
        this.props.adminActions.getWhiteboard( this.props.userId );
        this.setState({
            showWhiteboard: false,
        })        
    }

    render() {
        return (
            <div className={`whiteboard-container ${this.state.showWhiteboard ? 'show-whiteboard' : ''}`}>
                <div className="whiteboard-header">
                    <div id="whiteboardOpen" className="whiteboard-header-open" onClick={this.expandWhiteboard.bind(this)}>
                        <span><i className="fa fa-pencil fa-lg" aria-hidden="true"></i></span>
                    </div>
                    {
                        // <div className="whiteboard-header-close">
                        //     <i  className="fa fa-arrow-right fa-lg" 
                        //         aria-hidden="true" 
                        //         onClick={this.collapseWhiteboard.bind(this)}>
                        //     </i>
                        // </div>
                    }
                </div>
                <div className="whiteboard-content" >
                    <textarea   className="whiteboard-content-input" 
                                value={this.props.whiteboardContent} 
                                onChange={this.onBodyChange.bind(this)}
                                tabIndex={100}
                                ref="whiteboardTextarea"
                    />
                    <div className="whiteboard-actions">
                        <div    className="btn btn-primary" 
                                onClick={this.saveChanges.bind(this)} 
                                onKeyPress={this._handleKeyPress.bind(this, 'saveChanges')}
                                tabIndex={101}>Save</div>
                        <div    className="btn btn-danger" 
                                onClick={this.cancelChanges.bind(this)} 
                                onKeyPress={this._handleKeyPress.bind(this, 'cancelChanges')}
                                tabIndex={102}>Cancel</div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    whiteboardContent: state.admin.whiteboardContent,
    whiteboardExists: state.admin.whiteboardExists,
    whiteboardId: state.admin.whiteboardId,
    openWhiteboard: state.admin.openWhiteboard,
    userId: state.auth.id,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Whiteboard);
