/**
 * Created by synerzip on 10/03/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Panel,Input,Button,Accordion,FormControl,FormGroup,Modal} from 'react-bootstrap';
import {Table, Column, Cell} from 'fixed-data-table';
import _ from 'lodash';
import moment from 'moment';
import CarePlanSteps from './CarePlanSteps';
import CarePlan from './CarePlan';
var LinkedStateMixin = require('react-addons-linked-state-mixin');


class CarePlanGoalItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit:false,
            goal:props.data.goal,
            goal_id:props.data.id,
            isAdding:false,
            step:null,
            step_title:null,
            edit_id:null,
            edit_step_title:null,
            openId:null,
            stepError:false
        }
    }

    onEdit(){
        this.setState({edit:true});
    }

    onCancel(){
        var {data} = this.props;
        this.setState({edit:false,goal:data.goal});
    }

    onSave(){
        var {data,adminActions,selectedPatient} = this.props;
        console.log(this.state.goal);
        adminActions.updateGoal(selectedPatient.id,data.id,this.state.goal);
        this.setState({edit:false});
    }
    onKeyDown(e){
        if(e.key == 'Enter'){
            console.log('enter press here! ')
            console.log(this.state.step);
            this.onSave();
        }
    }
    onGoalChanged(e){
        this.setState({goal:e.target.value})
    }

    stopAccordion(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    toggleOpen( id ) {
        if(this.state.openId != id) {
            this.setState({openId:id})
        } else {
            this.setState({openId:null})
        }
    }

    triggerAddCarePlan() {
        this.setState({
            isAdding:true
        })
    }

    addCarePlan(e) {
        e.preventDefault();
        if(this.state.step.trim() == '') {
            this.setState({stepError:true})
        } else {
            let stepTitle = this.state.step_title && this.state.step_title.trim() || 'Care Goal'
            this.props.adminActions.addStepForGoal(this.props.selectedPatient.id, this.state.goal_id, this.state.step, stepTitle);
            this.setState({
                isAdding:false,
                step:null,
                step_title:null,
                stepError:false
            })    
        }
    }

    cancelAddCarePlan() {
        this.setState({
            isAdding:false,
            step:null,
            step_title:null
        })
    }

    toggleAchievedModal() {
        this.setState({
            show: !this.state.show
        })
    }

    submitGoalAchieved() {
        this.props.adminActions.markGoalComplete( this.props.selectedPatient.id, this.props.data.id )
        this.setState({
            show: false
        })
    }

    toggleEdit( id, editStepTitle ) {
        if(!this.state.edit_id) {
            this.setState({
                edit_id:id,
                edit_step_title:editStepTitle
            })            
        } else {
            console.log('hit')
            this.setState({
                edit_id:null,
                edit_step_title:null
            })               
        }

    }

    saveEdit( stepId, step ) {
        let stepTitle = this.state.edit_step_title && this.state.edit_step_title.trim() || 'Care Goal';
        this.props.adminActions.updateStep(this.props.selectedPatient.id, this.props.data.id, stepId, step, stepTitle);
        this.setState({
            edit_id:null,
            edit_step_title:null
        })
    }

    stepHeader(step) {
        let rotate = this.state.openId == step.id ? ' rotate' : '';
        let style = {
            marginTop: '-10px',
            marginBottom: '-10px',
            marginLeft: '-15px',
            marginRight: '-15px',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '15px',
            paddingRight: '15px'
        }
        if(this.state.edit_id == step.id){
            return (
                <div className="clearfix">
                    <div className="clearfix" style={style} onClick={this.toggleOpen.bind(this, step.id)}>
                        <div className="pull-left">
                            <i className={'fa fa-angle-down circleBorder ' + rotate} aria-hidden="true"></i>
                            <FormControl
                                type="text"
                                placeholder="Care Goal Title"
                                style={{height:'34px !important',
                                        display:'inline-block',
                                        width:'auto'
                                }}
                                maxLength="100"
                                onClick={this.stopAccordion}
                                valueLink={this.linkState('edit_step_title')}
                            />
                        </div>
                        <div className="text-center" style={{margin:'0 auto', width:'200px'}}>
                            <span>{moment.utc(step.create_ts).format('MM/DD/YYYY')}</span>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="clearfix">
                    <div className="clearfix" style={style} onClick={this.toggleOpen.bind(this, step.id)}>
                        <div className="pull-left">
                            <i className={'fa fa-angle-down circleBorder ' + rotate} aria-hidden="true"></i>
                            {step.step_title}
                        </div>
                        <div className="text-center" style={{margin:'0 auto', width:'200px'}}>
                            <span>{moment.utc(step.create_ts).format('MM/DD/YYYY')}</span>
                        </div>
                    </div>
                </div>
            )            
        }
    }

    addCarePlanElement() {
        return (
            <div className="col-xs-12" style={{marginBottom:'10px'}}>
                <Panel header={this.addCarePlanHeader()}>
                    <div className="col-xs-12">
                        <form onSubmit={this.addCarePlan.bind(this)}>
                            <FormGroup className={this.state.stepError && 'has-error'}>
                                <div>
                                    <FormControl 
                                        componentClass="textarea" 
                                        placeholder="Enter care goal"
                                        style={{resize:'vertical',height:'150px'}}
                                        valueLink={this.linkState('step')}
                                        required
                                    />
                                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                                        {`${this.state.step ? this.state.step.length : 0}/4096`}
                                    </div> 
                                </div>
                                {this.state.stepError && <span className="text-danger"><small>Please enter a care goal</small></span>}
                            </FormGroup>
                            <Button 
                                bsStyle="danger"
                                className="pull-right"
                                style={{marginTop:'15px'}}
                                onClick={this.cancelAddCarePlan.bind(this)}>
                                Cancel
                            </Button> 
                            <Button 
                                bsStyle="success"
                                className="pull-right"
                                style={{marginRight:'20px',marginTop:'15px'}}
                                type="submit">
                                Save
                            </Button> 
                        </form>
                    </div>
                </Panel> 
            </div>
        )
    }

    addCarePlanHeader() {
        return (
            <div>
                <FormControl
                    type="text"
                    placeholder="Care Goal Title"
                    style={{height:'34px !important'}}
                    valueLink={this.linkState('step_title')}
                    maxLength="100"
                />
            </div>
        )
    }

    render() {
        return (
            <div>
                    <div className="col-xs-12 col-md-10 col-md-offset-1">
                        <div className="col-xs-12 col-md-6 col-md-offset-3 clearfix">
                            <Button 
                                bsStyle="success"
                                className="aligncenter addLabButton"
                                id={'addCarePlanButton' + this.props.data.id}
                                onClick={this.triggerAddCarePlan.bind(this)}
                                disabled={this.props.complete}>
                                Update Goal
                            </Button>  
                            <Button 
                                bsStyle="danger"
                                className="aligncenter addLabButton pull-right"
                                id={'goalAchievedButton' + this.props.data.id}
                                onClick={this.toggleAchievedModal.bind(this)}
                                disabled={this.props.complete}>
                                Goal Achieved
                            </Button>  
                        </div>
                        {this.state.isAdding && this.addCarePlanElement()}
                        <Accordion className="col-xs-12">
                        {
                            _.map(this.props.goalSteps, (step)=> 
                                <Panel header={this.stepHeader(step)} eventKey={step.id} key={step.id} style={{marginBottom:'20px'}}>
                                    <CarePlan goal={step} toggleEdit={this.toggleEdit.bind(this)} saveEdit={this.saveEdit.bind(this)} edit={this.state.edit_id == step.id} complete={this.props.complete}/>
                                </Panel> 
                            )
                        }     
                        </Accordion>  
                        <div style={{paddingLeft:15,clear:'both'}}>
                          {this.props.data.frequency_note}
                        </div>                   
                    </div>
                    <Modal show={this.state.show} onHide={this.toggleAchievedModal.bind(this)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Goal Achieved</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure you want to mark this goal as achieved? This goal will no longer be editable once it is complete.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button bsStyle="success" style={{marginRight: '10px'}} onClick={this.submitGoalAchieved.bind(this)}>Save Changes</Button>
                            <Button bsStyle="default" onClick={this.toggleAchievedModal.bind(this)}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>
            </div>
        )
    }
}

reactMixin(CarePlanGoalItem.prototype, LinkedStateMixin);
const mapStateToProps = (state) => ({
    'carePlanAndGoals': state.levelTwoDashboard.carePlanAndGoals,
    'userId': state.auth.id,
    'selectedPatient': state.app.selectedPatient
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CarePlanGoalItem);