/**
 * Created by synerzip on 08/02/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Input,Modal,Button,FormGroup,FormControl,Table} from 'react-bootstrap';

class MatchRecordPopup extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            nameMatch: false,
            dobMatch: false,
	        providerMatch: false,
        }
    }

    onCheckChange(name,e){
        if(name == 'nameMatch'){
            this.setState({nameMatch:e.target.checked})
        }else if(name == 'dobMatch'){
            this.setState({dobMatch:e.target.checked})
        }else if(name == 'providerMatch'){
            this.setState({providerMatch:e.target.checked})
        }
    }
    updateNotes( e ) {
        this.setState({ note: e.target.value });
    }

    updateNextChart( e ) {
    	this.setState({ nextChart: e.target.value });
    }

    onSubmit() {
        const {patientData,confirmationData} = this.props;
        this.props.onMatchRecord(confirmationData,patientData.id);
    }


    onCancel() {
        this.setState({
            nameMatch: false,
            dobMatch: false
        });
        this.props.onPopupHide();
    }


    render() {
        let {patientData,confirmationData} = this.props;
        patientData = patientData ? patientData : {};
        const {nameMatch,
            dobMatch} = this.state;
        return (
            <Modal bsSize="large" show={this.props.show}>
                <Modal.Header closeButton onHide={this.onCancel.bind(this)}>
                    <Modal.Title>
                        <div className="text-align-center">
                            <div>Confirm Fax Match</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <div>
                            <span
                                style={{color:'#337ab7',fontSize:22,fontWeight:600}}>
                                Patient Information
                            </span>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',marginTop:15}}>
                            <div style={{display:'flex',flexDirection:'column',flex:1}}>
                                <div style={{display:'flex',padding:'10px 0 0 0'}}>
                                    <div style={{fontWeight:600,width:100}}>Name</div>
                                    <div>{patientData.first_name +' '+patientData.last_name}</div>
                                </div>
                                <div style={{display:'flex',padding:'10px 0 0 0'}}>
                                    <div style={{fontWeight:600,width:100}}>DOB</div>
                                    <div>{patientData.date_of_birth}</div>
                                </div>
                                <div style={{display:'flex',padding:'10px 0 0 0'}}>
                                    <div style={{fontWeight:600,width:100}}>Phone</div>
                                    <div>{patientData.mobile ?
                                        patientData.mobile.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") :
                                        patientData.phone ?
                                        patientData.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") :
                                    ''}
                                    </div>
                                </div>
                                <div style={{display:'flex',padding:'10px 0 0 0'}}>
                                    <div style={{fontWeight:600,width:100}}>Provider</div>
                                    <div>{patientData.provider ? patientData.provider.provider : ''}</div>
                                </div>
                                <div style={{display:'flex',padding:'10px 0 0 0'}}>
                                    <div style={{fontWeight:600,width:100}}>Status</div>
                                    <div>{patientData.status_description}</div>
                                </div>

                            </div>
                            <div style={{display:'flex',justifyContent:'flex-end',flex:1}}>
                                <div style={{display:'flex',flexDirection:'column'}}>
                                    <div style={{display:'flex',padding:'10px 0 0 0'}}>
                                        <div style={{width:20}}>
                                            <input type="checkbox" onChange={this.onCheckChange.bind(this,'nameMatch')}/>
                                        </div>
                                        <div>Patient Name Match</div>
                                    </div>
                                    <div style={{display:'flex'}}>
                                        <div style={{width:20}}><input type="checkbox" onChange={this.onCheckChange.bind(this,'dobMatch')}/></div>
                                        <div>Patient DOB Matches Fax Record</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{borderTop: '1px solid gray', height: 50, marginTop:15,
                            textAlign: 'center', paddingTop: 15}}>
                            <Button id="patientContactCancel" bsStyle="danger" onClick={this.onCancel.bind(this)} style={{float: 'left'}}>
                                Cancel
                            </Button>
                            <Button id="patientContactClose"
                                    onClick={this.onSubmit.bind(this)}
                                    disabled={!nameMatch || !dobMatch}
                                    bsStyle="success" style={{float: 'right'}}>
                                Confirm Match
                            </Button>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        );

    }
}
const mapStateToProps = (state) => ({
    selectedPatient: state.app.selectedPatient,
    selectedQueue:state.app.selectedQueue,
    callLogSelections:state.app.callLogSelections,
    activeUser: state.admin.activeUser,
    workSummary: state.admin.workSummary,
	associatedCharts: state.levelTwoDashboard.associatedCharts,
    todoLists: state.levelTwoDashboard.todoLists
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchRecordPopup);
