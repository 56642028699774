/**
 * Created by synerzip on 29/04/16.
 */
/**
 * Created by synerzip on 22/01/16.
 */
import {createReducer} from '../utils';
import CONSTANTS from '../constants';
import {pushState} from 'redux-router';
import _ from 'lodash';

const {
    CCM_NOTIFICATION_UNREAD_REQUEST_SENT,
    CCM_NOTIFICATION_UNREAD_REQUEST_SUCCESS,
    CCM_NOTIFICATION_UNREAD_REQUEST_FAIL,
    CCM_NOTIFICATION_THREADS_REQUEST_SENT,
    CCM_NOTIFICATION_THREADS_REQUEST_SUCCESS,
    CCM_NOTIFICATION_THREADS_REQUEST_FAIL,
    CCM_NOTIFICATION_PATIENT_DATA_REQUEST_SENT,
    CCM_NOTIFICATION_PATIENT_DATA_REQUEST_SUCCESS,
    CCM_NOTIFICATION_PATIENT_DATA_REQUEST_FAIL,
    CCM_PATIENT_NOTIFICATION_UNREAD_REQUEST_SENT,
    CCM_PATIENT_NOTIFICATION_UNREAD_REQUEST_SUCCESS,
    CCM_PATIENT_NOTIFICATION_UNREAD_REQUEST_FAIL,
    CCM_PATIENT_NOTIFICATION_MESSAGE_REQUEST_SENT,
    CCM_PATIENT_NOTIFICATION_MESSAGE_REQUEST_SUCCESS,
    CCM_PATIENT_NOTIFICATION_MESSAGE_REQUEST_FAIL,
    RESET_CCM_NOTIFICATION_THREAD_MESSAGE,
    CCM_READ_NOTIFICATION_THREAD_REQUEST_SENT,
    CCM_READ_NOTIFICATION_THREAD_REQUEST_SUCCESS,
    CCM_READ_NOTIFICATION_THREAD_REQUEST_FAIL,
    CCM_NOTIFICATION_REPLY_REQUEST_SENT,
    CCM_NOTIFICATION_REPLY_REQUEST_SUCCESS,
    CCM_NOTIFICATION_REPLY_REQUEST_FAIL,
    CCM_CASE_MESSAGES_UNREAD_REQUEST_SUCCESS,
    CCM_CASE_MESSAGES_LIST_REQUEST_SUCCESS,
    PATIENT_CASE_MESSAGES_REQUEST_SUCCESS,
    PATIENT_CASE_DETAIL_MESSAGES_SUCCESS,
    RESET_PATIENT_CASES
} = CONSTANTS;

const initialState = {
    notificationUnreadCount:null,
    caseMessagesAvailable:null,
    notificationList:null,
    caseMessages:null,
    patientNotificationCount:null,
    patientMessages:null
};

export default createReducer(initialState, {
    [CCM_NOTIFICATION_UNREAD_REQUEST_SENT]: (state) => {
        return Object.assign({}, state, {
            'isFetching': true
        });
    },
    [CCM_NOTIFICATION_UNREAD_REQUEST_FAIL]: (state) => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [CCM_NOTIFICATION_UNREAD_REQUEST_SUCCESS]: (state,payload) => {
        return Object.assign({}, state, {
            'isFetching': false,
            'notificationUnreadCount':payload
        });
    },
    [CCM_NOTIFICATION_THREADS_REQUEST_SENT]: (state) => {
        return Object.assign({}, state, {
            'isFetching': true
        });
    },
    [CCM_NOTIFICATION_THREADS_REQUEST_FAIL]: (state) => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [CCM_NOTIFICATION_THREADS_REQUEST_SUCCESS]: (state,payload) => {
        return Object.assign({}, state, {
            'isFetching': false,
            'notificationList':payload
        });
    },
    [CCM_NOTIFICATION_PATIENT_DATA_REQUEST_SENT]: (state) => {
        return Object.assign({}, state, {
            'isFetching': true
        });
    },
    [CCM_NOTIFICATION_PATIENT_DATA_REQUEST_FAIL]: (state) => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [CCM_NOTIFICATION_PATIENT_DATA_REQUEST_SUCCESS]: (state,payload) => {
        return Object.assign({}, state, {
            'isFetching': false,
            'selectedPatientData':payload
        });
    },
    [CCM_PATIENT_NOTIFICATION_UNREAD_REQUEST_SENT]: (state) => {
        return Object.assign({}, state, {
            'isFetching': true
        });
    },
    [CCM_PATIENT_NOTIFICATION_UNREAD_REQUEST_FAIL]: (state) => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [CCM_PATIENT_NOTIFICATION_UNREAD_REQUEST_SUCCESS]: (state,payload) => {
        return Object.assign({}, state, {
            'isFetching': false,
            'patientNotificationCount':payload
        });
    },
    [CCM_PATIENT_NOTIFICATION_MESSAGE_REQUEST_SENT]: (state) => {
        return Object.assign({}, state, {
            'isFetching': true
        });
    },
    [CCM_PATIENT_NOTIFICATION_MESSAGE_REQUEST_FAIL]: (state) => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [CCM_PATIENT_NOTIFICATION_MESSAGE_REQUEST_SUCCESS]: (state,payload) => {
        return Object.assign({}, state, {
            'isFetching': false,
            'patientMessages':payload
        });
    },
    [RESET_CCM_NOTIFICATION_THREAD_MESSAGE]: (state,payload) => {
        return Object.assign({}, state, {
            'isFetching': false,
            'messagesByCase':null
        });
    },
    [CCM_READ_NOTIFICATION_THREAD_REQUEST_SENT]: (state) => {
        return Object.assign({}, state, {
            'isFetching': true
        });
    },
    [CCM_READ_NOTIFICATION_THREAD_REQUEST_FAIL]: (state) => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [CCM_READ_NOTIFICATION_THREAD_REQUEST_SUCCESS]: (state) => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [CCM_NOTIFICATION_REPLY_REQUEST_SENT]: (state) => {
        return Object.assign({}, state, {
            'isFetching': true
        });
    },
    [CCM_NOTIFICATION_REPLY_REQUEST_FAIL]: (state) => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [CCM_NOTIFICATION_REPLY_REQUEST_SUCCESS]: (state) => {
        return Object.assign({}, state, {
            'isFetching': false
        });
    },
    [CCM_CASE_MESSAGES_UNREAD_REQUEST_SUCCESS]: (state,payload) => {
        return Object.assign({}, state, {
            'isFetching': false,
            'caseMessagesAvailable':payload
        });
    },
    [CCM_CASE_MESSAGES_LIST_REQUEST_SUCCESS]: (state,payload) => {
        return Object.assign({}, state, {
            'isFetching': false,
            'caseMessages':payload
        });
    },
    [PATIENT_CASE_MESSAGES_REQUEST_SUCCESS]: (state, payload) => {
        return Object.assign({}, state, {
            'isFetching': false,
            'patientCaseMessages':payload
        });
    },
    [RESET_PATIENT_CASES]: (state, payload) => {
        return Object.assign({}, state, {
            'isFetching': false,
            'patientCaseMessages':undefined
        });
    },
    [PATIENT_CASE_DETAIL_MESSAGES_SUCCESS]: (state, payload) => {
        return Object.assign({}, state, {
            'isFetching': false,
            'messagesByCase':payload
        });
    },
});

