/**
 * Created by synerzip on 07/02/16.
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import * as adminActionCreator from '../../actions/admin';
import * as commonActionCreator from '../../actions/common';
import * as appActionCreator from '../../actions/app';
import { connect } from 'react-redux';
import reactMixin from 'react-mixin';
import { Link } from 'react-router';
import {Panel, Input, Button, Row, Col} from 'react-bootstrap';
import {Table, Column, Cell} from 'fixed-data-table';
import ResponsiveFixedDataTable from '../../components/ResponsiveFixedDataTable';
import AdminCloseChartPopup from './AdminCloseChartPopup';
import moment from 'moment';
import {isAdmin, isMA, getTimeString, getFullNameString} from '../../utils';
import {DATE_DISPLAY_FORMAT, DATE_API_EXCHANGE_FORMAT, DATE_TIME_DISPLAY_FORMAT, DATE_TIME_API_EXCHANGE_FORMAT} from '../../constants/other';
import PageHeader from '../../components/PageHeader';
import PatientChartClosureRequestedDataModal from './PatientChartClosureRequestedDataModal';

class OpenCharts extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            columnWidths: {
                maName: 382,
                patientName: 383,
                chartOpenedAt: 216,
                elapsedTimeOpen: 216,
                actions: 353
            },
            openChart: null,
            showCloseChartPopup: false,
            searchCompleted: false,
            showPatientDataModal: false,
        };
    }

    showMessage(message, title = 'Invalid Value', status = 'error') {
        this.props.commonActions.showToast({
            status: status,
            title: title,
            message: message
        });
        var interval = setInterval(()=> {
            clearInterval(interval);
            this.props.commonActions.hideToast();
        }, 2);
    }

    onCloseChartClicked(openChart) {
        this.setState({openChart: openChart, showCloseChartPopup: true});
    }

    onCloseChartPopupHide() {
        this.setState({openChart: null, showCloseChartPopup: false});
    }

    _getData() {
        this.props.adminActions.getOpenChartsDetails(() => {
            this.setState({searchCompleted: true});
        });
    }

    componentDidMount() {
        this._getData();
    }

    componentWillReceiveProps( nextProps ) {
        if ( nextProps.chartClosureRequested && ( nextProps.chartClosureRequested.showPatientDataModal !== this.state.showPatientDataModal ) ) {
            this.setState({ 'showPatientDataModal': nextProps.chartClosureRequested.showPatientDataModal });
        }
    }

    handleRequestChartClosure = ( openChart ) => {
        this.props.adminActions.requestChartClosure( openChart );
        this.setState({ openChart });
    };

    render(){
        let {columnWidths, searchCompleted} = this.state,
            dataList = this.props.openCharts || [],
            body = '';

        if (searchCompleted) {
            body = <div className="text-align-center">No Open Charts!</div>;
        }

        if (dataList.length) {
            dataList = _.cloneDeep(dataList);

            dataList = _(dataList).
                sortBy('open_for_millis').
                reverse().
                value();

            body = (
                <div style={{width:'100%', height:'502px'}}>
                    <ResponsiveFixedDataTable
                        rowHeight={50}
                        headerHeight={40}
                        rowsCount={dataList.length}
                        isColumnResizing={false}
                        width={1550}
                        height={502}
                        {...this.props}>
                        <Column
                            columnKey="maName"
                            header={<Cell>
                                        <div className="text-align-center">Employee Name</div>
                                    </Cell>}
                            cell={ ({rowIndex, data, columnKey, ...props}) => {
                                let openChart = dataList[rowIndex];

                                return (
                                    <Cell {...props}>
                                        <b className="clearfix">{openChart.ma_name}</b>
                                    </Cell>
                                );
                            }}
                            fixed={true}
                            width={columnWidths.maName}
                            isResizable={true}
                            />
                        <Column
                            columnKey="patientName"
                            header={<Cell>
                                        <div className="text-align-center">Patient Name</div>
                                    </Cell>}
                            cell={ ({rowIndex, data, columnKey, ...props}) => {
                                let openChart = dataList[rowIndex],
                                    patientName = getFullNameString(
                                        openChart.active_patient_first_name,
                                        openChart.active_patient_middle_name,
                                        openChart.active_patient_last_name
                                    );

                                return (
                                    <Cell {...props}>
                                        <div className="clearfix">
                                            {patientName}
                                        </div>
                                    </Cell>
                                );
                            }}
                            width={columnWidths.patientName}
                            isResizable={false}
                            flexGrow={1}
                            />
                        <Column
                            columnKey="chartOpenedAt"
                            header={<Cell>
                                        <div className="text-align-center">Chart Opened At</div>
                                    </Cell>}
                            cell={ ({rowIndex, data, columnKey, ...props}) => {
                                let openChart = dataList[rowIndex];
                                return (
                                    <Cell {...props}>
                                        <div className="text-align-center">
                                            {moment.utc(openChart.started_ts).local().format('MM/DD/YYYY h:mm a')}
                                        </div>
                                    </Cell>
                                );
                            }}
                            width={columnWidths.chartOpenedAt}
                            isResizable={false}
                            />
                        <Column
                            columnKey="elapsedTimeOpen"
                            header={<Cell>
                                        <div className="text-align-center">Elapsed Time Open</div>
                                    </Cell>}
                            cell={ ({rowIndex, data, columnKey, ...props}) => {
                                let openChart = dataList[rowIndex];

                                return (
                                    <Cell {...props}>
                                        <div className="text-align-center">{getTimeString(openChart.open_for_millis)}</div>
                                    </Cell>
                                );
                            }}
                            width={columnWidths.elapsedTimeOpen}
                            isResizable={false}
                            />
                        <Column
                            columnKey="actions"
                            header={<Cell>
                                        <div className="text-align-center">Actions</div>
                                    </Cell>}
                            cell={ ({rowIndex, data, columnKey, ...props}) => {
                                let openChart = dataList[rowIndex];

                                return (
                                    <Cell {...props}>
                                        <div className="text-align-center">
                                            <Button
                                                bsStyle="danger"
                                                type="button"
                                                style={{ marginLeft: "10px" }}
                                                onClick={ this.handleRequestChartClosure.bind( this, openChart ) }>
                                                Request chart closure
                                            </Button>
                                        </div>
                                    </Cell>
                                );
                            }}
                            width={columnWidths.actions}
                            isResizable={false}
                            />
                    </ResponsiveFixedDataTable>
                </div>
            );
        }

        return (
            <div>
                <PageHeader>
                    Open Charts
                </PageHeader>
                <Panel style={{marginTop:10}} header={(
                    <div>
                        Open Charts
                        <Button onClick={this._getData.bind(this)}
                                style={{marginLeft: 15}}
                                bsSize="xsmall"
                                bsStyle="primary">
                            <i className="fa fa-refresh"
                                title="Refresh"></i>
                        </Button>
                    </div>
                )}>
                    {body}
                </Panel>

                <AdminCloseChartPopup show={this.state.showCloseChartPopup}
                                      onPopupHide={this.onCloseChartPopupHide.bind(this)}
                                      openChart={this.state.openChart}
                />
                <PatientChartClosureRequestedDataModal
                    patient={ this.state.openChart && this.state.openChart.active_patient_id }
                    show={ this.state.showPatientDataModal }
                    onHide={ () => this.setState({ showPatientDataModal: false }) }
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    openCharts: state.app.openCharts,
    'userId': state.auth.id,
    chartClosureRequested: state.admin.chartClosureRequested,
});

const mapDispatchToProps = (dispatch) => ({
    adminActions: bindActionCreators(adminActionCreator, dispatch),
    commonActions: bindActionCreators(commonActionCreator, dispatch),
    appActions:bindActionCreators(appActionCreator,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenCharts);
