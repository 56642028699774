import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as adminActionCreator from '../../actions/admin';
import { Modal, Button } from 'react-bootstrap';

class NotificationOfChartClosureRequestModal extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {};
    }

    render() {
        return (
            <Modal show={ this.props.show } onHide={ this.props.onConfirm }>
                <Modal.Header closeButton>
                    <Modal.Title>Chart Closure Requested</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-align-center">
                        <p>We are really sorry but the patient has called back and we needed to access the patient's chart. You have 2 minutes to complete documentation before you will be logged out of the chart.</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="primary" onClick={ this.props.onConfirm }>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = ( dispatch ) => ({
    adminActions: bindActionCreators( adminActionCreator, dispatch ),
});

export default connect( mapStateToProps, mapDispatchToProps )( NotificationOfChartClosureRequestModal );
