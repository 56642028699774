import React from 'react';
import {Panel,Input,Button,OverlayTrigger,Popover} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';

class CarePlanComp extends React.Component {
    render(){
        const {onPlanSelected} = this.props;
        const data = this.props.carePlan;
        let backgroundColor = '';
        let border = 'unset'
        let statusText = '';
        if(data.engagement_count == 0){
            backgroundColor = 'lightgray'
            statusText = `New: ${moment(data.created_ts).format('MM-DD-YYYY')}`
        }else{
            if(data.gaps_care_plan_status == 'In Progress'){
                backgroundColor = 'rgb(220,228,239)'
                border = '1px solid rgb(115,148,189)'
                statusText = `Open: ${moment(data.status_updated_ts).format('MM-DD-YYYY')}`
            }else if(data.gaps_care_plan_status == 'Complete'){
                backgroundColor = 'rgb(218,228,214)'
                border = '1px solid rgb(159,193,143)'
                statusText = `Complete: ${moment(data.status_updated_ts).format('MM-DD-YYYY')}`
            }else if(data.gaps_care_plan_status == 'Closed'){
                backgroundColor = 'rgb(237,218,218)'
                border = '1px solid rgb(211,128,121)'
                statusText = `Closed Out: ${moment(data.status_updated_ts).format('MM-DD-YYYY')}`
            }
        }
        return (
         <div key={data.id} style={{
                    display:'flex',
                    justifyContent:'space-between',
                    backgroundColor:backgroundColor,
                    border:border,
                    alignItems:'center',
                    cursor:'pointer',
                    fontWeight:400,
                    margin:15,
                    padding:15}} onClick={()=>onPlanSelected(data)}>
            <div style={{display:'flex',flex:1}}>{data.gaps_care_plan_type}</div>
            <div style={{display:'flex',flex:1}}>Created: {moment(data.created_ts).format('MM-DD-YYYY')}</div>
             <div style={{display:'flex',flex:1}}>{statusText}</div>
            <div>{data.engagement_count} Engagements</div>
        </div>
        )
    }
}
export default CarePlanComp;
