import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const PatientSatisfactionPreferenceModal = ({ show, onHide, onConfirm, patient, inputStyle, updateComponent }) =>
	<Modal show={show} onHide={onHide}>
		<Modal.Header closeButton>
			<Modal.Title>Patient Satisfaction</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<div>
				<h5>Patient Satisfaction Preference</h5>
				<select style={ inputStyle } defaultValue={patient.show_nps === true ? 1 : 0} onChange={updateComponent}>
					<option value={1}>Display Patient Satisfaction Survey</option>
					<option value={0}>Do not display Patient Satisfaction Survey</option>
				</select>
			</div>
		</Modal.Body>
		<Modal.Footer>
			<Button bsStyle="danger" style={{float: 'left'}} onClick={onHide}>
				Cancel
			</Button>
			<Button bsStyle="success" onClick={onConfirm}>
				Save
			</Button>
		</Modal.Footer>
	</Modal>;

export default PatientSatisfactionPreferenceModal;
