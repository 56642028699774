import React from 'react';
import _ from 'lodash';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

export default class CSPanel extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    let titleClassName = 'csPanelTitleRowHeading ';
    if (this.props.titleClassName) {
      titleClassName += this.props.titleClassName;
    }

    let bodyClassName = 'csPanelBody ';
    if (this.props.bodyClassName) {
      bodyClassName += this.props.bodyClassName;
    }

    return (
        <div className="csPanel">
            <div className="csPanelTitleRow">
                <p className={titleClassName}>{this.props.titleText}</p>
            </div>
            <div className={bodyClassName}>
                {this.props.children}
            </div>
        </div>
    );
  }
}