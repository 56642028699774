import React from 'react';
import PatientQueue from './PatientQueue';

const UrgentQueueRequestedInfo = () =>
	<PatientQueue
		showQueueCount
		patientNameColumn
		providerDetailColumn
		timeBilledColumn
		todoItemColumn
		showFilters
		showProgramTypeFilter
        showScheduleCallFilter
		statusColumn
		chartColumn
		lastWorkedByColumn
		lastOutCallColumn
		callAttemptsCol
		showSort
		sortOptions={[
			{
				'name': 'Last Called Ascending',
				'key': 'lastOutCallTs&dir=asc',
			},
			{
				'name': 'Last Called Descending',
				'key': 'lastOutCallTs&dir=desc',
			}
		]}
		page
		queue="urgentRequestedInfo"
		endpoint="Urgent?typesToInclude=10"
		label="Urgent - Requested Info"
	/>;

export default UrgentQueueRequestedInfo;
